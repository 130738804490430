import { indexColumnId } from "./../../data/DbStore";
import { Column as TableColumn } from "react-table";
import { TableId } from "../../data/DbStore";
import { tableColumns, tableRows } from "../../data/DbTables";
import { cellRenderer, RowsAndColumns, SimpleRow } from "./UseRowsAndColumns";

const maxColumns = 20;
export async function localRowsAndColumns(tableId: TableId): Promise<RowsAndColumns> {
  const promisedColumns = loadColumns(tableId);
  const promisedRows = loadRows(tableId);
  const [allRows, allColumns] = await Promise.all([promisedRows, promisedColumns]);

  // protect the sheet from hanging with large numbers of columns
  // LATER do something cleverer if we have more than maxColumns to display
  const columns = allColumns.slice(0, maxColumns);
  const rows = allRows.map((row) => ({ values: row.values.slice(0, maxColumns) }));

  return {
    rows,
    columns,
  };
}

async function loadColumns(tableId: TableId): Promise<TableColumn<SimpleRow>[]> {
  return tableColumns(tableId).then((colInfos) => {
    const tableColumns = colInfos.map((info, idx) => {
      const col: TableColumn<SimpleRow> = {
        Header: info.label,
        accessor: (row) => row.values[idx],
        Cell: cellRenderer(info.displayType),
      };
      if (info.columnId === indexColumnId) {
        col.width = 60; // looks better if this column isn't too wide
      }
      col.id = info.columnId.toString(); // unused for now
      (col as any).columnId = info.columnId;
      return col;
    });
    return tableColumns;
  });
}

async function loadRows(tableId: TableId): Promise<SimpleRow[]> {
  const rowData = await tableRows(tableId);
  const rows = rowData.map((row) => ({
    values: row.map((elem) => elem.toString()),
  }));
  return rows;
}
