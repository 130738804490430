import { useCallback } from "react";
import { ColumnId } from "../../data/ColumnFrame";
import { ColumnChunk } from "../../data/DataChunk";
import { addColumnChunks, addColumns } from "../../data/DbColumns";
import { likelyPlotColumns, newColumnTable } from "../../data/DbTables";
import { loadTabularFile } from "../../parse/LoadTabularFile";
import { ChartLoadingProgress, LoadingProgress } from "../../store/AppView";
import { DashId } from "../../store/DashId";
import { useStoreActions } from "../../store/Hooks";
import { LoadingTableSource, LocalTableSource } from "../../store/TableSource";
import { fileNoSuffix } from "../../util/PathUtil";
import { zip2tuple } from "../../util/Utils";

/** @return a function to load a file into chart */
export function useLoadFile(): (file: File, dashId: DashId) => Promise<void> {
  const modifyChartById = useStoreActions((app) => app.modifyChartById);
  const setLoadingProgress = useStoreActions((app) => app.view.setLoadingProgress);

  const loadFile = useCallback(
    async (file: File, dashId: DashId): Promise<void> => {
      const proposedName = fileNoSuffix(file.name);
      const { tableId, name } = await newColumnTable(proposedName);
      const loadingSource: LoadingTableSource = {
        kind: "loading",
        name: name,
      };
      const progress: LoadingProgress = {
        loadedBytes: 0,
        totalBytes: file.size,
      };
      const chartLoadingProgress: ChartLoadingProgress = {
        dashId,
        progress,
      };
      setLoadingProgress(chartLoadingProgress);
      const title = fileNoSuffix(name);

      modifyChartById({
        dashId,
        chartPartial: {
          title,
          tableSource: loadingSource,
          columnSet: undefined,
          zoom: undefined,
        },
      });
      let columnIds: ColumnId[] | undefined = undefined;
      await loadTabularFile(file, saveSomeChunks);

      const columnSet = await likelyPlotColumns(tableId);
      const tableSource: LocalTableSource = {
        kind: "local",
        name,
        tableId,
      };
      modifyChartById({ dashId, chartPartial: { tableSource, columnSet } });
      setLoadingProgress({ dashId, progress: undefined });

      /** Called once for each section of rows in the file. */
      async function saveSomeChunks(
        loadedChunks: ColumnChunk[],
        bytesRead: number
      ): Promise<void> {
        const currentProgress: LoadingProgress = { ...progress, loadedBytes: bytesRead };
        setLoadingProgress({ dashId, progress: currentProgress });

        if (!columnIds) {
          // create columns with the first chunk from columns
          columnIds = await addColumns(tableId, loadedChunks);
        }

        const chunksWithIds = zip2tuple(loadedChunks, columnIds).map(
          ([chunk, columnId]) => ({
            chunk,
            columnId,
          })
        );

        return addColumnChunks(chunksWithIds);
      }
    },
    [modifyChartById, setLoadingProgress]
  );

  return loadFile;
}

export function useLoadFailed(dashId: DashId): (message: string) => void {
  const setLoadingProgress = useStoreActions((app) => app.view.setLoadingProgress),
    modifyView = useStoreActions((app) => app.modifyView);

  return useCallback(
    (message: string) => {
      setLoadingProgress({ dashId, progress: undefined });
      modifyView({ notificationMessage: { message } });
    },
    [modifyView, setLoadingProgress, dashId]
  );
}
