import _ from "lodash";
import { UnaryFn } from "./SplineMath";

/** Collect equally spaced values from the domain [0, 1].
 * @returns equally spaced values, including 0 and 1 if there are at least two samples
 */
export function unitPartitions(count: number): number[] {
  if (!count) {
    return [];
  }
  if (count === 1) {
    return [0];
  }
  return _.times(count).map((i) => i / (count - 1));
}

/** A table interpolator based on cached table of x,y values*/
export interface TableInterpolator {
  /** sample interpolated values the specified x coordinates */
  samples: (xs: number[]) => number[];

  /** Return an TableInterpolator with x and y inverted */
  invert: () => TableInterpolator;

  /** return the underlying table */
  table: () => [number[], number[]];
}

/** Prefill a table of values for interpolation sampling.
 * Table values are sampled at regular spaced intervals in the domain [0,1]
 *
 * @param samples number of values to sample
 * @param fn function to sample in the domain [0,1]
 */
export function tableInterpolator(fn: UnaryFn, samples: number): TableInterpolator {
  const tableX = unitPartitions(samples);
  const tableY = tableX.map(fn);
  return createTable(tableX, tableY);
}

function createTable(tableX: number[], tableY: number[]): TableInterpolator {
  return {
    samples: (xs: number[]) => sampleTable(xs, tableX, tableY),
    invert: () => createTable(tableY, tableX), // we should really sort in case Y is not monotonic
    table: () => [tableX, tableY],
  };
}

/** sample one or more interpolated values from a table of X and Y coordinates.
 * X coordinates must be in sorted order.
 */
export function sampleTable(xs: number[], tableX: number[], tableY: number[]): number[] {
  let cursor = 0;
  if (!tableY.length || !tableX.length) {
    return [];
  }
  console.assert(
    tableX.length === tableY.length,
    "tableApproximate columns not same size",
    tableX,
    tableY
  );
  const lastY = _.last(tableY)!;

  return xs.map((x) => {
    const nextDex = _.findIndex(tableX, (tX) => tX >= x, cursor);
    if (nextDex === -1) {
      return lastY;
    }
    cursor = nextDex;
    const nextY = tableY[nextDex];
    if (nextDex === 0) {
      return nextY;
    } else {
      const prevDex = nextDex - 1;
      const prevX = tableX[prevDex];
      const nextX = tableX[nextDex];
      const prevY = tableY[prevDex];
      const t = (x - prevX) / (nextX - prevX);
      const value = prevY + t * (nextY - prevY);
      return value;
    }
  });
}
