import React, { CSSProperties } from "react";
import { RenderElementProps, RenderLeafProps } from "slate-react";

export function TextElement(props: RenderElementProps): JSX.Element {
  const { element, attributes, children } = props;
  const { indentLevel } = element;
  const style = indentStyle(indentLevel as number);

  const attr = { ...attributes, style };

  switch (element.kind) {
    case "block-quote":
      return <blockquote {...attr}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attr}>{children}</ul>;
    case "heading-1":
      return <h1 {...attr}>{children}</h1>;
    case "heading-2":
      return <h2 {...attr}>{children}</h2>;
    case "list-item":
      return <li {...attr}>{children}</li>;
    case "numbered-list":
      return <ol {...attr}>{children}</ol>;
    default:
      return <p {...attr}>{children}</p>;
  }
}

function indentStyle(level: number | undefined): CSSProperties {
  if (level && level > 0) {
    const padSize = level * 1.5;
    const pad = `${padSize}rem`;
    return { marginLeft: pad };
  } else {
    return {};
  }
}

export function TextLeaf(props: RenderLeafProps): JSX.Element {
  const { leaf, attributes } = props;
  let { children } = props;

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
}
