import { Action, action } from "easy-peasy";

export interface DataSourcesModel {
  enabledSources: EnabledDataSources;
  setEnabledSource: Action<DataSourcesModel, Partial<EnabledDataSources>>;
  listenForConnections: boolean;
}

export interface EnabledDataSources {
  generated: boolean;
  generatedStream: boolean;
  local: boolean;
  external: boolean;
}

const setEnabledSource = action<DataSourcesModel, Partial<EnabledDataSources>>(
  (state, enabled) => {
    const merge = Object.assign({}, state.enabledSources, enabled); // only one plot per chart for now
    state.enabledSources = merge;
  }
);

export const dataStoreModel: DataSourcesModel = {
  enabledSources: {
    generatedStream: true,
    generated: true,
    local: true,
    external: true,
  },
  setEnabledSource,
  listenForConnections: true, // TODO for debug
};
