import { useCallback } from "react";
import { Descendant as SlateDescendant } from "slate";
import { useUndoGroup } from "undo-peasy";
import { DashTextModel } from "../../store/DashItems";
import { useStoreActions } from "../../store/Hooks";

export function useAddRichText(): () => void {
  const addItem = useStoreActions((app) => app.addDashItem);
  const undoGroup = useUndoGroup();

  const addText = useCallback(
    () =>
      undoGroup(() => {
        const richText: SlateDescendant[] = [
          { kind: "paragraph", children: [{ text: "" }] },
        ];

        const dashText: Omit<DashTextModel, "id"> = {
          kind: "Text",
          richText,
          autoGrow: true,
        };

        addItem({ item: dashText, width: 50, height: 36 });
        // scrollToItem(richText.id);
      }),
    [addItem, undoGroup]
  );

  return addText;
}
