import React, { useCallback } from "react";
import { saveIntoTable } from "../../data/DbTables";
import { parseTabularText } from "../../parse/ParseTabular";
import { DashId } from "../../store/DashId";
import { useStoreActions } from "../../store/Hooks";
import { ExternalTableSource, TableSource } from "../../store/TableSource";
import { fileNoSuffix } from "../../util/PathUtil";
import { asURL } from "../../util/StringUtil";
import { pasteTextFormat } from "../dash-data/usePasteData";

export interface ChartPasteProps {
  dashId: DashId;
}

/**  handle pasting text atop a chart. */
export function useChartPaste(props: ChartPasteProps): (e: React.ClipboardEvent) => void {
  const { dashId } = props;
  const setTableSource = useStoreActions((app) => app.setTableSource);
  const modifyChart = useStoreActions((app) => app.modifyChartById);

  const saveTabularText = useCallback(
    async (text: string, sourceParams: Partial<TableSource>) => {
      const chunks = parseTabularText(text);
      const tableResult = await saveIntoTable(chunks, "pasted", "forceNew");
      const { tableId, name } = tableResult;
      const tableSource: TableSource = {
        kind: "local",
        name,
        tableId,
      };
      Object.assign(tableSource, sourceParams);

      setTableSource({ dashId, tableSource, columnSet: undefined });
      modifyChart({ dashId, chartPartial: { title: name } });
    },
    [dashId, setTableSource, modifyChart]
  );

  const onPaste = useCallback(
    async (e: React.ClipboardEvent) => {
      const hasText = e.clipboardData.types.includes(pasteTextFormat);
      if (hasText) {
        const text = e.clipboardData.getData(pasteTextFormat);
        const url = asURL(text);

        if (url) {
          const tableSource: ExternalTableSource = {
            kind: "external",
            name: fileNoSuffix(url.pathname),
            url: url.toString(),
          };
          setTableSource({ dashId, tableSource, columnSet: undefined });
        } else {
          saveTabularText(text, {});
        }
      }
      e.stopPropagation();
    },
    [dashId, saveTabularText, setTableSource]
  );

  return onPaste;
}
