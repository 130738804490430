import { Framebuffer2D, Regl, Texture2D } from "regl";
import * as ReglPerf from "./ReglPerf";
import { fullScreenTriangles } from "./WebGLUtil";

export interface MeanProps {
  /** source density buffer (we currently only read the red channel) */
  density: Texture2D;

  /** output texture 1 x width. red channel contains the mean, green is the sum */
  means: Framebuffer2D;
}

export interface MeanCmd {
  (props: MeanProps): void;
}

type MeanCmdProps = MeanProps;

export function meanCmd(regl: Regl, height: number): MeanCmd {
  const cmd = regl({
    vert: `
        precision highp float;
        attribute vec2 position;
        varying float u;
            
        void main() {
          gl_Position = vec4(position, 1.0, 1.0); // position range (-1, -1)
          u = 0.5 * (position.x + 1.0);           // uv range (0, 1) 
        }`,
    frag: `
        precision highp float;
        varying float u; // texel coordinate in src texture 
        uniform sampler2D density;

        void main() { 
          float dv = 1.0 / ${height}.0;             // advance by one texel each time
          float v = dv / 2.0;                       // start on the center of the first texel
          float sum = 0.0;
          float weightedSum = 0.0;
          for (int i = 0; i < ${height}; i++) {
            float density = texture2D(density, vec2(u, v)).r;
            weightedSum += density * v;
            sum += density;

            v += dv; 
          }
          float weightedMean = weightedSum / sum;   // NaN if sum is zero
          gl_FragColor = vec4(u, weightedMean, 0.0, 1.0);
        }`,
    uniforms: {
      density: (_ctx, props: MeanCmdProps) => props.density,
      means: (_ctx, props: MeanCmdProps) => props.means,
    },
    attributes: {
      position: fullScreenTriangles,
    },
    framebuffer: (_ctx: any, props: MeanCmdProps) => props.means,
    depth: { enable: false, mask: false },
    primitive: "triangles",
    count: 6,
  });

  ReglPerf.registerCmd(cmd, "mean");

  return function draw(props: MeanProps): void {
    cmd(props);
  };
}
