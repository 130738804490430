import _ from "lodash";
import { matrix, Matrix } from "mathjs";
import regression from "regression";
import { MakePolynomialFn } from "./PolynomialFunction";
import { ParametricFn, UnaryFn } from "./SplineMath";
import { unitPartitions } from "./TableInterpolator";
import { Vec2 } from "./Vec";

/** Approximate a series of points with a polynomial
 *
 * @return a 1 dimensional array of coefficients
 */
export function polynomialRegression(points: Vec2[], order = 3): Matrix {
  const regress = regression.polynomial(points, { order });
  return matrix(regress.equation.reverse());
}

/** Approximate a series of points with a polynomial
 *
 * @return the polynomial function.
 */
export function polynomialRegressionFn(points: Vec2[], order = 3): UnaryFn {
  const coefficients = polynomialRegression(points, order);
  return MakePolynomialFn(coefficients.toArray() as number[]);
}

interface ParametricRegressionResult {
  polynomialCoeff: Matrix;
  maxX: number;
}
/** Approximiate a parametric function that returns a 2d vector for each t in the range [0,1]
 * with a polynomial function that returns a y for each x.
 * @return the coefficients of the polynomial
 */
export function parametricToPolynomial(
  curve: ParametricFn,
  regressionSamples = 100,
  polynomialDegree = 3
): ParametricRegressionResult {
  const curvePoints = arrayFromParametric(curve, regressionSamples);
  const polynomialCoeff = polynomialRegression(curvePoints, polynomialDegree);
  const maxX = _.last(curvePoints)![0];

  return {
    polynomialCoeff,
    maxX,
  };
}

export function arrayFromParametric(fn: ParametricFn, length: number): Vec2[] {
  return unitPartitions(length).map((t) => fn(t));
}
