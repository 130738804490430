import { FormControlLabel, Switch, SwitchProps } from "@material-ui/core";
import React from "react";

interface LabeledSwitchProps<T extends string> extends SwitchProps {
  label: T;
}

export function LabeledSwitch<T extends string = string>(
  props: LabeledSwitchProps<T>
): JSX.Element {
  const { label, className, ...switchProps } = props;

  return (
    <FormControlLabel
      {...{ className, label }}
      control={<Switch color="primary" {...switchProps} />}
    />
  );
}
