import { DateTime } from "luxon";

enum DateFormat {
  "tt" = "tt", // 1:07:04 PM
  "MMM/dd/yyyy h:mm a" = "MMM/dd/yyyy h:mm a",
  "MM/dd/yy h:mm a" = "MM/dd/yy h:mm a",
  "M/d/yyyy" = "M/d/yyyy",
  "MM/dd/yyyy TT" = "MM/dd/yyyy TT",
  "yyyyQq" = "yyyyQq",
  "MMM dd, yyyy, tt" = "MMM dd, yyyy, tt",
  "HH:mm:ss:SSS" = "HH:mm:ss:SSS",
}

export type CustomDateFormat = `${DateFormat}`;

export function detectCustomDateFormat(text: string): CustomDateFormat | undefined {
  const dateFormats = Object.values(DateFormat);
  for (const format of dateFormats) {
    if (DateTime.fromFormat(text, format).isValid) {
      return format;
    }
    // DateTime.fromFormat(text, format).invalidExplanation; //?
  }
  return undefined;
}

const parsers = new Map<CustomDateFormat, (text: string) => number>();

export function customDateParser(format: CustomDateFormat): (text: string) => number {
  const found = parsers.get(format);
  if (found) {
    return found;
  }
  const parser = (text: string): number =>
    DateTime.fromFormat(text, format, { setZone: true }).toMillis();

  parsers.set(format, parser);
  return parser;
}
