import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";
import React, { useCallback } from "react";

interface LabeledCheckboxProps<T extends string> extends CheckboxProps {
  label: T;
  onChangeValue: (label: T, value: boolean) => void;
}

export const LabeledCheckBox = React.memo(
  LabeledCheckBoxInternal
) as typeof LabeledCheckBoxInternal;

export function LabeledCheckBoxInternal<T extends string = string>(
  props: LabeledCheckboxProps<T>
): JSX.Element {
  const { label, className, onChangeValue, ...checkboxProps } = props;

  const onChange = useCallback(
    (_e: any, checked: boolean) => {
      onChangeValue && onChangeValue(label, checked);
    },
    [onChangeValue, label]
  );

  return (
    <FormControlLabel
      {...{ className, label, onChange }}
      value={label}
      labelPlacement="end"
      control={<Checkbox {...checkboxProps} />}
    />
  );
}
