import { DateTime } from "luxon";
import {
  CustomDateFormat,
  customDateParser,
  detectCustomDateFormat,
} from "./CustomDateFormats";

export type DateFormat = "sql" | "iso" | "rfc2822" | "http" | CustomDateFormat;

export type DateParser = (dateString: string) => number;

const positiveInteger = /^\d*$/;

export function detectDate(text: string): DateFormat | undefined {
  if (positiveInteger.test(text)) {
    return undefined;
  } else if (DateTime.fromISO(text).isValid) {
    return "iso";
  } else if (DateTime.fromSQL(text).isValid) {
    return "sql";
  } else if (DateTime.fromHTTP(text).isValid) {
    return "http";
  } else if (DateTime.fromRFC2822(text).isValid) {
    return "rfc2822";
  } else {
    return detectCustomDateFormat(text);
  }
}

const isoParser = (text: string): number =>
  DateTime.fromISO(text, { setZone: true }).toMillis();
const rfcParser = (text: string): number =>
  DateTime.fromRFC2822(text, { setZone: true }).toMillis();
const sqlParser = (text: string): number =>
  DateTime.fromSQL(text, { setZone: true }).toMillis();
const httpParser = (text: string): number =>
  DateTime.fromHTTP(text, { setZone: true }).toMillis();

export function getDateParser(dateFormat: DateFormat): DateParser {
  if (dateFormat === "iso") {
    return isoParser;
  } else if (dateFormat === "sql") {
    return sqlParser;
  } else if (dateFormat === "http") {
    return httpParser;
  } else if (dateFormat === "rfc2822") {
    return rfcParser;
  } else {
    return customDateParser(dateFormat);
  }
}
