import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { columnFrame } from "../../data/ColumnFrame";
import { watchData } from "../../data/WatchData";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import {
  ColumnSet,
  noTableSource,
  SourceColumns,
  TableSource,
} from "../../store/TableSource";
import { useIsMounted } from "../../util/ReactUtil";
import { ChartFrame, FrameAndColumns } from "./../rich-chart/DashChart";
import { asyncLoop } from "./AsyncLoop";

interface FrameColumnsSource extends FrameAndColumns {
  source: TableSource;
}

const emptyState: FrameColumnsSource = {
  chartFrame: { frame: columnFrame() },
  columnSet: undefined,
  source: noTableSource,
};

const emptySourceColumns: SourceColumns = {
  columnSet: undefined,
  source: noTableSource,
};

/** @return a ChartFrame for a given chart.
 * Also sets the columnSet in the chart if none is specified
 */
export function useLoadedData(dashId: DashId): FrameAndColumns {
  const isMounted = useIsMounted(),
    /** current source in the chart model */
    modelSource = useStoreState(
      (app) => app.findChart(dashId)?.tableSource || noTableSource
    ),
    columnSet = useStoreState((app) => app.findChart(dashId)?.columnSet),
    /* source that we will load (data may not yet be ready to return) */
    [requestedSC, setRequested] = useState<SourceColumns>(emptySourceColumns),
    /* result frame and source that have been loaded. 
       returned to the caller to trigger a chart redraw with new data. */
    [result, setResult] = useState<FrameColumnsSource>(emptyState),
    /* Reference to latest request. The reference the latest version available
      even from an async loop launched in a previous render. */
    latestRequest = useRef<TableSource>(),
    modifyChartById = useStoreActions((app) => app.modifyChartById);

  /* update the chart columnSet, and reset zoom as appropriate */
  const updateChartModel = useCallback(
    (columnSet: ColumnSet | undefined): void => {
      if (!_.isEqual(result.source, requestedSC.source)) {
        // if we're changing data sources (vs. reloading for the first time)
        modifyChartById({
          dashId,
          chartPartial: { zoom: undefined, columnSet },
        });
      } else {
        modifyChartById({
          dashId,
          chartPartial: { columnSet },
        });
      }
    },
    [modifyChartById, result.source, requestedSC.source, dashId]
  );

  // update the chart metadata, and load the data into the chart as it arrives.
  const doWatchData = useCallback(
    (modelSC: SourceColumns): void => {
      watchData(modelSC)
        .then(async function frameStreamLoaded(frameStreamAndColumns) {
          const { columnSet, frameStream } = frameStreamAndColumns;
          updateChartModel(columnSet);
          asyncLoop(frameStream, stopStream, (chartFrame: ChartFrame): void => {
            // we've received new data, so modify this component's state so that
            // it re-renders with the new chartFrame
            setResult({ columnSet, chartFrame, source: modelSC.source });
          });

          /* stop stream if unmounted or no longer showing the same source */
          function stopStream(): boolean {
            return (
              !isMounted ||
              (latestRequest.current !== undefined &&
                latestRequest.current !== modelSC.source)
            );
          }
        })
        .catch((e) => {
          console.log(e);
          updateChartModel(undefined);
        });
    },
    [setResult, updateChartModel, isMounted]
  );

  // fetch data if the model now references a different data source
  useEffect(() => {
    const modelSC: SourceColumns = { source: modelSource, columnSet };
    latestRequest.current = requestedSC.source; // TODO try setting this with setRequested below

    if (!sameSource(modelSC, requestedSC)) {
      setRequested(modelSC);
      doWatchData(modelSC);
    }
  }, [result, setRequested, doWatchData, columnSet, modelSource, requestedSC]);

  return result;
}

function sameSource(a: SourceColumns, b: SourceColumns): boolean {
  // sources should match
  // columnsets should match if defined, and if columnset unset === undefined
  return _.isEqual(a.source, b.source) && _.isEqual(a.columnSet, b.columnSet);
}
