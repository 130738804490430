import { mat3 } from "gl-matrix";
import _ from "lodash";
import { spaces } from "./DebugLog";

export function mat3toString(mat: mat3, precision = 2): string {
  const strings = [...mat.values()].map((n) => roundNum(n, precision));
  const lengths = strings.map((s) => s.length);
  const cellSize = (_.max(lengths) || 0) + 2;
  const padded = strings.map((s) => fixWidth(s, cellSize));

  const rows = _.range(0, 3).map((r) => {
    const cols = padded.slice(r * 3, r * 3 + 3);
    return cols.join(" ");
  });
  return rows.join("\n");
}

export function fixWidth(s: string, targetSize = 8): string {
  if (s.length < targetSize) {
    const pad = targetSize - s.length;
    return spaces(pad) + s;
  } else if (s.length > targetSize) {
    return s.slice(0, targetSize - 1) + "~";
  }
  return s;
}

export function roundNum(n: number, fractionDigits = 1): string {
  const fixed = +n.toFixed(fractionDigits);
  return fixed.toString();
}

export function asURL(text: string): URL | undefined {
  if (text.includes("\n")) {
    return undefined;
  }
  let line = text;

  // copy and paste from browser url bar doesn't include protocol prefix, 
  // so we try adding it here if it seems reasonable
  if (!line.startsWith("http://") && !line.startsWith("https://")) {
    if (!line.includes("/")) {
      return undefined;
    }
    line = "http://" + text;
  }
  try {
    return new URL(line);
  } catch (e) {
    return undefined;
  }
}
