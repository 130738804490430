import React from "react";
import { HeatLinesPlot } from "../../chart/PlotModels";
import { useCurrentPlot, useModifyPlot, useStoreState } from "../../store/Hooks";
import { LabeledSlider } from "./LabeledSlider";

export const FeatherSettings = React.memo(FeatherSettingsInternal);

function FeatherSettingsInternal(): JSX.Element {
  const enabled = useStoreState((state) => state.debug.adjustableFeather);
  const feather = useCurrentPlot((p: HeatLinesPlot) => p.feather);
  const setFeather = useModifyPlot("feather") as (event: any) => void;

  if (enabled) {
    return (
      <LabeledSlider
        label="feather:"
        min={0}
        max={4}
        step={0.25}
        value={feather!}
        onChange={setFeather}
      />
    );
  } else {
    return <></>;
  }
}
