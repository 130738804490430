import { mat3 } from "gl-matrix";
import { DefaultContext, Framebuffer2D, Regl, Texture2D } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { Rectangle } from "../chart/Rectangle";
import { Vec2, Vec3, Vec4 } from "../math/Vec";
import { pairs } from "../util/Utils";
import {
  blendAndProjection,
  BlendSetupArgs,
  FixedProjectionArgs,
  fixedProjectionCmd,
} from "./BlendAndProjection";
import { endCapVertices } from "./EndCap";
import { LineBuffers } from "./LineBuffers";
import * as ReglPerf from "./ReglPerf";
import { ZoomRenderProps } from "./WebGLUtil";

/** external interface to draw() for thick lines */
export interface ThickLineProps extends ZoomRenderProps {
  width: number; // width of the line (including the feather)
  feather: number; // number of pixels to alpha blend on each side of the line
  color?: Vec3; // rgb color (0-255)
  joins?: boolean; // true to include round endcap/joins
  textureVerts?: Texture2D; // set vertices' xy coordinates according to texture (in (0,1) texture coords)
  lineBuffers: LineBuffers; // line vertex data
}

/** internal interface to cmd for drawing thick lines */
interface CmdProps {
  feather: number;
  halfWidth: number;
  featheredHalf: number;
  vertices: Vec4[];
  color: Vec3;
  textureVertsEnabled: boolean;
  textureVerts: Texture2D;
  lineBuffers: LineBuffers;
}

const defaultColor: Vec3 = [62, 12, 83];

export interface ThickLineFn {
  (props: ThickLineProps): void;
}

export interface ThickLinesArgs {
  regl: Regl;

  /** output framebuffer */
  framebuffer: Framebuffer2D | null;

  /** clip lines that are entirely outside left or right of this region of the canvas */
  plotRect: Rectangle;

  /** size of the pixel buffer (i.e. canvasSize) */
  pixelsSize: [number, number];

  /** @param normX scale function for vertices
   * (to increase dynamic range with 32 bit floats in gl) */
  normX: (x: number) => number;

  debug: ChartDebug;

  /** instead of zooming and scaling according to d3 x,y scales, just use this projection matrix
   * used for drawing summary statistic lines which are already in webgl coordinates */
  fixedProjection?: mat3;

  /** extra parameters passed to regl command */
  cmdParams?: Record<string, unknown>;
}

/**
 * Setup command to draw thick antialiased lines with round joins.
 *
 * @returns a draw function. The same data is drawn each time, but
 * parameters like zoom, color, and line width may be changed with each draw.
 */
export function ThickLinesCmd(args: ThickLinesArgs): ThickLineFn {
  const { regl, pixelsSize, plotRect, fixedProjection, cmdParams = {}, debug } = args;
  const blendArgs: BlendSetupArgs = { ...args, blendType: "alpha" };
  const fixedArgs: FixedProjectionArgs = {
    // for statline drawing
    regl,
    projection: fixedProjection!,
    blendType: "alpha",
    debug,
  };
  const dummyTexture = regl.texture();

  const baseCmd = fixedProjection
    ? fixedProjectionCmd(fixedArgs)
    : blendAndProjection(blendArgs);
  const clipRect = webglClipRect(plotRect, pixelsSize);

  const lineCmd = regl({
    vert: `
      precision highp float;

      uniform mat3 projection;     // convert from world (x,y) to webgl (-1,1) coordinates
      uniform vec2 canvasSize;     // size of canvas in pixels.
      uniform float featheredHalf; // distance from line to vertice in canvas pixels. (line width) / 2 + feather width. 

      uniform float clipLeft;      // clip lines that are wholly too far left or right
      uniform float clipRight;     // 
      uniform float clipTop;       // top and bottom clipping is currently ignored
      uniform float clipBottom;    // 

      uniform sampler2D textureVerts;     // offset each y by this amount in texture coords (for drawing e.g. mean stat line)
      uniform bool textureVertsEnabled;   // enable textureVertss

      attribute vec4 segment;   // flags to identify and offset vertices 
      attribute float x;        // vertex A in world coordinates
      attribute float y;
      attribute float x2;       // vertex B in world coordinates
      attribute float y2;

      varying float signedDist; // signed distance to line in canvas pixels (signed to enable interpolation)

      bool clip(vec2 v) {
        return v.x > clipRight || v.x < clipLeft; 
      }

      /* given position of line segment endpoints in webl coords, 
       * return the position for this triangle vertex in the thick line */
      void lineVertex(vec2 a, vec2 b, out vec4 position) {
        vec2 lineGl = b - a;

        // pixel space x: (-w/2, +w/2), y:(-h/2, +h/2). 
        // 1.0 is one screen pixel wide or high. Screen center is at 0,0.
        vec2 line = lineGl * canvasSize / 2.0;                 // line in pixel space
        vec2 unitLine = normalize(line);                    
        vec2 unitNormal = vec2(-unitLine.y, unitLine.x);       // left perpendicular in pixel space
        vec2 horizOffset = unitLine * featheredHalf * segment[1];
        vec2 vertOffset = unitNormal * featheredHalf * segment[2];    
        vec2 pixelOffset = vertOffset + horizOffset;           // offset to vertex for line thickness in pixel space

        // webgl coordinates
        vec2 pixelOffsetGl = pixelOffset / canvasSize * 2.0;   // offset to vertex for line thickness in webl 
        vec2 aOrB = a + lineGl * segment[0];                   // vertex a or b, depending on sequence
        vec2 spot = aOrB + pixelOffsetGl;                      // vertex in webgl coordinates

        position = vec4(spot, 0.0, 1.0);
      }
      
      // use texture to set ednpoint coordinates (used for stats line)
      void textureControl(inout vec2 a, inout vec2 b) {
        if (textureVertsEnabled) {
          float au = (a.x + 1.0) / 2.0;                        // back to texture coords
          vec2 at = texture2D(textureVerts, vec2(au, .5)).xy;  // position in texture coordinates
          a = at * 2.0 - 1.0;                                  // position in webgl coords

          float bu = (b.x + 1.0) / 2.0;                        // back to texture coords
          vec2 bt = texture2D(textureVerts, vec2(bu, .5)).xy;  // position in texture coords
          b = bt * 2.0 - 1.0;                                  // position in webgl coords
        }
      }

      void main() {
        // endpoints in world coordinates
        vec2 aWorld = vec2(x, y);
        vec2 bWorld = vec2(x2, y2);
      
        // endpoints in webgl coordinates
        vec2 a = (projection * vec3(aWorld, 1.0)).xy;   // TODO no need to do this in textureControl case
        vec2 b = (projection * vec3(bWorld, 1.0)).xy;

        textureControl(a, b);

        if (clip(a) && clip(b)) {
          gl_Position = vec4(-2.0, -2.0, -2.0, 1.0);  // place line out of view so it isn't rasterized
        } else {
          lineVertex(a, b, gl_Position);
        }

        // distance to line in canvas pixels: gl will interpolate per pixel and send to frag shader
        signedDist = featheredHalf * segment[3];
      }
      `,

    frag: `
      precision highp float;
      varying float signedDist;

      uniform vec3 color;
      uniform float halfWidth; // start blending this many pixels away from line line center
      uniform float feather;  // blend this many pixels after half width

      void main() {
        float dist = abs(signedDist);
        float blend = 1.0;
        if (dist > halfWidth) {
          blend -= (dist - halfWidth) / feather;
        }
        gl_FragColor = vec4(color, blend);  
      }`,
    primitive: "triangles",
    count: (_ctx, props: CmdProps) => props.vertices.length, // vertices per primitive (for a line segment and its endcap)
    instances: (_ctx, props: CmdProps) => props.lineBuffers.lines, // # of primitives
    profile: true,
    attributes: {
      segment: {
        // These flags are used to create a rectangle around the line segment between (x,y) and (x2,y2)
        // plus an end cap
        // The first coordinate 1,0 selects the first or second vertice (x,y) or (x2,y2).
        // The second and third coordinates offset that vertex in x and y
        // The final factor adjusts the distance to the line (for blending interpolation)
        //   The main segment rectangle interpolates vertically from featheredHalf to -featheredHalf.
        //     There is no vertice on the line center in two triangles that make up the main rectangle.
        //   The endcap triangles all meet at a vertex at the line center at 0, so they interpoloate
        //     vertically from featheredHalf to 0 to featherHalf (not negative)
        //   (distMult can also be used to tweak the blending rate.)
        // [a or b] | xOffset | yOffset | distMult
        buffer: (_ctx: DefaultContext, props: CmdProps) => props.vertices,
        divisor: 0,
      },
      x: {
        buffer: (_ctx: any, props: CmdProps) => props.lineBuffers.xPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 0,
        divisor: 1,
      },
      x2: {
        buffer: (_ctx: any, props: CmdProps) => props.lineBuffers.xPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 1,
        divisor: 1,
      },
      y: {
        buffer: (_ctx: any, props: CmdProps) => props.lineBuffers.yPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 0,
        divisor: 1,
      },
      y2: {
        buffer: (_ctx: any, props: CmdProps) => props.lineBuffers.yPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 1,
        divisor: 1,
      },
    },
    uniforms: {
      halfWidth: (_ctx, props: CmdProps) => props.halfWidth,
      featheredHalf: (_ctx, props: CmdProps) => props.featheredHalf,
      canvasSize: pixelsSize,
      feather: (_ctx, props: CmdProps) => props.feather,
      color: (_ctx, props: CmdProps) => props.color,
      textureVertsEnabled: (_ctx, props: CmdProps) => props.textureVertsEnabled,
      textureVerts: (_ctx, props: CmdProps) => props.textureVerts,
      ...clipRect,
    },
    ...cmdParams,
  });

  const draw = (props: ThickLineProps): void => {
    const vertices = cappedSegment(props.width, props.feather, props.joins);
    const {
      width,
      feather,
      lineBuffers,
      textureVerts = dummyTexture,
      color: cssColor = defaultColor,
    } = props;
    const color = cssColor.map((n) => n / 255) as Vec3;
    const cmdProps: CmdProps = {
      lineBuffers,
      feather: feather,
      halfWidth: width / 2,
      featheredHalf: width / 2 + feather,
      vertices,
      color,
      textureVerts,
      textureVertsEnabled: props.textureVerts !== undefined,
    };
    baseCmd(props, () => lineCmd(cmdProps));
  };
  ReglPerf.registerCmd(lineCmd, "thickLines");

  return draw;
}

function cappedSegment(width: number, feather: number, endCap?: boolean): Vec4[] {
  const points: Vec4[] = [
    [0, 0, 1, 1], // upper left
    [0, 0, -1, -1], // lower left
    [1, 0, 1, 1], // upper right
    [0, 0, -1, -1], // lower left
    [1, 0, 1, 1], // upper right
    [1, 0, -1, -1], // lower right
  ];
  if (endCap) {
    const vertCount = Math.round((width / 2 + feather) / 2);
    const first: Vec2 = [0, 1];
    const last: Vec2 = [0, -1];
    const verts: Vec2[] = [first, ...endCapVertices(vertCount), last];
    const pointB = 1; // we're putting a cap on vertex B (conceptually on the right)
    const posDistance = 1; // points above and below the line are counted as a positive signed distance
    const verts4: Vec4[] = verts.map((v) => [pointB, v[0], v[1], posDistance]);
    const zero: Vec4 = [pointB, 0, 0, 0];
    const sides = [...pairs(verts4)];
    const triangles = sides.flatMap(([a, b]) => [a, b, zero]);
    points.push(...triangles);
  }

  return points;
}

const unitToWebgl = (c: number): number => 2 * c - 1;
/** given a rectangle in canvas coordinates,
 * @return a rectangle in webl coordinates [-1, 1], [-1, 1]
 * @param plotRect rectangle in coordinates [0, canvasWidth], [0, canvasHeight]
 */
function webglClipRect(plotRect: Rectangle, canvasSize: Vec2): ClipRectangle {
  const clipLeft = unitToWebgl(plotRect.left / canvasSize[0]);
  const clipRight = unitToWebgl(plotRect.right / canvasSize[0]);
  const clipTop = unitToWebgl(plotRect.top / canvasSize[1]);
  const clipBottom = unitToWebgl(plotRect.bottom / canvasSize[1]);

  return {
    clipLeft,
    clipRight,
    clipTop,
    clipBottom,
  };
}

interface ClipRectangle {
  clipLeft: number;
  clipRight: number;
  clipTop: number;
  clipBottom: number;
}
