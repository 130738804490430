import { IconButton, makeStyles } from "@material-ui/core";
import { HelpOutline as HelpIcon } from "@material-ui/icons";
import React, { ReactNode, useCallback } from "react";
import { ClickTip } from "./ClickTip";

export interface HelpButtonProps {
  help: ReactNode | undefined;
  className?: string;
}

const useButtonStyles = makeStyles({
  help: {
    fontSize: 16,
    lineHeight: "normal",
    margin: 8,
    "& h2": {
      textAlign: "center",
    },
    "& h3": {
      textAlign: "center",
    },
    "& dt": {
      fontWeight: "bold",
      paddingBottom: ".2em",
    },
    "& dd": {
      paddingBottom: ".6em",
    },
  },
});

export const HelpButton = React.memo(HelpButtonInternal);

function HelpButtonInternal(props: HelpButtonProps): JSX.Element {
  const { help, className } = props;

  const renderButton = useCallback(
    (onClick: () => void): JSX.Element => {
      return (
        <IconButton {...{ className, onClick }}>
          <HelpIcon />
        </IconButton>
      );
    },
    [className]
  );

  const renderedHelp = useRenderedHelp(help);
  if (!help) {
    return <></>;
  }

  return <ClickTip arrow title={renderedHelp} placement="left" render={renderButton} />;
}

export function useRenderedHelp(help: ReactNode | undefined): JSX.Element {
  const classes = useButtonStyles();
  return <div className={classes.help}> {help}</div>;
}
