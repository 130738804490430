import { useCallback } from "react";
import { useStoreActions } from "../../store/Hooks";

export function useLoadDashboard(): (file: File) => Promise<void> {
  const modifyView = useStoreActions((app) => app.modifyView);
  const replaceDashboard = useStoreActions((app) => app.replaceDashboard);

  const loadFromFile = useCallback(
    async (file: File): Promise<void> => {
      const contents = await file.text();
      const newDash = JSON.parse(contents);
      if (newDash) {
        // LATER need to save/load ColumnSet with more persistent labels
        replaceDashboard(newDash);
        modifyView({ sheet: undefined, loadingProgress: {} });
      }
    },
    [modifyView, replaceDashboard]
  );

  return loadFromFile;
}

export function useLoadDashboardQuery(): (queryParam: string) => Promise<void> {
  const modifyView = useStoreActions((app) => app.modifyView);
  const replaceDashboard = useStoreActions((app) => app.replaceDashboard);
  const modifyHelp = useStoreActions((app) => app.modifyHelp);

  const loadFromQueryParam = useCallback(
    async (queryParam: string): Promise<void> => {
      const urlString = decodeURIComponent(queryParam);
      try {
        const response = await fetch(urlString);
        const newDash = await response.json();
        if (newDash) {
          replaceDashboard(newDash);
          modifyView({ sheet: undefined, loadingProgress: {} });
          modifyHelp({ demoShown: true });
        }
      } catch (error: any) {
        console.error(`error loading dashboard "${queryParam}" -  ${error.toString()}`);
      }
    },
    [modifyView, replaceDashboard, modifyHelp]
  );

  return loadFromQueryParam;
}
