import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { StoreProvider } from "easy-peasy";
import React, { useCallback, useRef } from "react";
import { DebugDialog } from "./components/dash-controls/DebugDialog";
import { useKeyboardShortcuts } from "./components/dash-controls/KeyboardShortcuts";
import LeftDrawer from "./components/dash-controls/LeftDrawer";
import { TitleBar } from "./components/dash-controls/TitleBar";
import { watchDragActive } from "./components/dash-controls/WatchDragActive";
import { usePasteData } from "./components/dash-data/usePasteData";
import { Dashboard } from "./components/dash-layout/Dashboard";
import { DataDialog } from "./components/data-dialog/DataDialog";
import { framesPerSecond } from "./components/fps/FramesPerSecond";
import RightDrawer from "./components/settings/RightDrawer";
import { useShowDemo } from "./store/DemoState";
import { useStoreActions, useStoreState } from "./store/Hooks";
import { useInitializeView } from "./store/InitializeView";
import { useDashQueryOption, useDebugQueryOptions } from "./store/QueryOptions";
import { store } from "./store/Store";
import { Notification } from "./components/dash-controls/Notification";
import { installBrowserDebug } from "./BrowserDebug";
import { ModalContainer } from "./components/dash-controls/ModalContainer";

const useStyles = makeStyles({
  belowTitle: {
    display: "flex",
  },
  full: {
    height: "100%",
    minHeight: "100vh",
    outline: "none",
  },
});

watchDragActive(store);

export function App(): JSX.Element {
  return (
    <StoreProvider store={store}>
      <InitializedApp />
    </StoreProvider>
  );
}

export function InitializedApp(): JSX.Element {
  const classes = useStyles();
  const initalized = useStoreState((app) => app.view.initialized);
  const onKeyDown = useKeyboardShortcuts();
  useInitialize();

  const onPaste = usePasteData();

  if (!initalized) {
    // CONSIDER waiting until intro state is installed too
    return <></>;
  }

  return (
    <div tabIndex={0} className={classes.full} {...{ onKeyDown, onPaste }}>
      <ModalContainer />
      <TitleBar />
      <div className={clsx(classes.belowTitle)}>
        <DebugDialog />
        <DataDialog />
        <LeftDrawer />
        <Dashboard />
        <RightDrawer />
      </div>
      <Notification />
    </div>
  );
}

function useInitialize(): void {
  const modifyView = useStoreActions((app) => app.modifyView);
  const level = useStoreState((app) => app.view.initLevel);
  const initView = useInitializeView();
  const undoReset = useStoreActions((app) => app.undoReset);
  const applyDebugOptions = useDebugQueryOptions();
  const applyDash = useDashQueryOption();
  const showDemo = useShowDemo();
  const fpsEnabled = useStoreState((app) => app.debug.framesPerSecond);
  const loaded = useRef(false);

  const storeLoaded = useCallback(() => {
    modifyView({ initLevel: 1 });
  }, [modifyView]);

  const dashLoaded = useCallback(() => {
    modifyView({ initLevel: 2 });
  }, [modifyView]);

  if (level === 0) {
    if (!loaded.current) {
      loaded.current = true;
      store.persist
        .resolveRehydration()
        .catch((e) => console.error("rehydration error", e))
        .finally(storeLoaded);
    }
  } else if (level === 1) {
    applyDash()
      .catch((e) => console.error("dash loading error", e))
      .finally(dashLoaded);
  } else if (level === 2) {
    applyDebugOptions();
    modifyView({ initLevel: 3 });
  } else if (level === 3) {
    showDemo();
    modifyView({ initLevel: 4 });
  } else if (level === 4) {
    initView();
    modifyView({ initialized: true, initLevel: 100 });
    if (fpsEnabled) {
      framesPerSecond({ startHidden: true });
    }
    undoReset();
    installBrowserDebug();
  }
}
