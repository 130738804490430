import { IconButton } from "@material-ui/core";
import { Home as HomeIcon } from "@material-ui/icons";
import React, { useCallback } from "react";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { plottableSource } from "../../store/TableSource";

export interface HomeButtonProps {
  dashId: DashId;
  className?: string;
}

export function HomeButton(props: HomeButtonProps): JSX.Element {
  const { dashId, className } = props;
  const modifyChartById = useStoreActions((app) => app.modifyChartById);
  const hasData = useStoreState((app) => {
    const kind = app.findChart(dashId)?.tableSource.kind;
    return plottableSource(kind);
  });

  const onClick = useCallback(() => {
    modifyChartById({ dashId, chartPartial: { resetZoom: true } });
  }, [modifyChartById, dashId]);

  if (!hasData) {
    return <></>;
  } else {
    return (
      <IconButton {...{ onClick, className }}>
        <HomeIcon />
      </IconButton>
    );
  }
}
