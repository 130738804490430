import _ from "lodash";
import { probit } from "simple-statistics";
import { pseudoRandom } from "./PsuedoRandom";

export function RandomWalk(step = 1, start = 0, seed = "seed"): () => number {
  const rand = pseudoRandom(seed);
  let current = start;
  return function () {
    current += (rand() - 0.5) * step;
    return current;
  };
}

export function CenteredWalk(
  step = 1,
  center = 0,
  centralTendency = 0.2,
  seed = "seed"
): () => number {
  const rand = pseudoRandom(seed);
  let current = center;
  return () => {
    let offset = (rand() - 0.5) * step;
    if (rand() <= centralTendency) {
      if ((current > center && offset > 0) || (current < center && offset < 0)) {
        offset = -offset;
      }
    }
    current += offset;

    return current;
  };
}

const abs = Math.abs;
const pow = Math.pow;
const exp = Math.exp;

export function convertToWalk(
  samples: number[],
  deltaPower = 0.1,
  deltaFactor = 0.1
): number[] {
  let prev = _.first(samples)!;
  return samples.map((s) => {
    const diff = s - prev;
    const step = prev + pow(abs(diff), deltaPower) * deltaFactor * Math.sign(diff);
    prev = step;
    return step;
  });
}

export function logNormalGenerator(mean = 0, sigma = 0.5, seed = "seed"): () => number {
  const rand = pseudoRandom(seed);
  return function logNormal(): number {
    return exp(mean + sigma * probit(rand()));
  };
}

export function mixGenerator(
  a: (t: number) => number,
  b: (t: number) => number,
  mix = 0.5,
  seed = "seed"
): (t: number) => number {
  const rand = pseudoRandom(seed);

  return function (t: number): number {
    if (rand() < mix) {
      return a(t);
    } else {
      return b(t);
    }
  };
}
