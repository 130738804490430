/* eslint-disable react/jsx-key */
import { makeStyles } from "@material-ui/core";
import {
  AllInclusive as AllInclusiveIcon,
  Computer as ComputerIcon,
  FlashOn as FlashOnIcon,
  Language as LanguageIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { TableSource } from "../../store/TableSource";

const useStyles = makeStyles({
  optionIcon: {
    marginRight: 6,
    position: "relative",
    top: 5,
  },
});

export interface TableSourceIconProps {
  sourceKind: TableSource["kind"];
  className?: string;
}

export default function TableSourceIcon(props: TableSourceIconProps): JSX.Element {
  const classes = useStyles();
  const sourceKind = props.sourceKind;

  return (
    <React.Fragment>
      <span className={clsx(classes.optionIcon, props.className)}>
        {iconForSeries(sourceKind)}
      </span>
    </React.Fragment>
  );
}

function iconForSeries(sourceKind: TableSource["kind"]): JSX.Element {
  switch (sourceKind) {
    case "generated":
      return <FlashOnIcon />;
    case "generatedStream":
      return <AllInclusiveIcon />;
    case "external":
      return <LanguageIcon />;
    case "local":
      return <ComputerIcon />;
    case "loading":
    case "none":
    default:
      return <></>;
  }
}
