import { Action, action } from "easy-peasy";
import { DashId } from "./DashId";

// Note that state in the view shouldn't assume
// it can stay in sync with state in the rest of the model.
// (view stuff isn't persisted in history or local storage, and so may change
// indendently of the rest of the model.)
export interface AppView {
  settingsOpen: boolean;
  // TODO replace most of these actions with modifyView
  setSettingsOpen: Action<AppView, boolean>;

  leftDrawerOpen: boolean;
  setLeftDrawerOpen: Action<AppView, boolean>;

  dataDialogOpen: boolean;
  setDataDialogOpen: Action<AppView, boolean>;

  rightDrawerOpen: boolean;
  setRightDrawerOpen: Action<AppView, boolean>;

  selectedDashItem: number; // TS? Why not DashID
  setSelectedDashItem: Action<AppView, DashId>;

  dragActive: boolean;
  setDragActive: Action<AppView, boolean>;

  dashWidth: number;
  setDashWidth: Action<AppView, number>;

  sheet: DashId | null;
  setSheet: Action<AppView, DashId | null>;

  initLevel: number;
  initialized: boolean;

  loadingProgress: Record<number, LoadingProgress>; // TS would prefer DashId
  setLoadingProgress: Action<AppView, ChartLoadingProgress>;

  introOpen: boolean;

  rearrangeMode: boolean;

  newDashItem: DashId | null;

  notificationMessage: NotificationMessage | null;
}

export interface LoadingProgress {
  totalBytes: number;
  loadedBytes: number;
}

export interface ChartLoadingProgress {
  dashId: DashId;
  progress: LoadingProgress | undefined;
}

const appView: AppView = {
  settingsOpen: false,
  setSettingsOpen: action((state, value) => {
    state.settingsOpen = value;
  }),
  leftDrawerOpen: false,
  setLeftDrawerOpen: action((state, open) => {
    state.leftDrawerOpen = open;
  }),
  dataDialogOpen: false,
  setDataDialogOpen: action((state, open) => {
    state.dataDialogOpen = open;
  }),
  rightDrawerOpen: false,
  setRightDrawerOpen: action((state, open) => {
    state.rightDrawerOpen = open;
  }),
  selectedDashItem: -1 as DashId,
  setSelectedDashItem: action((view, dashId) => {
    view.selectedDashItem = dashId;
    if (view.sheet !== dashId) {
      view.sheet = null;
    }
  }),
  dragActive: false,
  setDragActive: action((state, active) => {
    state.dragActive = active;
  }),
  dashWidth: 0,
  setDashWidth: action((state, width) => {
    state.dashWidth = width;
  }),
  sheet: null,
  setSheet: action((state, dashId) => {
    state.sheet = dashId;
  }),
  initLevel: 0,
  initialized: false,
  loadingProgress: {},
  setLoadingProgress: action((state, chartProgress) => {
    const { dashId, progress } = chartProgress;
    const lp = state.loadingProgress;
    if (progress) {
      lp[dashId] = progress;
    } else {
      delete lp[dashId];
    }
  }),
  introOpen: false,
  rearrangeMode: false,
  newDashItem: null,
  notificationMessage: null,
};

export interface NotificationMessage {
  message: string;
  severity?: "success" | "info" | "warning" | "error";
}

export default appView;
