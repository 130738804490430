import _ from "lodash";
import { detectColumnHeader } from "../csv/DetectColumnHeader";
import { formatRuns, mostUsedFormat } from "./ColumnFormatRuns";
import { CellParser, dataFormatParser, detectCellFormat } from "./DetectCellFormat";
import { transposeRows } from "./TransposeRows";

export interface ColumnDetect {
  dataParser: CellParser;
  percentSuccess: number;
  headerLines: number;
  column: string[];
}

/** Identify string data in columns as numbers, strings or dates. */
export function detectAllColumnTypes(rowTable: string[][]): ColumnDetect[] | undefined {
  return transposeRows(rowTable).map(detectColumnType);
}

/** Detect the most like data parsing format for a column of strings */
export function detectColumnType(column: string[]): ColumnDetect {
  const checkLines = 10;
  const slice = column.slice(0, checkLines);
  const dataFormats = slice.map(detectCellFormat);
  const runs = formatRuns(dataFormats);

  if (runs.length === 1) {
    // all the same format
    const { format } = runs[0];
    const dataParser = dataFormatParser(format);
    return { column, dataParser, percentSuccess: 1.0, headerLines: 0 };
  } else {
    const headerLines = detectColumnHeader(runs);
    const remainingRuns = headerLines > 0 ? _.tail(runs) : runs;
    const mostFormat = mostUsedFormat(remainingRuns);
    const dataParser = dataFormatParser(mostFormat);
    return { column, dataParser, percentSuccess: 1.0, headerLines };
  }
}
