import { op, table } from "arquero";
import _ from "lodash";
import { FrameAndColumns } from "../components/rich-chart/DashChart";
import { simpleChartFrame } from "../data/frameGenerators";

/** @return a histogram, a count of the number of y values in each range of y */
export function histogramFrame(
  ys: number[],
  binCount = 200,
  name = "histogram"
): FrameAndColumns {
  const dt = table({ ys });
  const grouped = dt
    .params({ binCount })
    .groupby({
      bin: (d: any, params: any) => {
        const [start, stop, step] = op.bins(d.ys, params.binCount, false);
        return op.bin(d.ys, start, stop, step);
      },
    })
    .count()
    .orderby("bin");

  const getBin = grouped.getter("bin");
  const getCount = grouped.getter("count");
  const bins: number[] = _.times(grouped.size).map(getBin);
  const counts: number[] = _.times(grouped.size).map(getCount);

  return simpleChartFrame(bins, counts, name);
}
