import { LinearProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { DashId } from "../../store/DashId";
import { useStoreState } from "../../store/Hooks";

const useStyles = makeStyles({
  loading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    color: "#bdbdbd",
    background: "#ffffffe0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
  },
  progress: {
    width: "50%",
  },
  loadingText: {
    margin: 8,
  },
});

export interface LoadingProgressProps {
  dashId: DashId;
}

export const LoadingProgress = React.memo(LoadingProgressInternal);

function LoadingProgressInternal(props: LoadingProgressProps): JSX.Element {
  const { dashId } = props,
    classes = useStyles(),
    loadingProgress = useStoreState((app) => app.view.loadingProgress[dashId]),
    tableSource = useStoreState((app) => app.findChart(dashId)?.tableSource);

  if (loadingProgress && tableSource) {
    const { loadedBytes, totalBytes } = loadingProgress;
    const progress = (100 * loadedBytes) / totalBytes;
    return (
      <div className={classes.loading}>
        <div className={classes.loadingText}>
          loading: <i>{tableSource.name}</i>
        </div>
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={progress}
        />
      </div>
    );
  } else {
    return <></>;
  }
}
