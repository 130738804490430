import React, { useContext } from "react";
import { usePrevious } from "../../util/ReactUtil";

/**
 * ResizeContext provides a scheme to allow parent elements to trigger
 * arbitrary descendent elements to take action when their container is resized.
 *
 * In our case, the react-grid-layout sets the size of dashboard items (and
 * changes sizes after user resize operations). The dashboard is configured to
 * to update a counter in react Context around the resized item. Descendants can
 * trigger by watching for changes in that context.
 */
export const ResizeContext = React.createContext<number>(0);

export function useResizeContext(): boolean {
  const resizeCount = useContext(ResizeContext) || 0;
  const prevCount = usePrevious(resizeCount);
  return resizeCount > 0 && prevCount !== resizeCount;
}
