import { makeStyles, Tooltip } from "@material-ui/core";
import { ScatterPlot, ShowChart } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import { Plot } from "../../chart/PlotModels";
import { useCurrentPlot, useStoreActions } from "../../store/Hooks";

interface ButtonEntry {
  icon: JSX.Element;
  kind: Plot["kind"];
  tip: string;
}
const scatterAndLine: ButtonEntry[] = [
  { icon: <ShowChart />, kind: "HeatLines", tip: "lines + density" },
  { icon: <ScatterPlot />, kind: "HeatScatter", tip: "scatter + density" },
];

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    margin: theme.spacing(2, 0),
    display: "inline-block",
  },
  toolPop: {
    fontSize: 14,
  },
}));

export const PlotTypeButtons = React.memo(PlotTypeButtonsInternal);

function PlotTypeButtonsInternal(): JSX.Element {
  const plotKind = useCurrentPlot((plot) => plot.kind);
  const modifyPlotKind = useStoreActions((actions) => actions.modifyPlotKind);
  const classes = useStyles();

  const buttonEntries = [...scatterAndLine];

  if (!plotKind) {
    return <></>;
  }

  const buttons: JSX.Element[] = buttonEntries.map(({ icon, kind, tip }) => {
    return (
      <Tooltip
        classes={{
          tooltip: classes.toolPop,
        }}
        title={tip}
        key={kind}
        placement="top"
        arrow
      >
        <ToggleButton
          value={tip}
          selected={kind === plotKind}
          onClick={() => modifyPlotKind(kind)}
        >
          {icon}
        </ToggleButton>
      </Tooltip>
    );
  });

  return (
    <div className={classes.toggleContainer}>
      <ToggleButtonGroup exclusive>{buttons}</ToggleButtonGroup>
    </div>
  );
}
