import { AxisScale, scaleTime, ScaleTime } from "d3";
import { LinearScale } from "../util/d3Util";

/**
 * @return a time scale that mirrors an underlying linear scale with
 * values in milliseconds.
 *
 * The goal is to have an scale for the x axis that uses dates,
 * while simultaneously allowing the rest of the code to use simpler
 * numeric linear scales.
 */
export function mirrorTimeScale(src: LinearScale): AxisScale<Date> {
  function domain(): Date[] {
    return src.domain().map((millis) => new Date(millis));
  }

  function range(): number[] {
    return src.range();
  }

  function copy(): AxisScale<Date> {
    return mirrorTimeScale(src.copy());
  }

  function scale(x: Date): number {
    return src(x.getTime())!;
  }

  function ticks(count: number): Date[] {
    return dateScale().ticks(count);
  }

  function tickFormat(): (d: Date) => string {
    return dateScale().tickFormat();
  }

  function dateScale(): ScaleTime<number, number> {
    return scaleTime().domain(domain()).range(src.range());
  }

  scale.domain = domain;
  scale.range = range;
  scale.copy = copy;
  scale.ticks = ticks;
  scale.tickFormat = tickFormat;

  return scale;
}
