import { XyDimensions } from "../chart/Chart";
import { Plot } from "../chart/PlotModels";
import { Vec2 } from "../math/Vec";
import { ColumnSet, TableSource } from "./TableSource";

export interface ChartModel {
  plot: Plot;
  tableSource: TableSource;
  columnSet: ColumnSet | undefined;
  fixedExtent?: DataExtentModel;
  zoom?: XyDimensions;
  resetZoom?: boolean;
  title?: string;
}

type TimeOrDate = Date | number;

export interface DataExtentModel {
  time: [TimeOrDate, TimeOrDate];
  value: Vec2;
}

export const plotDefaultMaxRows = 50000;
