import _ from "lodash";

export type ColorScale = (t: number) => string; // a d3 color scale

/** convert a d3 color scale into a data array to use as a webgl texture color map
 * @returns a flattened array of color channel values: [r,g,b,r,g,b,r,g,b,...]
 * each in the range 0-255.
 */
export function colorMap(colorScale: ColorScale): number[] {
  const samples = 256,
    stringColors = _.times(samples + 1, (i) => {
      return colorScale(i / (samples - 1));
    }),
    colors = stringColors.flatMap((s) => {
      const value = parseInt(s.substr(1), 16),
        b = value & 0xff,
        g = (value >> 8) & 0xff,
        r = (value >> 16) & 0xff;

      return [r, g, b];
    });

  return colors;
}
