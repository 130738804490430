import _ from "lodash";
import { isNumberOrString } from "./DetectJsonTable";

export type SimpleObject = Record<string, number | string>;

export type SignatureType = "number" | "string" | "undefined";
export type SimpleSignature = Record<string, SignatureType>;

export function detectObjectTable(array: unknown[]): SimpleSignature[] | undefined {
  if (array.length === 0) {
    return undefined;
  }
  const firstSig = simpleSignature(array[0]);
  if (!firstSig) {
    return undefined;
  }
  const allSignatures = array.map(simpleSignature);
  const match = allSignatures.every((sig) => matchSignature(firstSig, sig));
  if (match) {
    return allSignatures as SimpleSignature[];
  } else {
    return undefined;
  }
}

/** @return  a signature from the keys and the type of the values */
function simpleSignature(obj: unknown): SimpleSignature | undefined {
  if (_.isObject(obj)) {
    let fail = false;
    const entries = Object.entries(obj).map(([key, value]) => {
      let valueType;
      if (typeof value === "string") {
        valueType = "string";
      } else if (typeof value === "number") {
        valueType = "number";
      } else if (value === undefined) {
        valueType = "undefined";
      } else {
        fail = true;
      }

      return [key, valueType];
    });

    if (fail) {
      return undefined;
    } else {
      return Object.fromEntries(entries);
    }
  } else {
    return undefined;
  }
}

function matchSignature(a: SimpleSignature, b: SimpleSignature | undefined): boolean {
  if (b === undefined) {
    return false;
  }

  return (
    arraysEqual(Object.keys(a), Object.keys(b)) &&
    signatureTypeMatch(Object.values(a), Object.values(b))
  );
}

function signatureTypeMatch(a: SignatureType[], b: SignatureType[]): boolean {
  return a.every((aType, i) => {
    const bType = b[i];
    return aType === bType || aType === "undefined" || bType === "undefined";
  });
}

function arraysEqual(a: unknown[], b: unknown[]): boolean {
  return a.length === b.length && a.every((aElem, i) => aElem === b[i]);
}
