import { StandardTextFieldProps, TextField } from "@material-ui/core";
import React, { useCallback, useRef } from "react";
import NumberFormat, { NumberFormatProps, NumberFormatValues } from "react-number-format";

export interface FormattedTextFieldProps extends NumberFormatProps {
  /**  callback triggers when a new value is committed by return key or blur */
  onChangeNumber?: (value: number | undefined) => void;

  label?: StandardTextFieldProps["label"];
}

/** a Material UI TextField with formatting provided by react-number-format */
export function FormattedTextField(props: FormattedTextFieldProps): JSX.Element {
  const { onChangeNumber, ...standardProps } = props;
  // cache the value we're editing
  const displayValue = useRef<number | undefined>();
  const numericValue = typeof props.value === "number" ? props.value : undefined;
  displayValue.current = numericValue;

  // notify caller of new value on blur or enter key
  const notifyValue = useCallback(() => {
    if (onChangeNumber) {
      onChangeNumber(displayValue.current);
    }
  }, [onChangeNumber]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === " " || e.key === "Enter") {
        notifyValue();
      }
    },
    [notifyValue]
  );

  const onValueChange = useCallback((values: NumberFormatValues) => {
    displayValue.current = values.floatValue;
  }, []);

  return (
    <NumberFormat
      {...{ ...standardProps, onValueChange, onBlur: notifyValue, onKeyDown }}
      value={displayValue.current}
      customInput={TextField}
    />
  );
}
