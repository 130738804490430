/*
 * @param Adapted from public domain: https://2015fallhw.github.io/arcidau/dspUtils-11.js
 */

import { dlog } from "../util/DebugLog";
import { normalizeFilter } from "./Gaussian";

const PI = Math.PI;
const sqrt = Math.sqrt;
const pow = Math.pow;
const sin = Math.sin;
const abs = Math.abs;

export function kaiserFilter(
  kernelSize: number,
  attenuation = 50,
  highCutoff = 0.1,
  lowCutoff = 0,
  samplingFrequency = 2
): number[] {
  if ((kernelSize & 0x1) === 0) {
    kernelSize = kernelSize + 1;
  }
  const Np = (kernelSize - 1) / 2;
  const sinc = sincHalf();
  const alpha = attenuationFactor();
  const bAlpha = bessel0(0);
  const baseFilter = kaiserWindow();
  return normalizeFilter(baseFilter);

  // apply Kaiser-Bessel window to the sync function
  function kaiserWindow(): number[] {
    const kernel = new Array(kernelSize);
    const Np2 = Np * Np;
    for (let j = 0; j <= Np; j++) {
      const scale = bessel0(alpha * sqrt(1 - (j * j) / Np2)) / bAlpha;
      kernel[Np + j] = sinc[j] * scale;
    }
    // make kernel symmetric
    for (let j = 0; j < Np; j++) {
      kernel[j] = kernel[kernelSize - 1 - j];
    }
    return kernel;
  }

  /** return right side impulse response of sinc function, incl center value */
  function sincHalf(): number[] {
    const sinc = new Array(Np);
    sinc[0] = (2 * (highCutoff - lowCutoff)) / samplingFrequency;
    let sum = sinc[0];

    for (let j = 1; j <= Np; j++) {
      sinc[j] =
        (sin((2 * PI * j * highCutoff) / samplingFrequency) -
          sin((2 * PI * j * lowCutoff) / samplingFrequency)) /
        (PI * j);
      sum += sinc[j] * 2;
    }
    if (abs(sum - 1) > 0.15) {
      dlog("filter kernel too small.", { kernelSize, sum });
    }
    // normalize so that area under the curve is 1
    for (let j = 0; j <= Np; j++) {
      sinc[j] = sinc[j] / sum;
    }
    return sinc;
  }

  /** @return the window shape coefficient for Kaiser Bessel window */
  function attenuationFactor(): number {
    if (attenuation < 21) {
      return 0;
    } else if (attenuation > 50) {
      return 0.1102 * (attenuation - 8.7);
    } else {
      return 0.5842 * pow(attenuation - 21, 0.4) + 0.07886 * (attenuation - 21);
    }
  }
}

/*
 * zeroth order Bessel function
 */
export function bessel0(x: number): number {
  let d = 0,
    ds = 1,
    s = 1;
  do {
    d += 2;
    ds *= (x * x) / (d * d);
    s += ds;
  } while (ds > s * 1e-6);
  return s;
}
