import React, { useCallback } from "react";
import { DensityPlotData } from "../../chart/PlotModels";
import { useCurrentPlot, useStoreActions, useStoreState } from "../../store/Hooks";
import { LabeledSlider } from "./LabeledSlider";
import { OneStatProps } from "./OneStatSetting";

type StatFeatherSettingsProps = OneStatProps;

export const StatFeatherSettings = React.memo(StatFeatherSettings_);

function StatFeatherSettings_(props: StatFeatherSettingsProps): JSX.Element {
  const { dashId, summaryId } = props;
  const summary = useCurrentPlot((p: DensityPlotData) =>
    p.summaries?.find((s) => s.summaryId === summaryId)
  );
  const modifySummary = useStoreActions((app) => app.modifyPlotSummary);
  const enabled = useStoreState((state) => state.debug.adjustableFeather);
  const { feather = 2 } = summary || {};

  const setFeather = useCallback(
    (_e, feather: number) => {
      if (dashId) {
        modifySummary({ dashId, summaryId, summaryPartial: { feather } });
      }
    },
    [dashId, summaryId, modifySummary]
  );

  if (enabled) {
    return (
      <LabeledSlider
        label="feather:"
        min={0}
        max={4}
        step={0.25}
        value={feather}
        onChange={setFeather as any}
      />
    );
  } else {
    return <></>;
  }
}
