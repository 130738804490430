/**
 * @return a zoom interpolator that results in a linear interpolation on screen.
 */
export function linearZoom(a: d3.ZoomView, b: d3.ZoomView): (t: number) => d3.ZoomView {
  /*
      The zoom interpolators take as input and output homogenous affine transforms
      in the form of ZoomView objects (and are equivalent to ZoomTransforms). The
      transformations transform unzoomed coordinates to the the zoomed current screen 
      coordinates.

      To calculate the zoom interpolator for a given tween value t, we want the
      current screen coordinates to move linearly with t. 

      So, in math terms, some definitions:
        A,B input transforms
        C = result transform
        t = tween value.  0 <= t <= 1
        Ci = inverse of C

      We want the outputs of the transforms to be linear according to t, so the relationship
      is among the inverse transforms, as follows:
        Ci = (1-t) Ai + t Bi 

      And then we return the inverse of Ci as C.
     */

  const [ax, ay, ak] = a;
  const [bx, by, bk] = b;

  return function (t: number): d3.ZoomView {
    // linear combination of Ai and Bi transforms (Ai -> inverse of A)
    //    Ci = (1 - t) Ai + t Bi
    const k = (1 - t) / ak + t / bk;
    const x = (-(1 - t) * ax) / ak - (t * bx) / bk;
    const y = (-(1 - t) * ay) / ak - (t * by) / bk;

    // inverse of Ci
    const ki = 1 / k;
    const xi = -(x / k);
    const yi = -(y / k);

    return [xi, yi, ki];
  };
}
