import { ParseType } from "../parse/DetectCellFormat";
import { TabularColumn } from "../parse/ParseTabular";
import { zip2tuple } from "../util/Utils";
import { DataType } from "./ColumnFrame";

export type DataArray = Float64Array | string[];
export type ColumnChunk = NumberChunk | DateChunk | StringChunk;
export type StorageType = "string" | "float64";

export type ChunkFn = (columns: ColumnChunk[], bytesRead: number) => Promise<void>;

interface ColumnChunkBase {
  label: string;
  storageType: StorageType;
  displayType: DataType;
}

export interface NumberChunk extends ColumnChunkBase {
  data: Float64Array;
  storageType: "float64";
  displayType: "number";
}

export interface DateChunk extends ColumnChunkBase {
  data: Float64Array;
  storageType: "float64";
  displayType: "date";
}

export interface StringChunk extends ColumnChunkBase {
  data: string[];
  storageType: "string";
  displayType: "string";
}

export function constructChunks(
  columnInfo: TabularColumn[],
  columns: DataArray[]
): ColumnChunk[] {
  const chunks = zip2tuple(columnInfo, columns).map(([info, data]) =>
    constructChunk(info, data)
  );

  return chunks;
}

function constructChunk(column: TabularColumn, data: DataArray): ColumnChunk {
  const { label, dataParser } = column;
  const { parseType, parseResultType } = dataParser;
  const storageType = storageTypeForData(parseType);

  let { displayType } = column;
  if (!displayType) {
    displayType = parseResultType;
  }

  const result = {
    label,
    storageType,
    displayType,
    data,
  } as ColumnChunk;

  return result;
}

export function dataArray(parseType: ParseType, length: number): DataArray {
  const storageType = storageTypeForData(parseType);
  switch (storageType) {
    case "float64":
      return new Float64Array(length);
    case "string":
      return new Array<string>(length);
  }
}

function storageTypeForData(dataType: ParseType): StorageType {
  switch (dataType) {
    case "number":
      return "float64";
    case "jsonNumber":
      return "float64";
    case "date":
      return "float64";
    case "string":
    case "empty":
      return "string";
  }
}
