import {
  Checkbox,
  createStyles,
  Dialog,
  FormControlLabel,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import { AppDebugKey } from "../../store/AppDebug";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { DialogContent, DialogTitle } from "../misc/DialogTitle";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    checkBox: {
      display: "block",
    },
  });

const useStyles = makeStyles(styles);

export function DebugDialog(): JSX.Element {
  const open = useStoreState((state) => state.view.settingsOpen);
  const setOpen = useStoreActions((actions) => actions.view.setSettingsOpen);

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Debug Settings
      </DialogTitle>
      <DialogContent dividers>
        <DebugCheckBoxes />
      </DialogContent>
    </Dialog>
  );
}

function DebugCheckBoxes(): JSX.Element {
  const debugSettings = useStoreState((state) => state.debug);
  const boxes = Object.keys(debugSettings).map((label) =>
    DebugCheckBox(label as AppDebugKey)
  );
  return <>{boxes}</>;
}

function DebugCheckBox(label: AppDebugKey): JSX.Element {
  const classes = useStyles();
  const currentValue = useStoreState((state) => state.debug[label]);
  const modifyDebug = useStoreActions((actions) => actions.modifyDebug);

  function setFn(value: boolean): void {
    modifyDebug({ [label]: value });
    if (label === "miniCanvas") {
      // requires a lot of reinitialization
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  return (
    <FormControlLabel
      key={label}
      className={classes.checkBox}
      control={<Checkbox checked={currentValue} onChange={(_e, value) => setFn(value)} />}
      label={label}
    />
  );
}
