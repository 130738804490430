import { makeStyles } from "@material-ui/core";
import React, { ForwardedRef } from "react";

const useStyles = makeStyles({
  hiddenInput: {
    height: 0,
    width: 0,
    border: 0,
    padding: 0,
  },
});

export const HiddenInput = React.forwardRef(function HiddenInputInternal(
  props?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  ref?: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const classes = useStyles();

  return <input className={classes.hiddenInput} {...{ props, ref }}></input>;
});

export function isEditable(e: EventTarget | null): boolean {
  return (
    e !== null &&
    e instanceof HTMLElement &&
    (e.tagName === "INPUT" || e.tagName === "TEXTAREA" || e.isContentEditable)
  );
}
