import { makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { ColumnFrame } from "../../data/ColumnFrame";
import { DashId } from "../../store/DashId";
import { HelpButton } from "../help/HelpButton";
import { ChartMenu } from "./ChartMenu";
import { HomeButton } from "./HomeButton";

const useStyles = makeStyles({
  chartButtons: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: 0,
    top: 0,
    // opacity: 100, // handy for debug
    opacity: 0,
    transition: "opacity 250ms ease",
    ".react-grid-item:hover &": {
      opacity: 100,
    },
    "& button": {
      padding: 6,
    },
  },
});

interface ChartButtonProps {
  frame: ColumnFrame;
  dashId: DashId;
}

const chartHelp = (
  <div>
    <h3>Chart Zoom</h3>
    <dl>
      <dt>Brush</dt>
      <dd>Click and drag to selectiona region of the chart to zoom in.</dd>
      <dt>Wheel</dt>
      <dd>Use the mouse wheel (or two fingers on a trackpad) to zoom in and out.</dd>
      <dt>Pan</dt>
      <dd>Hold shift and drag with the mouse to pan left an right.</dd>
      <dt>Undo / Redo / Home</dt>
      <dd>
        To get back to where you used to be, use the undo/redo buttons found on the left
        edge of the dashboard. Or use the home button.
      </dd>
    </dl>
  </div>
);
export function ChartButtons(props: ChartButtonProps): JSX.Element {
  const { dashId, frame } = props;
  const classes = useStyles();

  const onMouseDown = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={classes.chartButtons} {...{ onMouseDown }}>
      <HomeButton {...{ dashId }} />
      <HelpButton help={chartHelp} />
      <ChartMenu {...{ dashId, frame }} />
    </div>
  );
}
