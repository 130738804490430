import * as d3 from "d3";
import { DataExtent, DataType } from "../data/ColumnFrame";
import { LinearScale } from "../util/d3Util";
import { SizedRectangle } from "./Rectangle";

export interface ChartScales {
  baseScales: XyScales; // scales when fully zoomed out
  zooming: XyScales; // scales adjusted for zoom, adjusted per tick
  zoomTarget: XyScales; // scales adjusted for zoom, adjusted to target at the beginning of a zoom
  xType: DataType;
  resize: (rect: SizedRectangle) => void;
  plotRect: SizedRectangle;
}

export interface XyScales {
  x: LinearScale;
  y: LinearScale;
}

export default function chartScales(
  dataExtent: DataExtent,
  xType: DataType,
  plotRect: SizedRectangle
): ChartScales {
  const xBaseScale = d3.scaleLinear().domain(dataExtent.x);
  const yBaseScale = d3.scaleLinear().domain(dataExtent.y);
  const baseScales = { x: xBaseScale, y: yBaseScale };
  const zooming = { x: baseScales.x.copy(), y: baseScales.y.copy() };
  const zoomTarget = { x: baseScales.x.copy(), y: baseScales.y.copy() };
  const scales = makeChartScales(baseScales, zooming, zoomTarget, xType, plotRect);

  scales.resize(plotRect);

  return scales;
}

function makeChartScales(
  baseScales: XyScales,
  zooming: XyScales,
  zoomTarget: XyScales,
  xType: DataType,
  plotRect: SizedRectangle
): ChartScales {
  function resize(newPlotRect: SizedRectangle): void {
    plotRect = newPlotRect;
    const xRange = [plotRect.left, plotRect.right];
    const yRange = [plotRect.bottom, plotRect.top];
    baseScales.x.range(xRange);
    zooming.x.range(xRange.slice());
    zoomTarget.x.range(xRange.slice());

    baseScales.y.range(yRange);
    zooming.y.range(yRange.slice());
    zoomTarget.y.range(yRange.slice());
  }

  return {
    baseScales,
    zooming,
    zoomTarget,
    xType,
    plotRect,
    resize,
  };
}
