import { makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import { CurrentPlotSettings } from "./CurrentPlotSettings";
import { PlotTypeButtons } from "./PlotTypeButtons";
import { SettingsSection } from "./SettingsSection";
import SvgPlotType from "./SvgPlotType";

const useStyles = makeStyles({
  chartSettings: {
    margin: 10,
  },
});

export const ChartSettings = React.memo(ChartSettingsInternal);

export function ChartSettingsInternal(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.chartSettings}>
      <SettingsSection title="Plot type">
        <PlotTypeButtons />
        <SvgPlotType />
      </SettingsSection>
      <SettingsSection title="settings">
        <CurrentPlotSettings />
      </SettingsSection>
    </div>
  );
}
