import { useCallback } from "react";
import { useLoadDashboardQuery } from "../components/dash-data/UseLoadDashboard";
import { useStoreActions, useStoreState } from "./Hooks";

/** Apply url query parameters to set properties on the debug state */
export function useDebugQueryOptions(): () => void {
  const modifyDebug = useStoreActions((app) => app.modifyDebug);
  const debugState = useStoreState((app) => app.debug);

  const applyQueryOptions = useCallback((): void => {
    const query = document.location.search;
    const params = new URLSearchParams(query);
    for (const [key, value] of params) {
      if (key in debugState) {
        const boolValue = parseBoolean(value);
        if (boolValue !== undefined) {
          modifyDebug({ [key]: boolValue });
        }
      }
    }
  }, [modifyDebug, debugState]);

  return applyQueryOptions;
}

export function useDashQueryOption(): () => Promise<void> {
  const loadDash = useLoadDashboardQuery();
  const dashQueryOption = useCallback(async (): Promise<void> => {
    const query = document.location.search;
    const params = new URLSearchParams(query);
    for (const [key, value] of params) {
      if (key === "dash") {
        return loadDash(value);
      }
    }
  }, [loadDash]);

  return dashQueryOption;
}

function parseBoolean(str: string): boolean | undefined {
  const text = str.toLowerCase();
  if (text === "true" || text === "1" || text === "yes") {
    return true;
  }
  if (text === "false" || text === "0" || text === "no") {
    return false;
  }
  return undefined;
}
