import * as d3 from "d3";
import { Vec4 } from "../../math/Vec";

export function bodyColor(): Vec4 {
  const body = document.querySelector("body")!;
  const style = getComputedStyle(body);
  const background = style.getPropertyValue("background-color");
  const color = d3.color(background)!.rgb();
  return [color.r, color.g, color.b, color.opacity * 255];
}
