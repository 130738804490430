import fileSaver from "file-saver";
import { useCallback } from "react";
import { useStoreState } from "../../store/Hooks";

export function useSaveDashboard(): () => void {
  const dashboard = useStoreState((app) => app.dashboard);

  const save = useCallback(() => {
    const content = JSON.stringify(dashboard, null, 2);
    const type = "application/json;charset=utf-8";
    const fileName = dashboard.title + ".dotd";
    const blob = new Blob([content], { type });
    fileSaver.saveAs(blob, fileName);
  }, [dashboard]);

  return save;
}
