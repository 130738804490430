import { useEffect, useState } from "react";

interface DensityThresholds {
  min: number;
  max: number;
  threshold: number;
}

export function useDensityListener(container: HTMLDivElement): DensityThresholds {
  const [density, setDensityThreshold] = useState({ min: 0, max: 10, threshold: 10 });

  useEffect(() => {
    // DOM event when the density scale changes
    container.addEventListener("density-scale", (event: Event): void => {
      const { threshold, max, min } = (event as CustomEvent).detail;
      if (density.threshold !== threshold || density.max !== max || density.min !== min) {
        setDensityThreshold({ threshold, max, min });
      }
    });
  }, [container, setDensityThreshold, density.max, density.threshold, density.min]);

  return density;
}
