import { DataArray } from "../data/DataChunk";
import { dsert } from "../util/DebugLog";
import { AsciiTableFormat } from "./DetectAsciiTable";
import { TabularColumn } from "./ParseTabular";
import { parseColumns } from "./ParseColumns";

export interface AsciiTableParseParams {
  columns: TabularColumn[];
  format: AsciiTableFormat;
}

export function parseAsciiTable(
  text: string,
  params: AsciiTableParseParams
): DataArray[] {
  const rowTable = parseRows(text, params.format);
  const data = parseColumns(rowTable, params.columns);
  return data;
}

function parseRows(text: string, format: AsciiTableFormat): string[][] {
  const { linePattern, headerLines, lineBreak } = format;
  const { starts, ends } = linePattern;

  const lines = splitIntoLines(text, lineBreak, headerLines);

  return lines.map(selectColumns);

  function selectColumns(line: string): string[] {
    dsert(starts.length === ends.length);
    return starts.map((start, i) => {
      const end = ends[i];
      return line.slice(start, end);
    });
  }
}

export function splitIntoLines(
  text: string,
  lineBreak: string,
  headerLines: number
): string[] {
  return text
    .split(lineBreak)
    .filter((l) => l.length)
    .slice(headerLines);
}
