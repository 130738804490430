import { matrixString } from "./LinearAlgebra";
import { cubicFromMatrix, FourPoints, ParametricFn } from "./SplineMath";

export const bezierMat = matrixString(`
   1  0  0  0
  -3  3  0  0
   3 -6  3  0
  -1  3 -3  1
`);

export const hermiteMat = matrixString(`
   1  0  0  0
   0  1  0  0
  -3 -2  3 -1
   2  1 -2  1
`);

export const bezierInvMat = matrixString(`
   1   0   0   0
   1  1/3  0   0
   1  2/3 1/3  0
   1   1   1   1
`);

export function BezierCurve(points: FourPoints): ParametricFn {
  return cubicFromMatrix(points, bezierMat);
}

export function HermiteCurve(points: FourPoints): ParametricFn {
  return cubicFromMatrix(points, hermiteMat);
}
