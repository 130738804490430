import { useCallback } from "react";
import { ColumnChunk, constructChunks } from "../../data/DataChunk";
import { likelyPlotColumns, saveIntoTable } from "../../data/DbTables";
import { parseTabularText, TabularParser } from "../../parse/ParseTabular";
import { ChartEditMode } from "../../store/EditChartData";
import { useStoreActions } from "../../store/Hooks";
import { LocalTableSource } from "../../store/TableSource";

export interface DataEditOptions {
  editMode: ChartEditMode;
  chartName: string;
  tableName?: string;
  noShow?: boolean;
}

/** return a function for parsing data from one stream and storing it into the app. */
export function useTabularData(): () => (
  text: string,
  dataEdit: DataEditOptions,
  parser?: TabularParser,
  bodyOnly?: boolean
) => Promise<string> {
  const makeDataIntoApp = useDataIntoApp();
  return useCallback(() => {
    const dataIntoApp = makeDataIntoApp();

    return (
      text: string,
      dataEdit: DataEditOptions,
      parser?: TabularParser,
      bodyOnly?: boolean
    ): Promise<string> => {
      let chunks: ColumnChunk[];
      if (parser) {
        const dataArray = bodyOnly ? parser.parseBody(text) : parser.parse(text);
        chunks = constructChunks(parser.columns, dataArray);
      } else {
        chunks = parseTabularText(text);
      }
      if (chunks.length > 0) {
        return dataIntoApp(chunks, dataEdit);
      } else {
        return Promise.resolve("?");
      }
    };
  }, [makeDataIntoApp]);
}

/** Return a function to store parsed tabular data chunks in the dbStore for one stream.
 * The returned function will also update the app chart metadata, according
 * to the DataEditOptions, creating a chart or modifying an existing chart's table and column references.  */
function useDataIntoApp(): () => (
  chunks: ColumnChunk[],
  dataEdit: DataEditOptions
) => Promise<string> {
  const editChartData = useStoreActions((app) => app.editChartData);

  return useCallback(() => {
    let started = false;

    return (chunks: ColumnChunk[], dataEdit: DataEditOptions): Promise<string> => {
      const { chartName, tableName = chartName, editMode } = dataEdit;
      const saved = saveIntoTable(chunks, tableName, editMode).then(
        async (tableResult) => {
          const { tableId, name: newTableName, newTable } = tableResult;
          const tableSource: LocalTableSource = {
            kind: "local",
            name: newTableName,
            tableId,
          };

          if (!started) {
            started = true;
            let newColumnSet = undefined;
            if (newTable) {
              newColumnSet = await likelyPlotColumns(tableId);
              editChartData({
                dataEdit: { editMode: "forceNew", chartName: newTableName },
                tableSource,
                newColumnSet,
              });
            } else {
              editChartData({
                dataEdit: { editMode: dataEdit.editMode, chartName },
                tableSource,
                newColumnSet,
              });
            }
          }

          return newTableName;
        }
      );
      return saved;
    };
  }, [editChartData]);
}
