const aCode = "A".charCodeAt(0);
/** @return a spreadsheet style column label for an index
 *    0 =>  "A"
 *    27 => "AB"
 */
export function letterIndex(i: number): string {
  const result: string[] = [];
  let remainder = i;
  do {
    const digit = remainder % 26;
    remainder -= digit;
    remainder /= 26;
    const letter = String.fromCharCode(aCode + digit);
    result.push(letter);
  } while (remainder > 0);

  return result.join("");
}

export function syntheticLabel(columnIndex: number): string {
  return "[" + letterIndex(columnIndex) + "]";
}
