import {
  ClickAwayListener,
  makeStyles,
  PaperProps,
  Tooltip,
  TooltipProps,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";

export interface ClickTipProps {
  /** tip content in the pop-up */
  title: TooltipProps["title"];

  /** for e.g. button content
   * @param toggleOpen callback function available so buttons can trigger open/close
   */
  render: (toggleOpen: () => void) => TooltipProps["children"];

  placement?: TooltipProps["placement"];

  arrow?: TooltipProps["arrow"];
  className?: TooltipProps["className"];
  elevation?: PaperProps["elevation"];

  startOpen?: boolean;

  onClose?: () => void;
}

const useTooltipStyles = makeStyles({
  tooltip: {
    background: "#eaeaea",
    maxWidth: 600,
    color: "black",
    boxShadow: (params: { shadow: string }) => params.shadow,
    pointerEvents: "all",
  },
  arrow: {
    color: "#eaeaea",
    fontSize: 36,
  },
});

export const ClickTip = React.memo(ClickTipInternal);

function ClickTipInternal(props: ClickTipProps): JSX.Element {
  const {
    placement,
    render,
    arrow,
    title,
    className,
    onClose,
    startOpen,
    elevation = 4,
  } = props;
  const theme = useTheme();
  const tooltipClasses = useTooltipStyles({ shadow: theme.shadows[elevation] });
  const [open, setOpen] = useState(startOpen === true);

  const close = useCallback(() => {
    if (open) {
      if (onClose) {
        onClose();
      }
      setOpen(false);
    }
  }, [setOpen, open, onClose]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  function tipContent(): JSX.Element {
    if (render) {
      return render(toggleOpen);
    } else {
      return <span />;
    }
  }

  return (
    <ClickAwayListener onClickAway={close}>
      <Tooltip
        {...{ title, arrow, open, classes: tooltipClasses, className, placement }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        {tipContent()}
      </Tooltip>
    </ClickAwayListener>
  );
}
