import _ from "lodash";
import { useState } from "react";
import { storeTables } from "../../data/DbTables";
import { LocalTableSource } from "../../store/TableSource";

/** a deferred list the local sources found in indexDB. */
export function useLocalSources(): LocalTableSource[] {
  const [localSources, setLocalSources] = useState<LocalTableSource[]>([]);

  // check the db every time someone asks for the sources.
  // returns the previously loaded set of sources initially, and then triggers
  // a render with the new sources when the promise fires.
  fetchLocalTableSources().then((sources) => {
    if (!_.isEqual(localSources, sources)) {
      setLocalSources(sources);
    }
  });

  return localSources;
}

async function fetchLocalTableSources(): Promise<LocalTableSource[]> {
  const stored = await storeTables();
  const newTables: LocalTableSource[] = stored.map(({ name, tableId }) => ({
    kind: "local",
    name,
    tableId,
  }));
  return newTables;
}
