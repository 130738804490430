import { Regl } from "regl";
import { Vec4 } from "../math/Vec";
import { clearFramebuffer } from "./ClearFramebuffer";
import { white } from "./WebGLUtil";

/** return a clear() function to erase a webgl canvas to a provided color */
export default function clearCanvas(regl: Regl, background?: Vec4): () => void {
  const backgroundGlColor = background ? (background.map((v) => v / 255) as Vec4) : white;
  function clear(): void {
    clearFramebuffer(regl, null, "ClearCanvas", backgroundGlColor);
  }

  return clear;
}
