import { createSvgIcon, makeStyles } from "@material-ui/core";
import React from "react";
import { HeaderGroup, TableInstance } from "react-table";
import { indexColumnId } from "../../data/DbStore";
import { DashId } from "../../store/DashId";
import { ColumnSet } from "../../store/TableSource";
import { BackgroundVariant, ColumnMenu } from "./ColumnMenu";
import { SimpleRow } from "./UseRowsAndColumns";

export const SeparatorIcon = createSvgIcon(<path d="M11 19V5h2v14z" />, "Separator");

export interface DashPosition {
  left: number;
  center: number;
}

const rowsHeight = 350;
const headerRowHeight = 50;
export const sheetHeight = rowsHeight + headerRowHeight;

const columnSpacer = 20;

const cellPadding = {
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
  paddingLeft: columnSpacer,
};
const rowBorder = ".5px solid #E0E0E0";

interface StyleParams {
  tooWide: boolean;
}

const useStyles = makeStyles(
  {
    sheet: {
      height: sheetHeight,
    },
    table: {
      display: "inline-block",
      borderSpacing: 0,
      fontSize: 14,
      width: "100%",
      overflow: (params: StyleParams) => (params.tooWide ? "scroll" : "visible"),
    },
    headerRow: {
      borderBottom: "1.5px solid #E0E0E0",
      height: headerRowHeight,
    },
    dataRows: {
      height: rowsHeight,
      overflowY: (params: StyleParams) => (params.tooWide ? "visible" : "scroll"),
    },
    tr: {
      borderBottom: rowBorder,
      "&:last-child": {
        borderBottom: 0,
      },
    },
    th: {
      ...cellPadding,
      overflow: "hidden",
    },
    indexTh: {
      ...cellPadding,
      paddingLeft: 0,
      overflow: "hidden",
    },
    td: {
      marginRight: columnSpacer, // set margin rather than padding so that we get clipping via overflow: hidden
      ...cellPadding,
      position: "relative",
      overflow: "hidden",
    },
    resizer: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: columnSpacer * 2,
      height: "100%",
      right: -columnSpacer,
      top: 0,
      zIndex: 1,
      touchAction: "none",
      "&:hover $resizeBar": {
        backgroundColor: "black",
      },
    },
    resizeBar: {
      width: 3,
      borderRadius: 2,
      height: "50%",
      backgroundColor: "#E0E0E0",
    },
  },
  {
    link: true,
  }
);

export interface SheetProps {
  tableInstance: TableInstance<SimpleRow>;
  dashId: DashId;
  columnSet: ColumnSet | undefined;
  sheetWidth: number;
  data: SimpleRow[]; // triggers redraw on data change
}

export const DataGrid = React.memo(DataGridInternal);

export function DataGridInternal(props: SheetProps): JSX.Element {
  const { dashId, sheetWidth, tableInstance, columnSet } = props;
  const xColumnId = columnSet?.xColumn;
  const yColumnId = columnSet?.yColumns[0];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
  } = tableInstance;
  const tooWide = totalColumnsWidth > sheetWidth;
  const classes = useStyles({ tooWide });

  return (
    <div className={classes.sheet}>
      <div {...getTableProps()} className={classes.table}>
        <div>{headerGroups.map(renderHeaders)}</div>

        <div {...getTableBodyProps()} className={classes.dataRows}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              /* eslint-disable react/jsx-key*/
              <div {...row.getRowProps()} className={classes.tr}>
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps()} className={classes.td}>
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  function renderHeaders(headerGroup: HeaderGroup<SimpleRow>): JSX.Element {
    return (
      <div {...headerGroup.getHeaderGroupProps()} className={classes.headerRow}>
        {headerGroup.headers.map(renderColumnHeader)}
      </div>
    );
  }

  function renderColumnHeader(column: HeaderGroup<SimpleRow>): JSX.Element {
    const columnId = (column as any).columnId;
    let backgroundVariant: BackgroundVariant = "none";
    if (columnId === xColumnId) {
      backgroundVariant = "xAxis";
    } else if (columnId === yColumnId) {
      backgroundVariant = "yAxis";
    }
    const headerClass = columnId === indexColumnId ? classes.indexTh : classes.th;

    return (
      <div {...column.getHeaderProps()}>
        <div className={headerClass}>
          <ColumnMenu {...{ dashId, columnId, backgroundVariant, columnSet }}>
            {column.render("Header")}
          </ColumnMenu>
        </div>
        <div {...column.getResizerProps()} className={classes.resizer}>
          <span className={classes.resizeBar} />
        </div>
      </div>
    );
  }
}
