import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";

interface SectionTitleProps {
  children?: ReactNode[] | string;
  title?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: 10,
      fontFamily: "Lato,Helvetica,sans-serif,Arial",
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      "text-transform": "uppercase",
    },
  })
);

export function SectionTitle(props: SectionTitleProps): JSX.Element {
  const classes = useStyles();

  return (
    <Typography className={classes.title}>
      {props.children}
      {props.title}
    </Typography>
  );
}
