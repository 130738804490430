import React, { useCallback, useMemo, useReducer, useRef } from "react";
import { useStoreState } from "../../store/Hooks";
import { dlog } from "../../util/DebugLog";
import { fileSuffix } from "../../util/PathUtil";
import { useLoadFile } from "../dash-data/LoadFileHook";
import { useLoadDashboard } from "../dash-data/UseLoadDashboard";
import { useAddChart } from "./UseAddChart";

export const dashFileFormats = ["dotdash", "dotd", "dot-"];

/** return an onDrop handler for dropping dashboard or .csv files onto the dashboard background */
export function useDashboardDrop(): (e: React.DragEvent) => void {
  const loadDash = useLoadDashboard();
  const loadFileNew = useLoadFileNewChart();

  const onDrop = useCallback(
    (e: React.DragEvent): void => {
      if ((e as any).chartDrop !== true) {
        const { items } = e.dataTransfer;
        const list = [...items];
        const files = list.filter((i) => i.kind === "file").map((i) => i.getAsFile());
        for (const file of files) {
          dlog({ file });
          if (file) {
            const suffix = fileSuffix(file.name);
            if (dashFileFormats.includes(suffix)) {
              loadDash(file);
            } else {
              loadFileNew(file);
            }
          }
        }
      }
    },
    [loadDash, loadFileNew]
  );

  return onDrop;
}

/** return a function that will add a new chart and load a file into it.
 *
 * Uses a scheme with two render passes: add the chart to the dashboard
 * in the first render, loads the file into the chart in a second render.
 */
export function useLoadFileNewChart(): (file: File) => Promise<void> {
  const addChart = useAddChart();
  const loadFile = useLoadFile();

  // on render #1 map loading files to add tags for forthcoming charts
  const loading = useRef(new Map<number, File>());

  // on render #2, map add tags to dashIds of added charts
  const dashIds = useStoreState((app) => app.tagsToDashIds([...loading.current.keys()]));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const loadFileNew = useCallback(
    async (file: File): Promise<void> => {
      const newAddTag = addChart();
      loading.current.set(newAddTag, file);
      forceUpdate();
    },
    [addChart]
  );

  useMemo(() => {
    for (const { tag, dashId } of dashIds) {
      // on render #2, load queued files now that we know their charts' dashIds
      const file = loading.current.get(tag);
      if (file) {
        loadFile(file, dashId);
      } else {
        dlog("no file for tag", { tag });
      }
    }
    loading.current.clear();
  }, [dashIds, loadFile]);

  return loadFileNew;
}
