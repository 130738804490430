import _ from "lodash";
import { gaussian1D } from "../math/Gaussian";
import { kaiserFilter } from "../math/KaiserBessel";

export interface InterpolatedFilter {
  offsets: number[];
  weights: number[];
}

/**
 * Get gaussian filter coefficients for a filter optimized to reduce texture fetches.
 *
 * The optimization idea is:
 * . the GPU can fetch two neigbhoring source texels and interpolate between them in one step,
 *   just about as fast as fetching one texel.
 * . we can adjust the sampling location between the two texels so that the math works out the same
 *
 * So if the normal FIR filter calculation for a two pixel kernel is:
 *     out = t1 * w1 + t2 * w2     ; combine two weighted texels
 *
 * We'll instead fetch a cleverly interpolated value, and multiply that by the sum of the weights
 *     ti = o1 * t1 + (1- o1) t2   ; load interpolated texel
 *     ws = w1 + w2                ; sum of each pair of weights (easily precalculated)
 *     out = ti * ws               ; and then calculate the result
 *
 * We need to emit the o interpolation factor for each pair of texels.
 *     o1 = w1 / ws                ; i.e. we interpolate the pixels accoding to the ratio of the weights
 *
 * To do the shader calculation we'll need:
 *   ws and o for each pair of weights
 *
 * Since the filter is symmetric, we'll just store one half of it.
 *
 * For now we'll force the size to be 4n-3, where n is an integer > 1. i.e. sizes 5, 9, 13, ...
 * (Filters of size 4n - 1 cases will require a slightly different pattern since they overlap the
 * center pixel.)
 *
 *     w1 w2 w3 w4 wc w4 w3 w2 w1     ; original weights for filter of kernel size 9
 *                        ; we emit:
 *     wc ws43 ws21       ;   weights. (ws43 = w3+w4)
 *     o43 o21            ;   offsets. align with weights
 *
 *     w1 w2 w3 w4 w3 w2 w1     ; original weights for filter of kernel size 7
 *                        ; we emit:
 *     ws43 ws21          ;   weights. (ws43 = w3+w4)
 *     o43 o21            ;   offsets. align with weights
 *
 * @return a filter designed to be used with a bilinear texture fetch
 * @param kernelSize of expanded gaussian, interpolated filter will be about half as big
 *
 * see https://software.intel.com/content/www/us/en/develop/blogs/an-investigation-of-fast-real-time-gpu-based-image-blur-algorithms.html
 */
export function interpolationGaussian(
  kernelSize: number,
  sigma?: number
): InterpolatedFilter {
  const kernel = gaussian1D(kernelSize, sigma);
  return interpolationFilter(kernel);
}

export function interpolationKaiser(
  kernelSize: number,
  attenuation?: number,
  highCutoff?: number,
  lowCutoff?: number,
  samplingFrequency?: number
): InterpolatedFilter {
  const kernel = kaiserFilter(
    kernelSize,
    attenuation,
    highCutoff,
    lowCutoff,
    samplingFrequency
  );

  return interpolationFilter(kernel);
}

function interpolationFilter(kernel: number[]): InterpolatedFilter {
  const length = kernel.length;
  if ((length + 3) % 4 === 0) {
    // size 5, 9, 13, etc.
    // pairs of weights do not overlap in the center.
    const center = (length - 1) / 2;
    const weights = [kernel[center]];
    const offsets: number[] = [];
    _.range(center + 1, length, 2).map((i) => {
      const w1 = kernel[i];
      const w2 = kernel[i + 1];
      const ws = w1 + w2;
      const o = w1 / ws;
      weights.push(ws);
      offsets.push(o);
    });

    return {
      weights,
      offsets,
    };
  } else {
    console.error("unsupported size", length);
    return {
      weights: [],
      offsets: [],
    };
  }
}
