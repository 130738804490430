import { makeStyles, Snackbar, SnackbarOrigin } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import React, { useCallback, useState } from "react";

export interface DashboardAlertProps {
  children: AlertProps["children"];
  severity?: AlertProps["severity"];
}

const anchorOrigin: SnackbarOrigin = {
  vertical: "top",
  horizontal: "center",
};

const useStyles = makeStyles({
  dashAlert: {
    top: 50,
  },
});

export function DashboardAlert(props: DashboardAlertProps): JSX.Element {
  const { children, severity } = props;
  const { dashAlert } = useStyles();
  const [open, setOpen] = useState(true);
  const onClick = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onClose = useCallback(
    (_e?: React.SyntheticEvent, reason?: string): void => {
      if (reason !== "clickaway") {
        setOpen(false);
      }
    },
    [setOpen]
  );

  return (
    <Snackbar
      className={dashAlert}
      autoHideDuration={3000}
      {...{ open, onClose, onClick, anchorOrigin }}
    >
      <Alert {...{ severity }}>{children}</Alert>
    </Snackbar>
  );
}
