import { dlog } from "./DebugLog";

/** combine two streams of arrays into a stream of a equal length arrays */
export async function* zipBalanced(
  a: AsyncGenerator<Float64Array, void>,
  b: AsyncGenerator<Float64Array, void>
): AsyncGenerator<[Float64Array, Float64Array], void> {
  const aBuffers: Float64Array[] = [];
  const bBuffers: Float64Array[] = [];
  let aDone = false;
  let bDone = false;
  let allDone = false;

  while (!allDone) {
    const aLength = combinedLength(aBuffers);
    const bLength = combinedLength(bBuffers);
    if (aLength <= bLength) {
      if (aDone) {
        allDone = true;
      }
      const aNext = await a.next();
      if (!aNext.done) {
        aBuffers.push(aNext.value);
      } else {
        aDone = true;
      }
    }

    if (bLength <= aLength) {
      if (bDone) {
        allDone = true;
      } else {
        const bNext = await b.next();
        if (!bNext.done) {
          bBuffers.push(bNext.value);
        } else {
          bDone = true;
        }
      }
    }
    if (aDone && bDone) {
      allDone = true;
    }
    if (!allDone && aBuffers.length && bBuffers.length) {
      const taken = rmEqualSlices(aBuffers, bBuffers);
      if (taken) {
        yield taken;
      } else {
        dlog("unexpected from rmEqualSlices", { aBuffers, bBuffers });
      }
    }
  }
}

interface HasLength {
  length: number;
}

/** remove equal size slices from both array sequences, modifying the original
 * sequences and returning the removed entries.
 */
export function rmEqualSlices(
  as: Float64Array[],
  bs: Float64Array[]
): [Float64Array, Float64Array] | undefined {
  const aLength = combinedLength(as);
  const bLength = combinedLength(bs);
  const takeLength = Math.min(aLength, bLength);
  const aTaken = rmSlice(as, takeLength);
  const bTaken = rmSlice(bs, takeLength);
  if (aTaken && bTaken) {
    return [aTaken, bTaken];
  } else {
    return undefined;
  }
}

const emptyFloat64Array = new Float64Array(0);

/** remove @param size elements from a sequence of arrays
 * @return the elements removed
 * modifies the sequence of arrays to remove the taken elements
 */
export function rmSlice(arrays: Float64Array[], size: number): Float64Array | undefined {
  if (size === 0) {
    return emptyFloat64Array;
  }
  let taken = arrays.shift();
  if (!taken) {
    return undefined;
  }
  while (taken.length < size) {
    const nextArray = arrays.shift();
    if (!nextArray) {
      return undefined;
    }
    taken = joinFloatArrays(taken, nextArray);
  }
  if (taken.length > size) {
    const remainder = taken.slice(size);
    arrays.unshift(remainder);
    taken = taken.slice(0, size);
  }
  return taken;
}

function combinedLength(arrays: Iterable<HasLength>): number {
  let length = 0;
  for (const a of arrays) {
    length += a.length;
  }
  return length;
}

/** concatenate a sequence of Float64Arrays into one larger Float64Array */
export function joinFloatArrays(...arrays: Float64Array[]): Float64Array {
  const length = arrays.map((a) => a.length).reduce((a, b) => a + b);
  const buffer = new Float64Array(length);
  let offset = 0;
  for (const a of arrays) {
    buffer.set(a, offset);
    offset += a.length;
  }
  return buffer;
}

/** asynchronously collect the results of a generator into an array. 
 * Note: terminates only if the generator terminates on its own.
 */
export async function drainAsyncGenerator<T>(gen: AsyncGenerator<T, void>): Promise<T[]> {
  const buf: T[] = [];
  for await (const value of gen) {
    buf.push(value);
  }
  return buf;
}

/** return a float64 array filled with an increasing sequence */
export function float64ArraySequence(from: number, until: number): Float64Array {
  const length = until - from;
  const array = new Float64Array(length);
  for (let i = 0, x = from; i < length; i++, x++) {
    array[i] = x;
  }
  return array;
}

const maxSequenceRows = 1e8; // sanity check to not generate forever
const defaultBlockSize = 1e5;

/** generate a sequence for index values. The sequence values are returned  */
export function* float64SequenceGenerator(
  maxRows?: number,
  blockSize = defaultBlockSize
): Generator<Float64Array, void, unknown> {
  const max = maxRows || maxSequenceRows;

  for (let start = 0; start < max; ) {
    const end = start + blockSize;
    const array = float64ArraySequence(start, end);
    yield array;
    start = end;
  }
}

