import { Matrix, multiply } from "mathjs";
import { matrixString } from "./LinearAlgebra";
import { MakePolynomialFn } from "./PolynomialFunction";
import { UnaryFn } from "./SplineMath";

export const constantIntegral = matrixString(`
  0         
  1         
`);

export const linearIntegral = matrixString(`
  0   0      
  1   0      
  0  1/2     
`);

export const quadricIntegral = matrixString(`
  0   0   0   
  1   0   0   
  0  1/2  0   
  0   0  1/3  
`);

export const cubicIntegral = matrixString(`
  0   0   0   0
  1   0   0   0
  0  1/2  0   0
  0   0  1/3  0
  0   0   0  1/4 
`);

export const quarticIntegral = matrixString(`
  0   0   0   0   0   
  1   0   0   0   0   
  0  1/2  0   0   0   
  0   0  1/3  0   0   
  0   0   0  1/4  0   
  0   0   0   0  1/5  
`);

export const quinticIntegral = matrixString(`
  0   0   0   0   0   0
  1   0   0   0   0   0
  0  1/2  0   0   0   0
  0   0  1/3  0   0   0
  0   0   0  1/4  0   0
  0   0   0   0  1/5  0
  0   0   0   0   0  1/6 
`);

const degreeToIntegralMatrix = new Map([
  [0, constantIntegral],
  [1, linearIntegral],
  [2, quadricIntegral],
  [3, cubicIntegral],
  [4, quarticIntegral],
  [5, quinticIntegral],
]);

export interface PolynomialIntegration {
  integralCoeff: Matrix;
  integral: UnaryFn;
  totalArea: number;
}

/** Integrate a polynomial, producing a higher degree polynomial.
 *
 * @return the integral, the integrated area under the curve, and the integral coefficients
 */
export function integratePolynomial(
  polynomialCoeff: Matrix,
  integralEnd = 1
): PolynomialIntegration {
  const integralCoeff = integratePolyCoefficients(polynomialCoeff);
  const integral = MakePolynomialFn(integralCoeff.toArray() as number[]);
  const totalArea = integral(integralEnd);

  return {
    integralCoeff,
    integral,
    totalArea,
  };
}

/** Integrate a polynomial analytically.
 *
 * @param coefficients matrix in the shape [n,1] containing the source polynomial coefficients
 * in ascending order
 * @return matrix in the shape [n+1,1] containing the polynomial coefficients of the integral
 */
export function integratePolyCoefficients(coefficients: Matrix): Matrix {
  const degree = coefficients.size()[0] - 1;
  const integralMatrix = degreeToIntegralMatrix.get(degree) || bail();
  const integralCoefficients = multiply(integralMatrix, coefficients) as Matrix;

  return integralCoefficients;

  function bail(): never {
    console.log(
      "polynomialIntegral: can't take integral of coefficient matrix of size:",
      coefficients.size()
    );
    throw {};
  }
}

export function integralFromCubic(cubicCoefficients: Matrix): UnaryFn {
  const integral = multiply(cubicIntegral, cubicCoefficients) as Matrix; // size 5,1
  return MakePolynomialFn(integral.toArray() as number[]);
}

export function cubicFn(cubicCoefficients: Matrix): UnaryFn {
  return MakePolynomialFn(cubicCoefficients.toArray() as number[]);
}
