import { ChartFrame } from "../components/rich-chart/DashChart";
import { GeneratedDataLoaders } from "../example-data/GeneratedData";
import { parseTabularText } from "../parse/ParseTabular";
import { plotDefaultMaxRows } from "../store/ChartModel";
import {
  ColumnSet,
  ExternalTableSource,
  GeneratedStreamSource,
  GeneratedTableSource,
  SourceColumns
} from "../store/TableSource";
import { dlog, dLog, dsert } from "../util/DebugLog";
import { joinFloatArrays, zipBalanced } from "../util/FloatArrayUtil";
import { GeneratedStreams } from "./../example-data/GeneratedStreamData";
import { columnFrame, columnsInFrame } from "./ColumnFrame";
import { getBasicColumnInfo, watchFloatColumn } from "./DbColumns";
import { urlToTable } from "./DbExternalCache";
import { TableId } from "./DbStore";
import { likelyPlotColumns, saveIntoTableExternal } from "./DbTables";

export interface FrameStreamAndColumns {
  frameStream: AsyncGenerator<ChartFrame, void, unknown>;
  columnSet: ColumnSet | undefined;
}

/** return an indefinite stream of ChartFrames for a given set of source columns */
export async function watchData(
  sourceColumns: SourceColumns
): Promise<FrameStreamAndColumns> {
  const { source, columnSet } = sourceColumns;
  switch (source.kind) {
    case "generatedStream":
      return generateStream(source);
    case "generated":
      return generateStaticStream(source, columnSet);
    case "local":
      return localDataStream(source.tableId, columnSet, source.name, source.maxRows);
    case "none":
    case "loading":
      return noDataStream();
    case "external":
      return externalDataStream(source, columnSet);
    default:
      return Promise.reject("generated data source not supported: ");
  }
}

/** find or load an external table*/
async function externalDataStream(
  source: ExternalTableSource,
  columnSet: ColumnSet | undefined
): Promise<FrameStreamAndColumns> {
  const { url, name, maxRows } = source;
  const existingTable = await urlToTable(url);
  // TODO map external columnSet too.
  if (existingTable) {
    return localDataStream(existingTable, columnSet, name, maxRows);
  } else {
    // TODO move into a separate function
    const response = await fetch(url.toString());
    if (response.ok) {
      const text = await response.text();

      const chunks = parseTabularText(text);
      const tableResult = await saveIntoTableExternal(chunks, name, "forceNew", url);
      const { tableId: newTableId, name: storedName } = tableResult;
      if (name !== storedName) {
        // LATER if there's a name collision and we get a suffix like name-1,
        // we should probably modify the chart title to match the suffixed name.
        dlog("name changed, but chart title not updated", { name, storedName });
      }

      return localDataStream(newTableId, columnSet, name, maxRows);
    } else {
      return noDataStream();
    }
  }
}

/** return a ChartFrame stream for a data from the internal DbStore */
async function localDataStream(
  tableId: TableId,
  columnSet: ColumnSet | undefined,
  name: string,
  maxRows?: number
): Promise<FrameStreamAndColumns> {
  const columns = columnSet || (await likelyPlotColumns(tableId));
  if (!columns) {
    dLog("no columns to plot", { name, tableId });
    return Promise.reject(new Error(`no columns to plot ${name}`));
  }

  const frameStream = makeFrameStream(columns, name, maxRows);
  return { frameStream, columnSet: columns };
}

/** return a ChartFrame stream from a known set of columns in the DbStore */
async function* makeFrameStream(
  columns: ColumnSet,
  name: string,
  maxRows?: number
): AsyncGenerator<ChartFrame, void, unknown> {
  const { xColumn, yColumns } = columns;

  const xColInfo = await getBasicColumnInfo(xColumn);
  const yColInfo = await getBasicColumnInfo(yColumns[0]);
  dsert(xColInfo.storageType === "float64");
  dsert(yColInfo.storageType === "float64");

  const xRawStream = watchFloatColumn(xColumn, maxRows);
  const yRawStream = watchFloatColumn(yColumns[0], maxRows);

  const syncdStreams = await zipBalanced(xRawStream, yRawStream);
  let xBuf = new Float64Array();
  let yBuf = new Float64Array();
  for await (const [xs, ys] of syncdStreams) {
    const frame = columnFrame().named(name);
    xBuf = joinFloatArrays(xBuf, xs);
    yBuf = joinFloatArrays(yBuf, ys);
    frame.addColumn(xBuf, xColInfo.label, xColInfo.displayType, xColumn);
    frame.addColumn(yBuf, yColInfo.label, yColInfo.displayType, yColumns[0]);

    const chartFrame: ChartFrame = {
      frame,
    };
    yield chartFrame;
  }
}

/** frame stream containing empty data  */
function noDataStream(): Promise<FrameStreamAndColumns> {
  const chartFrame: ChartFrame = {
    frame: columnFrame(),
  };
  const fsc: FrameStreamAndColumns = {
    columnSet: undefined,
    frameStream: generatorStatic(chartFrame),
  };

  return Promise.resolve(fsc);
}

/** return a frame stream from an internal generator function */
function generateStream(source: GeneratedStreamSource): Promise<FrameStreamAndColumns> {
  const { maxRows } = source;
  const entry = GeneratedStreams.find(({ name }) => name === source.name);
  if (entry) {
    return entry.stream(maxRows);
  } else {
    return Promise.reject("generated stream not found: " + source.name);
  }
}

/** return a frame stream containing one frame, with the data generated by a static function */
async function generateStaticStream(
  dataSeries: GeneratedTableSource,
  columnSet: ColumnSet | undefined
): Promise<FrameStreamAndColumns> {
  const loaderEntry = GeneratedDataLoaders.find(({ name }) => name === dataSeries.name);
  if (loaderEntry) {
    const { loader } = loaderEntry;
    const loaded = await loader(dataSeries.maxRows || plotDefaultMaxRows);
    const { chartFrame } = loaded;
    const set = columnsInFrame(chartFrame.frame, columnSet)
      ? columnSet
      : loaded.columnSet;
    const frameStream = generatorStatic(chartFrame);
    const result: FrameStreamAndColumns = { frameStream, columnSet: set };
    return result;
  } else {
    return Promise.reject("generated data not found: " + dataSeries.name);
  }
}

async function* generatorStatic<T>(value: T): AsyncGenerator<T, void, unknown> {
  yield value;
}
