import { Descendant as SlateDescendant } from "slate";
import { ChartModel } from "./ChartModel";
import { DashId } from "./DashId";

export interface DashItemsModel {
  items: DashItem[];
}

const dashItemsModel: DashItemsModel = {
  items: [],
};

export default dashItemsModel;

export type DashItem = DashChartModel | DashTextModel;

export interface DashChartModel extends DashBase {
  kind: "Chart";
  chart: ChartModel;
}

export interface DashTextModel extends DashBase {
  kind: "Text";
  richText: SlateDescendant[];
  autoGrow: boolean;
}

export interface DashBase {
  id: DashId; // unique id
  kind: string; // e.g. text or chart
  addTag?: number; // alternate id, to tag items before they're added to the dashboard
}

export type BareDashItem = Omit<DashChartModel, "id"> | Omit<DashTextModel, "id">;
