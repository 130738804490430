import { Regl, Vec2 } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { debugNzStats, debugPrintFB } from "./Debug";
import { DensityCmds } from "./DensityCmds";

export function pixelLogging(
  regl: Regl,
  cmds: DensityCmds,
  canvas: HTMLCanvasElement,
  debug: ChartDebug | undefined
): void {
  const {
    densityFB,
    blurFB,
    statLineFB: meanFB,
    statFillFB,
    smoothStatFB2,
    alignStatFB,
    red,
    green,
  } = cmds;

  const canvasSize: Vec2 = [canvas.width, canvas.height];
  const statWidth = canvas.width;

  if (debug?.logNzStats) debugNzStats(regl, red.nzFB);
  if (debug?.logNzStats && green) debugNzStats(regl, green.nzFB, "-g");

  if (debug?.logDensityFB) debugPrintFB(regl, densityFB, canvasSize, "density");
  if (debug?.logBlurredFB) {
    debugPrintFB(regl, blurFB, canvasSize, "density (postFilter)");
  }

  if (debug?.logBaseStat) {
    debugPrintFB(regl, meanFB, [statWidth, 1], "base stat values x", "r");
    debugPrintFB(regl, meanFB, [statWidth, 1], "base stat values y", "g");
  }

  if (debug?.logAlignStat) {
    debugPrintFB(regl, statFillFB, [statWidth, 1], "filled stat values x", "r");
    debugPrintFB(regl, statFillFB, [statWidth, 1], "filled stat values y", "g");
    debugPrintFB(regl, alignStatFB, [statWidth, 1], "aligned stat values x", "r");
    debugPrintFB(regl, alignStatFB, [statWidth, 1], "aligned stat values y", "g");
  }

  if (debug?.logSmoothedStat) {
    debugPrintFB(regl, smoothStatFB2, [statWidth, 1], "smoothed stat values y", "g");
  }

  if (debug?.logDisplayFB) debugPrintFB(regl, null, canvasSize, "fb-r", "r");
  if (debug?.logDisplayFB) debugPrintFB(regl, null, canvasSize, "fb-g", "g");
  if (debug?.logDisplayFB) debugPrintFB(regl, null, canvasSize, "fb-b", "b");
}
