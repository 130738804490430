import React, { useCallback } from "react";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";

export function useKeyboardShortcuts(): (ev: React.KeyboardEvent) => void {
  const sheetId = useStoreState((app) => app.view.sheet);
  const selectedId = useStoreState((app) => app.view.selectedDashItem);
  const modifyView = useStoreActions((app) => app.modifyView);
  const rightPanelOpen = useStoreState((app) => app.view.rightDrawerOpen);
  const rearrangeMode = useStoreState((app) => app.view.rearrangeMode);

  const keyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const { key } = event;
      if (key === "d" && !event.ctrlKey && !event.metaKey) {
        if (sheetId === null && selectedId !== undefined) {
          modifyView({ sheet: selectedId as DashId });
        } else {
          modifyView({ sheet: null });
        }
        event.stopPropagation();
        event.preventDefault(); // otherwise key event goes to autocomplete input.
      } else if (key === "." && (event.ctrlKey || event.metaKey)) {
        modifyView({ rightDrawerOpen: !rightPanelOpen });
      } else if (key === "r" && !event.ctrlKey && !event.metaKey) {
        modifyView({ rearrangeMode: !rearrangeMode });
      }
    },
    [modifyView, sheetId, selectedId, rightPanelOpen, rearrangeMode]
  );

  return keyDown;
}
