import * as d3 from "d3";
import { SvgGSelection, SvgSelection, SvgSVGSelection } from "../util/d3Util";

export function insertCircleMarkDefs(plotArea: SvgGSelection): void {
  const gradientId = "circle-mark-gradient";

  insertSvgDefs(plotArea, gradientId, append);
}

type SelectionFn = (selection: SvgSVGSelection, id: string) => SvgSelection;

function insertSvgDefs(
  plotArea: SvgGSelection,
  defsId: string,
  appendFn: SelectionFn
): void {
  const plotNode = plotArea.node()!;
  const svgNode = plotNode.closest("svg")!;
  const svg = d3.select(svgNode);

  svg
    .selectAll(`#${defsId}`)
    .data([1])
    .join((enter) => appendFn(enter as unknown as SvgSVGSelection, defsId));
}

function append(svg: SvgSVGSelection, gradientId: string): SvgSelection {
  const gradient = svg.append("radialGradient").attr("id", gradientId);

  gradient
    .append("stop")
    .attr("offset", "5%")
    .attr("stop-color", "red")
    .attr("stop-opacity", ".5");

  gradient
    .append("stop")
    .attr("offset", "100%")
    .attr("stop-color", "red")
    .attr("stop-opacity", "0");

  return gradient as any;
}
