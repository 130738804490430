import { mat3 } from "gl-matrix";
import { BlendingOptions, DrawCommand, Framebuffer2D, Regl, Vec2 } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { scalesToProjection, ZoomRenderProps } from "./WebGLUtil";

type BlendType = "add" | "alpha" | "none" | "min";

export interface BlendSetupArgs {
  regl: Regl;
  framebuffer: Framebuffer2D | null;
  pixelsSize: Vec2;
  blendType: BlendType;
  normX: (x: number) => number;
  debug: ChartDebug;
}

export interface FixedProjectionArgs {
  regl: Regl;
  blendType: BlendType;
  debug: ChartDebug;
  projection: mat3;
}

export function fixedProjectionCmd(args: FixedProjectionArgs): DrawCommand {
  const { blendType, debug, projection, regl } = args;
  return regl({
    uniforms: {
      projection,
    },
    blend: blendOptions(blendType),
    profile: debug.showPerformance,
    framebuffer: null,
    depth: { enable: false, mask: false },
  });
}

export function blendAndProjection(args: BlendSetupArgs): DrawCommand {
  const { regl, debug, framebuffer, pixelsSize, blendType, normX } = args;
  return regl({
    uniforms: {
      projection: (_ctx, props: ZoomRenderProps) =>
        scalesToProjection(props.scales, pixelsSize, normX, debug),
    },
    blend: blendOptions(blendType),
    depth: { enable: false, mask: false },
    framebuffer,
    profile: debug.showPerformance,
  });
}

export const blendAdd: BlendingOptions = {
  enable: true,
  func: {
    src: "one",
    dst: "one",
  },
  equation: {
    rgb: "add",
    alpha: "add",
  },
  color: [0, 0, 0, 0],
};

export const blendAlpha: BlendingOptions = {
  enable: true,
  func: {
    srcRGB: "src alpha",
    srcAlpha: 1,
    dstRGB: "one minus src alpha",
    dstAlpha: 1,
  },
  equation: {
    rgb: "add",
    alpha: "add",
  },
  color: [0, 0, 0, 0],
};

export const blendNone: BlendingOptions = {
  enable: false,
};

export const blendMin: BlendingOptions = {
  enable: true,
  func: {
    src: "src color",
    dst: "dst color",
  },
  equation: {
    rgb: "min",
    alpha: "min",
  },
};

function blendOptions(blendType: BlendType): BlendingOptions {
  switch (blendType) {
    case "add":
      return blendAdd;
    case "alpha":
      return blendAlpha;
    case "none":
      return blendNone;
    case "min":
      return blendMin;
  }
}
