import REGL, { Regl } from "regl";

let myId = 1;

export function setupRegl(
  canvas: HTMLCanvasElement,
  performanceReport = false,
  pixelLogging = false
): Regl {
  const gl = canvas.getContext("webgl", {
    antialias: false,
    preserveDrawingBuffer: pixelLogging,
  })!;
  const regl = REGL({
    gl,
    attributes: {
      depth: false,
      stencil: false,
      antialias: false,
    },
    extensions: [
      "WEBGL_color_buffer_float",
      "WEBGL_draw_buffers",
      "EXT_blend_minmax",
      "OES_texture_float",
      "OES_texture_float_linear",
      "ANGLE_instanced_arrays",
      "EXT_float_blend",
    ],
    optionalExtensions: ["EXT_disjoint_timer_query"],
    pixelRatio: 1,
    profile: performanceReport,
  });
  (regl as any).myId = myId++;

  return regl;
}
