import { mapFrom } from "../util/Utils";
import { UnaryFn } from "./SplineMath";

/** Compile a function for calculating a polynomial
 * @param coefficients polynomial coefficients in ascending order
 */
export function MakePolynomialFn(coefficients: number[]): UnaryFn {
  const useStrict = "'use strict';";
  const a = coefficients.slice();
  const first = a.length > 0 ? a[0] : 0;
  const second = a.length > 1 ? `+ ${a[1]} * t` : "";
  const powers = mapFrom(a, 2, (c, i) => `const t${i} = t * ${prevT(i)};`);
  const powersLines = powers.join("\n");
  const summands = mapFrom(a, 2, (c, i) => `${c} * t${i}`);
  const polySum = " + " + summands.join(" + ");
  const sum = `${first} ${second} ${polySum}`;
  const returnSum = `return ${sum};`;
  const body = `
${useStrict}
${powersLines} 
${returnSum}
  `;
  //   console.log(body);
  return new Function("t", body) as UnaryFn;

  function prevT(i: number): string {
    if (i === 2) {
      return "t";
    } else {
      return `t${i - 1}`;
    }
  }
}

export function MakeCubicFn(coefficients: number[]): UnaryFn {
  const [a, b, c, d] = coefficients;
  return function (t: number): number {
    const t2 = t * t;
    const t3 = t2 * t;
    const sum = a + b * t + c * t2 + d * t3;
    return sum;
  };
}
