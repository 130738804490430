import { makeStyles, Slider, SliderProps, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { HelpButton } from "../help/HelpButton";

const useStyles = makeStyles(() => ({
  sliderBox: {
    display: "inline-block",
    position: "relative",
    left: 20,
    width: 200,
  },
  sliderText: {
    position: "relative",
    top: -10,
  },
}));

export interface LabeledSliderProps
  extends SliderProps,
    Pick<SliderProps, "value" | "min" | "max" | "step" | "marks" | "valueLabelFormat"> {
  label?: string;
  help?: ReactNode;
}

export const LabeledSlider = React.memo(LabeledSliderInternal);

function LabeledSliderInternal(props: LabeledSliderProps): JSX.Element {
  const classes = useStyles();

  // prettier-ignore
  const { onChange, label = "", min, value, max, step, marks, help, 
          valueLabelFormat, } = props;

  return (
    <div>
      <span className={classes.sliderText}>
        <Typography display="inline">{label}</Typography>
      </span>
      <span className={classes.sliderBox}>
        <Slider
          valueLabelDisplay="auto"
          {...{ value, onChange, min, max, step, marks, valueLabelFormat }}
        />
      </span>
      <HelpButton {...{ help }} />
    </div>
  );
}
