import { makeStyles } from "@material-ui/styles";

export const reactGridLayoutStyles = makeStyles({
  "@global": {
    ".react-resizible-hide": {
      display: "none",
    },
    ".react-grid-placeholder": {
      background: "grey",
      opacity: 0.2,
      transition: "100ms",
      zIndex: 2,
      userSelect: "none",
      borderRadius: 4,
    },
    ".react-resizable-handle": {
      position: "absolute",
      width: 30,
      height: 30,
      bottom: 0,
      right: 0,
      cursor: "se-resize",
    },
    ".react-grid-item:hover .react-resizable-handle::after": {
      content: `""`,
      position: "absolute",
      right: 5,
      bottom: 5,
      width: 12,
      height: 12,
      borderRight: "2px solid rgba(0, 0, 0, 0.15)",
      borderBottom: "2px solid rgba(0, 0, 0, 0.15)",
    },
  },
  reactGridLayout: {
    position: "relative",
    transition: "height 200ms ease",
  },
  reactGridItem: {
    backgroundColor: "#fafafa",
    transition: "all 200ms ease",
    transitionProperty: "left, top",
    "&.cssTransforms": {
      transitionProperty: "transform",
    },
    "&.promoted": {
      zIndex: 2,
    },
    "&.selected": {
      zIndex: 2,
    },
    "&.resizing": {
      zIndex: 3,
      willChange: ["width", "height"],
    },
    "&.react-draggable-dragging": { // order of rules is important for z-index priority
      zIndex: 4,
      transition: "none",
      willChange: "transform",
    },
    "&.dropping": {
      visibility: "hidden",
    },
    "&:not(.react-grid-placeholder) .dash-item:hover": {
      cursor: "move",
    },
  },
});
