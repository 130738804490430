import { makeStyles } from "@material-ui/core";
import React, { ChangeEvent, ForwardedRef, RefObject, useCallback } from "react";

const useStyles = makeStyles({
  hidden: {
    // from MDN
    position: "absolute",
    height: 1,
    width: 1,
    overflow: "hidden",
    clip: "rect(1px, 1px, 1px, 1px)",
  },
});

export interface HiddenFileInputProps {
  ref: RefObject<HTMLInputElement>;
  onFiles: (files: FileList) => void;
  accept?: string;
}

/** A component for asking the user to select a file to upload into a chart.
 *
 * The component is invisible. To use, the containing app should pass a 'ref' parameter
 * and call click() on the provided ref.
 */
export const HiddenFileInput = React.forwardRef(function HiddenFileInputInternal(
  props: HiddenFileInputProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const { accept, onFiles } = props;
  const classes = useStyles();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        onFiles(e.target.files);
      }
    },
    [onFiles]
  );

  return (
    <input ref={ref} {...{ accept, onChange }} type="file" className={classes.hidden} />
  );
});
