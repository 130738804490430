import memoizeOne from "memoize-one";
import { Regl } from "regl";
import { HeatScatterPlot } from "../chart/PlotModels";
import { Renderable, WebglRenderable } from "../chart/RenderPlot";
import { FrameAndSet } from "../data/ColumnFrame";
import { Vec2 } from "../math/Vec";
import { AnyTransition } from "../util/d3Util";
import { densityCommon } from "./DensityCommon";
import {
  DensityArgs,
  densityDraw,
  densityShading,
  transitionClearAndDraw,
} from "./DensityShading";
import { PointsBuffer, pointsBuffer } from "./PointsBuffer";
import { ScatterMarksCmd } from "./ScatterMarkShader";
import { ScatterCmd } from "./ScatterShader";

export type HeatScatterArgs = DensityArgs<HeatScatterPlot>;

export function heatScatter(regl: Regl): WebglRenderable<HeatScatterArgs> {
  return densityCommon<HeatScatterArgs>(regl, createRenderer);

  function createRenderer(
    regl: Regl,
    args: HeatScatterArgs
  ): Renderable<HeatScatterArgs> {
    const { canvas, plotRect, debug: propsDebug } = args;
    const pixelsSize: Vec2 = [canvas.width, canvas.height];
    const densityArgs: DensityArgs = { ...args, plotRect }; // CONSIDER changing densityRect in densityDraw instead of at init
    const {
      blurTexture: densityMap,
      densityFB,
      normX,
      cmds,
    } = densityShading(regl, densityArgs);
    const debug = propsDebug || {};

    function makePointsBuffer(frameAndSet: FrameAndSet): PointsBuffer {
      return pointsBuffer(regl, frameAndSet, normX);
    }
    const getPointsBuffer = memoizeOne(makePointsBuffer);

    // prettier-ignore
    const scatterArgs = { regl, debug, framebuffer: densityFB, pixelsSize, normX };
    const drawScatter = ScatterCmd(scatterArgs);

    const marksArgs = { ...scatterArgs, framebuffer: null, plotRect, densityMap };
    const drawMarks = ScatterMarksCmd(marksArgs);

    function draw(transition: AnyTransition, freshArgs: HeatScatterArgs): void {
      const { frameAndSet, zoomingScales: scales } = freshArgs;
      const { points, numPoints } = getPointsBuffer(frameAndSet);
      const { brightness, summaries, size: cssSize = 4, feather = 2 } = freshArgs.plot;
      const size = debug?.rawWidth
        ? cssSize
        : Math.round(cssSize * window.devicePixelRatio);

      if (size === 0) {
        drawDensityOnly();
      } else if (brightness > 0 || (summaries && summaries.length > 0)) {
        drawWithDensity();
      } else {
        drawMarksOnly();
      }

      function drawDensityOnly(): void {
        const renderDensity = (): void => drawScatter({ scales, points, numPoints });
        const noShowDensity = brightness === 0;
        densityDraw(regl, cmds, { ...freshArgs, noShowDensity }, transition, {
          renderDensity,
        });
      }

      function drawWithDensity(): void {
        const fadeRange: Vec2 = freshArgs.plot.smoothDensity ? [0.37, 0.16] : [3, 1]; // LATER a more principled way to set these constants
        const noShowDensity = brightness === 0;
        const skipEnabled = !noShowDensity;
        const argsWithFade = { ...freshArgs, noShowDensity, fadeRange };
        const renderMore = (): void =>
          drawMarks({ scales, points, size, feather, skipEnabled, numPoints });
        const renderDensity = (): void => drawScatter({ scales, points, numPoints });
        densityDraw(regl, cmds, argsWithFade, transition, { renderDensity, renderMore });
      }

      function drawMarksOnly(): void {
        const draw = (): void =>
          drawMarks({ scales, points, size, feather, skipEnabled: false, numPoints });
        transitionClearAndDraw(transition, regl, scales, draw, freshArgs.background);
      }
    }

    return { draw };
  }
}
