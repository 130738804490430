import { Regl } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { Vec2 } from "../math/Vec";
import { alignLine, AlignLineFn } from "./AlignLine";
import { blurCmd, gaussianFilter, nullFilter } from "./Blur";
import { DensityArgs } from "./DensityShading";
import { FramePercentile, PercentileOperation } from "./FramePercentile";
import { frameReduce, NzStatsOperation } from "./FrameReduce";
import { LineBuffers, singleLineBuffers } from "./LineBuffers";
import { DensityMapArgs, densityMapCmd } from "./MapDensity";
import { meanCmd, MeanProps } from "./Mean";
import { percentileLineCmd, PercentileLineProps } from "./PercentileLine";
import { smoothStatLineCmd, SmoothStatLineCmd } from "./SmoothStatLine";
import { fillStatValues, StatFillFn } from "./StatLineFill";
import { statsLineCmd } from "./StatsLine";
import { textureFB } from "./TextureFB";
import { ThickLineFn } from "./ThickLineShader";
import { FullFB } from "./WebGLUtil";

/** regl webgl shaders and buffers for working with the density map */
export interface DensityCmds {
  blur: () => void;
  blurFB: FullFB;
  mean: (meanProps: MeanProps) => void;
  percentileLine: (percentileProps: PercentileLineProps) => void;
  statLineFB: FullFB;
  statsLine: ThickLineFn;
  statFill: StatFillFn;
  statsLineBuffers: LineBuffers;
  statFillFB: FullFB;
  smoothStat: SmoothStatLineCmd;
  smoothStatFB1: FullFB;
  smoothStatFB2: FullFB;
  alignStat: AlignLineFn;
  alignStatFB: FullFB;
  mapDensity: (drawOptions?: DrawFrameOptions) => void;
  densityFB: FullFB;
  red: StatsCmds;
  green?: StatsCmds;
}

/** Internal interface, these fields can be changed with each drawn frame.
 * (Scales from the DensityArgs may also be changed with each drawn frame)
 */
interface DrawFrameOptions {
  brightness?: number;
  fadeRange?: Vec2;
}

/** setup regl webgl shaders and buffers for working with the density map */
export function setupCmds(regl: Regl, args: DensityArgs): DensityCmds {
  const { canvas, plotRect, debug = {}, percentilePasses } = args;
  const { colorScale, plot, greenStats } = args;
  const { smoothDensity } = plot;
  const canvasSize: Vec2 = [canvas.width, canvas.height];
  const pixelsSize = canvasSize;

  // LATER the density buffer could be smaller than the canvas due to the 3-4 pixel innerPlot
  // margin for drawing marks w/o clipping. For now the density buffer has an unused border of
  // a few pixels on the sides..
  const [densityTexture, densityFB] = textureFB(regl, canvasSize, {
    mag: "nearest", // This is the default anyway, but in case you were wondering.
    min: "nearest",
  });

  const separableFilter = smoothDensity ? gaussianFilter : nullFilter;
  const { blur, blurFB } = blurCmd(regl, densityTexture, separableFilter);

  const mean = meanCmd(regl, canvas.height);
  const percentileLine = percentileLineCmd(regl, canvas.height);
  const statFill = fillStatValues(regl, canvas.width); // TODO should this be statWidth?
  const smoothStat = smoothStatLineCmd(regl, 81, 20, 0.02); // TODO store filter kernel size in plot data
  const alignStat = alignLine(regl);
  const statWidth = canvas.width;
  const [, statLineFB] = textureFB(regl, [statWidth, 1]);
  const [, statFillFB] = textureFB(regl, [statWidth, 1]);
  const [, smoothStatFB1] = textureFB(regl, [statWidth, 1]);
  const [, smoothStatFB2] = textureFB(regl, [statWidth, 1]);
  const [, alignStatFB] = textureFB(regl, [statWidth, 1], {
    min: "linear",
    mag: "linear",
  });

  const statsLineProps = {
    debug,
    stats: statLineFB.color[0],
    plotRect,
    pixelsSize,
  };
  const statsLine = statsLineCmd(regl, statsLineProps);
  const statsLineBuffers = singleLineBuffers(regl, statWidth);

  let green: StatsCmds | undefined = undefined;
  const red = statsCmds(regl, blurFB, "nzStats", "percentile", debug, percentilePasses);
  if (greenStats)
    green = statsCmds(regl, blurFB, "nzStatsG", "percentileG", debug, percentilePasses);

  const densityMapArgs: DensityMapArgs = { colorScale };
  const mapDensity = densityMapCmd(
    regl,
    blurFB.color[0],
    plotRect,
    red.percentileFB.color[0],
    green ? green.percentileFB.color[0] : red.percentileFB.color[0],
    densityMapArgs
  );

  return {
    blur,
    blurFB,
    mean,
    percentileLine,
    statLineFB: statLineFB,
    statFill,
    statFillFB,
    statsLine,
    smoothStat,
    statsLineBuffers,
    smoothStatFB1,
    smoothStatFB2,
    alignStat,
    alignStatFB,
    mapDensity,
    densityFB,
    red,
    green,
  };
}

/** regl webgl shaders and buffers for collecting density statistics from a density buffer color channel */
interface StatsCmds {
  nzStats: () => void;
  nzFB: FullFB;
  percentile: (percentage: number | undefined) => void;
  percentileFB: FullFB;
}

/** setup regl webgl shaders and buffers for collecting stats from the density map */
function statsCmds(
  regl: Regl,
  src: FullFB,
  nzStatsOperation: NzStatsOperation,
  percentileOperation: PercentileOperation,
  debug: ChartDebug | undefined,
  percentilePasses: number | undefined
): StatsCmds {
  const nzResult = frameReduce(regl, src, nzStatsOperation);
  const { reduce: nzStats, reducedFB: nzFB, reducedTexture: nzTexture } = nzResult;

  const percentileResult = FramePercentile(regl, src, nzTexture, percentileOperation, {
    percentilePasses,
    debug,
  });
  const { percentileCmd, percentileFB } = percentileResult;
  return {
    nzStats,
    nzFB,
    percentile: percentileCmd,
    percentileFB,
  };
}
