import _ from "lodash";
import { Vec2 } from "../math/Vec";

const sin = Math.sin;
const cos = Math.cos;

export function endCapVertices(count: number): Vec2[] {
  const sliceAngle = Math.PI / (count + 1);
  let angle = sliceAngle;
  const vertices = _.times(count).map(() => {
    const vec: Vec2 = [sin(angle), cos(angle)];
    angle += sliceAngle;
    return vec;
  });

  return vertices;
}

export function circleVertices(count: number): Vec2[] {
  const sliceAngle = (2 * Math.PI) / (count + 1);
  let angle = sliceAngle;
  const vertices = _.times(count).map(() => {
    const vec: Vec2 = [sin(angle), cos(angle)];
    angle += sliceAngle;
    return vec;
  });

  return vertices;
}
