import { dsert } from "../util/DebugLog";
import { isRectangular, minMax, transpose } from "../util/Utils";

/** transpose rows into columns, adding empty strings as necessary to make rectangular */
export function transposeRows(rows: string[][]): string[][] {
  if (rows.length === 0) {
    return [[]];
  }
  const { min, max } = minMax(rows, (row) => row.length);
  if (min === max) {
    return transpose(rows)!;
  } else {
    const tabular = rows.map((row) => extendRow(row, max));
    dsert(isRectangular(tabular));
    return transpose(tabular)!;
  }
}

/** add empty strings to extend the row if necessary */
function extendRow(row: string[], targetLength: number): string[] {
  const add = targetLength - row.length;
  if (add > 0) {
    const extension = new Array(add).fill("");
    return row.concat(extension);
  } else {
    return row;
  }
}

/** Force string table rows into a constant number of columns.
 *
 * Too long rows are clipped.
 * Too short rows are extended with empty strings.
 */
export function fitTableToWidth(rowTable: string[][], fixedWidth: number): string[][] {
  return rowTable.map((row) => fitRow(row, fixedWidth));
}

function fitRow(row: string[], fixedWidth: number): string[] {
  if (row.length > fixedWidth) {
    return row.slice(0, fixedWidth);
  } else {
    return extendRow(row, fixedWidth);
  }
}
