import React from "react";
import { HeatScatterPlot } from "../../chart/PlotModels";
import { useCurrentPlot, useModifyPlot } from "../../store/Hooks";
import DensitySettings from "./DensitySettings";
import { FeatherSettings } from "./FeatherSettings";
import { LabeledSlider } from "./LabeledSlider";
import SettingsGroup from "./SettingsGroup";

export const ScatterSettings = React.memo(ScatterSettingsInternal);

function ScatterSettingsInternal(): JSX.Element {
  const plotKind = useCurrentPlot((plot) => plot.kind);
  const size = useCurrentPlot((plot: HeatScatterPlot) => plot.size)!;
  const setSize = useModifyPlot("size") as (event: any) => void;

  if (plotKind !== "HeatScatter") {
    console.log("ScatterSettings: unexpected plot kind:", plotKind);
    return <div></div>;
  }

  return (
    <div>
      <SettingsGroup title="Scatter">
        <LabeledSlider label="size:" min={0} max={20} value={size} onChange={setSize} />
        <FeatherSettings />
      </SettingsGroup>
      <DensitySettings />
    </div>
  );
}
