import { Framebuffer2D, Regl } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { blendAndProjection, BlendSetupArgs } from "./BlendAndProjection";
import { LineBuffers } from "./LineBuffers";
import * as ReglPerf from "./ReglPerf";
import { ZoomRenderProps } from "./WebGLUtil";

export interface ThinLinesArgs {
  regl: Regl;
  framebuffer: Framebuffer2D | null;
  pixelsSize: [number, number];
  normX: (x: number) => number;
  weightHorizontal: boolean;
  debug: ChartDebug;
}

export function ThinLinesCmd(args: ThinLinesArgs): (props: ThinLineProps) => void {
  const { regl } = args;
  const blendArgs: BlendSetupArgs = { ...args, blendType: "add" };
  const baseCmd = blendAndProjection(blendArgs);

  const lineCmd = regl({
    vert: `
      precision highp float;

      uniform vec2 start;
      uniform vec2 extent;
      uniform float minDensity;
      uniform mat3 projection;
      uniform vec2 canvasSize;
      uniform bool weightHorizontal;

      attribute float x;
      attribute float y;
      attribute float x2;
      attribute float y2;
      attribute float odd;

      // density contribution per pixel for this line
      // horizontal lines are full weight,
      // partially vertical lines are weighted proportionally less
      varying float weight;  

      void calcWeight(vec2 aClip, vec2 bClip) {
        if (!weightHorizontal) {
          weight = 1.0;
        } else {
          vec2 vClip = (odd == 0.0) ? abs(aClip- bClip) : abs(bClip - aClip); 
          vec2 p = vClip * canvasSize;  // point in screen coordinates

          // CONSIDER rounding to integers, to make pixel count more accurate
          float pixels = max(p.x, p.y); // estimated # of rasterized pixels
          weight = p.x / pixels;

          weight *= 100.0;  // scale up weights arbitrarily, so that scale legend has bigger numbers..

          // vertical lines are weighted at minDensity not zero so they don't disappear
          weight = clamp(weight, minDensity, 1.0); 
        }
      }

      void main() {
        vec2 a = vec2(x, y);
        vec2 b = vec2(x2, y2);
        vec2 aClip = (projection * vec3(a, 1)).xy;
        vec2 bClip = (projection * vec3(b, 1)).xy;
        
        calcWeight(aClip, bClip);

        vec2 webglVertex = odd == 0.0 ? aClip: bClip;
        gl_Position = vec4(webglVertex, 0, 1);
      }`,

    frag: `
      precision highp float;
      varying float weight;

      void main() {
        gl_FragColor = vec4(weight, 1, 1, 1);
      }`,
    // Vertex data is shared between successive line segments as in a line strip.
    // But we draw each line segment as a separate instance so that we can pass
    // both endpoints of a segment to each vertex shader. (A line strip
    // vertex shader for the whole strip would only sees the data for one endpoint
    // at a time. We want to pass two endpoints at a time, so we use one primitive
    // instance with two vertices for each line segment.)
    primitive: "lines",
    count: 2, // vertices per primitive
    instances: (_ctx, props: ThinLineProps) => props.lineBuffers.lines, // # of primitives
    attributes: {
      odd: [0, 1],
      // we want to get:
      //   instance 1  vertex1: o1,x1,y1
      //               vertex2: o2,x2,y2
      //   instance 2  vertex1: o1,x2,y2
      //               vertex2: o2,x3,y3
      // but only o (odd) changes per vertex:
      //   we send both x2,y1 and x2,y2 and then use o1,o2 to pick the appropriate x,y.
      //   we need both vertices to calculate slope anyway
      x: {
        buffer: (_ctx: any, props: ThinLineProps) => props.lineBuffers.xPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 0,
        divisor: 1,
      },
      x2: {
        buffer: (_ctx: any, props: ThinLineProps) => props.lineBuffers.xPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 1,
        divisor: 1,
      },
      y: {
        buffer: (_ctx: any, props: ThinLineProps) => props.lineBuffers.yPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 0,
        divisor: 1,
      },
      y2: {
        buffer: (_ctx: any, props: ThinLineProps) => props.lineBuffers.yPlus,
        offset: Float32Array.BYTES_PER_ELEMENT * 1,
        divisor: 1,
      },
      // for fine scale debugging:
      //   // In a four pixel canvas: pixels are 1/2 units wide in render coordinates.
      //   //                      |  •  |  •  |  •  |  •  |
      //   // pixel borders are: -1.0  -0.5   0.0   0.5   1.0
      //   // pixel centers are:    -0.75 -0.25  0.25  0.75
      //   buffer: [-1.0, -0.5, 0.0, 0.5, 1.0],
      // },
      // y: {
      //   buffer: [1.0, 0.5, 0.0, -0.5, -1.0], // make sure line crosses over some pixel centers
    },
    uniforms: {
      minDensity: 1e-37, // only used with weightHorizontal
      weightHorizontal: (_ctx, props: ThinLineProps) => props.weightHorizontal,
      canvasSize: (ctx) => [ctx.drawingBufferWidth, ctx.drawingBufferHeight],
    },
    lineWidth: 1, // note that 2 doesn't work on my macos laptop
  });

  const draw = (props: ThinLineProps): void => {
    baseCmd(props, () => lineCmd(props));
  };
  ReglPerf.registerCmd(lineCmd, "thinLines");

  return draw;
}

export interface ThinLineProps extends ZoomRenderProps {
  weightHorizontal: boolean;
  lineBuffers: LineBuffers;
}
