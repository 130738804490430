import memoizeOne from "memoize-one";
import { FrameAndSet, frameToXyArray } from "../data/ColumnFrame";
import { AnyTransition, SvgGSelection } from "../util/d3Util";
import { Renderable } from "./RenderPlot";
import { insertCircleMarkDefs } from "./SvgDefs";
import { SvgRendererArgs } from "./SvgLinePlot";

export interface SvgScatterArgs extends SvgRendererArgs {
  plot: { translucent?: boolean };
}

export default function svgCircles(plotArea: SvgGSelection): Renderable<SvgScatterArgs> {
  const dataArray = memoizeOne((frameAndSet: FrameAndSet) => {
    return frameToXyArray(frameAndSet);
  });

  insertCircleMarkDefs(plotArea);

  function draw(transition: AnyTransition, args: SvgScatterArgs): void {
    const { plot, frameAndSet, targetScales } = args;
    const { translucent = false } = plot;
    const data = dataArray(frameAndSet);

    plotArea
      .selectAll(".circle-mark")
      .data(data)
      .join((enter) => enter.append("circle").attr("class", "circle-mark").attr("r", 4))
      .classed("translucent", translucent)
      .transition(transition)
      .attr("cx", (d) => targetScales.x(d[0])!)
      .attr("cy", (d) => targetScales.y(d[1])!);
  }

  return {
    draw: draw,
  };
}
