import { MenuItem } from "@material-ui/core";
import React, { useCallback } from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { useLocalDataSocket } from "./useLocalDataSocket";

export interface LocalDataMenuItemProps {
  closeMenu: () => void;
}

/** Menu item for listening to local data */
export function useDataListenMenuItem(props: LocalDataMenuItemProps): () => JSX.Element {
  const { closeMenu } = props;
  const { listen, close, listening } = useLocalDataSocket();
  const listenForConnections = useStoreState((app) => app.data.listenForConnections);
  const modifyDataSources = useStoreActions((app) => app.modifyDataSources);

  const start = useCallback(() => {
    closeMenu();
    listen();
    modifyDataSources({ listenForConnections: true });
  }, [closeMenu, listen, modifyDataSources]);

  const stop = useCallback(() => {
    closeMenu();
    close();
    modifyDataSources({ listenForConnections: false });
  }, [closeMenu, close, modifyDataSources]);

  if (listenForConnections && !listening()) {
    start();
  }

  const fn = useCallback(() => {
    if (listening()) {
      return <MenuItem onClick={stop}>Stop Listening for Data</MenuItem>;
    } else {
      return <MenuItem onClick={start}>Listen for Data</MenuItem>;
    }
  }, [listening, start, stop]);
  return fn;
}
