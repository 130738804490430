/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from "lodash";
import * as stats from "simple-statistics";
import { probit } from "simple-statistics";
import { FrameAndColumns } from "../components/rich-chart/DashChart";
import {
  arrayToFrame,
  fnToFrame,
  fnToFrameGenerator,
  meanVarianceFrame,
  simpleChartFrame,
} from "../data/frameGenerators";
import { pseudoRandom } from "../math/PsuedoRandom";
import { CenteredWalk, logNormalGenerator, mixGenerator } from "../math/RandomWalk";
import { unitPartitions } from "../math/TableInterpolator";
import { samplesFromSplinePDFApprox } from "../math/VaryingDensity";
import { Vec2 } from "../math/Vec";
import { histogramFrame } from "./HistogramFrame";
import { varying1Shape, varying2Shape, varying4Shape } from "./VaryingExample";

const abs = Math.abs;
const sin = Math.sin;
const log = Math.log;

/** generated data with varying density on the x coordinate.
 * y does a random walk on a down trending cosine curve.
 */
export function varying4(length: number): FrameAndColumns {
  const xs = samplesFromSplinePDFApprox(varying4Shape, length);
  const next = CenteredWalk();
  const ys = xs.map((x) => next() + 4 * Math.cos(3.5 * x));
  return simpleChartFrame(xs, ys, "varying4");
}

/** generated data with uniform density on the x coordinate
 * y follows a trend sine curve up and down, and varies in density.
 */
export function dataSet1(length: number): FrameAndColumns {
  const rand = pseudoRandom();
  const xs = unitPartitions(length);

  const ys = xs.map((x) => {
    const trend = Math.sin(x * 4);
    const yDensity = 0.4 + Math.sin(x * 5) / 6;
    const variation = stats.probit(rand()) * yDensity;

    return trend + variation;
  });

  return simpleChartFrame(xs, ys, "set1");
}

/** example for developing variance plotting */
export function meanVariance1(length: number): FrameAndColumns {
  const xs = unitPartitions(length);
  const jitter = CenteredWalk(0.01, 0, 0.2, "foo");
  const means = xs.map((x) => jitter() + 0.5 * sin(Math.PI * 0.6 * x) - x ** 2);
  const variances = xs.map((x) => 0.05 + 0.03 * sin(4 * Math.PI * x));
  return meanVarianceFrame(xs, means, variances, "meanVariance1");
}

/** example where skew of data varies  */
export function skew(length: number): FrameAndColumns {
  const rand = pseudoRandom();
  const xs = samplesFromSplinePDFApprox(varying1Shape, length);
  const samples = xs.map((x) => {
    const base = 1 + 1 / (1 + Math.exp(-(x * 10 - 5)));
    const skew = Math.cos((x * x + 0.2) * 6) / 2;
    const skewMinDispersion = 0.1;
    const skewSample = abs(probit(rand())) * skew + rand() * skewMinDispersion;
    const next = base + skewSample;
    return next;
  });

  return simpleChartFrame(xs, samples, "skew");
}

/** future example */
export function wip(length: number): FrameAndColumns {
  const xs = unitPartitions(length);

  const jitter = CenteredWalk(0.2, 0, 0.2);
  const ys = xs.map((x) => {
    const base = x + sin(20 * x) / (20 * x);
    return base + jitter();
  });

  return simpleChartFrame(xs, ys, "density1");
}

/** example where slope weighted density reveals a hidden pattern */
export function slopy(length: number): FrameAndColumns {
  const rand = pseudoRandom();
  const data: Vec2[] = unitPartitions(length * 0.9).map((x) => [x, probit(rand())]);
  data.push([NaN, NaN]); // hack to display a second line..
  const wavy = (x: number) => 1 + sin(x * 10) / 8 - 2 * x;
  const w: Vec2[] = unitPartitions(100).map((x) => [x, wavy(x)]);
  data.push(...w);
  return arrayToFrame(data);
}

/** example with a difference between mean and median */
export function meanMedian(length: number): FrameAndColumns {
  const rand = pseudoRandom();
  const points = unitPartitions(length / 2).flatMap((t) => {
    const [x, y] = varying2Shape(t);
    const a = y + probit(rand());
    const b = 2 + abs(2 - y) + probit(rand()) / 8;
    return [
      [x, a],
      [x, b],
    ] as Vec2[];
  });
  return arrayToFrame(points);
}

/** example with a pattern hidden in an overplotted area */
export function overplot(length: number): FrameAndColumns {
  const wave = waveFn();
  const curve = curveFn();
  const mix = mixGenerator(wave, curve);

  return fnToFrame(mix, length);
}

/* a gentle curve with logNormal jitter */
function curveFn(): (x: number) => number {
  const jitter = logNormalGenerator();
  return (x: number) => {
    const base = 1 + x ** 2 - (x * 2 - 1) ** 2;
    return base + jitter();
  };
}

function waveFn(): (x: number) => number {
  const rand = pseudoRandom();
  const jitter = () => probit(rand()) / 5;
  return function (x: number): number {
    const base = 2.5 + sin(x * 11) / 4;
    return base + jitter();
  };
}

/* some experiments with statistical distributions */

export const logNorm = fnToFrameGenerator(logNormalGenerator(log(2), 1));

export function logNormalPDF(length: number): FrameAndColumns {
  const sampler = logNormalGenerator(log(10), 1);
  const ys = unitPartitions(length).map(sampler);
  return histogramFrame(ys, 500);
}

export function bimodalPDF(length: number): FrameAndColumns {
  const rand = pseudoRandom("foo");
  const rand2 = pseudoRandom("bar");
  const sampler = mixGenerator(
    () => probit(rand()) + 0,
    () => probit(rand2()) + 4,
    0.7
  );
  const ys = _.times(length).map(sampler);
  return histogramFrame(ys, 500);
}

export function bimodal(length: number): FrameAndColumns {
  const rand = pseudoRandom("foo");
  const rand2 = pseudoRandom("bar");
  const sampler = mixGenerator(
    () => probit(rand()) + 0,
    () => probit(rand2()) + 4,
    0.7
  );
  return fnToFrame(sampler, length);
}
