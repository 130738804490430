/** Read continually from a stream, calling a function on each read value.
 * Stop reading from the strem when the promise returned by the function rejects or returns true.
 */
export async function readStreamUntil<T>(
  stream: ReadableStream<T>,
  fn: (t: T) => Promise<boolean>
): Promise<void> {
  const reader = stream.getReader();
  let done = false;
  while (!done) {
    const readResult = await reader.read();
    if (readResult.value) {
      done = await fn(readResult.value).catch(() => true);
    }
    if (!done) {
      done = readResult.done;
    }
  }
}
