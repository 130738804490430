import _ from "lodash";
import { Regl, Texture2D } from "regl";
import { Vec2 } from "../math/Vec";
import { FullFB } from "./WebGLUtil";

export interface ReductionFBsResult {
  reduceFBs: FullFB[];
  debugLastFBs: FullFB[]; // all bucket textures in the last 1x1 frame, bound to their own fbo for debug access
}

/**
 * @returns an array of framebuffers in decreasing size
 * @param reduce each framebuffer is smaller than the previous by
 *    this factor in x and y.
 *    the last framebuffer in the array will have a size of 1 x 1
 * @param numTextures number of textures to attach to each frame buffer
 * @param extraFinalTexture additional texture to attach to the final 1x1 frame buffer
 */
export function reductionFBs(
  regl: Regl,
  origSize: Vec2,
  reduce: Vec2,
  numTextures = 1,
  extraFinalTexture?: Texture2D
): ReductionFBsResult {
  const sizes = reductionSizes(origSize, reduce);
  let debugLastFBs: FullFB[] = [];

  const reduceFBs = [...sizes()].map((size) => {
    const colors = _.times(numTextures).map(() =>
      regl.texture({
        shape: size,
        format: "rgba",
        type: "float",
      })
    );
    if (lastReduction(size)) {
      debugLastFBs = colors.map(
        (texture) =>
          regl.framebuffer({
            color: texture,
          }) as FullFB
      );
    }
    colors.push(...extraTexture(size));
    return regl.framebuffer({
      colors,
    }) as FullFB;
  });

  return { reduceFBs, debugLastFBs };

  function lastReduction(size: [number, number]): boolean {
    return size[0] === 1 && size[1] === 1;
  }

  function extraTexture(size: [number, number]): Texture2D[] {
    if (extraFinalTexture && lastReduction(size)) {
      return [extraFinalTexture];
    } else {
      return [];
    }
  }
}

export function reductionSizes(
  origSize: Vec2,
  reduce: Vec2
): () => Generator<Vec2, void, unknown> {
  function nextSize(dims: Vec2): Vec2 {
    const x = Math.ceil(dims[0] / reduce[0]);
    const y = Math.ceil(dims[1] / reduce[1]);
    return [x, y] as Vec2;
  }

  function* sizes(): Generator<Vec2, void, unknown> {
    let curSize: Vec2 = origSize.slice() as Vec2;
    while (curSize[0] > 1 || curSize[1] > 1) {
      curSize = nextSize(curSize);
      yield curSize;
    }
  }

  return sizes;
}
