import _ from "lodash";
import { dlog } from "./DebugLog";

/** like map, but doesn't have to start at index 0 */
export function mapFrom<T, U>(
  a: T[],
  start: number,
  fn: (value: T, index: number) => U
): U[] {
  const result: U[] = [];
  for (let i = start; i < a.length; i++) {
    const value = fn(a[i], i);
    result.push(value);
  }
  return result;
}

// SOON look at adopting wu or itiriri
export function* tail<T>(a: Iterable<T>): Generator<T, void, unknown> {
  const iter = a[Symbol.iterator]();
  iter.next(); // skip first
  let next = iter.next();
  while (!next.done) {
    yield next.value;
    next = iter.next();
  }
}

export function* head<T>(a: Iterable<T>): Generator<T, void, unknown> {
  const iter = a[Symbol.iterator]();
  const next = iter.next();
  if (!next.done) {
    yield next.value;
  }
}

// sliding window of size 2
export function pairs<T>(a: Iterable<T>): Generator<[T, T], void, unknown> {
  return zip2(a, tail(a));
}

export function* zip2<T>(
  a: Iterable<T>,
  b: Iterable<T>
): Generator<[T, T], void, unknown> {
  const iterA = a[Symbol.iterator]();
  const iterB = b[Symbol.iterator]();
  let nextA = iterA.next();
  let nextB = iterB.next();
  while (!nextA.done && !nextB.done) {
    yield [nextA.value, nextB.value];
    nextA = iterA.next();
    nextB = iterB.next();
  }
}

export function* count(c: number): Generator<number, void, unknown> {
  let a = 0;
  while (a < c) {
    yield a;
    a++;
  }
}

export function* takeWhile<T>(
  gen: Generator<T, void, undefined>,
  fn: (value: T) => boolean
): Generator<T> {
  for (const value of gen) {
    if (fn(value)) {
      yield value;
    } else {
      break;
    }
  }
}

export const identityFn = <T>(x: T): T => x;

type ObjOrArray = Record<string, unknown> | Array<unknown>;

export function removeKeysDeep(obj: ObjOrArray, prefix: string): ObjOrArray {
  return removeDeep(obj, (_value: any, key: string) => key === prefix);
}

/** return a copy of an object with fields removed by a filter function */
export function removeDeep(
  src: ObjOrArray,
  fn: (value: any, key: string, path: string[]) => boolean
): ObjOrArray {
  return removeRecursive(src, []) as ObjOrArray;

  function removeRecursive(obj: unknown, path: string[]): unknown {
    if (_.isArray(obj)) {
      return obj.map((elem) => removeDeep(elem, fn));
    }

    if (_.isObject(obj)) {
      const filtered = Object.entries(obj).filter(
        ([key, value]) => !fn(value, key, path)
      );
      const copies = filtered.map(([key, value]) => [
        key,
        removeRecursive(value, path.concat([key])),
      ]);
      return Object.fromEntries(copies);
    }

    return obj;
  }
}

/** replace undefined fields with a default value */
export function replaceUndefined<T extends Partial<U>, U>(obj: T, defaults: U): T & U {
  const result = { ...defaults, ...removeUndefined(obj) };
  return result;
}

/** @return a copy, eliding fields with undefined values */
export function removeUndefined<T>(obj: T): T {
  const result = { ...obj };
  for (const key in result) {
    if (result[key] === undefined) {
      delete result[key];
    }
  }
  return result;
}

export function zip2tuple<A, B>(as: A[], bs: B[]): [A, B][] {
  const result: [A, B][] = new Array(as.length);
  for (let i = 0; i < as.length; i++) {
    result[i] = [as[i], bs[i]];
  }
  return result;
}

export function transpose<T>(rowTable: T[][]): T[][] | undefined {
  if (rowTable.length === 0) {
    return [[]];
  }

  if (!isRectangular(rowTable)) {
    // LATER get rid of this
    const firstLength = rowTable[0].length;
    const oddRows = rowTable.filter((row) => row.length !== firstLength);
    const firstOddRow = oddRows[0];
    dlog("not rectangular", {
      firstLength,
      oddRowLength: firstOddRow.length,
      oddRowsCount: oddRows.length,
      firstOddRow,
    });
    return undefined;
  }

  const numColumns = rowTable[0].length;
  const colTable = new Array(numColumns);
  for (let i = 0; i < numColumns; i++) {
    colTable[i] = new Array(rowTable.length);
  }
  rowTable.forEach((row, rowDex) => {
    row.forEach((item, colDex) => {
      colTable[colDex][rowDex] = item;
    });
  });

  return colTable;
}

export function isRectangular<T>(rowTable: T[][]): boolean {
  if (rowTable.length === 0) {
    return true;
  }
  const rowLengths = rowTable.map((row) => row.length);
  const firstLength = rowLengths[0];
  return rowLengths.every((l) => l == firstLength);
}

export function isAsyncFunction(fn: () => void): boolean {
  return fn.constructor.name === "AsyncFunction";
}

export function invertMap<K, V>(map: Map<K, V>): Map<V, K> {
  const invertedEntries: [V, K][] = [...map.entries()].map(([k, v]) => [v, k]);
  return new Map(invertedEntries);
}

/** @return a function that caches a single value with a deep equality check.
 */
export function cacheOneDeep<T>(): (value: T) => T {
  let saved: { str: string; value: T } | undefined = undefined;

  return function (value: T) {
    const stringValue = JSON.stringify(value);
    if (!saved || saved.str != stringValue) {
      saved = { str: stringValue, value };
    }
    return saved.value;
  };
}

/** Combines two async generators into an async tuple generator */
export async function* zipAsync<A>(
  a: AsyncGenerator<A, void>,
  b: AsyncGenerator<A, void>
): AsyncGenerator<[A, A], void> {
  for (;;) {
    const aNextPromise = a.next();
    const bNextPromise = b.next();
    const aNext = await aNextPromise;
    const bNext = await bNextPromise;
    if (aNext.done || bNext.done) {
      break;
    }
    const aValue = aNext.value;
    const bValue = bNext.value;
    yield [aValue, bValue];
  }
}

/** return the count of element values in an array */
export function histogram<T>(elems: T[]): [T, number][] {
  const byCount = new Map<T, number>();
  elems.forEach((elem) => {
    const n = byCount.get(elem) || 0;
    byCount.set(elem, n + 1);
  });
  return [...byCount.entries()];
}

/** return the most popular bucket in a histogram */
export function histogramMost<T>(counted: [T, number][]): [T, number] | undefined {
  if (counted.length === 0) {
    return undefined;
  }

  const mostFrequent = counted.reduce((a, b) => {
    if (a[1] >= b[1]) {
      return a;
    } else {
      return b;
    }
  });

  return mostFrequent;
}

/** type an array as a tuple */
export function tuple<T extends unknown[]>(args: [...T]): T {
  return args;
}

export interface MinMax {
  min: number;
  max: number;
}

/** @return the min and maxiumum of an array,
 * given a helper function that converts array elements into a number
 */
export function minMax<T>(array: Array<T>, fn: (elem: T) => number): MinMax {
  if (array.length === 0) {
    return { min: NaN, max: NaN };
  }

  let min = fn(array[0]);
  let max = min;

  for (let i = 1; i < array.length; i++) {
    const current = fn(array[i]);
    min = Math.min(current, min);
    max = Math.max(current, max);
  }
  return { min, max };
}

interface HasNumericEvery {
  every: (fn: (a: number, index: number) => boolean) => boolean;
  [i: number]: number;
}

/** @return true if the array is sorted */
export function checkSorted(array: HasNumericEvery): boolean {
  return array.every((element, i) => i === 0 || element >= array[i - 1]);
}
