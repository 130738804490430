import { Button, Menu, MenuItem } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useState } from "react";
import { DensityPlotData } from "../../chart/PlotModels";
import { useCurrentPlot, useStoreActions } from "../../store/Hooks";
import { useModalContainer } from "../dash-controls/ModalContainer";

const useStyles = makeStyles({
  plus: {
    marginRight: 5,
  },
  buttonText: {
    paddingTop: 2.5,
  },
});

export function AddStatButton(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [addAnchor, setAddAnchor] = useState<HTMLElement | null>(null);
  const addSummary = useStoreActions((app) => app.addPlotSummary);
  const container = useModalContainer();

  const hasMean = useCurrentPlot(
    (plot: DensityPlotData) =>
      plot.summaries?.find((s) => s.kind === "mean") !== undefined
  );

  const addMean = useCallback(() => {
    setOpen(false);
    addSummary({ kind: "mean", thickness: 2, feather: 2 });
  }, [setOpen, addSummary]);

  const addPercentile = useCallback(() => {
    setOpen(false);
    addSummary({ kind: "percentile", percentile: 0.5, thickness: 2, feather: 2 });
  }, [setOpen, addSummary]);

  const onClose = useCallback(() => {
    setOpen(false);
    setAddAnchor(null);
  }, [setAddAnchor, setOpen]);

  const toggleMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setAddAnchor(event.currentTarget);
      setOpen(!open);
    },
    [open, setOpen, setAddAnchor]
  );

  const addChoices: JSX.Element[] = [];
  if (!hasMean) {
    addChoices.push(
      <MenuItem key="mean" onClick={addMean}>
        Mean
      </MenuItem>
    );
  }
  addChoices.push(
    <MenuItem key="percentile" onClick={addPercentile}>
      Percentile
    </MenuItem>
  );

  return (
    <>
      <Button onClick={toggleMenu}>
        <AddIcon className={classes.plus} />{" "}
        <span className={classes.buttonText}>Add Statistic</span>
      </Button>
      <Menu
        anchorEl={addAnchor}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        {...{ container, open, onClose }}
      >
        {addChoices}
      </Menu>
    </>
  );
}
