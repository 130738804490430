import React, { useCallback } from "react";
import { useStoreActions } from "../../store/Hooks";
import { ExternalTableSource } from "../../store/TableSource";
import { fileNoSuffix } from "../../util/PathUtil";
import { asURL } from "../../util/StringUtil";
import { useTabularData } from "./useTabularData";

export const pasteTextFormat = "text/plain";

/* handle pasting data on the dashboard by creating a new chart */
export function usePasteData(): (e: React.ClipboardEvent) => void {
  const toUseTabular = useTabularData();
  const externalTableChart = useExternalTableChart();

  const onPaste = useCallback(
    (e: React.ClipboardEvent) => {
      const hasText = e.clipboardData.types.includes(pasteTextFormat);
      if (hasText) {
        const text = e.clipboardData.getData(pasteTextFormat);
        const url = asURL(text);
        if (url) {
          externalTableChart(url);
        } else {
          const handleTabular = toUseTabular();
          handleTabular(text, { chartName: "pasted", editMode: "forceNew" });
        }
      }
    },
    [toUseTabular, externalTableChart]
  );

  return onPaste;
}

/** create a new chart that references an external table */
export function useExternalTableChart(): (url: URL) => void {
  const editChartData = useStoreActions((app) => app.editChartData);
  return useCallback(
    (url: URL): void => {
      const name = fileNoSuffix(url.pathname);
      const tableSource: ExternalTableSource = {
        kind: "external",
        name,
        url: url.toString(),
      };
      editChartData({ dataEdit: { editMode: "forceNew", chartName: name }, tableSource });
    },
    [editChartData]
  );
}
