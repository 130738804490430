import { makeStyles, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { HelpButton } from "../help/HelpButton";

interface SettingsGroupProps {
  title?: string;
  children?: ReactNode;
  help?: ReactNode;
}

export const settingsGroupIndent = 15;

const useStyles = makeStyles({
  settingsGroup: {
    marginTop: 0,
    marginBottom: 10,
    marginLeft: settingsGroupIndent,
  },
  titleContainer: {
    marginTop: 10,
    marginBottom: 5,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    display: "inline-block",
  },
});

export default function SettingsGroup(props: SettingsGroupProps): JSX.Element {
  const classes = useStyles();
  const { help, title, children } = props;

  function Title(): JSX.Element {
    if (title) {
      return (
        <div className={classes.titleContainer}>
          <Typography className={classes.titleText}> {props.title} </Typography>
          <HelpButton {...{ help }} />
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <Title />
      <div className={classes.settingsGroup}>{children}</div>
    </>
  );
}
