import _ from "lodash";
import { DataArray } from "../data/DataChunk";
import { identityFn } from "../util/Utils";
import { JsonNumberParser } from "./DetectCellFormat";
import { detectColumnType } from "./DetectColumnTypes";
import { DateParser } from "./DetectDateFormat";
import { baseJsonScore } from "./DetectJsonTable";
import { JsonTableParser, TabularColumn } from "./ParseTabular";

export const jsonNumberParser: JsonNumberParser = {
  parseType: "jsonNumber",
  parser: identityFn,
  parseResultType: "number",
};

/** table parser for single numberic json column */
export const numberColumnParser: JsonTableParser = (() => {
  const dataParser = jsonNumberParser;
  const columns: TabularColumn[] = [{ label: "[A]", dataParser }];
  const jsonParser: JsonTableParser = {
    score: baseJsonScore() + 1,
    kind: "json",
    parse: parseSingleNumberColumn,
    parseBody: "???" as any, // unused
    columns,
    format: "single",
  };
  return jsonParser;
})();

export function stringColumnParser(array: string[]): JsonTableParser {
  const { dataParser } = detectColumnType(array);
  const columns: TabularColumn[] = [{ label: "[A]", dataParser }];
  const { parser } = dataParser;

  function parse(text: string): DataArray[] {
    const strings = JSON.parse(text) as string[];
    const result = strings.map(parser as DateParser);
    if (typeof _.head(result) === "number") {
      return [new Float64Array(result)];
    } else {
      return [result as unknown as string[]];
    }
  }

  const jsonParser: JsonTableParser = {
    score: baseJsonScore() + 1,
    kind: "json",
    parse,
    parseBody: parse,
    columns,
    format: "single",
  };
  return jsonParser;
}

function parseSingleNumberColumn(text: string): DataArray[] {
  const numbers = JSON.parse(text) as number[];
  const data = new Float64Array(numbers);
  return [data];
}
