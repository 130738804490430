import React, { useCallback, useState } from "react";
import { XyDimensions } from "../../chart/Chart";
import { DensityPlotData } from "../../chart/PlotModels";
import ChartReact from "../../ChartReact";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { ColumnSet } from "../../store/TableSource";
import { bodyColor } from "./BodyColor";
import { ChartButtons } from "./ChartButtons";
import { ChartDropzone } from "./ChartDropzone";
import { ChartFrame } from "./DashChart";
import { DensityLegend } from "./DensityLegend";
import { LoadingProgress } from "./LoadingProgress";
import { useDensityListener } from "./UseDensityListener";

export interface ChartElementsProps {
  dashId: DashId;
  chartFrame: ChartFrame;
  columnSet: ColumnSet | undefined;
  container: HTMLDivElement;
  title?: string;
}

export const ChartElements = React.memo(ChartElementsInternal);

function ChartElementsInternal(props: ChartElementsProps): JSX.Element {
  const { dashId, chartFrame, title, container, columnSet } = props,
    { frame: columnFrame, descriptor: frameDescriptor } = chartFrame,
    zoom = useStoreState((app) => app.findChart(dashId)?.zoom),
    resetZoom = useStoreState((app) => app.findChart(dashId)?.resetZoom),
    chartDebug = useStoreState((app) => app.debug),
    modifyChartById = useStoreActions((app) => app.modifyChartById),
    plot = useStoreState((app) => app.findChart(dashId)?.plot),
    density = useDensityListener(container);

  const [background] = useState(bodyColor);
  const brightness: number | undefined = (plot as DensityPlotData).brightness; // undefined if not a DensityPlot

  const viewChanged = useCallback(
    (zoom: XyDimensions) => {
      modifyChartById({ dashId, chartPartial: { zoom, resetZoom: undefined } });
    },
    [modifyChartById, dashId]
  );

  return (
    <>
      <ChartReact
        frame={columnFrame}
        fixedExtent={frameDescriptor?.extent}
        plot={plot!}
        debug={chartDebug}
        zoomChanged={viewChanged}
        {...{ title, zoom, resetZoom, background, columnSet }}
      />
      <DensityLegend
        dashId={dashId}
        min={density.min}
        max={density.max}
        threshold={density.threshold}
        brightness={brightness}
      />
      <ChartDropzone dashId={dashId} />
      <LoadingProgress dashId={dashId} />
      <ChartButtons frame={columnFrame} dashId={dashId} />
    </>
  );
}
