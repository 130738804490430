import { Action, action, Computed, computed, State } from "easy-peasy";
import _ from "lodash";
import { Layout } from "react-grid-layout";
import { AnyAction } from "redux";
import { undoable, WithUndo } from "undo-peasy";
import {
  DensityPlotData,
  isDensityPlot,
  MeanStat,
  PercentileStat,
  Plot,
  setPlotKind,
  SummaryId,
  SummaryStat,
} from "../chart/PlotModels";
import { removeUndefined } from "../util/Utils";
import appDebug, { AppDebug } from "./AppDebug";
import { AppHelp, appHelpModel } from "./AppHelp";
import appView, { AppView } from "./AppView";
import { ChartModel } from "./ChartModel";
import dashboardModel, { DashboardModel } from "./DashboardModel";
import { DashId } from "./DashId";
import { BareDashItem, DashItem } from "./DashItems";
import { DataSourcesModel, dataStoreModel } from "./DataSourcesModel";
import { editChartData, EditChartDataProps } from "./EditChartData";
import { noSaveKeys } from "./Store";
import { ColumnSet, LoadedTableSource, noTableSource, TableSource } from "./TableSource";

export interface AppModel extends WithUndo {
  dashboard: DashboardModel;
  debug: AppDebug;
  view: AppView;
  data: DataSourcesModel;
  setPlot: Action<AppModel, Plot>;
  help: AppHelp;
  modifyPlot: Action<AppModel, Partial<Plot>>;
  modifyPlotKind: Action<AppModel, Plot["kind"]>;
  modifyPlotSummary: Action<AppModel, ModifySummaryProps>;
  removePlotSummary: Action<AppModel, RemoveSummaryProps>;
  addPlotSummary: Action<AppModel, AddSummaryProps>;
  modifyChart: Action<AppModel, Partial<ChartModel>>;
  editChartData: Action<AppModel, EditChartDataProps>;
  modifyChartById: Action<AppModel, ModifyChartByIdProps>;
  modifyChartByTag: Action<AppModel, ModifyChartByTagProps>;
  modifyPlotById: Action<AppModel, ModifyPlotByIdProps>;
  setTableSource: Action<AppModel, ChangeTableSourceProps>;
  modifyDataById: Action<AppModel, ModifyDataByIdProps>;
  modifyDebug: Action<AppModel, Partial<AppDebug>>;
  modifyHelp: Action<AppModel, Partial<AppHelp>>;
  modifyView: Action<AppModel, Partial<AppView>>;
  currentPlot: Computed<AppModel, Plot | undefined>;
  currentChart: Computed<AppModel, ChartModel | undefined>;
  currentSelection: Computed<AppModel, DashItem | undefined>;
  findDashItem: Computed<AppModel, (id: DashId) => DashItem | undefined>;
  modifyDashItem: Action<AppModel, ModifyDashItemProps>;
  findChart: Computed<AppModel, (id: DashId) => ChartModel | undefined>;
  removeDashItem: Action<AppModel, DashId>;
  replaceDashboard: Action<AppModel, DashboardModel>;
  addDashItem: Action<AppModel, AddDashItemProps>;
  modifyDataSources: Action<AppModel, Partial<DataSourcesModel>>;
  tagsToDashIds: Computed<AppModel, (tags: number[]) => TagDashId[]>;
}

export interface AddDashItemProps {
  item: BareDashItem;
  width: number;
  height: number;
  addTag?: number;
}

export interface ModifyDashItemProps {
  dashId: DashId;
  dashPartial: Partial<DashItem>;
}

export interface RemoveSummaryProps {
  dashId: DashId;
  summaryId: number;
}

export interface ModifySummaryProps {
  dashId: DashId;
  summaryId: number;
  summaryPartial: Partial<SummaryStat>;
}

export type AddSummaryProps =
  | Omit<MeanStat, "summaryId">
  | Omit<PercentileStat, "summaryId">;

export interface ModifyDataByIdProps {
  dashId: DashId;
  dataPartial: Partial<LoadedTableSource>;
}

export interface ModifyChartByIdProps {
  dashId: DashId;
  chartPartial: Partial<ChartModel>;
}

export interface ModifyChartByTagProps {
  addTag: number;
  chartPartial: Partial<ChartModel>;
}

export interface ModifyPlotByIdProps {
  dashId: DashId;
  plotPartial: Partial<Plot>;
}

export interface ChangeTableSourceProps {
  dashId: DashId;
  tableSource: TableSource;
  columnSet: ColumnSet | undefined;
}

const findDashItem = computed<AppModel, (id: DashId) => DashItem | undefined>(
  (app) => (id: DashId) => dashItemById(app, id)
);

const findChart = computed<AppModel, (id: DashId) => ChartModel | undefined>(
  (app) => (id: DashId) => {
    const items = app.dashboard.dashItems.items;
    const found = items.find((item: DashItem) => item.id === id);
    if (found?.kind === "Chart") {
      return found.chart;
    }
    return undefined;
  }
);

const currentChart = computed<AppModel, ChartModel | undefined>(
  [(app) => app.view.selectedDashItem, (app) => app.dashboard.dashItems.items],
  (id: number, items: DashItem[]) => {
    const found = items.find((item) => item.id === id);
    if (found?.kind === "Chart") {
      return found.chart;
    }
    return undefined;
  }
);

const currentPlot = computed<AppModel, Plot | undefined>(
  [(app) => app.view.selectedDashItem, (app) => app.dashboard.dashItems.items],
  (id: number, items: DashItem[]) => {
    const found = items.find((item) => item.id === id);
    if (found?.kind === "Chart") {
      return found.chart.plot;
    }
    return undefined;
  }
);

const currentSelection = computed<AppModel, DashItem | undefined>(
  [(app) => app.view.selectedDashItem, (app) => app.dashboard.dashItems.items],
  (id: number, items: DashItem[]) => {
    return items.find((item) => item.id === id);
  }
);

const setPlot = action<AppModel, Plot>((state, plot) => {
  const chart = selectedChart(state);
  if (chart) {
    chart.plot = plot;
  } else {
    console.log("can't add plot, selection is not a chart", state.view.selectedDashItem);
  }
});

const addDashItem = action<AppModel, AddDashItemProps>((app, props) => {
  doAddDashItem(app, props);
});

export function doAddDashItem(app: State<AppModel>, props: AddDashItemProps): void {
  const { width, height, addTag = 0, item } = props;
  const id = app.dashboard.nextId++ as DashId;
  const dashItem = { ...item, id, addTag };
  app.dashboard.dashItems.items.push(dashItem);
  const placement: Layout = {
    i: id.toString(),
    w: width,
    h: height,
    x: 0,
    y: Infinity,
  };
  const { layout } = app.dashboard;
  const newLayout = layout.concat([placement]);
  app.dashboard.layout = newLayout;
  app.view.selectedDashItem = id;
  app.view.newDashItem = id;
}

const modifyDashItem = action<AppModel, ModifyDashItemProps>((app, props) => {
  const { dashId, dashPartial } = props;
  const item = dashItemById(app, dashId);
  if (item) {
    Object.assign(item, dashPartial);
  }
});

const modifyChart = action<AppModel, Partial<ChartModel>>((state, chartData) => {
  const chart = selectedChart(state);
  if (chart) {
    Object.assign(chart, chartData);
  } else {
    console.log(
      "can't modify chart, selection is not a chart",
      state.view.selectedDashItem
    );
  }
});

const modifyChartById = action<AppModel, ModifyChartByIdProps>((app, props) => {
  const { chartPartial, dashId } = props;
  const chart = chartById(app, dashId);
  if (chart) {
    Object.assign(chart, chartPartial);
  }
});

const modifyChartByTag = action<AppModel, ModifyChartByTagProps>((app, props) => {
  const { chartPartial, addTag } = props;
  const chart = chartByTag(app, addTag);
  if (chart) {
    Object.assign(chart, chartPartial);
  }
});

const setTableSource = action<AppModel, ChangeTableSourceProps>((app, props) => {
  const { tableSource, dashId, columnSet } = props;
  const chart = chartById(app, dashId);
  if (chart) {
    chart.tableSource = tableSource;
    chart.columnSet = columnSet;
  }
});

const modifyDataById = action<AppModel, ModifyDataByIdProps>((app, props) => {
  const { dataPartial, dashId } = props;
  const chart = chartById(app, dashId);

  if (chart) {
    if (!chart.tableSource) {
      chart.tableSource = { ...noTableSource };
    }
    Object.assign(chart.tableSource, dataPartial);
  }
});

const modifyPlotById = action<AppModel, ModifyPlotByIdProps>((state, props) => {
  const { dashId, plotPartial } = props;
  const chart = chartById(state, dashId);
  if (chart) {
    const merge = Object.assign({}, chart.plot, plotPartial); // only one plot per chart for now
    chart.plot = merge;
  } else {
    console.log("can't add plot, selection is not a chart", state.view.selectedDashItem);
  }
});

const modifyPlot = action<AppModel, Partial<Plot>>((state, plot) => {
  const chart = selectedChart(state);
  if (chart) {
    const merge = Object.assign({}, chart.plot, plot); // only one plot per chart for now
    chart.plot = merge;
  } else {
    console.log("can't add plot, selection is not a chart", state.view.selectedDashItem);
  }
});

const modifyPlotKind = action<AppModel, Plot["kind"]>((state, kind) => {
  const chart = selectedChart(state);
  if (chart) {
    setPlotKind(chart.plot, kind);
  }
});

const modifyPlotSummary = action<AppModel, ModifySummaryProps>((state, props) => {
  const { dashId, summaryId, summaryPartial } = props;
  const chart = chartById(state, dashId);
  if (chart && isDensityPlot(chart.plot)) {
    const densityPlot = chart.plot as DensityPlotData;
    const { summaries } = densityPlot;
    if (summaries) {
      const found = summaries.find((s) => s.summaryId == summaryId);
      if (found) {
        Object.assign(found, summaryPartial);
      }
    }
  }
});

const removePlotSummary = action<AppModel, RemoveSummaryProps>((state, props) => {
  const { dashId, summaryId } = props;
  const chart = chartById(state, dashId);
  if (chart && isDensityPlot(chart.plot)) {
    const densityPlot = chart.plot as DensityPlotData;
    const { summaries } = densityPlot;
    if (summaries) {
      const foundDex = summaries.findIndex((s) => s.summaryId === summaryId);
      if (foundDex >= 0) {
        summaries.splice(foundDex, 1);
      }
    }
  }
});

const addPlotSummary = action<AppModel, AddSummaryProps>((app, props) => {
  const chart = selectedChart(app); // should this take a DashId, or just use current..
  if (chart && isDensityPlot(chart.plot)) {
    const densityPlot = chart.plot as DensityPlotData;
    if (!densityPlot.summaries) {
      densityPlot.summaries = [];
    }
    const { summaries } = densityPlot;
    const max = _.max(summaries.map((s) => s.summaryId)) || 0;
    const summaryId = (max + 1) as SummaryId;
    summaries.push({ ...props, summaryId });
  }
});

const modifyDebug = action<AppModel, Partial<AppDebug>>((state, changes) => {
  Object.assign(state.debug, removeUndefined(changes));
});

const modifyHelp = action<AppModel, Partial<AppHelp>>((state, changes) => {
  Object.assign(state.help, removeUndefined(changes));
});

const modifyView = action<AppModel, Partial<AppView>>((state, partialView) => {
  Object.assign(state.view, removeUndefined(partialView));
});

const removeDashItem = action<AppModel, DashId>((app, dashId) => {
  const items = app.dashboard.dashItems.items;
  const found = items.findIndex((item) => item.id === dashId);
  if (found >= 0) {
    items.splice(found, 1);
  }
  const layouts = app.dashboard.layout;
  const idString = dashId.toString();
  const foundLayout = layouts.findIndex((item) => item.i === idString);
  if (foundLayout >= 0) {
    layouts.splice(foundLayout, 1);
  }
});

const replaceDashboard = action<AppModel, DashboardModel>((state, newDashboard) => {
  state.dashboard = newDashboard;
});

const modifyDataSources = action<AppModel, Partial<DataSourcesModel>>(
  (state, dataPartial) => {
    Object.assign(state.data, dataPartial);
  }
);

interface TagDashId {
  tag: number;
  dashId: DashId;
}

const noTags: TagDashId[] = []; // return the same empty array so to not trigger re-render

const tagsToDashIds = computed<AppModel, (tags: number[]) => TagDashId[]>(
  (app) =>
    (tags: number[]): TagDashId[] => {
      const found: TagDashId[] = [];
      for (const tag of tags) {
        const dashId = app.dashboard.dashItems.items.find(
          (item) => item.addTag === tag
        )?.id;
        if (dashId) {
          found.push({ tag, dashId });
        }
      }

      if (found.length > 0) {
        return found;
      } else {
        return noTags;
      }
    }
);

export const appModel: AppModel = undoable(
  {
    view: appView,
    dashboard: dashboardModel,
    debug: appDebug,
    data: dataStoreModel,
    help: appHelpModel,
    currentPlot,
    currentChart,
    currentSelection,
    setPlot,
    modifyPlot,
    modifyPlotById,
    editChartData,
    modifyChart,
    modifyChartById,
    modifyChartByTag,
    setTableSource,
    modifyDataById,
    modifyPlotKind,
    modifyPlotSummary,
    addPlotSummary,
    removePlotSummary,
    modifyDebug,
    modifyHelp,
    modifyView,
    findDashItem,
    findChart,
    modifyDashItem,
    addDashItem,
    removeDashItem,
    replaceDashboard,
    modifyDataSources,
    tagsToDashIds,
  },
  { noSaveKeys, maxHistory: 100, skipAction }
);

function selectedChart(state: State<AppModel>): ChartModel | undefined {
  const id = state.view.selectedDashItem;
  return chartById(state, id);
}

function chartById(app: State<AppModel>, id: number): ChartModel | undefined {
  const dashItem = dashItemById(app, id);
  return dashItem?.kind === "Chart" ? dashItem.chart : undefined;
}

function chartByTag(app: State<AppModel>, addTag: number): ChartModel | undefined {
  const dashItem = app.dashboard.dashItems.items.find((item) => item.addTag === addTag);
  return dashItem?.kind === "Chart" ? dashItem.chart : undefined;
}

function dashItemById(app: State<AppModel>, id: number): DashItem | undefined {
  return app.dashboard.dashItems.items.find((item) => item.id === id);
}

/** Don't save intermediate application states to undo history. */
function skipAction(state: State<AppModel>, action: AnyAction): boolean {
  /** undo layer ought not store changes to current UI view */
  if (action.type?.startsWith("@action.view") || action.type === "@action.modifyView") {
    return true;
  }

  /* Don't save the the intermediate state when we're switching to a new data table,
     that causes trouble for redo. Instead we skip this state. The async columnSet 
     is soon to be loaded from the new table and we save that state. */
  if (
    action.type === "@action.setTableSource" ||
    action.type === "@action.setTableSourceByTag"
  ) {
    return true;
  }

  /** layout y is set to infinity for newly added items. ReactGridLayout will set
   * a new layout with a non-infinite y and we'll save that subsequent state */
  if (state.dashboard.layout.find((l) => l.y === Infinity)) {
    return true;
  }

  return false;
}
