import { makeStyles, Toolbar, ToolbarProps } from "@material-ui/core";
import {
  Code,
  FormatBold,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  LooksOne,
  LooksTwo,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useCallback } from "react";
import { ReactEditor, useSlate } from "slate-react";
import { TextBlockButton, TextIndentButton, TextMarkButton } from "./TextButtons";

const useStyles = makeStyles({
  toolbar: {
    overflowX: "hidden",
    "& .MuiButtonBase-root": {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  hidden: {
    opacity: 0,
    visibility: "hidden",
  },
  visible: {
    opacity: 100,
    transition: "opacity 250ms ease",
    visibility: "visible",
  },
});

export function TextToolbar(props: ToolbarProps): JSX.Element {
  const editor = useSlate();
  const { toolbar, hidden, visible } = useStyles();
  const { selection } = editor;
  const showEdit = selection && ReactEditor.isFocused(editor);
  const className = clsx(toolbar, showEdit ? visible : hidden);
  const onMouseDown = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);
  const toolbarProps = { ...props, className, onMouseDown };

  return (
    <Toolbar {...toolbarProps} variant="dense">
      <TextMarkButton format="bold" icon={<FormatBold fontSize="small" />} />
      <TextMarkButton format="italic" icon={<FormatItalic fontSize="small" />} />
      <TextMarkButton format="underline" icon={<FormatUnderlined fontSize="small" />} />
      <TextMarkButton format="code" icon={<Code fontSize="small" />} />
      <TextBlockButton kind="heading-1" icon={<LooksOne fontSize="small" />} />
      <TextBlockButton kind="heading-2" icon={<LooksTwo fontSize="small" />} />
      <TextIndentButton kind="outdent" icon={<FormatIndentDecrease fontSize="small" />} />
      <TextIndentButton kind="indent" icon={<FormatIndentIncrease fontSize="small" />} />
      <TextBlockButton
        kind="bulleted-list"
        icon={<FormatListBulleted fontSize="small" />}
      />
    </Toolbar>
  );
}
