import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import React, { useCallback, useRef, useState } from "react";
import { useLoadDashboard } from "../dash-data/UseLoadDashboard";
import { useSaveDashboard } from "../dash-data/UseSaveDashboard";
import { useDataListenMenuItem } from "../listen/UseDataListenMenuItem";
import { useModalContainer } from "./ModalContainer";
import { HiddenFileInput } from "./HiddenFileInput";
import { dashFileFormats } from "./UseDashboardDrop";

const acceptDashFormats = dashFileFormats.map((suffix) => "." + suffix).join(",");

export function DashboardMenu(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const saveDash = useSaveDashboard();
  const loadDash = useLoadDashboard();
  const container = useModalContainer();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const exportDashboard = useCallback(() => {
    saveDash();
    onClose();
  }, [onClose, saveDash]);

  const importDashboard = useCallback(() => {
    fileInputRef.current?.click();
    onClose();
  }, [onClose]);

  const onFiles = useCallback(
    (list: FileList) => {
      for (const file of list) {
        loadDash(file);
      }
    },
    [loadDash]
  );
  const localDataMenuItem = useDataListenMenuItem({ closeMenu: onClose });

  return (
    <>
      <IconButton onClick={openMenu} color="inherit">
        <MoreVertIcon />
      </IconButton>
      <Menu {...{ anchorEl, container, onClose, open: !!anchorEl }} keepMounted>
        <MenuItem onClick={exportDashboard}>Export Dash</MenuItem>
        <MenuItem onClick={importDashboard}>Import Dash</MenuItem>
        {localDataMenuItem()}
      </Menu>
      <HiddenFileInput {...{ onFiles, accept: acceptDashFormats, ref: fileInputRef }} />
    </>
  );
}
