import * as d3 from "d3";
import React, { useCallback } from "react";
import { DensityPlotData, PercentileStat } from "../../chart/PlotModels";
import { useCurrentPlot, useStoreActions } from "../../store/Hooks";
import { LabeledSlider } from "./LabeledSlider";
import { OneStatProps } from "./OneStatSetting";

export const StatPercentileSetting = React.memo(StatPercentileSetting_);

export const percentFormat = d3.format(".1~%");
const fixedFormat = d3.format(".1~f");
const sliderFormat = (n: number): string => fixedFormat(n * 100);

// prettier-ignore
const marks = [
  0.001, 0.01, .05, 
  .1, .2, .3, .4, .5, .6, .7, .8, .9, 
  .95, .99, .999,
].map((value) => ({ value }));

function StatPercentileSetting_(props: OneStatProps): JSX.Element {
  const { dashId, summaryId } = props;
  const percentileStat = useCurrentPlot((p: DensityPlotData) => {
    const found = p.summaries?.find(
      (s) => s.summaryId === summaryId && s.kind === "percentile"
    );
    return found as PercentileStat | undefined;
  });
  const modifySummary = useStoreActions((app) => app.modifyPlotSummary);

  const setPercentile = useCallback(
    (_e, percentile: number) => {
      if (dashId) {
        modifySummary({ dashId, summaryId, summaryPartial: { percentile } });
      }
    },
    [dashId, summaryId, modifySummary]
  );

  if (percentileStat) {
    const { percentile = 0.9 } = percentileStat;
    const min = marks[0].value;
    const max = marks[marks.length - 1].value;

    return (
      <LabeledSlider
        label="percent:"
        {...{ marks, min, max, step: null }}
        value={percentile}
        onChange={setPercentile as any}
        valueLabelFormat={sliderFormat}
      />
    );
  } else {
    return <></>;
  }
}
