import Dexie from "dexie";
import { Brand } from "../util/Brand";
import { ColumnId, DataType } from "./ColumnFrame";
import { StorageType } from "./DataChunk";

const columnStoreName = "ColumnStore";

export const indexColumnId = -11 as ColumnId;

export type TableId = Brand<number, "tableId">;
export type ChunkId = Brand<number, "chunkId">;

/** a block of data in a column */
export interface StoredChunk {
  id?: ChunkId;
  startRow: number; // row number of the first item of the data array
  data: Float64Array | string[];
  columnId: ColumnId;
}

/** info about a stored column  */
interface StoredColumnInfoBase {
  label: string;
  storageType: StorageType;
  displayType: DataType;
  /** number of entries in the column */
  size?: number;
}

/** collection of tables in store */
export interface ColumnTable {
  id?: TableId;
  name: string; // name with unique suffix as necessary
  nameUsage?: number; // suffix to make baseName unique
  baseName: string;
}

/** structure report info about tables in store */
export interface ColumnTableInfo {
  tableId: TableId;
  name: string;
}

/** collection of column ids and info and the table to which the column belongs */
export interface StoredColumn extends StoredColumnInfoBase {
  id?: ColumnId;
  tableId: TableId;
}

/** reference to tables loaded from an external source */
export interface ExternalTableCache {
  url: string;
  tableId: TableId;
}

/** structure to report info about a stored column */
export interface StoredColumnInfo extends StoredColumnInfoBase {
  columnId: ColumnId;
}

export class DbStore extends Dexie {
  chunks: Dexie.Table<StoredChunk, number>;
  columns: Dexie.Table<StoredColumn, number>;
  columnTables: Dexie.Table<ColumnTable, number>;
  cachedTables: Dexie.Table<ExternalTableCache, string>;

  constructor() {
    super(columnStoreName);
    this.version(1).stores({
      columnTables: "++id, name, baseName",
      columns: "++id, tableId",
      chunks: "++id, columnId",
      cachedTables: "url",
    });
    this.columnTables = this.table("columnTables");
    this.columns = this.table("columns");
    this.chunks = this.table("chunks");
    this.cachedTables = this.table("cachedTables");
  }
}

export let dbStore = new DbStore();

/** Delete everything! */
export function deleteLocalStore(): Promise<void> {
  return Dexie.delete(columnStoreName);
}

/** reset store, for tests */
export async function resetDbStore(): Promise<void> {
  await deleteLocalStore();
  dbStore = new DbStore();
}

// TODO move this into new file for dbColumnTables

