import _ from "lodash";
import { Regl } from "regl";
import { Renderable, WebglRenderable } from "../chart/RenderPlot";
import { AnyTransition } from "../util/d3Util";
import { DensityArgs } from "./DensityShading";

export type CreateRenderer<T extends DensityArgs> = (
  regl: Regl,
  args: T
) => Renderable<T>;

/** shared functions for density renderers */
export function densityCommon<T extends DensityArgs>(
  regl: Regl,
  createRenderer: CreateRenderer<T>,
  rebuildCheck?: (args: T, lastArgs: T) => boolean
): WebglRenderable<T> {
  let lastArgs: T | undefined;
  let renderer: Renderable<T> | undefined;

  function rebuildRequired(args: T): boolean {
    const result =
      lastArgs !== undefined &&
      renderer !== undefined &&
      (!_.isEqual(lastArgs.frameAndSet.columnSet, args.frameAndSet.columnSet) || // TODO can this be dynamic?
        !_.isEqual(lastArgs.plotRect, args.plotRect) ||
        lastArgs.plot?.colorScale !== args.plot?.colorScale ||
        lastArgs.plot?.smoothDensity !== args.plot?.smoothDensity || // LATER make dynamic per frame
        !_.isEqual(lastArgs.debug, args.debug) ||
        (!!rebuildCheck && rebuildCheck(args, lastArgs!)));

    return result;
  }

  function draw(transition: AnyTransition, args: T): void {
    lastArgs = args;
    const renderer = getRenderer(args);
    renderer.draw(transition, args);
  }

  function getRenderer(args: T): Renderable<T> {
    if (!renderer) {
      renderer = createRenderer(regl, args);
    }
    return renderer;
  }

  return { rebuildRequired, draw };
}
