import { Vec2 } from "../math/Vec";
import { LinearScale } from "../util/d3Util";

/** an affine transform for 1D homogenous coordinates
 * i.e. a 2D matrix with the lower row [0 1]
 */
export interface MiniTransform {
  k: number;
  t: number;
}

/** @return the inverse of this mini Transform */
export function inverse(mt: MiniTransform): MiniTransform {
  const k = 1 / mt.k;
  const t = -mt.t * k;
  return { k, t };
}

/** @return the matrix product of two MiniTransforms */
export function mul(a: MiniTransform, b: MiniTransform): MiniTransform {
  const k = a.k * b.k;
  const t = a.k * b.t + a.t;
  return { k, t };
}

/**
 * @return a new transform that sums the top rows of the two provided transforms.
 * The bottom row is not summed, and so this isn't a true matrix sum.
 */
export function sumTopRow(mt1: MiniTransform, mt2: MiniTransform): MiniTransform {
  const k = mt1.k + mt2.k;
  const t = mt1.t + mt2.t;

  return { k, t };
}

/**
 * @return a new scaled version of the provided transform.
 *
 * This is the equivalent of left multiplying the provided transform by a scaling transform.
 *   |n 0|  |k t|
 *   |0 n|  |0 1|
 */
export function scale(mt: MiniTransform, n: number): MiniTransform {
  const k = mt.k * n;
  const t = mt.t * n;
  return { k, t };
}

/** @returns given the range, return the domain of the transform */
export function matToDomain(m: MiniTransform, range: Vec2): Vec2 {
  const { t, k } = m;
  const [r0, r1] = range;
  const d0 = (r0 - t) / k;
  const d1 = (r1 - t) / k;

  return [d0, d1];
}

/** @return the transform version of a LinearScale (that linearly maps a domain to a range) */
export function scaleToMTransform(scale: LinearScale): MiniTransform {
  return domainRangeToMTransform(scale.domain() as Vec2, scale.range() as Vec2);
}

/** @return the transform that linearly maps the provided domain to the provided range */
export function domainRangeToMTransform(domain: Vec2, range: Vec2): MiniTransform {
  const [d0, d1] = domain;
  const [r0, r1] = range;
  const k = (r0 - r1) / (d0 - d1);
  const t = r0 - k * d0;

  return { k, t };
}
