import { FormatRun } from "../parse/ColumnFormatRuns";

const maxHeaderLines = 10;

export function detectColumnHeader(runs: FormatRun[]): number {
  if (runs.length >= 2) {
    const firstRun = runs[0];
    if (firstRun.count < maxHeaderLines && firstRun.format.parseType === "string") {
      return firstRun.count;
    }
  }
  return 0;
}
