import { FrameReduceSnippets } from "./FrameReduce";

/**
 * Calculate basic statistics from two channels of src frame buffer.
 *
 * The src red or green channel contains the counts from the scatter or line plots.
 *
 * For line plots, the red channel contains the sum of horizontally weighted line
 * intersections at each pixel. The green channel contains the count of line intersections
 * at each pixel (unweighted by line angle).
 *
 * @returns a 1x1 rgba texture summary statistics in each of r,g,b,a
 * r contains the sum of the selected channel values (i.e. the post filtered density)
 * g contains the count of non-zero values in the selected channel
 * b contains the min of the selected channel values
 * a contains the max of the selected channel values
 */

// for the first shader that runs from the original source
export function nzFirstSnippets(channel: "r" | "g" | "b" | "a"): FrameReduceSnippets {
  return {
    declare: `
    float sum;
    float curMax;
    float curMin;
    float nzCount;`,
    initialize: `
    sum = 0.0;
    curMax = 0.0;
    curMin = 1e37;
    nzCount = 0.0;`,
    accumulate: `
    float value = srcTexel.${channel};
    if (value > 0.0) {
      nzCount += 1.0;
      sum += value;
      curMax = max(value, curMax);
      curMin = min(value, curMin);
    }
    `,
    assignColor: `
    gl_FragColor = vec4(sum, nzCount, curMin, curMax);`,
  };
}

// for the shaders that accumulate intermediate results
export const nzSnippets: FrameReduceSnippets = {
  declare: `
    float sum;
    float curMax;
    float curMin;
    float nzCount;`,
  initialize: `
    sum = 0.0;
    curMax = 0.0;
    curMin = 1e37;
    nzCount = 0.0;`,
  accumulate: `
    sum += srcTexel.r;
    nzCount += srcTexel.g;
    curMax = max(curMax, srcTexel.a);
    curMin = min(curMin, srcTexel.b);
    `,
  assignColor: `
    gl_FragColor = vec4(sum, nzCount, curMin, curMax);`,
};

/** for just calculating the max value from the red channel (currently unused) */
export const maxSnippets: FrameReduceSnippets = {
  declare: `float curMax;`,
  initialize: "curMax = 0.0;",
  accumulate: `curMax = max(curMax, srcTexel.r);`,
  assignColor: "gl_FragColor = vec4(curMax, 0.0, 0.0, 1.0);",
};
