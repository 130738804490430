import { mat3, vec2 } from "gl-matrix";
import { BlendingOptions, Framebuffer2D, Regl, Texture2D, Vec2, Vec4 } from "regl";
import { miniPlotMargin } from "../chart/Chart";
import { ChartDebug } from "../chart/ChartDebug";
import { XyScales } from "../chart/ChartScales";
import { time } from "../util/Perf";

export function fetchPixels(
  regl: Regl,
  buffer: Framebuffer2D | undefined | null = undefined,
  perfName = "fetchPixels"
): Uint8Array | Float32Array {
  return time(perfName, () => {
    if (buffer) {
      return regl.read({ framebuffer: buffer });
    } else {
      return regl.read();
    }
  });
}

const [top, bottom] = [1, -1],
  [left, right] = [-1, 1];

export const fullScreenTriangles = [
  [left, bottom],
  [left, top],
  [right, top], // upper left triangle
  [left, bottom],
  [right, top],
  [right, bottom], // lower right triangle
];

export type ProjectionDebug = Pick<ChartDebug, "miniCanvas" | "miniBorder">;

/** Return a 3d homogenous matrix to convert points in world space (the source data)
 * into webgl coordinates [-1, 1].
 */
export function scalesToProjection(
  scales: XyScales,
  canvasSize: Vec2,
  normX: (x: number) => number,
  debug: ProjectionDebug
): mat3 {
  // translate to start of data domain
  const { start, extent } = domainExtents(scales, normX);

  // translate to normalize data to start at 0
  const translate = mat3.fromTranslation(mat3.create(), [-start[0], -start[1]]);

  // scale to extent of data domain.
  const scaleInput = mat3.fromScaling(mat3.create(), [1 / extent[0], 1 / extent[1]]);

  // to unit coordinates: [0,1]
  const unit = mat3.multiply(mat3.create(), scaleInput, translate);

  // to pixel centers (half pixel margin)
  const toPixelCenters = pixelCenterTransform(canvasSize);
  const pixelCentered = mat3.multiply(mat3.create(), toPixelCenters, unit);

  // to inner plot area (margin for marks)
  const toInnerPlot = innerPlotTransform(scales, canvasSize, debug);
  const innerPlot = mat3.multiply(mat3.create(), toInnerPlot, pixelCentered);

  // to webgl coordinates [-1, 1]
  const scaleOutput = mat3.fromScaling(mat3.create(), [2, 2]);
  const translateOutput = mat3.fromTranslation(mat3.create(), [-1, -1]);
  const outScaled = mat3.multiply(mat3.create(), scaleOutput, innerPlot);
  const transform = mat3.multiply(mat3.create(), translateOutput, outScaled);

  return transform;
}

/** @return a matrix that scales and translates to pixel centers */
function pixelCenterTransform(canvasSize: Vec2): mat3 {
  const unitPixelSize: vec2 = [1 / (canvasSize[0] - 1), 1 / (canvasSize[1] - 1)];
  const shrink = vec2.subtract(vec2.create(), [1, 1], unitPixelSize);
  const pixelCenterScale = mat3.fromScaling(mat3.create(), shrink);
  const halfPixelSize = vec2.scale(vec2.create(), unitPixelSize, 0.5);
  const halfPixelOffset = mat3.fromTranslation(mat3.create(), halfPixelSize);

  return mat3.multiply(mat3.create(), pixelCenterScale, halfPixelOffset);
}

/**
 * Scale and offset the data to fit in the inner plot area. (The inner plot is slightly
 * smaller than the total canvas size to leave room for drawing marks without clipping.)
 *
 * @returns transform to convert coordinates from full frame size to the innerPlot
 * @param scales ranges from the x and y chart scales determine the size of the inner plot
 * @param canvasSize determines the full size
 */
function innerPlotTransform(
  scales: XyScales,
  canvasSize: Vec2,
  debug: ProjectionDebug
): mat3 {
  const { miniCanvas, miniBorder } = debug;
  if (miniCanvas) {
    if (miniBorder) {
      const { top, right, bottom, left } = miniPlotMargin;
      const [width, height] = canvasSize;
      const xScale = (width - (left + right)) / width;
      const yScale = (height - (top + bottom)) / height;
      const translate = mat3.fromTranslation(mat3.create(), [
        left / width,
        bottom / height,
      ]);
      const scale = mat3.fromScaling(mat3.create(), [xScale, yScale]);
      return mat3.multiply(mat3.create(), translate, scale);
    } else {
      return mat3.identity(mat3.create());
    }
  }

  const { xRange, yRange } = ranges(scales);
  yRange.reverse();
  const [xTranslate, xScale] = unitTranslateScale(devicePixels(xRange), canvasSize[0]);
  const [yTranslate, yScale] = unitTranslateScale(devicePixels(yRange), canvasSize[1]);

  const translate = mat3.fromTranslation(mat3.create(), [xTranslate, yTranslate]);
  const scale = mat3.fromScaling(mat3.create(), [xScale, yScale]);

  const transform = mat3.multiply(mat3.create(), translate, scale);
  return transform;
}

function devicePixels(size: Vec2): Vec2 {
  return size.map((v) => v * window.devicePixelRatio) as Vec2;
}

/**
 * @return translation and scale factors in unit coordinates [0, 1]
 * @param range start and end locations in in [0, size] coordinates
 */
function unitTranslateScale(range: Vec2, size: number): Vec2 {
  const translate = range[0] / size;
  const scale = Math.abs(range[1] - range[0]) / size;
  return [translate, scale];
}

interface Ranges {
  xRange: Vec2;
  yRange: Vec2;
}

function ranges(scales: XyScales): Ranges {
  const xRange = scales.x.range() as Vec2;
  const yRange = scales.y.range() as Vec2;
  return { xRange, yRange };
}

interface StartAndExtent {
  start: Vec2;
  extent: Vec2;
}

function domainExtents(scales: XyScales, normX: (x: number) => number): StartAndExtent {
  const xDomain = scales.x.domain(),
    xNormed = xDomain.map(normX),
    xStart = xNormed[0],
    xExtent = normX(xDomain[1]) - xStart;

  const yDomain = scales.y.domain();
  const yStart = yDomain[0],
    yExtent = yDomain[1] - yStart;

  return { start: [xStart, yStart], extent: [xExtent, yExtent] };
}

export interface FullFB extends Framebuffer2D {
  color: Texture2D[];
  width: number;
  height: number;
}

export const frameBufferSize = (fb: FullFB): Vec2 => [fb.width, fb.height];
export const textureSize = (fb: Texture2D): Vec2 => [fb.width, fb.height];

/** @return options for the regl command blend attribute that will
 * . blend dest color according to the src alpha
 * . leave the dest alpha is unchanged
 */
export function blendSrcAlpha(): BlendingOptions {
  return {
    enable: true,
    func: {
      srcRGB: "src alpha",
      srcAlpha: 0,
      dstRGB: "one minus src alpha",
      dstAlpha: 1,
    },
    equation: {
      rgb: "add",
      alpha: "add",
    },
  };
}

export const white: Vec4 = [1, 1, 1, 1];
export const black: Vec4 = [0, 0, 0, 1];
export const green: Vec4 = [0, 1, 0, 1];

export interface ZoomRenderProps {
  scales: XyScales;
}

export interface ZoomRenderFn {
  (props: ZoomRenderProps): void;
}

export function reglDestroy(regl: Regl): void {
  regl.destroy();
  (regl as any)._destroyed = true;
}

export function isDestroyed(regl: Regl): boolean {
  return !!(regl as any)._destroyed;
}
