import { useCallback } from "react";
import { useUndoGroup } from "undo-peasy";
import { DashChartModel } from "../../store/DashItems";
import { useStoreActions } from "../../store/Hooks";
import { noTableSource } from "../../store/TableSource";

export function useAddChart(): () => number {
  const addItem = useStoreActions((app) => app.addDashItem);
  const undoGroup = useUndoGroup<number>();
  const addChart = useCallback((): number => {
    return undoGroup((): number => {
      const demoChart: Omit<DashChartModel, "id"> = {
        kind: "Chart",
        chart: {
          plot: {
            kind: "HeatScatter",
            size: 4,
            feather: 2,
            brightness: 6,
            smoothDensity: true,
          },
          tableSource: { ...noTableSource },
          columnSet: undefined,
        },
      };

      const addTag = Math.random();
      addItem({ item: demoChart, width: 49, height: 119, addTag });
      return addTag;
    });
  }, [addItem, undoGroup]);

  return addChart;
}
