import React, { ReactElement } from "react";
import { useCurrentPlot } from "../../store/Hooks";
import LineSettings from "./LineSettings";
import { ScatterSettings } from "./ScatterSettings";
import { SvgScatterSettings } from "./SvgScatterSettings";

export const CurrentPlotSettings = React.memo(CurrentPlotSettingsInternal);

export function CurrentPlotSettingsInternal(): ReactElement {
  const plotKind = useCurrentPlot((plot) => plot.kind);

  if (plotKind === "HeatLines") {
    return <LineSettings />;
  } else if (plotKind === "HeatScatter") {
    return <ScatterSettings />;
  } else if (plotKind === "SvgScatter") {
    return <SvgScatterSettings />;
  } else {
    // console.log("plotSettings: unhandled plot type", plot);
    return <></>;
  }
}
