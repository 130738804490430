import { dlog } from "../../util/DebugLog";

export interface AsyncLoop {
  stop: () => void;
}

/** an async loop iterator, exposing external cancellation */
export function asyncLoop<T>(
  stream: AsyncGenerator<T, void>,
  stopCondition: () => boolean,
  action: (value: T) => void
): AsyncLoop {
  let stopped = false;

  loop().catch((e) => dlog("injest stream loop error", e));

  return { stop };

  async function loop(): Promise<void> {
    for await (const value of stream) {
      if (stopped || stopCondition()) {
        break;
      } else {
        action(value);
      }
    }
  }

  function stop(): void {
    stopped = true;
    dlog();
  }
}
