import { AppBar, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import React, { useCallback, useRef } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { DashboardMenu } from "./DashboardMenu";
import { DashDropZone } from "./DashDropZone";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  hidden: {
    visibility: "hidden",
  },
  appBar: {
    position: "sticky",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShiftRight: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export function TitleBar(): JSX.Element {
  const title = useStoreState((state) => state.dashboard.title);
  const editTitle = useRef(title);
  const leftDrawerOpen = useStoreState((state) => state.view.leftDrawerOpen);
  const setTitle = useStoreActions((actions) => actions.dashboard.setTitle);
  const setOpen = useStoreActions((actions) => actions.view.setLeftDrawerOpen);
  const classes = useStyles();

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }, []);

  const onBlur = useCallback(() => {
    const edited = editTitle.current;
    if (edited && edited !== title) {
      setTitle(edited);
    }
  }, [setTitle, title]);

  const onChange = useCallback((e: ContentEditableEvent) => {
    editTitle.current = e.target.value;
  }, []);

  return (
    <AppBar className={clsx(classes.appBar, leftDrawerOpen && classes.appBarShiftRight)}>
      <DashDropZone />

      <Toolbar>
        <IconButton
          edge="start"
          className={clsx(classes.menuButton, leftDrawerOpen && classes.hidden)}
          color="inherit"
          aria-label="menu"
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          <ContentEditable
            html={title}
            {...{ onChange, onKeyDown, onBlur }}
            tagName="span"
          />
        </Typography>
        <DashboardMenu />
      </Toolbar>
    </AppBar>
  );
}
