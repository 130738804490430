import _ from "lodash";
import { BerryPrettyOptions, pretty } from "./BerryPretty";

/** concise debug logging */
export function dLog(...items: any[]): void {
  console.log(...dLogMessages({}, ...items));
}

export const dlog = dLog;

/** concise debug logging, with options */
export function dlogOpt(options: BerryPrettyOptions, ...items: [any]): void {
  console.log(...dLogMessages(options, ...items));
}

/** concise assertion logging */
export function dsert(test: boolean, ...items: any[]): void {
  if (!test) {
    const messages = dLogMessages({}, items);
    console.assert(test, messages);
  }
}

export const spaces = _.memoize((nesting: number): string => {
  return " ".repeat(nesting);
});

const callerSize = 20;
const callerPad = spaces(callerSize);
const multiLinePad = "\n" + spaces(callerSize + 3);

/** exported for testing */
export function dLogMessages(options: BerryPrettyOptions, ...items: any[]): any[] {
  const messages: any[] = items.map((item) => {
    if (_.isPlainObject(item)) {
      return debugVars(item, options);
    } else {
      return item;
    }
  });

  const caller = callerName(new Error().stack || "", 2);
  const fixedCaller = (caller + callerPad).slice(0, callerSize);
  messages.unshift(`${fixedCaller} |`);
  return indentMultiLine(messages);
}

/** @return a string for convenient debug logging some variables. */
export function debugVars(
  vars: Record<string, unknown>,
  options?: BerryPrettyOptions
): string {
  const strings = Object.entries(vars).map(
    ([key, value]) => `${key}: ${pretty(value, options)}`
  );

  const multiline = strings.find((s) => s.includes("\n"));
  if (multiline) {
    return strings.join("\n");
  } else {
    return strings.join("  ");
  }
}

function indentMultiLine(messages: any[]): any[] {
  return messages.map(indentString);
}

function indentString(m: any): any {
  if (_.isString(m)) {
    return m.replace(/\n/g, multiLinePad);
  } else {
    return m;
  }
}

function callerName(stack: string, level = 1): string {
  const lines = stack.split("\n");
  if (lines.length && lines[0].startsWith("Error")) {
    return callerFromChromeStack(lines, level);
  } else {
    return callerFromFirefoxStack(lines, level);
  }
}

function callerFromFirefoxStack(stackLines: string[], level = 1): string {
  if (stackLines.length < level) {
    return "";
  }
  const line = stackLines[level];
  const atDex = line.indexOf("@");
  if (atDex === -1) {
    return "";
  }
  return line.slice(0, atDex);
}

function callerFromChromeStack(stackLines: string[], level = 1): string {
  if (stackLines.length < level + 1) {
    return "";
  }
  const line = stackLines[level + 1];

  const at = "at ";
  const atDex = line.indexOf(at);
  if (atDex === -1) {
    return "";
  }
  let start = atDex + at.length;
  let end = line.length;

  const space = line.indexOf(" ", start);
  if (space > 0) {
    // example:
    //   at setupPlots (http://localhost:8080/chart/Plot.js:48:3)
    end = space;
  } else {
    // example:
    //   at http://localhost:8080/chart/Chart.js:143:7
    const slash = line.lastIndexOf("/");
    if (slash > 0) {
      start = slash + 1;
      const lastColon = line.lastIndexOf(":");
      if (lastColon > 0) {
        const secondColon = line.lastIndexOf(":", lastColon - 1);
        if (secondColon > 0) {
          end = secondColon;
        } else {
          end = lastColon;
        }
      }
    }
  }

  const functionName = line.slice(start, end);

  const dot = functionName.indexOf(".");
  if (dot > 0) {
    const suffix = functionName.slice(dot + 1);
    if (suffix === "js" || suffix === "ts") {
      // e.g. StackedTransition.js
      return functionName;
    } else {
      // e.g. SVGSVGElement.toTweenFn
      return suffix;
    }
  } else {
    return functionName;
  }
}
