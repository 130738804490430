import { Checkbox, makeStyles } from "@material-ui/core";
import { Height as HeightIcon } from "@material-ui/icons";
import React from "react";

export interface AxisMenuItemProps {
  x?: boolean;
  checked: boolean;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  symbol: {
    flex: "0 0 auto",
    position: "relative",
    top: 3,
  },
  check: {
    flex: "0 0 auto",
  },
  text: {
    paddingLeft: 5,
    paddingRight: 25,
    flex: "1 1 auto",
  },
  rotate: {
    transform: "rotate(90deg)",
  },
});

const checkedBox: JSX.Element = (
  <Checkbox color="default" disabled checked size="small" />
);

const unCheckedBox: JSX.Element = (
  <Checkbox color="default" disabled checked={false} size="small" />
);

export function AxisMenuItem(props: AxisMenuItemProps): JSX.Element {
  const { x, checked } = props;
  const classes = useStyles();
  const axisSymbol = x ? <HeightIcon className={classes.rotate} /> : <HeightIcon />;
  const checkMark = checked ? checkedBox : unCheckedBox;
  return (
    <span className={classes.container}>
      <span className={classes.symbol}>{axisSymbol}</span>
      <span className={classes.text}>Axis</span>
      <span className={classes.check}>{checkMark}</span>
    </span>
  );
}
