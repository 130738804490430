import _ from "lodash";
import { matrix, Matrix, multiply, transpose } from "mathjs";
import { integralFromCubic } from "./IntegratePolynomial";
import { matrixString } from "./LinearAlgebra";
import { Vec2 } from "./Vec";

export type FourPoints = [Vec2, Vec2, Vec2, Vec2];
export type FourBases = [UnaryFn, UnaryFn, UnaryFn, UnaryFn];
export type UnaryFn = (n: number) => number;
export type ParametricFn = (n: number) => Vec2;

export const cubicDerivative = matrixString(`
  0 1 0 0
  0 0 2 0
  0 0 0 3
`);

// power basis for cubic functions
export const cubicBasis = [
  (): number => 1,
  (t: number): number => t,
  (t: number): number => t ** 2,
  (t: number): number => t ** 3,
];

// power basis for quartic functions
export const quarticBasis = [
  (): number => 1,
  (t: number): number => t,
  (t: number): number => t ** 2,
  (t: number): number => t ** 3,
  (t: number): number => t ** 4,
];

// power basis for quintic functions
export const quinticBasis = [
  (): number => 1,
  (t: number): number => t,
  (t: number): number => t ** 2,
  (t: number): number => t ** 3,
  (t: number): number => t ** 4,
  (t: number): number => t ** 5,
];

// power basis for sextic functions
export const sexticBasis = [
  (): number => 1,
  (t: number): number => t,
  (t: number): number => t ** 2,
  (t: number): number => t ** 3,
  (t: number): number => t ** 4,
  (t: number): number => t ** 5,
  (t: number): number => t ** 6,
];

/** This calculates the integral of y = curve(t).
 * Note that it is not in terms of x. */
export function integralFromMatrix(points: FourPoints, m: Matrix): UnaryFn {
  const controls = matrix(points.map(([, y]) => y)), // just y for integral
    coefficients = multiply(m, controls) as Matrix;
  return integralFromCubic(coefficients);
}

export function cubicFromMatrix(points: FourPoints, m: Matrix): ParametricFn {
  const controls = matrix(points), // size: 4,2
    coefficients = multiply(m, controls) as Matrix; // size: 4,2

  return function (t: number): Vec2 {
    // evaluate power basis functions at t
    const powers = matrix(cubicBasis.map((f) => f(t))),
      powersRow = transpose(powers);

    // multiply by coefficients and sum to get x,y values of curve at t
    const resultMat = multiply(powersRow, coefficients) as Matrix;

    return resultMat.toArray() as Vec2;
  };
}

export function cubicFromBases(points: FourPoints, bases: FourBases): ParametricFn {
  return function (t: number): Vec2 {
    const xs = points.map(([x], i) => bases[i](t) * x);
    const x = _.sum(xs);
    const ys = points.map(([, y], i) => bases[i](t) * y);
    const y = _.sum(ys);
    return [x, y];
  };
}
