import _ from "lodash";
import { dLog } from "../util/DebugLog";
import { CellFormat, fullDataFormatName, ParseDataFormat } from "./DetectCellFormat";

const floatFormat: CellFormat = {
  parseType: "number",
  formatSubtype: "float",
};

const specialFloatFormat: CellFormat = {
  parseType: "number",
  formatSubtype: "SpecialFloat",
};

const specialIntegerFormat: CellFormat = {
  parseType: "number",
  formatSubtype: "SpecialInteger",
};

export interface FormatRun {
  count: number;
  format: CellFormat;
}

/** run length encode a set of formats detected on individual cells.
 * Neighboring numeric formats are "promoted" if they're compatible: e.g. ints to floats */
export function formatRuns(dataFormats: ParseDataFormat[]): FormatRun[] {
  const { head, tail } = headNotEmpty(dataFormats);
  if (!head) {
    return [];
  }

  // walk through formats, combining elements.
  // previous element is always a parseable data format (not parseType:"empty")
  const runs: FormatRun[] = [{ format: head, count: 1 }];
  let prevRun = runs[0];

  for (const format of tail) {
    const mergedFormat = combineFormats(prevRun.format, format);
    if (mergedFormat) {
      const mergedRun = { count: prevRun.count + 1, format: mergedFormat };
      runs.pop();
      runs.push(mergedRun);
      prevRun = mergedRun;
    } else {
      prevRun = { count: 1, format: format as CellFormat };
      runs.push(prevRun);
    }
  }

  return runs;
}

interface HeadNotEmpty {
  head?: CellFormat;
  tail: ParseDataFormat[];
}

/** @return the head and the tail of the format list. The head is replaced
 * if it was originally parseType:"empty".
 */
function headNotEmpty(dataFormats: ParseDataFormat[]): HeadNotEmpty {
  const head = _.head(dataFormats);
  if (!head) {
    return { tail: [] };
  }

  const firstNonEmpty = dataFormats.find((fmt) => fmt.parseType !== "empty");
  const newHead: CellFormat = (firstNonEmpty as CellFormat) || { parseType: "string" };
  const tail = dataFormats.slice(1);

  return { head: newHead, tail };
}

/** combine two formats if they're the same or compatible numeric formats */
function combineFormats(a: CellFormat, b: ParseDataFormat): CellFormat | undefined {
  if (_.isEqual(a, b) || b.parseType === "empty") {
    return a;
  }
  return combineNumericFormats(a, b);
}

function combineNumericFormats(
  a: CellFormat,
  b: ParseDataFormat
): CellFormat | undefined {
  if (a.parseType === "number" && b.parseType === "number") {
    // integer + special integer => special integer
    if (
      (a.formatSubtype === "integer" && b.formatSubtype === "SpecialInteger") ||
      (b.formatSubtype === "integer" && a.formatSubtype === "SpecialInteger")
    ) {
      return specialIntegerFormat;
    }

    // integer + float => float
    if (
      (a.formatSubtype === "float" && b.formatSubtype === "integer") ||
      (b.formatSubtype === "float" && a.formatSubtype === "integer")
    ) {
      return floatFormat;
    }

    return specialFloatFormat;
  }

  return undefined;
}

/** @return the most used format by line count */
export function mostUsedFormat(runs: FormatRun[]): CellFormat {
  if (runs.length > 0) {
    // count lines matched by each formatter
    const groups = _.groupBy(runs, (run) => fullDataFormatName(run.format));
    const fmtUsage = Object.entries(groups).map(([, runs]) => {
      const lines = runs.reduce((sum, run) => sum + run.count, 0);
      const { format } = runs[0];
      return [lines, format] as [number, CellFormat];
    });

    // find formatter that covers the most lines
    let max = 0;
    let maxFormat = runs[0].format;
    fmtUsage.forEach(([lines, format]) => {
      if (lines > max) {
        max = lines;
        maxFormat = format;
      }
    });
    return maxFormat;
  }

  dLog("unexpected, shouldn't be called with runs.length === 0");
  return {
    parseType: "string",
  };
}
