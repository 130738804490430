import React, { useState } from "react";

const containerId = "modal-container";

/** Return a well known div in the dom.
 *
 * We place modal dialogs like Menu inside this container to avoid
 * issues from the popover component generating
 * unwise adjustments for scroll bars..
 */
export function useModalContainer(): HTMLDivElement | undefined {
  const [container, setContainer] = useState<HTMLDivElement>(
    document.getElementById(containerId) as HTMLDivElement
  );

  if (!container) {
    const newContainer = document.getElementById(containerId);
    if (newContainer) {
      setContainer(newContainer as HTMLDivElement);
    }
  }
  return container;
}

export function ModalContainer(): JSX.Element {
  return <div id={containerId} />;
}
