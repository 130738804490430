import { makeStyles } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  createEditor,
  Descendant as SlateDescendant,
  Editor,
  Element as SlateElement,
  Node,
  NodeEntry,
  Path,
  Text as SlateText,
  Transforms,
} from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { dlog } from "../../util/DebugLog";
import { GrowItemDispatch } from "../dash-layout/Dashboard";
import { handleHotKey } from "./RichTextHotKeys";
import { CustomElement, CustomText } from "./SlateTypes";
import { IndentOutdent } from "./TextButtons";
import { TextElement, TextLeaf } from "./TextElements";
import { TextMenu } from "./TextMenu";
import { TextToolbar } from "./TextToolbar";

const initialValue: SlateDescendant[] = [
  {
    kind: "paragraph",
    children: [{ text: "" }],
  },
];

export interface DashTextProps {
  dashId: DashId;
}

interface StyleParams {
  scrollBars: boolean;
}

const useStyles = makeStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    cursor: "move",
  },
  topBar: {
    opacity: 0,
    transition: "opacity 250ms ease",
    ".react-grid-item:hover &": {
      opacity: 100,
    },
    display: "flex",
    justifyContent: "space-between",
  },
  editContainer: {
    marginLeft: "2rem",
    marginRight: "2rem",
    overflow: (s: StyleParams) => (s.scrollBars ? "auto" : "hidden"),
  },
  editBox: {
    cursor: "text",
    "& :first-child": {
      marginBlockStart: 0,
    },
    "& p": {
      fontSize: 15,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    "& ul": {
      fontSize: 15,
      listStylePosition: "outside",
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingInlineStart: 25,
    },
    "& h2": {
      marginBlockStart: ".5rem",
      marginBlockEnd: ".5rem",
    },
    "& h1": {
      marginBlockStart: ".5rem",
      marginBlockEnd: ".5rem",
    },
  },
  dotsMenu: {},
  hidden: {
    show: "hidden",
  },
});

export function DashText(props: DashTextProps): JSX.Element {
  const { dashId } = props;
  const editor = useMemo(() => withReact(createEditor()), []);
  const editRef = React.useRef<HTMLDivElement>(null);
  const textItem = useStoreState((app) => {
    const item = app.findDashItem(dashId);
    return item?.kind === "Text" ? item : undefined;
  });
  const value = textItem?.richText || initialValue;
  const modifyDashItem = useStoreActions((app) => app.modifyDashItem);
  const renderElement = useCallback((props) => <TextElement {...props} />, []);
  const renderLeaf = useCallback((props) => <TextLeaf {...props} />, []);
  const [checkSizeTrigger, setCheckSize] = useState(0);
  const autoGrow = textItem?.autoGrow;
  const { dotsMenu, topBar, container, editContainer, editBox } = useStyles({
    scrollBars: !autoGrow,
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const target = event.nativeEvent.target;
      if (target instanceof HTMLElement) {
        target.scrollIntoView();
      }
      handleHotKey(editor, event);
      event.stopPropagation();
    },
    [editor]
  );

  const onPaste = useCallback((e: React.ClipboardEvent) => e.stopPropagation(), []);

  const onMouseDown = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);
  const growDispatch = useContext(GrowItemDispatch);

  useEffect(
    function checkSize() {
      if (checkSizeTrigger > 0) {
        const node = editRef.current;
        if (node) {
          const { clientHeight, scrollHeight } = node;
          if (clientHeight < scrollHeight) {
            if (growDispatch) {
              const lines = scrollHeight - clientHeight;
              growDispatch({ lines, dashId });
            } else {
              console.error("growItemDispatch undefined");
            }
          }
        } else {
          dlog("ref undefined");
        }
      }
    },
    [editRef, dashId, growDispatch, checkSizeTrigger]
  );

  const onChange = useCallback(
    (newValue: SlateDescendant[]) => {
      modifyDashItem({ dashId, dashPartial: { richText: newValue } }); // LATER save on blur
      if (autoGrow) {
        setCheckSize(Date.now());
      }
    },
    [dashId, modifyDashItem, autoGrow, setCheckSize]
  );

  return (
    <Slate editor={editor} {...{ value, onChange }}>
      <div className={container}>
        <div className={topBar}>
          <TextToolbar />
          <TextMenu {...{ dashId, className: dotsMenu }} />
        </div>
        <div className={editContainer} ref={editRef}>
          <Editable
            {...{
              className: editBox,
              onKeyDown,
              onMouseDown,
              onPaste,
              renderLeaf,
              renderElement,
            }}
            placeholder="type here"
          />
        </div>
      </div>
    </Slate>
  );
}

export function isMarkActive(
  editor: ReactEditor,
  format: keyof Omit<CustomText, "text">
): boolean {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
}

export function toggleMark(
  editor: ReactEditor,
  format: keyof Omit<CustomText, "text">
): void {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

export function isBlockActive(editor: ReactEditor, format: string): boolean {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.kind === format,
  });

  return !!match;
}

const listTypes = ["numbered-list", "bulleted-list"];

export function toggleBlock(
  editor: ReactEditor,
  kind: CustomElement["kind"],
  blockProps?: Record<string, unknown>
): void {
  const isActive = isBlockActive(editor, kind);
  const isList = listTypes.includes(kind);

  Transforms.unwrapNodes(editor, {
    match: (n: Node) => {
      if (!SlateElement.isElement(n)) {
        return false;
      }
      const element = n as SlateElement;
      return listTypes.includes(element.kind as string);
    },
    split: true,
  });

  const newProperties: Partial<SlateElement> = {
    kind: isActive ? "paragraph" : isList ? "list-item" : kind,
    ...blockProps,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { kind, children: [], indentLevel: 0, ...blockProps };
    Transforms.wrapNodes(editor, block);
  }
}

/**
 * Find the leafmost non-text nodes containing the selection range and indent those blocks.
 *
 * (The current text document model has li items nested within ul and ol blocks.)
 */
export function indentSelection(editor: ReactEditor, inOut: IndentOutdent): void {
  const blocks = selectionLeafBlocks(editor);

  const delta = inOut === "indent" ? 1 : -1;
  blocks.forEach((element) => {
    indentElement(editor, element, delta);
  });
}

/* adjust the indent level for one block */
function indentElement(editor: ReactEditor, element: SlateElement, delta: number): void {
  const indent = (element.indentLevel as number) || 0;
  const level = indent + delta;
  const indentLevel = level > 0 ? level : undefined;
  const match = (node: Node): boolean => node === element;
  Transforms.setNodes(editor, { indentLevel }, { match });
}

/** return the lowest level Elements (non-text nodes) in the selection */
function selectionLeafBlocks(editor: ReactEditor): SlateElement[] {
  const elementEntries = selectedElements(editor);

  // start with all elements
  const roots = new Set<NodeEntry<SlateElement>>(elementEntries);

  // remove parents, leaving only leaves
  elementEntries.forEach((entry) => {
    removeAncestors(entry, roots);
  });

  const elements = [...roots.values()].map(([node]) => node);
  return elements;
}

/** return all Elements (non-text, non-editor Nodes) in the selection */
function selectedElements(editor: ReactEditor): NodeEntry<SlateElement>[] {
  const selectedNodes = [
    ...Editor.nodes(editor, { at: editor.selection || undefined, mode: "all" }),
  ];
  const descendantEntries = selectedNodes.slice(1); // skip editor node
  const elements = descendantEntries.filter(
    // drop text nodes
    ([n]) => !SlateText.isText(n)
  ) as NodeEntry<SlateElement>[];
  return elements;
}

/* remove all path ancestors of a given entry from a set of NodeEntrys */
function removeAncestors(entry: NodeEntry, roots: Set<NodeEntry>): void {
  const [, path] = entry;
  const parentPaths = Path.ancestors(path).slice(1); // ancestors w/o editor node

  for (const parentPath of parentPaths) {
    for (const root of roots.values()) {
      const [, rootPath] = root;
      if (Path.equals(parentPath, rootPath)) {
        roots.delete(root);
      }
    }
  }
}
