import * as d3 from "d3";
import { Framebuffer2D, Regl } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import * as ReglPerf from "./ReglPerf";
import { FullFB, fullScreenTriangles } from "./WebGLUtil";

export interface StatCoalesceProps {
  /** source stat texture 1 x width */
  statValues: FullFB;
  evenSummaries: FullFB;
  oddSummaries: FullFB;

  /** filled result texture same size 1 x width */
  filled: Framebuffer2D;

  debug?: ChartDebug;
}

export interface StatCoalesceCmd {
  (props: StatCoalesceProps): void;
}

/**
 * Walk through the values and reductions, replacing all negative or NaN Y values in
 * the original with the neighboring non-negative value from the right or left.
 */
export function statCoalesce(regl: Regl, width: number): StatCoalesceCmd {
  const cmd = regl({
    vert: `
        precision highp float;
        attribute vec2 position;
        uniform float srcPixelWidth; // pixel width in (0, 1) texture coordinates in src buffer
        varying float u;
        varying float dd;
        const float scaleToSrc = 2.0;
            
        void main() {
            u = 0.5 * (position.x + 1.0);             // uv range (0, 1) 
            gl_Position = vec4(position, 1.0, 1.0);   // position range (-1, -1)
            dd = u;
        }`,
    frag: `
        precision highp float;
        varying float u; // x coordinate in texture 
        uniform sampler2D statValues;
        uniform sampler2D oddSummaries;
        uniform sampler2D evenSummaries;
        uniform float srcPixelWidth;
        varying float dd; // debug value
        uniform float summaryLevel;

        bool isNan(float f) {
          return !(f < 0.0 || 0.0 < f || f == 0.0 );
        } 

        void main() { 
          vec2 stat = texture2D(statValues, vec2(u, .5)).xy;

          ${checkSummaries(width)}

          gl_FragColor = vec4(stat, 0.0, 1.0);
        }`,
    uniforms: {
      statValues: (_ctx, props: StatCoalesceProps) => props.statValues,
      srcPixelWidth: (_ctx, props: StatCoalesceProps) => 1 / props.statValues.width,
      oddSummaries: (_ctx, props: StatCoalesceProps) => props.oddSummaries,
      evenSummaries: (_ctx, props: StatCoalesceProps) => props.evenSummaries,
    },
    attributes: {
      position: fullScreenTriangles,
    },
    framebuffer: (_ctx: any, props: StatCoalesceProps) => props.filled,
    depth: { enable: false, mask: false },
    primitive: "triangles",
    count: 6,
  });

  ReglPerf.registerCmd(cmd, "stats-coalesce-line");

  return cmd;
}

const floatFormat = d3.format(".8f");

/**
 * Traverse the stat output buffer and reduced summary buffers at this coordinate until
 * we find a y value >= 0.
 *
 * Each summary level propogates a non-negative value from the corresponding pair
 * of values at the level below. So the top level summary is guarranteed to have a non-negative value
 * if there is a legal value anywhere in the original stat output. And so the traversal of
 * the summaries is guaranteed to produce legal values at every position, excpeting the case
 * where there are no legal values at all in the original source.
 */
function checkSummaries(width: number): string {
  const levels = Math.ceil(Math.log2(width));
  const lines: string[] = [];
  let oddStart = 0;
  let evenStart = 0;
  let size = 0.5;
  for (let l = 1; l <= levels; l++) {
    if (l & 0x1) {
      lines.push(readSnippet("oddSummaries", size, oddStart));
      oddStart += size;
      size /= 2;
    } else {
      lines.push(readSnippet("evenSummaries", size, evenStart));
      evenStart += size;
      size /= 2;
    }
  }
  return lines.join("\n");
}

function readSnippet(texture: string, layerSize: number, layerStart: number): string {
  const offset = floatFormat(layerStart);
  const scale = floatFormat(layerSize);
  const read = `
    if (isNan(stat.y) || stat.y < 0.0) {
      stat = texture2D(${texture}, vec2(u*${scale} + ${offset}, .5)).xy;
    }
  `;
  return read;
}
