import _ from "lodash";

const exp = Math.exp;
const sqrt = Math.sqrt;
const PI = Math.PI;

/** Sample a gaussian function to generate a 1D filter kernel.
 *
 * @param kernelSize number of elements in the kernel (forced to an odd number of elements)
 * @returns filter coeeficients
 */
export function gaussian1D(kernelSize: number, sigma?: number): number[] {
  const size = kernelSize & 0x1 ? kernelSize : kernelSize + 1; // ensure size is odd
  if (size === 1) {
    return [1];
  }
  const sigma1 = sigma || (size - 1) / 6; // A rule of thumb I read on the web..
  const sigma2 = sigma1 ** 2;
  const center = size / 2;
  const denominator = sigma1 * sqrt(2 * PI);
  const baseFilter = _.times(size).map((x0) => {
    const x = x0 + 0.5; // sample gaussian in center of each x.
    const ePart = exp((-0.5 * (x - center) ** 2) / sigma2);
    return ePart / denominator;
  });

  // normalize so that coefficients sum to 1 (it's close to 1 already, but error from finite approximation)
  return normalizeFilter(baseFilter);
}

/** normalize so that coefficients sum to 1 */
export function normalizeFilter(baseFilter: number[]): number[] {
  const sum = _.sum(baseFilter);
  const filter = baseFilter.map((e) => e / sum);
  return filter;
}

// LATER rough numeric integration for better estimate of filter coefficients?
