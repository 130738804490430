import * as d3 from "d3";
import { Framebuffer2D, Regl, Texture2D } from "regl";
import { InterpolatedFilter, interpolationKaiser } from "./InterpolatedFilter";
import * as ReglPerf from "./ReglPerf";
import { fullScreenTriangles } from "./WebGLUtil";

export interface SmoothStatLineProps {
  /** source stats texture 1 x width */
  statValues: Texture2D;

  /** smoothed result texture same size 1 x width */
  smoothed: Framebuffer2D;
}

export interface SmoothStatLineCmd {
  (props: SmoothStatLineProps): void;
}

export function smoothStatLineCmd(
  regl: Regl,
  /** size of kaiser-bessel filter kernel to use */
  kernelSize: number,
  /** target attenuation of stop band  in db */
  attenuation?: number,
  /** stop band begin frequency. in 1/frequency */
  cutoff?: number
): SmoothStatLineCmd {
  const filter = interpolationKaiser(kernelSize, attenuation, cutoff);
  const snippets = filterSnippets(filter);
  const cmd = regl({
    vert: `
        precision highp float;
        attribute vec2 position;
        varying float u;
            
        void main() {
          gl_Position = vec4(position, 1.0, 1.0); // position range (-1, -1)
          u = 0.5 * (position.x + 1.0);           // uv range (0, 1) + offset to texel centers
        }`,
    frag: `
        precision highp float;
        varying float u; // x coordinate in texture 
        uniform sampler2D statValues;
        uniform float pixelWidth;

        ${snippets.constants}

        float weight[kernelHalfSize];
        float inter[kernelHalfSize];              // offset in pixel coordinates


        void main() { 
          // setup kernel weights and offsets (including interpolation)
          ${snippets.initialize}

          // weighted value from center texel
          float c = texture2D(statValues, vec2(u, .5)).g;
          float sum = c * cw;

          // accumulate weighted values from texels under left and right sides of filter
          for (int i = 0; i < kernelHalfSize; i++) {
            float w = weight[i];
            float offset = pixelWidth * inter[i];     // offset in webgl 

            float rightWithMirror = 1.0 - abs(u + offset - 1.0);
            float leftWithMirror = abs(u - offset);
            float r = texture2D(statValues, vec2(rightWithMirror, .5)).g;
            float l = texture2D(statValues, vec2(leftWithMirror, .5)).g;

            if (r > -1e99) {  
              sum += r * w;
            } else {
              sum += l * w; // right sample is NaN, use left side sample
            }

            if (l > -1e99) {  
              sum += l * w;
            } else {
              sum += r * w; // left sample is NaN, use right side sample
            }
          }

          gl_FragColor = vec4(u, sum, 0.0, 1.0);
        }`,
    uniforms: {
      statValues: (_ctx, props: SmoothStatLineProps) => props.statValues,
      pixelWidth: (_ctx, props: SmoothStatLineProps) => 1 / props.statValues.width,
    },
    attributes: {
      position: fullScreenTriangles,
    },
    framebuffer: (_ctx: any, props: SmoothStatLineProps) => props.smoothed,
    depth: { enable: false, mask: false },
    primitive: "triangles",
    count: 6,
  });

  ReglPerf.registerCmd(cmd, "smooth-stats-line");

  return function draw(props: SmoothStatLineProps): void {
    cmd(props);
  };
}

const locale = d3.formatLocale({
  decimal: ".",
  thousands: ",",
  grouping: [3],
  currency: ["$", ""],
  minus: "-", // lest we end up with hyphen, which doesn't parse in glsl
});
const floatFormat = locale.format(".8f");

interface Snippets {
  constants: string;
  initialize: string;
}

function filterSnippets(filter: InterpolatedFilter): Snippets {
  const { weights, offsets } = filter;
  const size = offsets.length;
  const constants = `
    const int kernelHalfSize = ${size};           // half the size of the kernel (not counting center)
  `;

  const initCenter = `
    float cw = ${floatFormat(weights[0])};
  `;

  const initSides = offsets
    .map((unitOffset, i) => {
      const weight = floatFormat(weights[i + 1]);
      const offset = floatFormat(i + 1 + unitOffset);
      return `
        inter[${i}] = ${offset};
        weight[${i}] = ${weight};`;
    })
    .join("\n");

  const initialize = [initCenter, initSides].join("\n");

  return { constants, initialize };
}
