const comments = ["#", "//"];

export function detectComments(lines: string[]): string {
  let foundComment: string | undefined = undefined;
  lines.find((line) => {
    foundComment = comments.find((comment) => {
      return line.startsWith(comment);
    });
    return foundComment !== undefined;
  });

  return foundComment || "";
}
