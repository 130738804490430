import { AsyncSink } from "ix";
import { parseHeader } from "../../protocol/PipeProtocol";
import { PendingData } from "./PendingData";
import { DashboardInfo, SocketListenerParams } from "./SocketListener";

let nextDebugId = 1;
const defaultPort = 3030;

export interface ListenOnce {
  debugId: number;
  close: () => void;
  pending: AsyncGenerator<PendingData, void>;
}

/** listener for one data transaction
 * One data transaction will span multiple websocket messages.
 * (one header message and one or more data web socket messages) */
export function listenOnce(params: SocketListenerParams): ListenOnce {
  const debugId = nextDebugId++;
  const sink = new AsyncSink<PendingData>();
  let open = false;
  const { makeWebSocket, port = defaultPort } = params;
  let headerDone = false;

  // if this fails it generates a warning in the console, which we don't want
  // but can't avoid. The spec says that scripts are not allowed to detect
  // info about the local network for security reasons, and the browser is unblockably
  // reporting the connection failure to make it obvious in case it's a security
  // scan. see: https://html.spec.whatwg.org/multipage/web-sockets.html#feedback-from-the-protocol
  //
  // the best we can do is allow the user to turn on/off local listening from the UI.
  //
  const socket = makeWebSocket(`ws://localhost:${port}`);
  socket.onopen = onOpen;
  socket.onmessage = onMessage;
  socket.onclose = onClose;
  socket.onerror = onError;
  socket.binaryType = "arraybuffer";

  function close(): void {
    socket.close();
    open = false;
  }

  let err: Error | undefined = undefined;

  function onOpen(): void {
    err = undefined;
    open = true;
    const dashInfo: DashboardInfo = {
      // TODO send real dashboard/browser title
      browserId: "browser-1",
      currentDashboard: "My Dashboard",
    };
    const dashInfoJson = JSON.stringify(dashInfo);
    socket?.send(dashInfoJson);
  }

  function onError(event: Event): void {
    err = new Error(`ws socket error ${event.returnValue}`);
    sink.error(err);
  }

  function onClose(): void {
    if (!err) {
      socket.close();
      sink.end();
    }
  }

  function onMessage(event: MessageEvent): void {
    const data = event.data;
    if (open && !headerDone) {
      const header = parseHeader(data);
      if (header) {
        if (header.kind === "data") {
          headerDone = true;
          sink.write({ kind: "header", header });
        } else {
          console.error("received unexpected web socket message", header);
        }
      }
    } else {
      sink.write({ kind: "data", data });
    }
  }

  async function* publicGenerator(): AsyncGenerator<PendingData, void> {
    for await (const next of sink) {
      yield next;
    }
  }

  return {
    close,
    debugId,
    pending: publicGenerator(),
  };
}
