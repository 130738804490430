import { FrameAndColumns } from "../components/rich-chart/DashChart";
import { arrayFromParametric } from "../math/PolynomialUtil";
import { ParametricFn, UnaryFn } from "../math/SplineMath";
import { TableInterpolator, unitPartitions } from "../math/TableInterpolator";
import { Vec2 } from "../math/Vec";
import { ColumnSet } from "../store/TableSource";
import { columnFrame, DataType } from "./ColumnFrame";

export function simpleChartFrame(
  xs: number[],
  ys: number[],
  name = "",
  xDisplayType: DataType = "number"
): FrameAndColumns {
  const frame = columnFrame().named(name);
  const xColId = frame.addColumn(xs, "X", xDisplayType);
  const yColId = frame.addColumn(ys, "Y", "number");

  const series: ColumnSet = {
    xColumn: xColId,
    yColumns: [yColId],
  };
  return { chartFrame: { frame }, columnSet: series };
}

export function meanVarianceFrame(
  xs: number[],
  means: number[],
  variances: number[],
  name = "",
  xDisplayType: DataType = "number"
): FrameAndColumns {
  const frame = columnFrame().named(name);
  const xColId = frame.addColumn(xs, "X", xDisplayType);
  const meanId = frame.addColumn(means, "mean", "number");
  const varianceId = frame.addColumn(variances, "variance", "number");

  const series: ColumnSet = {
    xColumn: xColId,
    yColumns: [meanId],
    yDispersion: [varianceId],
  };
  return { chartFrame: { frame }, columnSet: series };
}

export function arrayToFrame(
  xy: Vec2[],
  name = "",
  xDisplayType: DataType = "number"
): FrameAndColumns {
  const xs = xy.map(([x]) => x);
  const ys = xy.map(([, y]) => y);
  return simpleChartFrame(xs, ys, name, xDisplayType);
}

export function parametricToFrame(fn: ParametricFn, length: number): FrameAndColumns {
  return arrayToFrame(arrayFromParametric(fn, length));
}

export function fnToFrame(fn: UnaryFn, length: number): FrameAndColumns {
  const xs = unitPartitions(length);
  const ys = xs.map(fn);
  return simpleChartFrame(xs, ys);
}

export function fnToFrameGenerator(fn: UnaryFn): (length: number) => FrameAndColumns {
  return function (length: number): FrameAndColumns {
    return fnToFrame(fn, length);
  };
}

export function tableInterpolatorToFrame(
  interpolator: TableInterpolator,
  length: number
): FrameAndColumns {
  const xs = unitPartitions(length);
  const ys = interpolator.samples(xs);
  return simpleChartFrame(xs, ys);
}
