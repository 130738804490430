import { Framebuffer2D, Regl } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { debugPrintFB } from "./Debug";
import { statCoalesce } from "./StatLineCoalesceFill";
import { statReplaceNaN } from "./StatReplaceNaN";
import { textureFB } from "./TextureFB";
import { FullFB } from "./WebGLUtil";

export interface StatFillProps {
  /** source stats texture 1 x width */
  statValues: FullFB;

  /** filled result texture same size 1 x width */
  filled: Framebuffer2D;

  debug?: ChartDebug;
}

export interface StatFillFn {
  (props: StatFillProps): void;
}

const ceil = Math.ceil;
const log2 = Math.log2;

export function fillStatValues(regl: Regl, width: number): StatFillFn {
  const summaryWidth = 2 ** ceil(log2(width));
  const [, evenSummaries] = textureFB(regl, [summaryWidth, 1]);
  const [, oddSummaries] = textureFB(regl, [summaryWidth, 1]);
  const replaceNaN = statReplaceNaN(regl);
  const coalesce = statCoalesce(regl, summaryWidth);

  return function fill(props: StatFillProps): void {
    const { statValues, filled, debug } = props;
    const steps = ceil(log2(summaryWidth));

    if (steps > 0) {
      replaceNaN({ src: statValues.color[0], dst: oddSummaries, summaryLevel: 1 });
    }
    for (let i = 2; i <= steps; ) {
      replaceNaN({ src: oddSummaries.color[0], dst: evenSummaries, summaryLevel: i++ });
      if (i <= steps) {
        replaceNaN({ src: evenSummaries.color[0], dst: oddSummaries, summaryLevel: i++ });
      }
    }

    coalesce({ statValues, evenSummaries, oddSummaries, filled, debug });

    if (debug?.logFilledStat) {
      debugPrintFB(regl, statValues, [statValues.width, 1], "stat values", "g");
      debugPrintFB(regl, oddSummaries, [oddSummaries.width, 1], "odd summaries x", "r");
      debugPrintFB(regl, oddSummaries, [oddSummaries.width, 1], "odd summaries y", "g");
      debugPrintFB(regl, evenSummaries, [oddSummaries.width, 1], "even summaries x", "r");
      debugPrintFB(regl, evenSummaries, [oddSummaries.width, 1], "even summaries y", "g");
      debugPrintFB(regl, filled, [statValues.width, 1], "filled x", "r");
      debugPrintFB(regl, filled, [statValues.width, 1], "filled y", "g");
    }
  };
}
