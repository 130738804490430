import { FrameStreamAndColumns } from "../data/WatchData";
import * as SampleStreams from "./SampleStreams";

export interface GeneratedStreamEntry {
  name: string;
  debug: boolean;
  stream: (maxRows?: number, timeout?: number) => Promise<FrameStreamAndColumns>;
}

export const GeneratedStreams: GeneratedStreamEntry[] = generatedStreams();

function generatedStreams(): GeneratedStreamEntry[] {
  return Object.entries(SampleStreams).map(([name, fn]) => ({
    name,
    debug: false,
    stream: fn,
  }));
}
