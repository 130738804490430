import { createTypedHooks } from "easy-peasy";
import { useCallback } from "react";
import { AnyPlotProperty, Plot } from "../chart/PlotModels";
import { AppModel } from "./AppModel";
import { ChartModel } from "./ChartModel";
import { DashItem } from "./DashItems";

const typedHooks = createTypedHooks<AppModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export function useCurrentPlot<T extends Partial<Plot>, U>(
  fn: (plot: T) => U
): U | undefined {
  return useStoreState((app) => {
    const plot = app.currentPlot as T;
    const result = plot && fn(plot);
    return result;
  });
}

export function useCurrentChart<U>(fn: (chart: ChartModel) => U): U | undefined {
  return useStoreState((app) => {
    const chart = app.currentChart;
    const result = chart && fn(chart);
    return result;
  });
}

export function useCurrentSelection<T extends Partial<DashItem>, U>(
  fn: (item: T) => U
): U | undefined {
  return useStoreState((app) => {
    const item = app.currentSelection as T;
    const result = item && fn(item);
    return result;
  });
}

export function useModifyPlot<K extends keyof AnyPlotProperty>(
  property: K
): (_e: any, value: AnyPlotProperty[K]) => void {
  const modifyPlot = useStoreActions((app) => app.modifyPlot);

  const modify = useCallback(
    (_e: any, value: AnyPlotProperty[K]) => {
      modifyPlot({
        [property]: value,
      });
    },
    [modifyPlot, property]
  );
  return modify;
}
