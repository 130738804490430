import { DataArray } from "../data/DataChunk";
import { CellParser } from "./DetectCellFormat";
import { SimpleObject, SimpleSignature } from "./DetectJsonObjectTable";
import { baseJsonScore } from "./DetectJsonTable";
import { parseColumns } from "./ParseColumns";
import { stringParser } from "./ParseJsonArrayTable";
import { jsonNumberParser } from "./ParseJsonColumn";
import { JsonTableParser, TabularColumn } from "./ParseTabular";

/** given a signature for a simple object in each row, return a table parser */
export function objectTableParser(
  signatures: SimpleSignature[]
): JsonTableParser | undefined {
  if (signatures.length === 0) {
    return undefined;
  }
  const first = signatures[0];
  const columns = Object.keys(first).map((key) => {
    const dataParser = parserForKey(key);
    const column: TabularColumn = {
      label: key,
      dataParser,
    };
    return column;
  });

  const jsonParser: JsonTableParser = {
    score: baseJsonScore() + columns.length,
    kind: "json",
    parse,
    parseBody: parse,
    columns,
    format: "objects",
  };

  return jsonParser;

  function parse(text: string): DataArray[] {
    const objectTable = JSON.parse(text) as SimpleObject[];
    const rowTable = objectTable.map((o) => Object.values(o)) as string[][];
    return parseColumns(rowTable, columns);
  }

  function parserForKey(key: string): CellParser {
    const found = signatures.find((sig) => sig[key] !== undefined);
    if (found && found[key] === "number") {
      return jsonNumberParser;
    }
    return stringParser;
  }
}
