export function installBrowserDebug(): void {
  (globalThis as any).bd = {
    verticalScrollers,
  };
}

function verticalScrollers(): void {
  const big = [...document.querySelectorAll("*")].filter(
    (e) => e.scrollHeight > e.clientHeight && e.clientWidth > 0 && e.clientHeight > 0
  );
  const scrollVisible = big.filter((e) => {
    const style = getComputedStyle(e);
    if (
      e.tagName === "HTML" ||
      style.visibility === "hidden" ||
      style.overflowY === "hidden" ||
      style.position === "absolute"
    ) {
      return false;
    }
    return true;
  });
  console.log(scrollVisible);
  // big.filter((e) => e.nodeType === Nodeype).get("overflow-y").value !== "hidden");
}
