import { makeStyles, Popper, PopperProps, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GeneratedDataLoaders } from "../../example-data/GeneratedData";
import { GeneratedStreams } from "../../example-data/GeneratedStreamData";
import { plotDefaultMaxRows } from "../../store/ChartModel";
import { DashId } from "../../store/DashId";
import { EnabledDataSources } from "../../store/DataSourcesModel";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import {
  GeneratedStreamSource,
  GeneratedTableSource,
  likelySourceColumns,
  noTableSource,
  TableSource,
  toLoadTableSource,
} from "../../store/TableSource";
import { HiddenFileInput } from "../dash-controls/HiddenFileInput";
import { useLoadFailed, useLoadFile } from "../dash-data/LoadFileHook";
import { useLocalSources } from "../dash-data/LocalStoreCache";
import { acceptFileFormats } from "../rich-chart/ChartDropzone";
import DataSourceIcon from "./TableSourceIcon";

export interface PlotDataAutocompleteProps {
  dashId: DashId;
  onChangeSource?: (tableSource: TableSource | null) => void;
  className?: string;
}

export const autoCompleteWidth = 300;

const useStyles = makeStyles({
  autocompleteText: {
    width: autoCompleteWidth,
  },
});

export const PlotDataAutocomplete = React.memo(PlotDataSelect);

function PlotDataSelect(props: PlotDataAutocompleteProps): JSX.Element {
  const { dashId, onChangeSource } = props,
    classes = useStyles(),
    enabledSources = useStoreState((state) => state.data.enabledSources),
    debugSampleData = useStoreState((state) => state.debug.debugSampleData),
    tableSource = useStoreState((app) => app.findChart(dashId)?.tableSource),
    chartTitle = useStoreState((app) => app.findChart(dashId)?.title),
    maxRows = tableSource?.maxRows,
    setTableSource = useStoreActions((actions) => actions.setTableSource),
    modifyChartById = useStoreActions((app) => app.modifyChartById),
    fileInputRef = useRef<HTMLInputElement>(null),
    loadFile = useLoadFile(),
    loadFailed = useLoadFailed(dashId),
    tableSources: TableSource[] = useTableSources(
      tableSource,
      enabledSources,
      debugSampleData
    ),
    options = useMemo(() => [...tableSources], [tableSources]),
    autoCompleteRef = useRef<HTMLElement>(),
    [, forceRender] = useState(0);

  const onChange = useCallback(
    (e: React.ChangeEvent<unknown>, source: TableSource | null, reason: string) => {
      if (reason === "select-option" && source) {
        if (source.kind === "loading") {
          forceRender(Date.now());
          fileInputRef.current?.click();
        } else {
          onChangeSource && onChangeSource(source);
          // preserve existing maxRows
          let setMaxRows = {};
          if (maxRows) {
            setMaxRows = { maxRows };
          } else if (source.kind === "generated") {
            setMaxRows = { maxRows: plotDefaultMaxRows };
          }
          const newSource = { ...source, ...setMaxRows };
          if (!chartTitle || chartTitle === "" || chartTitle === tableSource?.name) {
            const title = source?.name === "<none>" ? "" : source?.name;
            modifyChartById({ dashId, chartPartial: { title } });
          }
          likelySourceColumns(source).then((columnSet) => {
            setTableSource({
              dashId: dashId!,
              tableSource: newSource,
              columnSet,
            });
          });
        }
      }
    },
    [dashId, maxRows, setTableSource, onChangeSource]
  );

  const onFiles = useCallback(
    (files: FileList) => {
      for (const file of files) {
        loadFile(file, dashId).catch((err) => {
          loadFailed(`unable to load file: ${file.name}`);
          console.error(file.name, err);
        });
      }
    },
    [loadFile, loadFailed, dashId]
  );

  const onKeyDown = useCallback((ev: React.KeyboardEvent) => ev.stopPropagation(), []);

  const renderOption = useCallback((dataSeries: TableSource): JSX.Element => {
    return (
      <>
        <DataSourceIcon sourceKind={dataSeries.kind} />
        {dataSeries.name}
      </>
    );
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => {
      return (
        <TextField
          className={classes.autocompleteText}
          {...params}
          label={"data"}
          variant="outlined"
        />
      );
    },
    [classes.autocompleteText]
  );

  useEffect(() => {
    const div = autoCompleteRef.current;
    if (div) {
      const input = div.querySelector("input");
      input?.focus();
    }
  }, []);

  /* force re-render of autcomplete in case load file is selected, and then cancelled. 
     (otherwise the displayed value stays <load file>) */
  const value = useMemo(() => tableSource && { ...tableSource }, [tableSource]);

  return (
    <span>
      <Autocomplete
        ref={autoCompleteRef}
        multiple={false}
        {...{ value, options, renderOption, renderInput, onChange, onKeyDown }}
        getOptionLabel={(item) => item.name}
        className={clsx(props.className)}
        PopperComponent={PopControl}
        getOptionSelected={(a, b) => a.name === b.name}
        autoComplete={true}
        autoSelect={true}
        blurOnSelect={true}
      />
      <HiddenFileInput {...{ onFiles, accept: acceptFileFormats, ref: fileInputRef }} />
    </span>
  );
}

function useTableSources(
  tableSource: TableSource | undefined,
  enabledSources: EnabledDataSources,
  debugSampleData: boolean
): TableSource[] {
  const localTables = useLocalSources();
  const generatedTables = useGeneratedTables(debugSampleData);
  const generatedStreams = useGeneratedStreamTables(debugSampleData);

  return useMemo((): TableSource[] => {
    const tableSources: TableSource[] = [noTableSource, toLoadTableSource];

    addUnique(tableSource);
    if (enabledSources.generated) {
      generatedTables.forEach(addUnique);
    }
    if (enabledSources.generatedStream) {
      generatedStreams.forEach(addUnique);
    }
    if (enabledSources.local) {
      localTables.forEach(addUnique);
    }

    /** add series to the option list unless it's already in the list */
    function addUnique(series: TableSource | undefined): void {
      if (!series) return;
      const found = tableSources.find(
        (s) => s.name === series.name && s.kind === series.kind
      );
      if (!found) {
        tableSources.push(series);
      }
    }

    return tableSources;
  }, [tableSource, enabledSources, localTables, generatedTables, generatedStreams]);
}

function useGeneratedTables(debugSampleData: boolean): GeneratedTableSource[] {
  return useMemo((): GeneratedTableSource[] => {
    const includeDebug = debugSampleData || false;
    const debugFiltered = GeneratedDataLoaders.filter(
      ({ debug }) => !debug || includeDebug
    );
    const tables: GeneratedTableSource[] = debugFiltered.map(({ name }) => ({
      name: name,
      kind: "generated",
    }));

    return tables;
  }, [debugSampleData]);
}

function useGeneratedStreamTables(debugSampleData: boolean): GeneratedStreamSource[] {
  return useMemo((): GeneratedStreamSource[] => {
    const includeDebug = debugSampleData || false;
    const debugFiltered = GeneratedStreams.filter(({ debug }) => !debug || includeDebug);
    const tables: GeneratedStreamSource[] = debugFiltered.map(({ name }) => ({
      name: name,
      kind: "generatedStream",
    }));

    return tables;
  }, [debugSampleData]);
}

function PopControl(props: PopperProps): JSX.Element {
  return <Popper {...{ ...props, placement: "top" }} />;
}
