import { makeStyles, Paper } from "@material-ui/core";
import React, { useCallback, useRef } from "react";
import { useBlockLayout, useResizeColumns, useTable } from "react-table";
import { ColumnFrame } from "../../data/ColumnFrame";
import { DashId } from "../../store/DashId";
import { useStoreState } from "../../store/Hooks";
import { ColumnSet } from "../../store/TableSource";
import { DashPosition, DataGrid, sheetHeight } from "./DataGrid";
import { MaxRows, maxRowsWidth } from "./MaxRows";
import { autoCompleteWidth, PlotDataAutocomplete } from "./PlotDataAutocomplete";
import { scrollBarWidth } from "./ScrollBarWidth";
import { SheetButtons } from "./SheetButtons";
import { useDashPosition } from "./UseDashPosition";
import { useRowsAndColumns } from "./UseRowsAndColumns";

interface SheetPosition {
  left: number;
  width: number;
}

const headerHeight = 100;

const useStyles = makeStyles(
  {
    dataSheet: {
      position: "absolute",
      bottom: -(sheetHeight + headerHeight),
      left: (styleParams: SheetPosition) => styleParams.left,
      width: (styleParams: SheetPosition) => styleParams.width,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignContent: "top",
      height: headerHeight,
    },
    autoComplete: {
      margin: 25,
    },
    maxRowsContainer: {
      marginTop: 18,
      marginLeft: 25,
      marginRight: 120, // enough margin for buttons
    },
  },
  { link: true }
);

export interface DataSheetProps {
  dashId: DashId;
  chartRef: HTMLElement | null;
  columnFrame: ColumnFrame;
  columnSet: ColumnSet | undefined;
}

export const ChartDataSheet = React.memo(ChartDataSheetInternal);

function ChartDataSheetInternal(props: DataSheetProps): JSX.Element {
  const sheetId = useStoreState((app) => app.view.sheet);
  const selectedId = useStoreState((app) => app.view.selectedDashItem);
  const { dashId, chartRef } = props;
  const show = dashId === sheetId && dashId === selectedId;

  if (chartRef) {
    const axisBackgrounds = chartRef.querySelectorAll(".axis rect.background");
    if (show) {
      axisBackgrounds.forEach((background) => background.setAttribute("opacity", "1.0"));
    } else {
      axisBackgrounds.forEach((background) => background.setAttribute("opacity", "0"));
    }
  }

  if (show) {
    return <DataSheet {...props} />;
  } else {
    return <></>;
  }
}

const defaultColumn = {
  minWidth: 30,
  maxWidth: 500,
  width: 150,
};

function DataSheet(props: DataSheetProps): JSX.Element {
  const { chartRef, dashId, columnFrame, columnSet } = props;
  const chartPosition = useDashPosition(chartRef);
  const { rows: data, columns } = useRowsAndColumns(dashId, columnFrame);
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns
  );
  const { totalColumnsWidth } = tableInstance;
  const sheetPosition = useSheetPosition(chartPosition, totalColumnsWidth);
  const classes = useStyles(sheetPosition);
  const sheetWidth = sheetPosition.width;

  const mouseDown = useCallback(function (e: React.MouseEvent) {
    // grid layout supports drag on our container with the chart.
    // We stop the mousedown, so that users won't move the chart when clicking on the sheet.
    e.stopPropagation();
  }, []);

  if (!chartPosition) {
    return <></>;
  }

  return (
    <Paper onMouseDown={mouseDown} className={classes.dataSheet} elevation={20}>
      <div className={classes.header}>
        <PlotDataAutocomplete {...{ dashId }} className={classes.autoComplete} />
        <MaxRows {...{ dashId }} className={classes.maxRowsContainer} />
        <SheetButtons />
      </div>
      <DataGrid {...{ dashId, sheetWidth, tableInstance, columnSet, data }} />
    </Paper>
  );
}

const sheetMargin = 30;

const closeButtonWidth = 100;
const sheetMinWidth =
  autoCompleteWidth + maxRowsWidth + sheetMargin * 2 + closeButtonWidth;

const dummySheetPosition: SheetPosition = { left: 0, width: 0 };
/**
 * @return the width and left position of the sheet relative to the chart.
 *
 * If it fits, center the sheet below the chart. Otherwise, make sure the sheet
 * doesn't overlap the left or right edge of the dashboard.
 */
function useSheetPosition(
  chartPosition: DashPosition | undefined,
  totalColumnsWidth: number
): SheetPosition {
  const dashWidth = useStoreState((app) => app.view.dashWidth);
  const sheetPosition = useRef({ left: 0, width: 0 }).current;
  if (!chartPosition) {
    return dummySheetPosition;
  }

  const { left: chartLeft, center: chartCenter } = chartPosition;
  const barWidth = scrollBarWidth();
  const maxWidth = dashWidth - sheetMargin * 2;
  const widthVsMax = Math.min(totalColumnsWidth + barWidth, maxWidth);
  const width = Math.max(sheetMinWidth, widthVsMax);
  const atLeft = -chartLeft + sheetMargin;
  const centered = -(width / 2 - chartCenter + chartLeft);
  const atRight = dashWidth - chartLeft - width + sheetMargin;
  const left = Math.max(atLeft, Math.min(centered, atRight));

  sheetPosition.left = left;
  sheetPosition.width = width;

  return sheetPosition;
}
