import * as d3 from "d3";
import _ from "lodash";
import memoizeOne from "memoize-one";
import { ColumnFrame, DataExtent, yExtent } from "../data/ColumnFrame";
import { Vec2, Vec4 } from "../math/Vec";
import { ColumnSet } from "../store/TableSource";
import {
  AnySelection,
  AnyTransition,
  SvgGSelection,
  SvgSVGSelection,
} from "../util/d3Util";
import { qaSet } from "../util/QaApi";
import axes, { Axes } from "./Axes";
import { Brush, brushBehavior, createBrushContainer } from "./Brush";
import { ChartDebug } from "./ChartDebug";
import chartScales, { ChartScales } from "./ChartScales";
import { homeButton } from "./HomeButtonSvg";
import { panZoom } from "./PanZoom";
import getPlotArea, { PlotArea } from "./plotArea";
import { Plot } from "./PlotModels";
import { Margin } from "./Rectangle";
import { plotter, Plotter } from "./RenderPlot";
import { fullSize } from "./SvgFullSize";

export interface SvgLinesOptions {
  kind: "SvgLinesOptions";
}

export interface SvgScatterOptions {
  kind: "SvgScatterOptions";
}

export interface MarkOptionsBase {
  kind: string;
}

export interface ChartProps {
  /** table of data, organized in columns */
  frame: ColumnFrame;

  /** selection of which columns to plot */
  columnSet: ColumnSet | undefined;

  /** description of plot settings for this plot */
  plot: Plot;

  /** x,y domain */
  fixedExtent?: DataExtent;

  /** developer debug settings passed to renderers */
  debug?: ChartDebug;

  /** background fill color */
  background?: Vec4;

  /** notify caller of zoom change (e.g. from user brushing) */
  zoomChanged?: (zoom: XyDimensions) => void;

  /** initial zoom */
  zoom?: XyDimensions;

  /** set to true to zoom to home */
  resetZoom?: boolean;

  /** true to display a home button */
  homeButton?: boolean;

  /** title for chart */
  title?: string;
}

export interface XyDimensions {
  x: Vec2;
  y: Vec2;
}

/* space for the axes, inside the chart's svg container, outside the plot area */
export const chartDefaultMargin: Margin = {
  top: 10,
  right: 20,
  bottom: 40,
  left: 80,
};

let debugId = 1;

export interface ChartAPI {
  zoomTo: (rangeExtent: Vec2, duration: number) => void;
  zoomToXDomain: (
    domainExtent: Vec2,
    duration: number,
    notifyViewChange?: boolean,
    clearWheelState?: boolean,
    yDomain?: Vec2
  ) => void;
  drawAll: (transition: AnyTransition) => void;
}

export interface Destroyable {
  destroy(): void;
}

export function Chart(
  containerNode: HTMLElement | undefined | null,
  props: ChartProps
): Destroyable {
  if (!containerNode) {
    return { destroy: () => {} };
  }
  const background = props.background || ([0xff, 0xff, 0xff, 0xff] as Vec4);
  const newProps = { ...props, background };
  const container = d3.select(containerNode);
  const titleHeight = chartTitle(container, props.title);
  const svg = chartSvg(container);
  const state: ChartState | undefined = svg.datum();

  if (!state) {
    initialChart(svg, newProps, titleHeight);
    const newState: ChartState = svg.datum();
    const propsZoom = props.zoom?.x || newState.dataExtent.x;
    newState.api.zoomToXDomain(propsZoom, 0, false);
  } else {
    const dirty = updateState(state, svg, newProps);
    if (dirty) {
      if (props.columnSet) {
        state.api.drawAll(d3.transition("chartRedraw").duration(0));
      }
    } else {
      zoomReload(state, newProps);
    }
  }

  function destroy(): void {
    state?.plotter.destroy();
    svg.remove();
  }

  return { destroy };
}

/** reload the zoom state from undo/redo if necessary */
function zoomReload(state: ChartState, newProps: ChartProps): void {
  const propsZoom = newProps.zoom?.x || state.dataExtent.x;
  const targetZoom = state.scales.zoomTarget.x.domain();

  if (!_.isEqual(targetZoom, propsZoom)) {
    state.api.zoomToXDomain(propsZoom, 100, false);
  }
}

/** Revise the chart state if the props have changed
 * @return true if the chart needs to be redrawn
 */
function updateState(
  state: ChartState,
  svg: SvgSVGSelection,
  props: ChartProps
): boolean {
  const { frame, columnSet, plot, debug, fixedExtent } = props;
  const oldProps = state.props;
  let redraw = false;

  if (
    frame !== oldProps.frame ||
    columnSet != oldProps.columnSet ||
    fixedExtent != oldProps.fixedExtent
  ) {
    resetChartState(state, svg, props);
    if (!columnSet && oldProps.columnSet) {
      state.plotter.clear();
    }
    redraw = true;
  } else if (!_.isEqual(plot, oldProps.plot)) {
    if (plot.kind !== oldProps.plot.kind) {
      state.plotter.clear();
    }
    redraw = true;
  } else if (!_.isEqual(debug, state.props.debug)) {
    redraw = true;
  } else if (props.resetZoom && !oldProps.resetZoom) {
    setTimeout(() => {
      state.api.zoomToXDomain(state.dataExtent.x, 200, true, true, state.dataExtent.y);
    }, 0);
    redraw = true;
  }

  state.props = props;

  return redraw;
}

/** margin inside the axes for the plot area, so the plotted marks don't get clipped */
const normalPlotMargin: Margin = { top: 3, right: 3, bottom: 3, left: 3 };
export const miniPlotMargin: Margin = { top: 2, right: 1, bottom: 1, left: 2 };

/** reset chart state after data has changed */
function resetChartState(
  state: StaticChartState,
  svg: SvgSVGSelection,
  props: ChartProps
): ChartState {
  const outState = state as ChartState;
  outState.props = props;
  const { frame, columnSet } = props;
  const xColumn = columnSet?.xColumn;
  const xType = (xColumn && frame.getColumn(xColumn)?.displayType) || "number";
  const frameAndSet = { frame, columnSet };

  const dataExtent = props.fixedExtent || {
    x: frame.xExtent(xColumn),
    y: yExtent(frameAndSet),
  };

  const [svgSize] = fullSize(svg, state.axesMargin, state.titleHeight),
    scales = chartScales(dataExtent, xType, state.plotArea.plotRect);
  outState.dataExtent = dataExtent;
  outState.scales = scales;
  outState.axes = axes(svg, scales, svgSize, state.axesMargin, columnSet === undefined);
  setupPanZoom(svg);
  return outState;
}

/** create new plotter. Dispose webgl resources from old plotter */
function resetPlotter(state: ChartState): void {
  const debug = state.props.debug;
  state.plotter = plotter(state.plotArea, debug?.miniCanvas, debug?.showPerformance);
}

/** create the chart subcomponents for the first time */
function initialChart(
  svg: SvgSVGSelection,
  props: ChartProps,
  titleHeight: number
): void {
  const chartId = debugId++,
    axesMargin = chartDefaultMargin,
    [, plotSize] = fullSize(svg, axesMargin, titleHeight),
    plotMargin = props?.debug?.miniCanvas ? miniPlotMargin : normalPlotMargin,
    plotArea = getPlotArea(svg, plotSize, axesMargin, plotMargin, chartId),
    brushElem = createBrushContainer(plotArea),
    api = getDrawAll(svg) as ChartAPI; // zoom functions will be added to api in resetChartState/setupPanZoom

  const state = {
    chartId,
    plotArea,
    brush: undefined as any,
    api,
    axesMargin,
    titleHeight,
  };
  svg.data([state]);
  const fullState = resetChartState(state, svg, props);
  resetPlotter(fullState);
  setupBrush(svg, brushElem);
  props.homeButton && homeButton(svg);
  qaSet("chartApi", api);
}

function setupBrush(svg: SvgSVGSelection, brushElem: SvgGSelection): void {
  const state: StaticChartState = svg.datum();
  const brush = brushBehavior(svg, brushElem);
  state.brush = brush;
}

function setupPanZoom(svg: SvgSVGSelection): void {
  const state: StaticChartState = svg.datum();
  const { api, plotArea } = state;
  const { zoomTo, zoomToXDomain } = panZoom(plotArea.area, svg);
  api.zoomTo = zoomTo;
  api.zoomToXDomain = zoomToXDomain;
}

interface DrawAll {
  drawAll: (transition: AnyTransition) => void;
}

function getDrawAll(svg: SvgSVGSelection): DrawAll {
  const cachedFrameAndSet = memoizeOne((frame: ColumnFrame, columnSet?: ColumnSet) => ({
    frame,
    columnSet,
  }));
  function drawAll(transition: AnyTransition): void {
    const state: ChartState = svg.datum();
    const { plotter } = state;

    state.brush.draw(transition);
    state.axes.draw(transition, state.scales.zoomTarget);
    const { props, scales } = state;
    const { debug, plot, frame, columnSet, background } = props;
    const frameAndSet = cachedFrameAndSet(frame, columnSet);
    plotter.render(transition, plot, frameAndSet, scales, background!, debug || {});
  }

  return {
    drawAll,
  };
}

/** @return the svg element containing the chart plot and axes. create it if necessary */
function chartSvg(container: AnySelection): SvgSVGSelection {
  let svg: SvgSVGSelection = container.select("svg.chart");
  if (svg.size() === 0) {
    svg = container
      .append("svg")
      .attr("class", "chart")
      .attr("data-qa", "chart")
      .style("display", "block");
  }
  return svg;
}

function chartTitle(container: AnySelection, titleText?: string): number {
  let title = container.select(".chart-title");
  if (title.size() === 0) {
    title = container
      .append("div")
      .classed("chart-title-container", true)
      .append("div")
      .classed("chart-title", true)
      .attr("contenteditable", "true") as AnySelection;
  }
  title.text(titleText || "");
  title.on("keydown mousedown", (e: Event) => {
    e.stopPropagation();
  });
  title.on("blur", () => {
    const currentText = title.text();
    if (currentText !== titleText) {
      const titleEvent = new CustomEvent("chart-title", {
        detail: currentText,
        bubbles: true,
        cancelable: true,
      });
      (title.node() as HTMLElement)?.dispatchEvent(titleEvent);
    }
  });
  const titleNode = title.node() as HTMLElement;
  return titleNode?.clientHeight || 0;
}

/** internal state of the chart, stored in the DOM, attached to the svg.chart element */
export interface ChartState extends StaticChartState {
  scales: ChartScales; // functions to convert time,value numbers to x,y coordinates
  axes: Axes; // d3 components for drawing axes
  dataExtent: DataExtent;
  plotter: Plotter;
  props: ChartProps;
}

interface StaticChartState {
  chartId: number;
  plotArea: PlotArea; // g element containing plots, plot area size metadata
  brush: Brush;
  api: ChartAPI;
  axesMargin: Margin;
  titleHeight: number;
}
