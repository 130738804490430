import { IconButton, makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { useSlate } from "slate-react";
import {
  indentSelection,
  isBlockActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
} from "./DashText";
import { CustomElement, CustomText } from "./SlateTypes";

const useStyles = makeStyles({
  active: {},
  inactive: {
    color: "#b0b0b0",
  },
});

export interface MarkButtonProps {
  format: keyof Omit<CustomText, "text">;
  icon: JSX.Element;
}

export interface BlockButtonProps {
  kind: CustomElement["kind"];
  icon: JSX.Element;
}

export interface IndentButtonProps {
  kind: IndentOutdent;
  icon: JSX.Element;
}
export type IndentOutdent = "indent" | "outdent";

export function TextMarkButton(props: MarkButtonProps): JSX.Element {
  const { active, inactive } = useStyles();
  const { format, icon } = props;
  const editor = useSlate();

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      toggleMark(editor, format);
    },
    [editor, format]
  );
  const className = isMarkActive(editor, format) ? active : inactive;

  return <IconButton {...{ onMouseDown, className }}>{icon}</IconButton>;
}

export function TextBlockButton(props: BlockButtonProps): JSX.Element {
  const { kind, icon } = props;
  return BlockButton(kind, icon);
}

function BlockButton(
  kind: CustomElement["kind"],
  icon: JSX.Element,
  blockProps?: Record<string, unknown>
): JSX.Element {
  const { inactive, active } = useStyles();
  const editor = useSlate();

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      toggleBlock(editor, kind, blockProps);
    },
    [editor, kind, blockProps]
  );
  const className = isBlockActive(editor, kind) ? active : inactive;

  return <IconButton {...{ onMouseDown, className }}>{icon}</IconButton>;
}

export function TextIndentButton(props: IndentButtonProps): JSX.Element {
  const { inactive: className } = useStyles();
  const { kind, icon } = props;
  const editor = useSlate();

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      indentSelection(editor, kind);
    },
    [editor, kind]
  );

  return <IconButton {...{ onMouseDown, className }}>{icon}</IconButton>;
}
