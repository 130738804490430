import memoizeOne from "memoize-one";
import { Regl } from "regl";
import { FrameAndSet } from "../data/ColumnFrame";
import { Vec4 } from "../math/Vec";
import { AnyTransition, SvgGSelection } from "../util/d3Util";
import clearCanvas from "../webgl/ClearCanvas";
import { DensityArgs } from "../webgl/DensityShading";
import { heatLines, LinesArgs } from "../webgl/HeatLines";
import { heatScatter, HeatScatterArgs } from "../webgl/HeatScatter";
import { plotCanvas } from "../webgl/PlotCanvas";
import { setupRegl } from "../webgl/SetupRegl";
import { reglDestroy } from "../webgl/WebGLUtil";
import { ChartDebug } from "./ChartDebug";
import { ChartScales } from "./ChartScales";
import { PlotArea } from "./plotArea";
import { DensityPlotData, Plot } from "./PlotModels";
import { Rectangle } from "./Rectangle";
import svgCircles, { SvgScatterArgs } from "./SvgCircleScatterPlot";
import svgLines, { SvgRendererArgs } from "./SvgLinePlot";

export interface Renderable<T> {
  draw: (transition: AnyTransition, args: T) => void;
}

export interface WebglRenderable<T> extends Renderable<T> {
  rebuildRequired: (args: T) => boolean;
}

export interface Plotter {
  render: (
    transition: AnyTransition,
    desc: Plot,
    frameAndSet: FrameAndSet,
    chartScales: ChartScales,
    background: Vec4,
    debug: ChartDebug
  ) => void;
  clear: (background?: Vec4) => void;
  destroy: () => void;
}

/*
lifecycle of a plotter()
one plotter() per chart.
  must be destroyed if the chart is discarded (to free gl resources)
one canvas per plotter
resize:
. support resize? or require caller to create a new plotter()?
. everything changes on resize, so let's have caller do it.
new data:
. rebuild regl for now, l8r could just transfer new data over.
*/

interface Renderers {
  // L8r initialize these on demand
  regl: Regl;
  densityScatter: WebglRenderable<HeatScatterArgs>;
  densityLines: WebglRenderable<LinesArgs>;
  clearWebGl: any;
  svgLine: Renderable<SvgRendererArgs>;
  svgScatter: Renderable<SvgScatterArgs>;
}

export function plotter(
  plotArea: PlotArea,
  debugMini = false,
  performanceReport = false
): Plotter {
  const canvas = plotCanvas(plotArea, debugMini);
  const plotRect = calcCanvasRect(plotArea.plotRect);
  const marks = marksContainer(plotArea);

  let renderers: Renderers;
  resetRenderers();

  return { render, clear, destroy };

  function destroy(): void {
    reglDestroy(renderers.regl);
  }

  function resetRenderers(): void {
    if (renderers) {
      reglDestroy(renderers.regl);
    }
    const regl = setupRegl(canvas, performanceReport, debugMini);
    const densityScatter = heatScatter(regl);
    const densityLines = heatLines(regl);
    const svgLine = svgLines(marks);
    const svgScatter = svgCircles(marks);
    const clearWebGl = memoizeOne((background?: Vec4) => clearCanvas(regl, background));
    renderers = {
      regl,
      densityScatter,
      densityLines,
      svgScatter,
      svgLine,
      clearWebGl,
    };
  }

  function render(
    transition: AnyTransition,
    plot: Plot,
    frameAndSet: FrameAndSet,
    chartScales: ChartScales,
    background: Vec4,
    debug: ChartDebug
  ): void {
    if (plot.kind === "HeatLines") {
      const args = webGlSetup(plot, renderers.densityLines);
      renderers.densityLines.draw(transition, args);
    } else if (plot.kind === "HeatScatter") {
      const args = webGlSetup(plot, renderers.densityScatter);
      renderers.densityScatter.draw(transition, args);
    } else if (plot.kind === "SvgLines") {
      renderers.svgLine.draw(transition, svgArgs());
    } else if (plot.kind === "SvgScatter") {
      const args: SvgScatterArgs = { plot, ...svgArgs() };
      renderers.svgScatter.draw(transition, args);
    }

    function svgArgs(): SvgRendererArgs {
      const args: SvgRendererArgs = {
        frameAndSet,
        targetScales: chartScales.zoomTarget,
      };
      return args;
    }

    function webGlSetup<T extends DensityPlotData>(
      plot: T,
      renderable: WebglRenderable<DensityArgs<T>>
    ): DensityArgs<T> {
      const zoomingScales = chartScales.zooming;
      // prettier-ignore
      const args: DensityArgs<T> = { canvas, background, plotRect, debug, frameAndSet, zoomingScales, plot };
      if (renderable.rebuildRequired(args)) {
        resetRenderers();
      }
      return args;
    }
  }

  function clear(background?: Vec4): void {
    removeSvgMarks(plotArea);
    renderers.clearWebGl(background)();
  }
}

function removeSvgMarks(plotArea: PlotArea): void {
  plotArea.area.selectAll("g.marks").selectAll("*").remove();
}

/** convert a rectangle in svg coordinates to canvas coordinates */
function calcCanvasRect(plotRect: Rectangle): Rectangle {
  const pixRatio = window.devicePixelRatio;
  return {
    top: plotRect.bottom * pixRatio, // svg 0 is top, webgl 0 is bottom
    bottom: plotRect.top * pixRatio,
    left: plotRect.left * pixRatio,
    right: plotRect.right * pixRatio,
  };
}

/** return the svg g element to hold plotted marks */
function marksContainer(plotArea: PlotArea): SvgGSelection {
  return plotArea.area
    .selectAll("g.marks")
    .data([0])
    .join("g")
    .attr("class", "marks") as SvgGSelection;
}
