import { syntheticLabel } from "../csv/LetterIndex";
import { DataArray } from "../data/DataChunk";
import { transpose } from "../util/Utils";
import { identityFn } from "./../util/Utils";
import { CellParser, StringFormatParser } from "./DetectCellFormat";
import { baseJsonScore, isNumberArray } from "./DetectJsonTable";
import { parseColumns } from "./ParseColumns";
import { jsonNumberParser } from "./ParseJsonColumn";
import { JsonTableParser, TabularColumn } from "./ParseTabular";

export const stringParser: StringFormatParser = {
  parseType: "string",
  parser: identityFn,
  parseResultType: "string",
};

/** return a parser for a text in json array of arrays format. */
export function arrayRowsParser(array: unknown[][]): JsonTableParser {
  const colTable = transpose(array)!;
  const columns: TabularColumn[] = colTable.map((col, i) => {
    const label = syntheticLabel(i);
    let dataParser: CellParser;
    if (isNumberArray(col)) {
      dataParser = jsonNumberParser;
    } else {
      dataParser = stringParser;
    }
    const column: TabularColumn = {
      label,
      dataParser,
    };
    return column;
  });

  function parse(text: string): DataArray[] {
    const rowTable = JSON.parse(text);
    return parseColumns(rowTable, columns);
  }

  const jsonParser: JsonTableParser = {
    score: baseJsonScore() + columns.length,
    kind: "json",
    parse,
    parseBody: parse,
    columns,
    format: "rows",
  };
  return jsonParser;
}
