import memoizeOne from "memoize-one";
import { Regl } from "regl";
import { HeatLinesPlot } from "../chart/PlotModels";
import { Renderable, WebglRenderable } from "../chart/RenderPlot";
import { FrameAndSet } from "../data/ColumnFrame";
import { Vec2 } from "../math/Vec";
import { AnyTransition } from "../util/d3Util";
import { replaceUndefined } from "../util/Utils";
import { densityCommon } from "./DensityCommon";
import {
  defaultPercentile,
  DensityArgs,
  densityDraw,
  densityShading,
  transitionClearAndDraw,
} from "./DensityShading";
import { setupLineBuffers } from "./LineBuffers";
import { ThickLinesCmd } from "./ThickLineShader";
import { ThinLinesCmd } from "./ThinLineShader";

export type LinesArgs = DensityArgs<HeatLinesPlot>;

const defaults = {
  width: 4,
  feather: 2,
  weightHorizontal: false,
};

function chooseFade(
  smoothDensity: boolean | undefined,
  disableFade: boolean | undefined
): Vec2 {
  if (disableFade) {
    return [0, 0];
  } else if (smoothDensity) {
    return [5, 1];
  } else {
    return [6, 1.5];
  }
}

export function heatLines(regl: Regl): WebglRenderable<LinesArgs> {
  return densityCommon(regl, createRenderer, rebuild);

  function rebuild(args: LinesArgs, lastArgs: LinesArgs): boolean {
    return args.plot.weightHorizontal !== lastArgs.plot.weightHorizontal; // L8r make this dynamic per frame
  }

  function createRenderer(regl: Regl, args: LinesArgs): Renderable<LinesArgs> {
    const { canvas, plot, plotRect } = args;
    const pixelsSize: Vec2 = [canvas.width, canvas.height];
    const { weightHorizontal } = replaceUndefined(plot, defaults);

    /* Fading of the density map is based on the frame statistics. However the frame statistics
     * are a bit noisy when weightHorizontal is enabled. To avoid visual artifacts
     * with the fade, we also calculate statistics without weight horizontal enabled and
     * use those unweighted values for a smoother fade. */
    const greenStats = weightHorizontal;

    /* the weightHorizontal density distribution is more skewed and unstable, so we search
       a bit harder to get a more accurate percentile */
    const percentilePasses = weightHorizontal ? 3 : 1;

    const densityArgs: DensityArgs = { ...args, greenStats, plotRect, percentilePasses };
    const { densityFB: framebuffer, cmds, normX } = densityShading(regl, densityArgs);

    const getLineBuffers = memoizeOne((frameAndSet: FrameAndSet) =>
      setupLineBuffers(regl, frameAndSet, normX)
    );

    const debug = args.debug || {};
    // prettier-ignore
    const thin = { regl, framebuffer, pixelsSize, normX, weightHorizontal, debug };
    const densityThinLines = ThinLinesCmd(thin);

    const thick = { ...thin, framebuffer: null, plotRect };
    const drawThickLines = ThickLinesCmd(thick);

    function draw(transition: AnyTransition, freshArgs: LinesArgs): void {
      const { frameAndSet } = freshArgs;
      const lineBuffers = getLineBuffers(frameAndSet);
      const { zoomingScales: scales, debug } = freshArgs;
      const plot = replaceUndefined(freshArgs.plot, defaults);
      const {
        width: cssWidth,
        feather,
        smoothDensity,
        weightHorizontal,
        brightness,
        summaries,
      } = plot;
      let { disableFade } = plot;
      const width = debug?.rawWidth ? cssWidth : cssWidth * window.devicePixelRatio;

      if (width === 0) {
        drawThinOnly();
      } else if (brightness > 0 || (summaries && summaries.length > 0)) {
        drawWithDensity();
      } else {
        drawThickOnly();
      }

      function drawWithDensity(): void {
        const renderDensity = (): void => {
          drawThickLines({ lineBuffers, scales, width, feather, joins: true });
          densityThinLines({ scales, weightHorizontal, lineBuffers });
        };
        densityDraw(regl, cmds, enhanceArgs(), transition, { renderDensity });
      }

      function drawThickOnly(): void {
        const draw = (): void =>
          drawThickLines({ lineBuffers, scales, width, feather, joins: true });
        transitionClearAndDraw(transition, regl, scales, draw, freshArgs.background);
      }

      function drawThinOnly(): void {
        disableFade = true;
        const renderDensity = (): void =>
          densityThinLines({ scales, weightHorizontal, lineBuffers });
        densityDraw(regl, cmds, enhanceArgs(), transition, { renderDensity });
      }

      function enhanceArgs(): DensityArgs {
        const fadeRange: Vec2 = chooseFade(smoothDensity, disableFade);
        const percentage = weightHorizontal ? 0.7 : defaultPercentile;
        const scaleThreshold = weightHorizontal ? 2.5 : 1.0;
        const noShowDensity = brightness === 0;
        return { ...freshArgs, noShowDensity, scaleThreshold, fadeRange, percentage };
      }
    }

    return { draw };
  }
}
