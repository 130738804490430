import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import React, { ReactNode, useCallback, useState } from "react";
import { xAxisHighlightColor, yAxisHighlightColor } from "../../chart/Axes";
import { ColumnId } from "../../data/ColumnFrame";
import { DashId } from "../../store/DashId";
import { useStoreActions } from "../../store/Hooks";
import { ColumnSet } from "../../store/TableSource";
import { XorY } from "../../util/MiscTypes";
import { useModalContainer } from "../dash-controls/ModalContainer";
import { AxisMenuItem } from "./AxisMenuItem";

const useStyles = makeStyles(
  {
    button: {
      backgroundColor: (props: HasBackgroundVariant) => {
        const variant = props.backgroundVariant;
        if (variant === "none") {
          return "#00000000";
        } else if (variant === "xAxis") {
          return xAxisHighlightColor;
        } else if (variant === "yAxis") {
          return yAxisHighlightColor;
        }
      },
      whiteSpace: "nowrap",
    },
  },
  { link: true }
);

export type BackgroundVariant = "xAxis" | "yAxis" | "none";
interface HasBackgroundVariant {
  backgroundVariant: BackgroundVariant;
}
export interface ColumnMenuProps extends HasBackgroundVariant {
  dashId: DashId;
  columnId: ColumnId;
  children?: ReactNode;
  columnSet: ColumnSet | undefined;
}

export function ColumnMenu(props: ColumnMenuProps): JSX.Element {
  const { children, dashId, columnId, columnSet, backgroundVariant } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles({ backgroundVariant });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const modifyChart = useStoreActions((app) => app.modifyChartById);
  const xColumnId = columnSet?.xColumn;
  const yColumnId = columnSet?.yColumns[0];
  const xChecked = xColumnId !== undefined && xColumnId === columnId;
  const yChecked = yColumnId !== undefined && yColumnId === columnId;
  const container = useModalContainer();

  const toggleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(!open);
    },
    [setOpen, setAnchorEl, open]
  );

  const onClose = useCallback(() => {
    // setAnchorEl(null);
    setOpen(false);
  }, [setOpen]);

  const setDataColumn = useCallback(
    (xOrY: XorY) => {
      if (columnId) {
        const xColumn = xOrY === "x" ? columnId : xColumnId!;
        const yColumns = xOrY === "y" ? [columnId] : [yColumnId!];
        const columnSet: ColumnSet = {
          xColumn,
          yColumns,
        };
        modifyChart({ dashId, chartPartial: { columnSet } });
      } else {
        console.error("columnId is", columnId);
      }
      setOpen(false);
    },
    [setOpen, xColumnId, yColumnId, modifyChart, columnId, dashId]
  );

  return (
    <>
      <Button className={classes.button} onClick={toggleMenu}>
        {children}
      </Button>
      <Menu
        anchorOrigin={{ horizontal: "right", vertical: "center" }}
        {...{ anchorEl, open, onClose, container }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={() => setDataColumn("x")}>
          <AxisMenuItem x checked={xChecked} />
        </MenuItem>
        <MenuItem onClick={() => setDataColumn("y")}>
          <AxisMenuItem x={false} checked={yChecked} />
        </MenuItem>
      </Menu>
    </>
  );
}
