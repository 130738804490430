import { Lethargy } from "lethargy";
import { fixWidth, roundNum } from "../util/StringUtil";
import { pairs } from "../util/Utils";

export interface KineticDetect {
  /**  check wheel event
   * @return true if the event appears to be a synthetic
   * event generated by the OS for kinetic scrolling */
  isKinetic: (e: WheelEvent) => boolean;
}

/* This does some data collection towards better kinetic event detection.
 *
 * For now it just reports results from the Lethargy library.
 *
 * With default settings, Lethargy reports one event for a long
 * trackpad swipe on MacOS. But that's not what we want, a long swipe
 * should report many events.
 *
 * So we tune the default Lethargy settings to
 * be more sensitive. But it's not perfect.
 * For example, a manually decelerating scrolls is falsely reported
 * as kinetic.
 *
 * LATER we can tune better kinetic detection algorithm.
 */
export function kineticDetect(): KineticDetect {
  const lethargy = new Lethargy(5, 1, 0.1, 0);
  const events: WheelEvent[] = [];
  const kinetic: boolean[] = [];

  function isKinetic(event: WheelEvent): boolean {
    events.push(event);
    const kineticEvent = lethargy.check(event) === false;
    kinetic.push(kineticEvent);

    if (events.length > 8) {
      events.shift();
      kinetic.shift();
    }
    // log();
    return kineticEvent;
  }

  // prettier-ignore
  function log(): void { // eslint-disable-line @typescript-eslint/no-unused-vars
    const dt = [...pairs(events)].map(([a, b]) => b.timeStamp - a.timeStamp);
    const snips = dt.map((t, i) => {
      const time = roundNum(t);
      const k = kinetic[i] ? "!" : "";
      const scroll = Math.abs(events[i + 1].deltaY);
      const cell = `${k} ${time}: ${scroll}`;
      return fixWidth(cell, 12);
    });
    console.log(snips.join("  "));
  }

  return { isKinetic };
}
