import { FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useCallback } from "react";
import { isSvgPlotKind, Plot } from "../../chart/PlotModels";
import { useCurrentPlot, useStoreActions, useStoreState } from "../../store/Hooks";

const useStyles = makeStyles(() => ({
  selectContainer: {
    position: "relative",
    top: 12,
    left: 10,
  },
  emptySelect: {
    display: "inline-block",
    height: 18,
    width: "2em",
  },
  svgLabel: {
    marginLeft: 10,
  },
}));

type PlotEntry = [Plot["kind"], string];

const plotEntries: PlotEntry[] = [
  ["SvgLines", "Lines"],
  ["SvgScatter", "Scatter"],
];
const entriesMap = new Map(plotEntries);

const plotTypeMenu: JSX.Element[] = (() => {
  const menuItems = plotEntries.map(([key, text]) => {
    return (
      <MenuItem key={key} value={key}>
        {text}
      </MenuItem>
    );
  });
  return menuItems;
})();

export default function SvgPlotType(): JSX.Element {
  const plotKind = useCurrentPlot((plot) => plot.kind);
  const svgEnabled = useStoreState((state) => state.debug.svgCharts);
  const modifyPlot = useStoreActions((actions) => actions.modifyPlot);
  const classes = useStyles();

  const changePlotKind = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const kind = event.target.value as Plot["kind"];
      modifyPlot({ kind });
    },
    [modifyPlot]
  );

  if (!svgEnabled || !plotKind) {
    return <></>;
  }

  const selectValue = isSvgPlotKind(plotKind) ? plotKind : "";

  /** render the value manually so that we can control spacing for empty selection */
  function renderValue(value: unknown): JSX.Element {
    if (value === "") {
      return <span className={classes.emptySelect} />;
    } else {
      const display = entriesMap.get(value as Plot["kind"]);
      return <>{display}</>;
    }
  }

  return (
    <span className={classes.selectContainer}>
      <FormControl>
        <InputLabel className={classes.svgLabel} id="choose-plot-type-label">
          SVG
        </InputLabel>
        <Select
          autoWidth
          displayEmpty
          renderValue={renderValue}
          variant="outlined"
          value={selectValue}
          labelId="choose-plot-type-label"
          onChange={changePlotKind}
        >
          {plotTypeMenu}
        </Select>
      </FormControl>
    </span>
  );
}
