import _ from "lodash";
import { Buffer, Regl } from "regl";
import { FrameAndSet, frameXydColumns } from "../data/ColumnFrame";

export interface PointsBuffer {
  points: Buffer;
  dispersion?: Buffer;
  numPoints: number;
}

export function pointsBuffer(
  regl: Regl,
  frameAndSet: FrameAndSet,
  normX: (t: number) => number
): PointsBuffer {
  const { xys, ds, numPoints } = pointsBufferData(frameAndSet, normX);

  return {
    points: floatBuffer(xys),
    dispersion: ds && floatBuffer(ds),
    numPoints,
  };

  function floatBuffer(data: Float32Array): Buffer {
    return regl.buffer({
      data,
      type: "float32",
    });
  }
}

export interface PointsBufferData {
  xys: Float32Array;
  ds?: Float32Array; // dispersion
  numPoints: number;
}

export function pointsBufferData(
  frameAndSet: FrameAndSet,
  normX: (t: number) => number
): PointsBufferData {
  if (!frameAndSet.columnSet) {
    return {
      xys: new Float32Array(),
      numPoints: 0,
    };
  }

  const seriesXYD = frameXydColumns(frameAndSet);
  const xLength = _.sum(seriesXYD.map(([x]) => x.data.length));
  const xyLength = xLength * 2;
  const xys = new Float32Array(xyLength);
  const ds = frameAndSet.columnSet.yDispersion ? new Float32Array(xLength) : undefined;

  // fill xy array
  let index = 0;
  seriesXYD.forEach(([xCol, yCol]) => {
    for (let i = 0; i < xCol.data.length; i++) {
      const x = xCol.data[i];
      const y = yCol.data[i];
      xys[index++] = normX(x); 
      xys[index++] = y;
    }
  });

  // fill dispersion array
  if (ds) {
    let i = 0;
    const dDatas = seriesXYD.map((xyd) => xyd[2]?.data || []);
    for (const data of dDatas) {
      for (const element of data) {
        ds[i++] = element;
      }
    }
  }
  console.assert(index === xys.length);
  console.assert(Number.isInteger(xys.length / 2));
  return { xys, ds, numPoints: xys.length / 2 };
}
