import { miniChart } from "./MiniBarChart";

export interface FramesPerSecond {
  remove: () => void;
  hide: () => void;
  show: () => void;
  toggle: () => void;
}

export interface FramesPerSecondProps {
  historyLength?: number;
  maxFPS?: number;
  listenKeys?: boolean;
  startHidden?: boolean;
}

export function framesPerSecond(props?: FramesPerSecondProps): FramesPerSecond {
  const historyLength = props?.historyLength || 400;
  const maxFPS = props?.maxFPS || 60;
  const listenKeys = props?.listenKeys === undefined ? true : props.listenKeys;
  const history = frameHistory(historyLength, maxFPS);
  let halted = false;
  let hidden = props?.startHidden || false;

  let chart = hidden ? undefined : miniChart(historyLength, maxFPS);

  const api = {
    show,
    hide,
    toggle,
    remove,
  };

  if (listenKeys) {
    listenForKeys(api);
  }

  frameLoop();

  return api;

  function frameLoop(): void {
    if (!halted) {
      history.saveFrameTime();
      if (!hidden && chart) {
        chart.update(history.fpsRates());
      }
      window.requestAnimationFrame(frameLoop);
    }
  }

  function hide(): void {
    if (!hidden && chart) {
      hidden = true;
      chart.destroy();
    }
  }

  function show(): void {
    if (hidden) {
      hidden = false;
      chart = miniChart(historyLength, maxFPS);
    }
  }

  function toggle(): void {
    if (hidden) {
      show();
    } else {
      hide();
    }
  }

  function remove(): void {
    halted = true;
    hide();
  }
}

function listenForKeys(fps: FramesPerSecond): void {
  document.addEventListener("keydown", keydown);

  function keydown(event: KeyboardEvent): void {
    if (event.key === "f") {
      fps.toggle();
    }
  }
}

export type TimeRate = [number, number];
type TimeDelta = [number, number];

interface FrameHistory {
  history: TimeDelta[];
  saveFrameTime: () => void;
  fpsRates: () => TimeRate[];
}

function frameHistory(historyLength: number, maxFPS: number): FrameHistory {
  let lastSample = performance.now();
  const bucketTime = 1000 / maxFPS;

  // frame times exceeding bucketTime will be plotted with more bars
  const maxBucketTime = bucketTime * 1.5;

  const savedHistory: TimeDelta[] = [];

  return { history: savedHistory, saveFrameTime, fpsRates };

  function saveFrameTime(): void {
    const now = performance.now();
    const time = now - lastSample;
    lastSample = now;
    savedHistory.push([now, time]);
    if (savedHistory.length > historyLength) {
      savedHistory.shift();
    }
  }

  function fpsRates(): TimeRate[] {
    const timeRates: TimeRate[] = new Array(historyLength);

    // traverse backwards through the history
    let bucketDex = savedHistory.length - 1; // bucket index
    let historyDex = bucketDex; // history index
    for (; historyDex >= 0; historyDex--) {
      const [time, delta] = savedHistory[historyDex];
      const rate = 1000 / delta;
      const repeats = delta <= maxBucketTime ? 1 : Math.trunc(delta / bucketTime);

      for (let i = 0; i < repeats; i++) {
        const endTime = time - i * bucketTime;
        timeRates[bucketDex--] = [endTime, rate];
      }
    }
    return timeRates;
  }
}
