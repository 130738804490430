import React from "react";
import { ReactEditor } from "slate-react";
import { toggleMark } from "./DashText";
import { CustomText } from "./SlateTypes";

const hotKeys = new Map([
  ["b", "bold"],
  ["i", "italic"],
  ["u", "underline"],
  ["`", "code"],
]);

const isMac =
  typeof window != "undefined" && /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);

const macModKey = (event: React.KeyboardEvent): boolean => event.metaKey;
const nonMacModKey = (event: React.KeyboardEvent): boolean => event.ctrlKey;
const modKey = isMac ? macModKey : nonMacModKey;

export function handleHotKey(editor: ReactEditor, event: React.KeyboardEvent): void {
  const { key } = event;
  const format = hotKeys.get(key) as keyof Omit<CustomText, "text">;
  if (format && modKey(event)) {
    event.preventDefault();
    toggleMark(editor, format);
  }
}
