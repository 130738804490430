import Papa, { ParseConfig, ParseResult } from "papaparse";
import { splitIntoLines } from "../parse/ParseAsciiTable";
import { CsvFormat } from "./CsvFormat";

export function parseCsv(csvText: string, fmt: CsvFormat): string[][] {
  const config = makePapaConfig(fmt);
  const afterHeader = skipHeader(csvText, fmt);
  const result = Papa.parse(afterHeader, config);
  // if (result.errors.length) {
  //   dLog({ fmt, err: result.errors });
  // }
  return result.data;
}

function skipHeader(text: string, fmt: CsvFormat): string {
  const { headerLines, lineBreak } = fmt;
  const lines = splitIntoLines(text, lineBreak, headerLines);
  return lines.join(lineBreak);
}

export function parseCsvLines(lines: string[], format: CsvFormat): string[][] {
  const csvText = lines.join("\n");
  const joinedFormat = { ...format, lineBreak: "\n" };
  return parseCsv(csvText, joinedFormat);
}

export interface ParsedChunk {
  rows: string[][];
  bytesRead: number;
}

export function parseDsvFile(
  file: File,
  fmt: CsvFormat,
  handleRows: (chunk: ParsedChunk) => Promise<void>
): Promise<void> {
  return new Promise((resolve, reject) => {
    const baseConfig = makePapaConfig(fmt);
    const fullConfig: ParseConfig = { ...baseConfig, chunk, complete };
    Papa.parse(file, fullConfig);

    async function chunk(
      results: ParseResult<string[]>,
      parser: Papa.Parser
    ): Promise<void> {
      // if (results.errors.length) {
      //   dLog("errors", results.errors);
      // }
      const parsedChunk = {
        rows: results.data,
        bytesRead: results.meta.cursor,
      };

      parser.pause();
      await handleRows(parsedChunk).catch((e) => reject(e));
      parser.resume();
    }

    function complete(): void {
      resolve();
    }
  });
}

export function makePapaConfig(fmt: CsvFormat): ParseConfig {
  const { separator, lineBreak, quoteChar, escapeChar, comment } = fmt;
  const fastMode = quoteChar === "";
  const papaConfig: ParseConfig = {
    delimiter: separator,
    newline: lineBreak,
    skipEmptyLines: true,
    quoteChar,
    escapeChar,
    fastMode,
    comments: comment,
    chunkSize: 2 ** 24, // 16M buffer
  };
  return papaConfig;
}
