import { Framebuffer2D, Regl, Texture2D, Vec4 } from "regl";
import { ChartProps } from "../chart/Chart";
import { ChartDebug } from "../chart/ChartDebug";
import { XyScales } from "../chart/ChartScales";
import { NormalizeX } from "../chart/NormalizeX";
import { DensityPlotData, PercentileStat, SummaryStat } from "../chart/PlotModels";
import { Rectangle } from "../chart/Rectangle";
import { FrameAndSet } from "../data/ColumnFrame";
import { Vec2 } from "../math/Vec";
import { AnyTransition } from "../util/d3Util";
import * as Perf from "../util/Perf";
import { qaPush } from "../util/QaApi";
import { clearFramebuffer } from "./ClearFramebuffer";
import { debugFade } from "./Debug";
import { DensityCmds, setupCmds } from "./DensityCmds";
import { pixelLogging } from "./DensityLogging";
import { FramePercentileOptions } from "./FramePercentile";
import { InitBucketStatsProps } from "./InitialBucketStats";
import { LineBuffers } from "./LineBuffers";
import { DensityMapArgs, MapDensityProps } from "./MapDensity";
import * as ReglPerf from "./ReglPerf";
import { ThickLineFn, ThickLineProps } from "./ThickLineShader";
import { fetchPixels, FullFB, isDestroyed, white } from "./WebGLUtil";

/** consolidated arguments to densityDraw */
export interface DensityArgs<T extends DensityPlotData = DensityPlotData>
  extends DensityMapArgs,
    Pick<ChartProps, "background">,
    Pick<MapDensityProps, "scaleThreshold">,
    Pick<InitBucketStatsProps, "percentage">,
    Pick<FramePercentileOptions, "percentilePasses"> {
  frameAndSet: FrameAndSet;
  canvas: HTMLCanvasElement;
  debug: ChartProps["debug"];
  plot: T;
  greenStats?: boolean; // collect stats from green channel and use for fade ontrol
  plotRect: Rectangle;
  zoomingScales: XyScales;
  fadeRange?: Vec2;

  /** don't copy density field to screen */
  noShowDensity?: boolean;
}

/** fields returned to caller of densityDraw, useful for other renderers*/
export interface DensityShading {
  /** uniformly expand x coordinates to improve resolution for 32 bit floats */
  normX: (x: number) => number;

  /** Framebuffer containing the density field. The containing plot is
   * responsible for filling this in via the renderDensity() hook. */
  densityFB: Framebuffer2D;

  /** filtered version of density */
  blurTexture: Texture2D;

  cmds: DensityCmds;
}

export const defaultPercentile = 0.99;

/**
 * Setup webgl shaders to do density shading.
 *
 * @returns a DensityShading structure that contains buffers and commands for densityDraw()
 */
export function densityShading(regl: Regl, args: DensityArgs): DensityShading {
  const { debug, frameAndSet } = args;
  const normX = NormalizeX(frameAndSet, debug?.no32expand);
  const cmds = setupCmds(regl, args);
  const { blurFB, densityFB } = cmds;

  return {
    normX,
    densityFB,
    blurTexture: blurFB.color[0],
    cmds,
  };
}

/**
 * Master draw function for plots that that use a webgl density map.
 *
 * webgl plotters (scatter, line) use densityDraw to drive the flow of execution.
 *
 * Executes rendering commands once per animation frame in the transition: (see runCmds() for details)
 *  1) clear the buffer
 *  2) (caller supplied) render data into a density buffer (typically drawing lines or points into the buffer)
 *  3) apply a smoothing filter to the density buffer
 *  4) (caller supplied) optionally do more rendering commands
 *  5) collect statistics about the range of densities in the image (for dynamic mapping of density to color)
 *  6) copy a color mapped version of the density buffer to the screen buffer (potentially
 *     merging density colors atop caller rendered pixels)
 *  7) calculate stats lines from density buffer and draw stat lines onto screen buffer
 */
export function densityDraw(
  regl: Regl,
  cmds: DensityCmds,
  args: DensityArgs,
  transition: AnyTransition,
  hooks: DensityDrawHooks
): void {
  const { red } = cmds;
  const { canvas, debug, zoomingScales } = args;

  const draw = (): void => runCmds(regl, args, cmds, hooks);
  transitionDraw(transition, regl, zoomingScales, draw);

  transition
    .end()
    .then(() => {
      if (!isDestroyed(regl)) {
        if (args.plot.publishDensityScale) {
          updateColorLegend(regl, red.nzFB, red.percentileFB, canvas);
        }
        if (debug?.logFade)
          debugFade(regl, red.nzFB, red.percentileFB, cmds.green?.percentileFB);
        if (debug?.showPerformance) ReglPerf.asyncReport(regl);
      }
    })
    .catch(() => {});
}

export interface DensityDrawHooks {
  /** hook for caller to render density */
  renderDensity: () => void;

  /** 2nd hook for caller to use, called after density rendering and filtering */
  renderMore?: () => void;
}

/** execute our prebuilt regl shaders to render one frame */
function runCmds(
  regl: Regl,
  args: DensityArgs,
  cmds: DensityCmds,
  hooks: DensityDrawHooks
): void {
  const {
    plot,
    debug,
    canvas,
    background,
    noShowDensity,
    fadeRange,
    scaleThreshold,
    percentage,
  } = args;
  const { brightness, copyAbove, summaries } = plot;
  // prettier-ignore
  const { mapDensity, densityFB, blur, smoothStatFB2, statsLine, statsLineBuffers, red, green } = cmds;
  const backgroundGlColor = background ? (background.map((v) => v / 255) as Vec4) : white;

  clear();
  renderDensity();
  blur();

  hooks.renderMore && hooks.renderMore();
  frameStats();
  if (!noShowDensity) showDensity();
  // L8R is it faster to calc stats before rendering FB?
  // (in theory, density stats and fb rendering could go in parallel..)
  // prettier-ignore
  calcAndDrawStatLines(regl, summaries, cmds, debug, statsLine, statsLineBuffers, smoothStatFB2);

  pixelLogging(regl, cmds, canvas, debug);

  function clear(): void {
    clearFramebuffer(regl, null, "clearBackground", backgroundGlColor);
    clearFramebuffer(regl, densityFB, "densityClear");
  }

  function renderDensity(): void {
    hooks.renderDensity && hooks.renderDensity();
  }

  function showDensity(): void {
    const props: MapDensityProps = {
      brightness,
      fadeRange,
      copyAbove,
      background,
      scaleThreshold,
    };
    if (!debug?.hideDensity) mapDensity(props);
  }

  function frameStats(): void {
    red.nzStats();
    green?.nzStats();
    red.percentile(percentage);
    green?.percentile(defaultPercentile);
  }
}

/** dispatch a custom dom event to report the density statistics of this frame.
 * This event is normally caught by UI that displays a color 'key' showing the
 * density values for various colors in the plot.
 */
function updateColorLegend(
  regl: Regl,
  nzStatsFB: Framebuffer2D,
  bucketStatsFB: Framebuffer2D,
  node: Element
): void {
  const [min, max] = fetchMinMax(regl, nzStatsFB);
  const percentile = fetchPercentile(regl, bucketStatsFB);

  dispatchColorScale(min, max, percentile, node);
}

function fetchPercentile(regl: Regl, bucketStatsFB: Framebuffer2D): number {
  const pixels = fetchPixels(regl, bucketStatsFB, "fetchBucketStats");
  const [min, max] = pixels;
  const bucketCenter = (min + max) / 2;
  return bucketCenter;
}

function fetchMinMax(regl: Regl, nzStatsFB: Framebuffer2D): [number, number] {
  const pixels = fetchPixels(regl, nzStatsFB, "fetchNzMean");
  return [pixels[2], pixels[3]];
}

function dispatchColorScale(
  min: number,
  max: number,
  threshold: number,
  node: Element
): void {
  const colorScale = { threshold, min, max };
  const colorEvent = new CustomEvent("density-scale", {
    detail: colorScale,
    bubbles: true,
    cancelable: true,
  });
  node.dispatchEvent(colorEvent);
}

export function transitionDraw(
  transition: AnyTransition,
  regl: Regl,
  scales: XyScales,
  cmd: () => void
): void {
  transition.tween("densityShading", () => tweenDraw);

  function tweenDraw(): void {
    if (!isDestroyed(regl)) {
      Perf.time("drawFrame", () => {
        Perf.mark("startFrame");
        qaPush("shadingX", scales.x.domain());
        cmd();
        regl.poll();
      });
    }
  }
}

export function transitionClearAndDraw(
  transition: AnyTransition,
  regl: Regl,
  scales: XyScales,
  cmd: () => void,
  background: Vec4 | undefined
): void {
  const backgroundGlColor = background ? (background.map((v) => v / 255) as Vec4) : white;
  const cmdWithClear = (): void => {
    clearFramebuffer(regl, null, "clearBackground", backgroundGlColor);
    cmd();
  };
  transitionDraw(transition, regl, scales, cmdWithClear);
}

function calcAndDrawStatLines(
  regl: Regl,
  summaries: SummaryStat[] | undefined,
  cmds: DensityCmds,
  debug: ChartDebug | undefined,
  statsLine: ThickLineFn,
  statsLineBuffers: LineBuffers,
  lineData: FullFB
): void {
  summaries?.forEach((summary) => {
    if (summary.kind === "mean") {
      calcMean(regl, cmds, debug);
    } else if (summary.kind === "percentile") {
      calcPercentile(summary, regl, cmds, debug);
    }
    renderStatLine(summary, statsLine, statsLineBuffers, lineData);
  });
}

function renderStatLine(
  summary: SummaryStat,
  statsLine: ThickLineFn,
  statsLineBuffers: LineBuffers,
  lineYs: FullFB
): void {
  const { thickness: width = 2, feather = 1, color = [199, 0, 0] } = summary;
  const statsLineProps: ThickLineProps = {
    width,
    feather,
    color,
    joins: true,
    textureVerts: lineYs.color[0],
    lineBuffers: statsLineBuffers,
    scales: undefined as any, // unused
  };

  statsLine(statsLineProps);
}

const missingStat: Vec4 = [-Infinity, -Infinity, -Infinity, -Infinity];

function calcMean(regl: Regl, cmds: DensityCmds, debug: ChartDebug | undefined): void {
  const {
    mean,
    statFill,
    smoothStat,
    alignStat,
    statLineFB,
    blurFB,
    statFillFB,
    alignStatFB,
    smoothStatFB1,
    smoothStatFB2,
  } = cmds;
  const { noSmoothStat } = debug || {};
  mean({ density: blurFB.color[0], means: statLineFB });
  statFill({ statValues: statLineFB, filled: statFillFB, debug });
  clearFramebuffer(regl, alignStatFB, "stat-line-clear", missingStat);
  if (noSmoothStat) {
    alignStat({ filled: statFillFB.color[0], aligned: smoothStatFB2 });
  } else {
    alignStat({ filled: statFillFB.color[0], aligned: alignStatFB });
    smoothStat({ statValues: alignStatFB.color[0], smoothed: smoothStatFB1 });
    smoothStat({ statValues: smoothStatFB1.color[0], smoothed: smoothStatFB2 });
  }
}

// TODO DRY with calcMean
function calcPercentile(
  summary: PercentileStat,
  regl: Regl,
  cmds: DensityCmds,
  debug: ChartDebug | undefined
): void {
  const {
    percentileLine,
    statFill,
    smoothStat,
    alignStat,
    statLineFB,
    blurFB,
    statFillFB,
    alignStatFB,
    smoothStatFB1,
    smoothStatFB2,
  } = cmds;
  const { noSmoothStat } = debug || {};
  const { percentile = 0.9 } = summary;
  percentileLine({ density: blurFB.color[0], percentile, percentileFB: statLineFB });
  statFill({ statValues: statLineFB, filled: statFillFB, debug });
  clearFramebuffer(regl, alignStatFB, "stat-line-clear", missingStat);
  if (noSmoothStat) {
    alignStat({ filled: statFillFB.color[0], aligned: smoothStatFB2 });
  } else {
    alignStat({ filled: statFillFB.color[0], aligned: alignStatFB });
    smoothStat({ statValues: alignStatFB.color[0], smoothed: smoothStatFB1 });
    smoothStat({ statValues: smoothStatFB1.color[0], smoothed: smoothStatFB2 });
  }
}
