import { Regl, Texture2D, Texture2DOptions } from "regl";
import { FullFB } from "./WebGLUtil";

/** @return a texture and a framebuffer */
export function textureFB(
  regl: Regl,
  size: [number, number],
  options?: Texture2DOptions
): [Texture2D, FullFB] {
  const texture = regl.texture({
    shape: size,
    format: "rgba",
    type: "float32",
    ...options,
  });

  const fb = regl.framebuffer({
    color: texture,
    stencil: false,
  }) as FullFB;

  return [texture, fb];
}
