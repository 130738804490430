import { Vec2 } from "../math/Vec";
import { SvgSVGSelection } from "../util/d3Util";
import { Margin } from "./Rectangle";

type Sizes = [[number, number], [number, number]];

/** set sizing of svg elem and based on parent container */
export function fullSize(
  svg: SvgSVGSelection,
  margin: Margin,
  titleHeight: number
): Sizes {
  const [svgWidth, svgHeight] = expandSize(svg, titleHeight),
    plotWidth = svgWidth - margin.left - margin.right,
    plotHeight = svgHeight - margin.top - margin.bottom;

  return [
    [svgWidth, svgHeight],
    [plotWidth, plotHeight],
  ];
}

function expandSize(svg: SvgSVGSelection, titleHeight: number): Vec2 {
  const container = svg.node()!.parentNode as HTMLElement,
    width = container.clientWidth || 200,
    height = container.clientHeight - titleHeight;

  // dLog({ height, width });
  svg.attr("width", width);
  svg.attr("height", height);
  return [width, height];
}
