import _ from "lodash";
import { FrameAndColumns } from "../components/rich-chart/DashChart";
import { DataExtent } from "../data/ColumnFrame";
import { arrayToFrame } from "../data/frameGenerators";
import { Vec2 } from "../math/Vec";

export function zigZag(): FrameAndColumns {
  const data: Vec2[] = [
    [0, 0],
    [1, 10],
    [NaN, NaN],
    [2, 0],
    [100, 0],
  ];

  return arrayToFrame(data);
}

export function zag(): FrameAndColumns {
  const data: Vec2[] = [
    [0, 0],
    [1, 1],
  ];

  return arrayToFrame(data);
}

export function zagShort(): FrameAndColumns {
  const data: Vec2[] = [
    [0.25, 0.25],
    [0.75, 0.75],
  ];

  const frameAndSeries = arrayToFrame(data);
  const extent: DataExtent = { x: [0, 1], y: [0, 1] };
  frameAndSeries.chartFrame.descriptor = { extent };
  return frameAndSeries;
}

export function doubleZigZag(): FrameAndColumns {
  const data: Vec2[] = [
    [0, 1],
    [1, 10],
    [2, 1],
    [20, 1],
  ];

  const frameAndSeries = arrayToFrame(data);
  const extent: DataExtent = { x: [0, 25], y: [0, 15] };
  frameAndSeries.chartFrame.descriptor = { extent };
  return frameAndSeries;
}

export function steepAndShallow(): FrameAndColumns {
  const data: Vec2[] = [
    [0.1, 0],
    [0.2, 1],
    [NaN, NaN],
    [0, 0.5],
    [1, 0.6],
  ];

  return arrayToFrame(data);
}

export function verticalAndHorizontal(): FrameAndColumns {
  const data: Vec2[] = [
    [0, 0],
    [20, 10],
    [NaN, NaN],
    [1, 1],
    [1, 8],
    [8, 8],
  ];

  return arrayToFrame(data);
}

export function steep(): FrameAndColumns {
  const extent: DataExtent = { x: [0, 7], y: [0, 7] };
  const data: Vec2[] = [
    [3, 0],
    [4, 7],
  ];

  const frameAndSeries = arrayToFrame(data);
  frameAndSeries.chartFrame.descriptor = { extent };
  return frameAndSeries;
}

export function verticalOverwrite(): FrameAndColumns {
  const data: Vec2[] = [];
  _.times(100, () => {
    data.push([8, 1], [8, 8], [NaN, NaN]);
  });
  data.push([NaN, NaN], [10, 10]); // not sure why this is needed..

  const frameAndSeries = arrayToFrame(data);
  const extent: DataExtent = { x: [0, 10], y: [0, 10] };
  frameAndSeries.chartFrame.descriptor = { extent };
  return frameAndSeries;
}

export function endCap(): FrameAndColumns {
  const data: Vec2[] = [
    [4, 5],
    [6, 5],
  ];

  const frameAndSeries = arrayToFrame(data);
  const extent: DataExtent = { x: [0, 10], y: [0, 10] };
  frameAndSeries.chartFrame.descriptor = { extent };
  return frameAndSeries;
}

export function oneDot(): FrameAndColumns {
  const data: Vec2[] = [[4, 4]];

  const frameAndSeries = arrayToFrame(data);
  const extent: DataExtent = { x: [0, 10], y: [0, 10] };
  frameAndSeries.chartFrame.descriptor = { extent };
  return frameAndSeries;
}

export function overlappingDots(): FrameAndColumns {
  const data: Vec2[] = [
    [4, 4],
    [4, 4],
    [4, 4],
    [8, 8],
  ];

  const frameAndSeries = arrayToFrame(data);
  const extent: DataExtent = { x: [0, 10], y: [0, 10] };
  frameAndSeries.chartFrame.descriptor = { extent };
  return frameAndSeries;
}
