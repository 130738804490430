import { ColumnArray } from "./ColumnFrame";

export interface RangeTree {
  rangeQuery: (start: number, end: number) => MinMax;
}

/** A range tree used for querying the min and max y values given a range in x.
  
  * The tree is a balanced binary tree of the elements in x sorted order
  * Internal nodes in the tree are packed in single array for memory and cache efficiency. The 
    first element is the root, the second element is the left child of the root, etc. 
    * the children of a node at index n are at 2n+1 and 2n+2.
  * Each internal node stores a min and max y value for its subtree
  * Leaf nodes (containing one element) are not stored in the tree, instead the algorithm refers 
    to the source x and y data arrays.
  
  * Constructing the tree proceeds recursively, tracking the left and right indices
    in the source data arrays as each node is calculated. 
  
  * Querying the tree for an x range proceeds recursively for both the left and right subranges 
    of the provided x range.
  * Recursion stops if the x (sub)range is not within the node. The min and mix values
    from the left and right subtrees are combined and returned.
    
  * X values must not be NaN.
  * Y values may be NaN, they are ignored.
*/

export interface RangeTreeInternal extends RangeTree {
  nodes: Float64Array; // packed storage of internal nodes
  length: number;
  nodeCount: number;
  nodesBuilt: number;
  minMax: (i: number, l?: number, r?: number) => MinMax;
  xData: ColumnArray;
  yData: ColumnArray;
}

export interface MinMax {
  min: number;
  max: number;
}

const nodeSize = 2; // min, max

export function rangeTree(xData: ColumnArray, yData: ColumnArray): RangeTree {
  return rangeTreeInternal(xData, yData);
}

export function rangeTreeInternal(
  xData: ColumnArray,
  yData: ColumnArray
): RangeTreeInternal {
  console.assert(xData.length === yData.length);
  const dataLength = xData.length;
  // note that some nodes in the last node layer may be unused
  const nodeCount = dataLength;
  let nodesBuilt = 0;
  const storeSize = nodeCount * nodeSize;
  const nodes = new Float64Array(storeSize);

  buildRecurse(0, 0, dataLength - 1);

  /**
   * Recursively walk the tree to be built in a preorder traversal (children first).
   *
   * @param nodeDex index in the node storage array, index 0 is the root of the tree
   * @param l index of left boundary in the data arrays
   * @param r index of right boundary in the data arrays
   */
  function buildRecurse(nodeDex: number, l: number, r: number): MinMax {
    if (r - l < 2) {
      // we've reached a leaf
      const y1 = yData[l];
      const y2 = yData[r];
      const min = safeMin(y1, y2);
      const max = safeMax(y1, y2);
      return { min, max };
    } else {
      const midDex = Math.floor((l + r) / 2);
      const left = buildRecurse(nodeDex * 2 + 1, l, midDex);
      const right = buildRecurse(nodeDex * 2 + 2, midDex + 1, r);
      const min = safeMin(left.min, right.min);
      const max = safeMax(left.max, right.max);
      writeNode(nodeDex, min, max);
      return { min, max };
    }
  }

  function writeNode(nodeDex: number, min: number, max: number): void {
    const index = nodeDex * nodeSize;
    console.assert(
      index + 1 < nodes.length,
      `writeNode nodeDex:${nodeDex} nodeCount:${nodeCount}`
    );
    nodes[index] = min;
    nodes[index + 1] = max;
    nodesBuilt++;
  }

  function leafMinMax(l: number, r: number): MinMax {
    console.assert(l >= 0);
    console.assert(r < dataLength);
    if (l === r) {
      const d = yData[l];
      return {
        min: d,
        max: d,
      };
    }
    const ld = yData[l];
    const rd = yData[r];
    return {
      min: safeMin(ld, rd),
      max: safeMax(ld, rd),
    };
  }

  function minMax(nodeDex: number, l = 0, r = dataLength - 1): MinMax {
    if (r - l < 2) {
      return leafMinMax(l, r);
    }
    const index = nodeDex * nodeSize;
    const min = nodes[index];
    const max = nodes[index + 1];

    return {
      min,
      max,
    };
  }

  /** return the min and max y from x,y pairs where:  from <= x <= to */
  function rangeQuery(from: number, to: number): MinMax {
    return searchRecurse(root())!;
    /**
     * @param l index in data array of left boundary (inclusive)
     * @param r index in data array of right boundary (inclusive)
     */
    function searchRecurse(visit: VisitNode): MinMax | undefined {
      const { nodeDex, l, r } = visit;
      if (r === l) {
        // leaf virtual node
        const x = xData[l];
        if (x < from || x > to) {
          // leaf is out of range
          // dLog("skipping leaf", { nodeDex, layer: layer(nodeDex), l, r, x });
          return undefined;
        }
        const max = yData[l];
        const min = max;
        // dLog("leaf", { nodeDex, layer: layer(nodeDex), l, r, min, max });
        return { min, max };
      }

      const lx = xData[l];
      const rx = xData[r];

      if (from <= lx && to >= rx) {
        const mm = minMax(nodeDex, l, r);
        // dLog("internal node", { nodeDex, layer: layer(nodeDex), l, r, mm });
        return mm;
      }

      const m = Math.floor((l + r) / 2); // middle index
      const mx = xData[m];
      const mx1 = xData[m + 1];

      // dLog({ nodeDex, layer: layer(nodeDex), l, r, mx, mx1, lx, rx });
      let left: MinMax | undefined = undefined;
      let right: MinMax | undefined = undefined;
      if (from <= mx) {
        // dLog("  left", { fromNodeDex: nodeDex });
        // check left side
        left = searchRecurse(leftChild(visit));
      }
      if (to >= mx1) {
        // dLog("  right", { fromNodeDex: nodeDex });
        // check right side
        right = searchRecurse(rightChild(visit));
      }
      return combine(left, right);
    }
  }

  function root(): VisitNode {
    return {
      nodeDex: 0,
      l: 0,
      r: dataLength - 1,
    };
  }

  return {
    rangeQuery,
    nodes,
    nodeCount,
    nodesBuilt,
    length: xData.length,
    xData,
    yData,
    minMax,
  };
}

function combine(a: MinMax | undefined, b: MinMax | undefined): MinMax | undefined {
  let min: number, max: number;

  if (a && b) {
    min = safeMin(a.min, b.min);
    max = safeMax(a.max, b.max);
  } else if (a) {
    min = a.min;
    max = a.max;
  } else if (b) {
    min = b.min;
    max = b.max;
  } else {
    return undefined;
  }

  return {
    min,
    max,
  };
}

export interface VisitNode {
  nodeDex: number;
  l: number;
  r: number;
}

function leftChild(parent: VisitNode): VisitNode {
  const { nodeDex, l, r } = parent;
  const midDex = Math.floor((l + r) / 2);
  return {
    nodeDex: nodeDex * 2 + 1,
    l,
    r: midDex,
  };
}

function rightChild(parent: VisitNode): VisitNode {
  const { nodeDex, l, r } = parent;
  const midDex = Math.floor((l + r) / 2);
  return {
    nodeDex: nodeDex * 2 + 2,
    l: midDex + 1,
    r,
  };
}

function safeMin(a: number, b: number): number {
  if (!Number.isFinite(a)) {
    return b;
  }
  if (!Number.isFinite(b)) {
    return a;
  }
  return Math.min(a, b);
}

function safeMax(a: number, b: number): number {
  if (!Number.isFinite(a)) {
    return b;
  }
  if (!Number.isFinite(b)) {
    return a;
  }
  return Math.max(a, b);
}

// for debug
export function* breadthFirstTraverse(
  tree: RangeTreeInternal
): Generator<VisitNode, void, undefined> {
  const toVisit: VisitNode[] = [];
  queueChild(0, 0, tree.length - 1);

  while (toVisit.length) {
    const node = toVisit.shift()!;
    yield node;

    const { nodeDex, r, l } = node;
    if (l < r) {
      const midDex = Math.floor((l + r) / 2);
      queueChild(nodeDex * 2 + 1, l, midDex);
      queueChild(nodeDex * 2 + 2, midDex + 1, r);
    }
  }

  function queueChild(nodeDex: number, l: number, r: number): void {
    toVisit.push({ nodeDex, l, r });
  }
}

// for debug
export function layer(nodeDex: number): number {
  return Math.floor(Math.log2(nodeDex + 1));
}
