const encoding = new Map([
  ["\t", "\\t"],
  [",", ","],
]);

/** @return a function that will escape newlines and known separators
 *   (\t, or ',') in a string.
 */
export function stringEncoder(separator: string): (value: any) => string {
  const encodeSeparator = encoding.get(separator);
  let encodeString: (value: string) => string;

  if (encodeSeparator) {
    encodeString = (value: string) =>
      value.replaceAll("\n", "\\n").replaceAll(separator, encodeSeparator);
  } else {
    encodeString = (value: string) => value.replaceAll("\n", "\\n");
  }

  return function (value: any): string {
    if (typeof value === "string") {
      return encodeString(value);
    } else {
      return value.toString();
    }
  };
}
