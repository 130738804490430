import { Framebuffer2D, Regl, Vec4 } from "regl";
import * as Perf from "../util/Perf";

export function clearFramebuffer(
  regl: Regl,
  framebuffer?: Framebuffer2D | null,
  perfLabel?: string,
  color?: Vec4 // color in gl coordinates [0,1]
): void {
  if (perfLabel) {
    Perf.time(perfLabel, () => clearFB());
  } else {
    clearFB();
  }

  function clearFB(): void {
    if (!color) color = [0, 0, 0, 1];
    regl.clear({
      color,
      framebuffer,
    });
  }
}
