import { mat3 } from "gl-matrix";
import { Regl, Texture2D } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { Rectangle } from "../chart/Rectangle";
import { Vec2 } from "../math/Vec";
import { identityFn } from "../util/Utils";
import { ThickLineFn, ThickLinesArgs, ThickLinesCmd } from "./ThickLineShader";

export interface StatsLineArgs {
  debug: ChartDebug;
  stats: Texture2D;
  plotRect: Rectangle;
  pixelsSize: Vec2;
}

/** @return a ThickLine command to draw a line with one x coordinate per width.
 * The shader will adjust the Y position of each vertex according to the stats buffer.
 * This allows to effectively generate a dynamic line in the Mean shader without
 * creating new geometry (which we can't do in webgl without a slow round trip to the CPU).
 */
export function statsLineCmd(regl: Regl, props: StatsLineArgs): ThickLineFn {
  const { debug, plotRect, pixelsSize } = props;
  const fixedProjection = mat3.identity(mat3.create());

  const args: ThickLinesArgs = {
    regl,
    framebuffer: null,
    plotRect,
    pixelsSize,
    normX: identityFn,
    debug,
    fixedProjection,
  };

  return ThickLinesCmd(args);
}
