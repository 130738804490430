import { SvgSVGSelection } from "../util/d3Util";
import { ChartState } from "./Chart";

export function homeButton(svg: SvgSVGSelection): void {
  const width = 30;
  const height = 30;
  const left = 10;
  const top = 5;

  const button = svg // background rect for button click area
    .append("rect")
    .attr("width", height)
    .attr("height", width)
    .attr("fill", "#ff000000") // transparent
    .attr("transform", `translate(${left},${top})`)
    .style("cursor", "default");

  const icon = svg
    .append("g")
    .attr("width", height)
    .attr("height", width)
    .attr("fill", "grey")
    .attr("transform", `translate(${left},${top})`)
    .style("pointer-events", "none");

  icon.append("path").attr("d", "M0 0h24v24H0z").attr("fill", "none");
  icon.append("path").attr("d", "M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z");

  button.on("click", zoomHome);

  function zoomHome(): void {
    const state: ChartState = svg.datum();
    const { api, dataExtent } = state;

    api.zoomToXDomain(dataExtent.x, 200, true);
  }
}
