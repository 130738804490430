import { makeStyles } from "@material-ui/core";
import * as d3 from "d3";
import React, { useCallback, useState } from "react";
import { tableRowCount } from "../../data/DbTables";
import { plotDefaultMaxRows } from "../../store/ChartModel";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { TableSource } from "../../store/TableSource";
import { usePrevious } from "../../util/ReactUtil";
import { FormattedTextField } from "./FormattedTextField";

export const maxRowsWidth = 120;

const useStyles = makeStyles({
  maxRows: {
    width: maxRowsWidth,
  },
});

const formatInteger = d3.format(",d");

export interface MaxRowsProps {
  dashId: DashId;
  className?: string;
}

export const MaxRows = React.memo(MaxRowsInternal);

function MaxRowsInternal(props: MaxRowsProps): JSX.Element {
  const { className, dashId } = props;
  const classes = useStyles();
  const tableSource = useStoreState((app) => app.findChart(dashId)?.tableSource);
  const modifyData = useStoreActions((actions) => actions.modifyDataById);
  const sourceRows = useSourceRowCount(tableSource);
  const displayRows = rowCountDisplay(tableSource, sourceRows);
  const label = <span>{formatInteger(sourceRows)}&nbsp;rows</span>;

  const onChangeNumber = useCallback(
    (rows: number | undefined) => {
      modifyData({ dashId, dataPartial: { maxRows: rows } });
    },
    [modifyData, dashId]
  );

  return (
    <div {...{ className }}>
      <FormattedTextField
        {...{ label, onChangeNumber, className: classes.maxRows, value: displayRows }}
        isNumericString
        allowNegative={false}
        thousandSeparator
      />
    </div>
  );
}

function useSourceRowCount(table: TableSource | undefined): number {
  const [count, setCount] = useState(0);
  const lastTable = usePrevious(table);
  if (lastTable !== table) {
    tableSourceRowCount(table).then(setCount);
  }

  return count;
}

function rowCountDisplay(
  tableSource: TableSource | undefined,
  sourceRows: number
): number | string {
  const { maxRows, kind } = tableSource || {};

  if (!maxRows) {
    if (kind === "generated") {
      return plotDefaultMaxRows;
    } else {
      return "";
    }
  } else {
    return Math.min(maxRows, sourceRows);
  }
}

/** @return a Promise containing the number of rows in the table */
async function tableSourceRowCount(table: TableSource | undefined): Promise<number> {
  if (!table) {
    return 0;
  } else if (table.kind === "generated" || table.kind === "generatedStream") {
    return Infinity;
  } else if (table.kind === "local") {
    return tableRowCount(table.tableId!);
  } else {
    return 0;
  }
}
