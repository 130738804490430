import _ from "lodash";
import { useCallback } from "react";
import { useStoreActions, useStoreState } from "./Hooks";

export function useInitializeView(): () => void {
  const modifyView = useStoreActions((app) => app.modifyView);
  const selectedDashItem = useStoreState((app) => app.view.selectedDashItem);
  const firstDashItem = useStoreState((app) => _.head(app.dashboard.dashItems.items)?.id);
  const openChartData = useStoreState((app) => app.debug.openChartData);
  const openChartSettings = useStoreState((app) => app.debug.openChartSettings);

  const initView = useCallback(() => {
    if (selectedDashItem === -1 && firstDashItem) {
      modifyView({ selectedDashItem: firstDashItem });
    }
    if (openChartData) {
      modifyView({ sheet: firstDashItem });
    }
    if (openChartSettings) {
      modifyView({ rightDrawerOpen: true });
    }
  }, [modifyView, firstDashItem, selectedDashItem, openChartData, openChartSettings]);

  return initView;
}
