import { ColumnId } from "../data/ColumnFrame";
import { TableId } from "../data/DbStore";
import { likelyPlotColumns } from "../data/DbTables";

/** information to load columns from a table.
 * If no columns are specified, load default columns for the table.
 */
export interface SourceColumns {
  source: TableSource;
  columnSet: ColumnSet | undefined;
}

export type LoadedTableSource =
  | LocalTableSource
  | GeneratedTableSource
  | GeneratedStreamSource
  | NoTableSource;

export type TableSource = LoadedTableSource | LoadingTableSource | ExternalTableSource;

interface TableSourceBase {
  kind: string;
  name: string;
  maxRows?: number;
}

export interface LocalTableSource extends TableSourceBase {
  kind: "local";
  tableId: TableId;
}

export interface ExternalTableSource extends TableSourceBase {
  kind: "external";
  url: string;
}

export interface GeneratedTableSource extends TableSourceBase {
  kind: "generated";
}

export interface GeneratedStreamSource extends TableSourceBase {
  kind: "generatedStream";
}

export interface NoTableSource extends TableSourceBase {
  kind: "none";
}

/** Temporary source while the user is selecting a source e.g. from a 'load file' menu */
export interface LoadingTableSource extends TableSourceBase {
  kind: "loading";
}

export const noTableSource: NoTableSource = {
  name: "<none>",
  kind: "none",
};

export const toLoadTableSource: LoadingTableSource = {
  name: "<load file>",
  kind: "loading",
};

export interface ColumnSet {
  xColumn: ColumnId;
  yColumns: ColumnId[];
  yDispersion?: ColumnId[];
}

/** @return true if the source will generate a plot (i.e. not interim loading, or no data)*/
export function plottableSource(kind: TableSource["kind"] | undefined): boolean {
  return kind === "local" || kind === "generated" || kind === "external";
}

export async function likelySourceColumns(
  tableSource: TableSource
): Promise<ColumnSet | undefined> {
  if (tableSource.kind === "local") {
    return likelyPlotColumns(tableSource.tableId);
  } else {
    return undefined;
  }
}
