import * as d3 from "d3";
import { Vec2 } from "../math/Vec";
import { AnySelection, SvgSVGSelection } from "../util/d3Util";
import { qaSet } from "../util/QaApi";
import { ChartState } from "./Chart";
import { kineticDetect } from "./DetectKineticScroll";
import { matToDomain, mul, scaleToMTransform } from "./MiniTransform";

export interface Wheeling {
  wheeled: (event: any) => void;
  clearLastWheel: () => void;
}

/** Support wheel events with a smooth animation between wheel states. */
export function wheeling(
  selection: AnySelection,
  svg: SvgSVGSelection,
  zoomToXDomain: (xDomain: Vec2, duration: number, notifyViewChange?: boolean) => void
): Wheeling {
  const kinetic = kineticDetect();
  let lastWheelScale: number | undefined = undefined;
  const state: ChartState = svg.datum(),
    { baseScales } = state.scales,
    xRange = baseScales.x.range() as Vec2,
    xBaseTransform = scaleToMTransform(baseScales.x);

  qaSet({ wheelTo });

  return { wheeled, clearLastWheel };

  function clearLastWheel(): void {
    lastWheelScale = undefined;
  }

  function wheeled(event: WheelEvent): void {
    event.preventDefault();
    if (!kinetic.isKinetic(event)) {
      const delta =
        -event.deltaY * (event.deltaMode === 1 ? 0.05 : event.deltaMode ? 1 : 0.002);

      const [x] = (d3 as any).pointer(event, selection.node());
      wheelTo(delta, x);
    }
  }

  /** scroll in response to a wheel event (exposed for testing) */
  function wheelTo(delta: number, x: number): void {
    const t0 = d3.zoomTransform(selection.node());

    // new scale factor
    const prevK = lastWheelScale ? lastWheelScale : t0.k;
    const toK = prevK * Math.pow(2, delta);
    const k = Math.max(toK, 1.0);
    lastWheelScale = k;

    /* (see Wheel math below for details) */

    // inverse old transform of x
    const a = t0.invertX(x);

    // new translation of x
    const t = x - k * a;

    const xExtent = zoomedXDomain(k, t);

    zoomToXDomain(xExtent, 200);
  }

  /**
   * @return the xDomain resulting from applying a zoom transform
   * to the base xScale.
   * @param k,t coefficients of zoom transform for x coordinate
   */
  function zoomedXDomain(k: number, t: number): Vec2 {
    const z = { k, t };
    const zl = mul(z, xBaseTransform);
    return matToDomain(zl, xRange);
  }
}

/* Wheel math

  Here we determine the translation factor for our matrix. We already know x, the current 
  mouse position; and k, the scale factor.

  We want the transformation to be stable around x.

  So the same src point should map to x under the new and old transformations.
  i.e., given
      T0i = inverse of old transformation of x
      T1i = inverse of new transformation of x
  
  So the following will hold.
      T0i x = T1i x     

  Now we solve the translation factor t1.x in the matrix T1.

  left multiply both sides by T1:
      T1 T0i x = x

  for clarity, define
      a = T0i x 

  so
      T1 a = x

  Writing out transform T1
      t1.k * a + t1.x = x

  and we solve for t1.x:
      t1.x = x - t1.k * a
*/
