/* eslint-disable react/jsx-no-undef */
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { CSSProperties, useCallback, useRef, useState } from "react";
import { ColumnFrame, DataExtent } from "../../data/ColumnFrame";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { ColumnSet } from "../../store/TableSource";
import { useLoadedData } from "../dash-data/UseDataHook";
import { HiddenInput, isEditable } from "../misc/HiddenInput";
import { ChartDataSheet } from "../sheet/ChartDataSheet";
import { ChartElements } from "./ChartElements";
import { useChartPaste } from "./useChartPaste";

export interface ChartFrame {
  frame: ColumnFrame;
  descriptor?: ChartDescriptor;
}

export interface FrameAndColumns {
  chartFrame: ChartFrame;
  columnSet: ColumnSet | undefined;
}

export interface ChartDescriptor {
  extent?: DataExtent;
}

// export const chartButtonRowHeight = 35;  // fails, not initialized when used externally. TS?
// export const chartButtonRowHeight = (): number => 35;  // fails, not initialized when used externally
export function chartButtonRowHeight(): number {
  return 0; // the button row now overlaps the the chart title area
}

export function chartRightGutterWidth(): string {
  return "4em";
}

export function chartTitleHeight(): number {
  return 37;
}

const useStyles = makeStyles({
  dashChart: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: `25fr minmax(10px, 1fr) ${chartRightGutterWidth()}`,
    gridTemplateRows: `${chartButtonRowHeight()}px 6em 2em 3fr 2fr`,
    gridTemplate: `
      "title title title"
      "chart _a _a"
      "chart _b max"
      "chart strip axis"
      "chart _c _c"
    `,
    "& .axis": {
      fontSize: 14,
      fontFamily: ["Roboto", "Helvitica", "Arial", "sans-serif"],
    },
    "& .chart-title-container": {
      display: "flex",
      justifyContent: "center",
      marginRight: 35, // to leave space for buttons
    },
    "& .chart-title": {
      fontSize: 20,
      fontWeight: "bold",
      fontFamily: ["Roboto", "Helvitica", "Arial", "sans-serif"],
      marginBottom: 8,
      cursor: "text",
      textAlign: "center",
      transition: "background-color 1250ms ease",
      outlineStyle: "none",
      overflow: "hidden",
    },
    "& .chart-title:empty": {
      minWidth: "10em",
    },
    "& .chart-title:empty:hover": {
      backgroundColor: "#efefef",
    },
  },
  chartAndSheet: {
    height: "100%",
    width: "100%",
    marginTop: 4,
  },
});

export interface DashChartProps {
  dashId: DashId;
  className?: string;
  style?: CSSProperties;
}

export const DashChart = React.memo(DashChartInternal);

function DashChartInternal(props: DashChartProps): JSX.Element {
  const classes = useStyles(),
    { dashId } = props,
    [container, setContainer] = useState<HTMLDivElement | null>(null),
    setSelected = useStoreActions((app) => app.view.setSelectedDashItem),
    plot = useStoreState((app) => app.findChart(dashId)?.plot),
    title = useStoreState((app) => app.findChart(dashId)?.title),
    modifyChart = useStoreActions((app) => app.modifyChartById),
    loaded = useLoadedData(dashId),
    onPaste = useChartPaste({ dashId }),
    { chartFrame, columnSet } = loaded,
    { frame: columnFrame } = chartFrame,
    inputRef = useRef<HTMLInputElement>(null);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      setSelected(dashId);
      if (!isEditable(e.nativeEvent.target)) {
        // paste events go only to editable elements
        // so we focus on a hidden input element so that a subsequent paste will target the chart
        inputRef.current && inputRef.current.focus();
        e.stopPropagation();
      }
    },
    [setSelected, dashId]
  );

  const titleChange = useCallback(
    (event: Event) => {
      const title = (event as CustomEvent).detail;
      modifyChart({ dashId, chartPartial: { title } });
    },
    [dashId, modifyChart]
  );

  const setContainerRef = useCallback(
    (elem: HTMLDivElement) => {
      setContainer(elem);
      elem?.addEventListener("chart-title", titleChange);
    },
    [setContainer, titleChange]
  );

  if (!plot) {
    return <div></div>;
  }

  return (
    <div style={props.style} className={clsx(classes.chartAndSheet, props.className)}>
      <div
        data-dashid={dashId}
        ref={setContainerRef}
        className={clsx(classes.dashChart, "dash-item")}
        {...{ onPaste, onClick }}
      >
        <HiddenInput ref={inputRef} />
        {container && (
          <ChartElements {...{ dashId, chartFrame, columnSet, container, title }} />
        )}
      </div>
      {container && (
        <ChartDataSheet {...{ columnFrame, columnSet, dashId, chartRef: container }} />
      )}
    </div>
  );
}
