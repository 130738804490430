import { makeStyles } from "@material-ui/styles";
import React, { useCallback } from "react";
import { DensityPlotData, isDensityPlotKind } from "../../chart/PlotModels";
import {
  useCurrentPlot,
  useModifyPlot,
  useStoreActions,
  useStoreState,
} from "../../store/Hooks";
import { LabeledSlider } from "./LabeledSlider";
import { LabeledSwitch } from "./LabeledSwitch";
import SettingsGroup from "./SettingsGroup";
import { StatsSettings } from "./StatsSettings";

const useStyles = makeStyles({
  entry: {
    display: "block",
  },
});
export interface DensitySettingsProps {
  children?: React.ReactNode;
}

export const DensitySettings = React.memo(DensitySettingsInternal);
export default DensitySettings;

const densityHelp = (
  <div>
    <h3>Density Heat Map</h3> <p>DotDash draws a brighter color where data is denser.</p>
    <dl>
      <dt>Brighten</dt>
      <dd>
        Higher brighten values will highlight more high data density portions of the plot
        by showing them in a brighter color. Set brighten to zero to hide the density heat
        map.
      </dd>
      <dt>Smoothing</dt>
      <dd>
        Filter the density map with a gaussian blur kernel. Useful to smooth distractions
        from noise in the data.
      </dd>
      <dt>Density Scale</dt>
      <dd>
        Show a legend of the density map colors. The legend scale shows the color for a
        corresponding data density. (The max density is in parantheses).
      </dd>
      <dt>Slope Weighted</dt>
      <dd>
        Calculate density based on the horizontal span of each line. Usually this produces
        a more fair density for time series.
      </dd>
    </dl>
  </div>
);

function DensitySettingsInternal(props: DensitySettingsProps): JSX.Element {
  const classes = useStyles();
  const brightness = useCurrentPlot((p: DensityPlotData) => p.brightness);
  const smoothDensity = useCurrentPlot((p: DensityPlotData) => p.smoothDensity);
  const publishDensity =
    useCurrentPlot((p: DensityPlotData) => p.publishDensityScale) || false;
  const plotKind = useCurrentPlot((p) => p.kind);

  const setBrightness = useModifyPlot("brightness") as (event: unknown) => void;
  const setSmoothing = useModifyPlot("smoothDensity");
  const setPublishDensity = useModifyPlot("publishDensityScale");

  if (!isDensityPlotKind(plotKind)) {
    return <> </>;
  }

  return (
    <>
      <SettingsGroup title="Density" help={densityHelp}>
        <LabeledSlider
          label="brighten:"
          value={brightness || 0}
          min={0}
          max={20}
          onChange={setBrightness}
        />
        <CopyAboveSlider />
        <LabeledSwitch
          className={classes.entry}
          checked={smoothDensity}
          onChange={setSmoothing}
          label="smoothing"
        />
        <LabeledSwitch
          className={classes.entry}
          checked={publishDensity}
          onChange={setPublishDensity}
          label="show density scale"
        />
        <DensityFadeSwitch {...{ classes }} />
        {props.children}
        {/* // LATER choose color map */}
      </SettingsGroup>
      <StatsSettings />
    </>
  );
}

function CopyAboveSlider(): JSX.Element {
  const copyAbove = useCurrentPlot((p: DensityPlotData) => p.copyAbove);
  const modifyPlot = useStoreActions((app) => app.modifyPlot);
  const enabled = useStoreState((app) => app.debug.minDensitySlider);
  const delta = 0.0001; // UI says minumum which implies >=, but mapDensity code uses >. So we offset slightly.

  const onChange: any = useCallback(
    (_e: React.ChangeEvent, value: number) => {
      modifyPlot({ copyAbove: value - delta });
    },
    [modifyPlot]
  );

  const value = copyAbove ? copyAbove + delta : 0;

  if (enabled) {
    return (
      <LabeledSlider
        label="minimum:"
        {...{ value, onChange, min: 0, max: 2, step: 0.1 }}
      />
    );
  } else {
    return <></>;
  }
}

interface DensityFaceSwitchProps {
  classes: Record<string, string>;
}

function DensityFadeSwitch(props: DensityFaceSwitchProps): JSX.Element {
  const { classes } = props;
  const fadeSwitch = useStoreState((app) => app.debug.fadeSwitch);
  const disableFade = useCurrentPlot((p: DensityPlotData) => p.disableFade || false);
  const setDisableFade = useModifyPlot("disableFade");
  if (fadeSwitch || disableFade) {
    return (
      <LabeledSwitch
        className={classes.entry}
        checked={disableFade}
        onChange={setDisableFade}
        label="disable density fade"
      />
    );
  } else {
    return <></>;
  }
}
