import { CellParseResultType } from "./DetectCellFormat";
import { dataArray, DataArray } from "../data/DataChunk";
import { TabularColumn } from "./ParseTabular";

/** Given parser info for each column and a table of raw string data,
 * parse the table into numeric/string arrays for each column
 */
export function parseColumns(
  rowTable: string[][],
  tabularColumns: TabularColumn[]
): DataArray[] {
  const rowCount = rowTable.length;
  const colCount = tabularColumns.length;
  const parsers = tabularColumns.map((info) => info.dataParser.parser);
  const columns = tabularColumns.map((info) =>
    dataArray(info.dataParser.parseType, rowCount)
  );

  rowTable.forEach((row, rowDex) => {
    for (let i = 0; i < colCount; i++) {
      const parser = parsers[i] as (cell: string) => CellParseResultType;
      columns[i][rowDex] = parser(row[i]);
    }
  });

  return columns;
}
