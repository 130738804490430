import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { useModalContainer } from "./ModalContainer";
import { useAddChart } from "./UseAddChart";
import { useAddRichText } from "./UseAddText";

export function AddToDashListItem(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [addAnchor, setAddAnchor] = useState<HTMLElement | null>(null);
  const addChart = useAddChart();
  const addTextEditor = useAddRichText();
  const container = useModalContainer();

  const toggleMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      setAddAnchor(event.currentTarget);
      setOpen(!open);
    },
    [open, setOpen, setAddAnchor]
  );

  const onClose = useCallback(() => {
    setAddAnchor(null);
    setOpen(false);
  }, [setOpen, setAddAnchor]);

  const addButtonText = "Add to Dashboard";

  const result = (
    <ListItem button key={addButtonText} onClick={toggleMenu}>
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText primary={addButtonText} />
      <Menu
        anchorEl={addAnchor}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        {...{ open, onClose, container }}
      >
        <MenuItem onClick={addChart}>Add Chart</MenuItem>
        <MenuItem onClick={addTextEditor}>Add Text</MenuItem>
      </Menu>
    </ListItem>
  );
  return result;
}
