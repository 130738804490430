import * as d3 from "d3";
import { Axis, AxisDomain, AxisScale } from "d3";
import _ from "lodash";
import { Vec2 } from "../math/Vec";
import {
  AnyTransition,
  LinearScale,
  PossibleTransition,
  SvgGSelection,
  SvgSelection,
  SvgSVGSelection,
} from "../util/d3Util";
import { XorY } from "../util/MiscTypes";
import { ChartScales, XyScales } from "./ChartScales";
import { mirrorTimeScale } from "./MirrorTimeScale";
import { Margin } from "./Rectangle";

export interface Axes {
  axes: [d3.Axis<number> | d3.Axis<Date>, d3.Axis<number>];
  draw: (transition: PossibleTransition, zoomTarget: XyScales) => void;
  setSize: (size: Vec2) => void;
}

export default function axes(
  svg: SvgSVGSelection,
  scales: ChartScales,
  size: Vec2,
  margin: Margin, // CONSIDER might be easier to take plotArea.plotRect
  hidden: boolean
): Axes {
  svg.selectAll("g.axis").remove();
  if (hidden) {
    return {
      // return dummy result, we won't be using the axes with no data
      axes: undefined as any,
      draw: () => {},
      setSize: () => {},
    };
  }

  const { zooming, xType } = scales;
  const xScale = mirrorIfNeeded(zooming.x);

  const [axisX, drawX] = createOneAxis(
    svg,
    "bottom",
    xScale as AxisScale<Date>,
    size,
    margin
  );
  const [axisY, drawY] = createOneAxis<number>(svg, "left", zooming.y, size, margin);
  let svgSize = size;

  function draw(possibleTransition: PossibleTransition, zoomTarget: XyScales): void {
    const xTargetScale = mirrorIfNeeded(zoomTarget.x);
    drawX(possibleTransition, svgSize, xTargetScale as LinearScale);
    drawY(possibleTransition, svgSize, zoomTarget.y);
  }

  const result: Axes = {
    axes: [axisX, axisY],
    draw,
    setSize: (size: Vec2) => (svgSize = size),
  };

  return result;

  function mirrorIfNeeded(scale: LinearScale): AxisScale<Date> | AxisScale<number> {
    if (xType === "date") {
      return mirrorTimeScale(scale);
    } else {
      return scale;
    }
  }
}

type Orientation = "left" | "right" | "bottom" | "top";

type AxisResizeFn = (
  possibleTransition: PossibleTransition,
  newSize: Vec2,
  zoomTarget: LinearScale
) => void;

type CreateOneAxisResult<Domain extends number | Date> = [Axis<Domain>, AxisResizeFn];

function createOneAxis<Domain extends number | Date>(
  svg: SvgSVGSelection,
  orientation: Orientation,
  scale: AxisScale<Domain>,
  size: Vec2,
  margin: Margin
): CreateOneAxisResult<Domain> {
  const ticksPerPixel = 1 / 80;
  const [dataDex, xOrY, axis, location] = orientedAxis(orientation, scale);
  const selection = svg
    .append("g")
    .attr("class", "axis axis-" + xOrY) as SvgSelection<SVGGElement>;
  appendBackground(selection, xOrY, size);

  resize(selection, size, scale as unknown as LinearScale);
  return [axis, resize];

  function resize<E extends SVGElement>(
    possibleTransition: PossibleTransition<E>,
    newSize: Vec2,
    zoomTarget: LinearScale
  ): void {
    // try to estimate a good number of ticks, and then animate
    let tickCount: number;
    if (horizontal(orientation)) {
      tickCount = estimateTickCount(newSize[dataDex], zoomTarget);
    } else {
      tickCount = Math.round(newSize[dataDex] * ticksPerPixel);
    }

    axis.ticks(tickCount);
    if (isTransition(possibleTransition)) {
      const transition = possibleTransition as AnyTransition;
      const name = `axis.draw.${orientation}`;
      transition
        .tween(name, () => () => {
          draw(axis, selection, newSize, margin, location);
        })
        .end()
        .then(() => {
          if (horizontal(orientation)) {
            // ticks(6) might return 8 ticks in d3. ticks(4) might return 7 ticks!
            // (and our ticks estimation is imperfect)
            // so we cleanup at the end of the animation if necessary
            // by dropping half the ticks.
            if (ticksOverlap() && tickCount > 1) {
              axis.ticks(Math.trunc(tickCount / 2));
              draw(axis, selection, newSize, margin, location);
            }
          }
        })
        .catch(() => {});
    } else {
      draw(axis, selection, newSize, margin, location);
    }
  }

  function horizontal(orientation: Orientation): boolean {
    return orientation === "bottom" || orientation === "top";
  }

  /** choose number of ticks dynamically for x axis based on size */
  function estimateTickCount(chartWidth: number, endScale: LinearScale): number {
    const characterWidth = 8; // LATER measure this based on font and font size
    let ticks = Math.trunc(chartWidth * ticksPerPixel);
    const paddingChars = 3;
    const startScale = axis.scale() as unknown as LinearScale;

    while (ticks > 1) {
      // largest tick with begin zoom scale, and end zoom scale
      if (
        tooManyTicks(ticks, chartWidth, startScale) ||
        tooManyTicks(ticks, chartWidth, endScale)
      ) {
        ticks = Math.trunc(ticks * 0.7);
      } else {
        break;
      }
    }

    return ticks;

    function tooManyTicks(
      requestedTicks: number,
      chartWidth: number,
      scale: LinearScale
    ): boolean {
      const fmt = scale.tickFormat();
      const tickValues = scale.ticks(requestedTicks);
      const actualTicks = tickValues.length;
      const formatted = tickValues.map((v) => fmt(v));
      const lengths = formatted.map((s) => s.length);
      const largestChars = _.max(lengths) || 0;
      const largestWidth = (largestChars + paddingChars) * characterWidth;
      const maxTickWidth = chartWidth / actualTicks;
      return largestWidth > maxTickWidth;
    }
  }

  function ticksOverlap(): boolean {
    const container = selection.node();
    if (container) {
      const ticks = [...container.querySelectorAll(".tick").values()];
      const boxes = ticks.map((t) => (t as SVGGElement).getBoundingClientRect());
      const borders = boxes.map((b) => [b.left, b.right]);
      let prevRight = Number.NEGATIVE_INFINITY;
      const cross =
        borders.find((lr) => {
          const [left, right] = lr;
          if (left < prevRight) {
            return true;
          } else {
            prevRight = right;
          }
        }) !== undefined;
      if (cross) {
        // dlog("overlap", { ticksFound: ticks.length, borders });
      }
      return cross;
    }
    return false;
  }
}

function isTransition(selection: any): boolean {
  const transition = selection as unknown as AnyTransition;
  return transition.ease !== undefined || transition.constructor.name === "Transition";
}

function draw<Domain extends AxisDomain>(
  axis: Axis<Domain>,
  selection: SvgGSelection,
  size: Vec2,
  margin: Margin,
  location: LocationFn
): void {
  const spot = location(margin, size);
  selection.attr("transform", `translate(${spot})`).call(axis);
}

type LocationFn = (margin: Margin, size: Vec2) => string;

type AxisResult<Domain extends AxisDomain> = [number, XorY, Axis<Domain>, LocationFn];

function orientedAxis<Domain extends AxisDomain>(
  orientation: Orientation,
  scale: AxisScale<Domain>
): AxisResult<Domain> {
  switch (orientation) {
    case "left":
      return [1, "y", d3.axisLeft(scale), left];
    case "right":
      return [1, "y", d3.axisRight(scale), right];
    case "bottom":
      return [0, "x", d3.axisBottom(scale), bottom];
    case "top":
      return [0, "x", d3.axisTop(scale), top];
    default:
      console.log("orientedAxis error, orientation:", orientation);
      throw {};
  }

  function top(): string {
    return "0, 0";
  }
  function bottom(margin: Margin, size: Vec2): string {
    return `${margin.left}, ${size[1] - margin.bottom}`;
  }
  function left(margin: Margin): string {
    return `${margin.left}, ${margin.top}`;
  }
  function right(margin: Margin, size: Vec2): string {
    return `${margin.left + size[0]}, ${margin.top}`;
  }
}

const backgroundWidth = 30;
export const xAxisHighlightColor = "#f0faf5";
export const yAxisHighlightColor = "#eaf6ff";
const backgroundCornerRadius = 5;

function appendBackground(
  selection: SvgSelection<SVGGElement>,
  xOrY: XorY,
  chartSize: Vec2
): void {
  const background = selection
    .append("rect")
    .attr("class", "background")
    .attr("opacity", 0)
    .attr("rx", backgroundCornerRadius);

  if (xOrY === "x") {
    background
      .attr("fill", xAxisHighlightColor)
      .attr("width", chartSize[0] - backgroundWidth * 3)
      .attr("height", backgroundWidth);
  } else {
    background
      .attr("fill", yAxisHighlightColor)
      .attr("width", backgroundWidth)
      .attr("height", chartSize[1] - backgroundWidth * 2)
      .attr("transform", `translate(${-backgroundWidth}, 0)`);
  }
}
