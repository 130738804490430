import { NumberParser } from "../parse/DetectNumberFormat";
import { TabularColumn } from "./ParseTabular";
import { pairs } from "../util/Utils";
import { transposeRows } from "./TransposeRows";

const timelikeLabels = ["date", "time", "datetime", "timestamp", "epochtime"];

/** convert some numeric columns to dates they seem likely to be dates */
export function promoteTimeLike(
  columns: TabularColumn[],
  rowTable: string[][]
): TabularColumn[] {
  const columnTable = transposeRows(rowTable);
  const newColumns = columns.map((info, i) =>
    promoteTimeLikeColumn(info, columnTable[i])
  );
  return newColumns;
}

/**
 * If a numeric column has a date/time sounding label and an increasing number sequence,
 * we'll guess the number should be interpreted as an epoch milliseconds datetime.
 */
// CONSIDER requiring that the data subtype be integer
// CONSIDER dropping the requirement that the numbers be increasing
// CONSIDER requiring that the numbers be within 25 yrs of current epoch milliseconds time
// CONSIDER using this for ascii and name value format detectors
export function promoteTimeLikeColumn(
  info: TabularColumn,
  column: string[]
): TabularColumn {
  if (info.dataParser.parseType === "number") {
    const { label } = info;
    if (timelikeLabels.includes(label.toLowerCase())) {
      const parser = info.dataParser.parser as NumberParser;
      const numbers = column.map(parser);
      if (areIncreasing(numbers)) {
        return { ...info, displayType: "date" };
      }
    }
  }
  return info;
}

function areIncreasing(numbers: number[]): boolean {
  for (const [a, b] of pairs(numbers)) {
    if (b < a) {
      return false;
    }
  }
  return true;
}
