import { Vec3, Vec4 } from "../math/Vec";
import { Brand } from "../util/Brand";
import { replaceUndefined } from "../util/Utils";

export type Plot = HeatLinesPlot | HeatScatterPlot | SvgLinePlot | SvgScatterPlot;

export type AnyPlotProperty = Partial<HeatLinesPlot> &
  Partial<HeatScatterPlot> &
  Partial<SvgLinePlot> &
  Partial<SvgScatterPlot>;

export interface HeatLinesPlot extends DensityPlotData {
  kind: "HeatLines";
  width: number;
  feather: number;
  weightHorizontal?: boolean;
  color?: Vec3;
}

export interface HeatScatterPlot extends DensityPlotData {
  kind: "HeatScatter";
  size: number;
  feather: number;
}

/** properties for density based plots */
export interface DensityPlotData {
  colorScale?: ColorScaleName;
  brightness: number;
  smoothDensity: boolean;
  background?: Vec4;
  publishDensityScale?: boolean;
  copyAbove?: number;
  disableFade?: boolean;
  summaries?: SummaryStat[];
}

export interface SvgLinePlot {
  kind: "SvgLines";
}

export interface SvgScatterPlot {
  kind: "SvgScatter";
  translucent?: boolean;
}

export type ColorScaleName = "viridis" | "plasma";

export function isDensityPlotKind(kind: Plot["kind"] | undefined): boolean {
  return kind === "HeatScatter" || kind === "HeatLines";
}

export function isSvgPlotKind(kind: Plot["kind"] | undefined): boolean {
  return kind === "SvgLines" || kind === "SvgScatter";
}

export function isDensityPlot(plot: Plot | undefined): boolean {
  return isDensityPlotKind(plot?.kind);
}

const universalDefaults: Partial<Plot> = {
  size: 4,
  feather: 2,
  brightness: 0,
  smoothDensity: true,
};

export function setPlotKind(plot: Plot, kind: Plot["kind"]): void {
  const defaulted = replaceUndefined(plot, universalDefaults);
  Object.assign(plot, defaulted);
  plot.kind = kind;
}

export type SummaryStat = MeanStat | PercentileStat;

export interface MeanStat extends StatBase {
  kind: "mean";
}

export interface PercentileStat extends StatBase {
  kind: "percentile";
  percentile?: number;
}

export interface StatBase {
  kind: string;
  summaryId: SummaryId;
  thickness?: number;
  color?: Vec3;
  feather?: number;
}

export type SummaryId = Brand<number, "statId">;
