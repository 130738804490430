import { FrameAndColumns } from "../components/rich-chart/DashChart";
import * as DebugSampleData from "./debugSampleData";
import * as SampleData from "./sampleData";

export interface LoaderEntry {
  name: string;
  debug: boolean;
  loader: (maxRows: number) => Promise<FrameAndColumns>;
}

export const GeneratedDataLoaders: LoaderEntry[] = [
  ...debugDataLoaders(),
  ...syntheticDataLoaders(),
];

function syntheticDataLoaders(): LoaderEntry[] {
  const debug = false;
  return Object.entries(SampleData).map(([name, fn]) => {
    const loader = (maxRows: number): Promise<FrameAndColumns> =>
      Promise.resolve(fn(maxRows));
    return { name, debug, loader };
  });
}

function debugDataLoaders(): LoaderEntry[] {
  const debugSamples: Generator[] = Object.entries(DebugSampleData);
  return exportedLoaders(debugSamples);
}

type Generator = [string, (length: number) => FrameAndColumns];

function exportedLoaders(exports: Generator[], debug = true): LoaderEntry[] {
  return exports.map(([name, fn]) => {
    const loader = (maxRows: number): Promise<FrameAndColumns> =>
      Promise.resolve(fn(maxRows));
    return { name, debug, loader };
  });
}
