import _ from "lodash";
import { labelColumns } from "../csv/DetectCsvFormat";
import { DataArray } from "../data/DataChunk";
import { Vec2 } from "../math/Vec";
import { ColumnDetect, detectAllColumnTypes } from "./DetectColumnTypes";
import { choosePattern, linePattern, LinePattern } from "./LinePattern";
import { parseAsciiTable } from "./ParseAsciiTable";
import { AsciiTableParser } from "./ParseTabular";
import { splitTextByLine } from "./SplitTextByLine";

export interface AsciiTableFormat {
  linePattern: LinePattern;
  lineBreak: string;
  headerLines: number;
}

/** Detect a table that's where the columns are visually aligned and separated by spaces */
export function detectAsciiTable(
  text: string,
  ignoreLastLine = false
): AsciiTableParser | undefined {
  const { nonEmpty: lines, lineBreak } = splitTextByLine(text, ignoreLastLine);
  if (lines.length === 0) {
    return undefined;
  }
  const rowPatterns = lines.map(linePattern);

  const { score, pattern } = choosePattern(rowPatterns);

  const columnDetects = valueTypesFromPattern(lines, pattern);
  if (!columnDetects) {
    return undefined;
  }
  const { columns, headerLines } = labelColumns(columnDetects);
  const format: AsciiTableFormat = {
    lineBreak,
    linePattern: pattern,
    headerLines,
  };
  const bodyFormat = { ...format, headerLines: 0 };

  const parse = (text: string): DataArray[] => parseAsciiTable(text, { columns, format });
  const parseBody = (text: string): DataArray[] =>
    parseAsciiTable(text, { columns, format: bodyFormat });

  const parser: AsciiTableParser = {
    kind: "ascii",
    score,
    columns,
    format,
    parse,
    parseBody,
  };
  return parser;
}

/** return a parser for the values for the chosen keys */
function valueTypesFromPattern(
  lines: string[],
  pattern: LinePattern
): ColumnDetect[] | undefined {
  const startEnd = _.zip(pattern.starts, pattern.ends) as Vec2[];

  const valueRows = lines.map((line) => {
    const values = startEnd.map(([start, end]) => line.slice(start, end));
    return values.map((s) => s.trim());
  });
  return detectAllColumnTypes(valueRows);
}
