import { Regl, Texture2D } from "regl";
import { defaultPercentile } from "./DensityShading";
import { textureFB } from "./TextureFB";
import { FullFB } from "./WebGLUtil";

export interface BucketStatsResult {
  texture: Texture2D;
  fb: FullFB;
  initBucketStats: (props: InitBucketStatsProps) => void;
}

/**
 * Produce a single pixel texture with 'bucket stats' to start off the percentile reduction.
 *
 * The bucket stats structure needs the min and max of the range, and the percentileNth - the
 * sequence number of the percentile element that the percentile reduction will search for.
 *
 */
export function InitialBucketStats(regl: Regl, nzStats: Texture2D): BucketStatsResult {
  const [texture, fb] = textureFB(regl, [1, 1]);

  const initBucketStatsCmd = regl({
    vert: `
      precision lowp float;
      attribute float zero; // use, or webgl complains: "Drawing without vertex attrib 0 array enabled"

      void main() {
        gl_Position = vec4(zero, zero, 1.0, 1.0);
      }
    `,
    frag: `
      precision highp float;

      uniform sampler2D nzStats; 
      uniform float percentage;

      vec4 stats;

      void main() {
        stats = texture2D(nzStats, vec2(.5, .5));
        float min = stats.b;
        float max = stats.a + .0001;  // we use < comparison later for consistency and want to keep this max value
        float percentileCount = 0.0;
        
        // if we have 500 non-zero pixels, and we're searching for the 95th percentile value,
        // that means we're looking for the value of the 475th element (500*.95) 
        float nzCount = stats.g;
        float percentileNth = floor(nzCount * percentage + .5);
        
        gl_FragColor = vec4(min, max, percentileCount, percentileNth);  
      }
    `,
    attributes: {
      zero: [0],
    },
    uniforms: {
      percentage: (_ctx, props: InitBucketStatsProps) =>
        props.percentage || defaultPercentile,
      nzStats,
    },
    depth: { enable: false, mask: false },
    primitive: "points",
    count: 1,
    framebuffer: fb,
  });

  function initBucketStats(props: InitBucketStatsProps): void {
    initBucketStatsCmd(props);
  }

  return {
    texture,
    fb,
    initBucketStats,
  };
}

export interface InitBucketStatsProps {
  percentage?: number;
}
