import { action, State } from "easy-peasy";
import { DataEditOptions } from "../components/dash-data/useTabularData";
import { AddDashItemProps, AppModel, doAddDashItem } from "./AppModel";
import { ChartModel } from "./ChartModel";
import { DashChartModel } from "./DashItems";
import { ColumnSet, TableSource } from "./TableSource";

export interface EditChartDataProps {
  dataEdit: DataEditOptions;
  newColumnSet?: ColumnSet;
  tableSource: TableSource;
}

export type ChartEditMode = "replace" | "forceNew" | "append"; // TODO rename

/** Modify chart references to table and columns, adding a new chart if necessary.  */
export const editChartData = action<AppModel, EditChartDataProps>((state, props) => {
  const { dataEdit, tableSource, newColumnSet } = props;
  const { noShow, chartName, editMode } = dataEdit;
  if (editMode === "forceNew" && !noShow) {
    addNewChart(state, tableSource, newColumnSet, chartName);
  } else {
    const chart = findChartByName(state, chartName);
    if (!chart && !noShow) {
      addNewChart(state, tableSource, newColumnSet, chartName);
    } else if (chart) {
      if (editMode === "replace") {
        chart.tableSource = tableSource;
        chart.columnSet = newColumnSet;
      } else {
        console.assert(editMode === "append");
      }
    }
  }
});

function addNewChart(
  state: State<AppModel>,
  tableSource: TableSource,
  newColumnSet: ColumnSet | undefined,
  title: string | undefined
): void {
  const demoChart: Omit<DashChartModel, "id"> = {
    kind: "Chart",
    chart: {
      plot: {
        kind: "HeatScatter",
        size: 4,
        feather: 2,
        brightness: 6,
        smoothDensity: true,
      },
      tableSource,
      columnSet: newColumnSet,
      title,
    },
  };
  const addItem: AddDashItemProps = {
    item: demoChart,
    width: 49,
    height: 119,
  };
  doAddDashItem(state, addItem);
}

function findChartByName(state: State<AppModel>, name: string): ChartModel | undefined {
  const found = state.dashboard.dashItems.items.find(
    (item) => item.kind === "Chart" && item.chart.title === name
  );
  return (found as DashChartModel)?.chart;
}
