import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { InfoOutlined as InfoIcon } from "@material-ui/icons";
import React, { useCallback } from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { ClickTip } from "./ClickTip";
import { useRenderedHelp } from "./HelpButton";

const introHelp = (
  <>
    <h2>Welcome to Dashberry</h2>
    <p>Dashberry is a data dashboard for line, scatter, and time series charts.</p>
    <p>I’d love to hear your thoughts: lee@dashberry.dev. This is the M1 version. </p>
    <p>
      <b>Scale</b> Dashberry charts handle more data than most comparable tools. Try
      increasing the number number of points in the Data panel. How high can you go on
      your computer?
    </p>
    <p>
      <b>Density Heat Maps</b> Dashberry charts sport an unusual feature: all of the
      charts include integrated density heat maps. Does the density display make sense to
      you?
    </p>
    <p>
      <b>Your Data</b> Try dragging a .csv/.tsv file onto a chart. Does your data work in
      Dashberry? (Data you add to Dashberry is stored only on your computer.)
    </p>
  </>
);

export function IntroHelp(): JSX.Element {
  const modifyHelp = useStoreActions((app) => app.modifyHelp);
  const introShown = useStoreState((state) => state.help.introShown);
  const renderedHelp = useRenderedHelp(introHelp);

  const onClose = useCallback((): void => {
    if (!introShown) {
      modifyHelp({ introShown: true });
    }
  }, [introShown, modifyHelp]);

  const introText = "Introduction";

  const button = useCallback((toggleOpen: () => void): JSX.Element => {
    return (
      <ListItem button key={introText} {...{ onClick: toggleOpen }}>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary={introText} />
      </ListItem>
    );
  }, []);

  return (
    <ClickTip
      render={button}
      title={renderedHelp}
      startOpen={!introShown}
      {...{ onClose }}
      arrow
      placement="right"
    />
  );
}
