export function fileSuffix(fileName: string): string {
  const dot = fileName.lastIndexOf(".");
  if (dot >= 0) {
    return fileName.slice(dot + 1);
  } else {
    return "";
  }
}

export function fileNoSuffix(filePath: string): string {
  const slash = filePath.lastIndexOf("/");
  const start = Math.max(slash + 1, 0);
  const dot = filePath.lastIndexOf(".");
  const end = dot >= 0 ? dot : filePath.length;
  return filePath.slice(start, end);
}
