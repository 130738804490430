import {
  createStyles,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  ChevronLeft as ChevronLeftIcon,
  DeleteForever as DeleteForeverIcon,
  Redo as RedoIcon,
  Settings as SettingsIcon,
  Storage as StorageIcon,
  Undo as UndoIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useCallback } from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { eraseAll } from "../dash-data/EraseAll";
import { IntroHelp } from "../help/IntroHelp";
import { TricksButton } from "../help/TricksButton";
import { AddToDashListItem } from "./AddToDashMenu";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    list: {
      height: "100%",
    },
  })
);

type IconAction = [JSX.Element, string, () => void];

export default function LeftDrawer(): JSX.Element {
  const classes = useStyles();
  const open = useStoreState((state) => state.view.leftDrawerOpen);
  const debugMenu = useStoreState((state) => state.debug.debugMenu);
  const eraseButton = useStoreState((state) => state.debug.eraseButton);
  const dataMenu = useStoreState((state) => state.debug.dataMenu);
  const setOpen = useStoreActions((actions) => actions.view.setLeftDrawerOpen);
  const showSettings = useStoreActions((actions) => actions.view.setSettingsOpen);
  const dataDialog = useStoreActions((actions) => actions.view.setDataDialogOpen);
  const undoAction = useStoreActions((actions) => actions.undoUndo);
  const redoAction = useStoreActions((actions) => actions.undoRedo);

  const showData = useCallback(() => {
    dataDialog(true);
  }, [dataDialog]);

  const undoRedo: IconAction[] = [
    [<UndoIcon data-qa="undo" />, "Undo", () => undoAction()], // eslint-disable-line react/jsx-key
    [<RedoIcon data-qa="redo" />, "Redo", () => redoAction()], // eslint-disable-line react/jsx-key
  ];

  const debug: IconAction = [
    <SettingsIcon data-qa="debugSettings" />, // eslint-disable-line react/jsx-key
    "Debug Settings",
    () => showSettings(true),
  ];

  const data: IconAction = [<StorageIcon />, "Data", showData]; // eslint-disable-line react/jsx-key

  const deleteForever: IconAction = [<DeleteForeverIcon />, "Erase All Data", eraseAll]; // eslint-disable-line react/jsx-key

  const activeItems: IconAction[] = undoRedo;

  if (dataMenu) {
    activeItems.push(data);
  }
  if (debugMenu) {
    activeItems.push(debug);
  }
  if (eraseButton) {
    activeItems.push(deleteForever);
  }

  const listItems = activeItems.map(([icon, text, fn]) => (
    <ListItem button key={text} onClick={fn}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  ));

  const { drawerContainer, drawer, drawerOpen, drawerClose } = classes;

  return (
    <div className={drawerContainer}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(drawer, open ? drawerOpen : drawerClose)}
        classes={{ paper: open ? drawerOpen : drawerClose }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List className={classes.list}>
          {listItems}
          <Divider />
          <AddToDashListItem />
          <IntroHelp />
          <TricksButton />
        </List>
      </Drawer>
    </div>
  );
}
