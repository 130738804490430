import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useStoreState } from "../../store/Hooks";
import { fileSuffix } from "../../util/PathUtil";
import { useLoadDashboard } from "../dash-data/UseLoadDashboard";
import { DashboardAlert } from "./DashboardAlert";
import { dashFileFormats } from "./UseDashboardDrop";

const useStyles = makeStyles({
  dropZoneStyle: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    borderWidth: 4,
    borderRadius: 8,
    borderColor: "darkgrey",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition:
      "border .24s ease-in-out, background .24s ease-in-out, color .24s ease-in-out",
    background: "#ffffffe0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2000,
  },
  activeStyle: {
    borderColor: "blue",
  },
  acceptStyle: {
    borderColor: "green",
    backgroundColor: "#b6dbb0e0",
    color: "black",
  },
  rejectStyle: {
    borderColor: "red",
    backgroundColor: "#f1babae0",
    color: "black",
  },
  dropText: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
  },
});

export function DashDropZone(): JSX.Element {
  const dragActiveInWindow = useStoreState((app) => app.view.dragActive);
  const classes = useStyles();
  const { dropText, dropZoneStyle, activeStyle, acceptStyle, rejectStyle } = classes;
  const acceptMode = useRef(0);
  const loadDashboard = useLoadDashboard();
  const [loadError, setLoadError] = useState(false);
  const onDrop = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        const suffix = fileSuffix(file.name);
        if (dashFileFormats.includes(suffix)) {
          loadDashboard(file);
          setLoadError(false);
        } else {
          setLoadError(true);
        }
      });
    },
    [loadDashboard]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    // accept doesn't identify .dotd types by suffix on linux
  });

  const className = useMemo(
    () =>
      clsx(
        dropZoneStyle,
        isDragActive && activeStyle,
        acceptMode.current === 1 && acceptStyle,
        acceptMode.current === 2 && rejectStyle
      ),
    [isDragActive, acceptStyle, activeStyle, rejectStyle, dropZoneStyle]
  );

  if (isDragActive) {
    acceptMode.current = 0;
  }

  if (dragActiveInWindow) {
    return (
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        <p className={dropText}>dashboard file (.dotd) </p>
      </div>
    );
  } else if (loadError) {
    return <DashboardAlert severity="error">dashboard files only (.dotd)</DashboardAlert>;
  } else {
    return <></>;
  }
}
