import { Vec2 } from "./Vec";

export interface LirpFn {
  (t: number): number;
  range(): number;
}

export function Lirp(start = 0, end = 1): LirpFn {
  const range = end - start;
  const fn = (t: number): number => start + range * t;
  fn.range = () => range;
  return fn;
}

export function InverseLirp(start = 0, end = 1): LirpFn {
  const range = end - start;
  const fn = (x: number): number => (x - start) / range;
  fn.range = () => range;
  return fn;
}

export function mixV(range: Vec2, p: number): number {
  console.assert(p >= 0);
  console.assert(p <= 1);
  return range[0] + (range[1] - range[0]) * p;
}

export function percentV(range: Vec2, value: number): number {
  console.assert(value >= range[0]);
  console.assert(value <= range[1]);
  if (range[0] === range[1]) {
    return range[0];
  }
  return (value - range[0]) / (range[1] - range[0]);
}
