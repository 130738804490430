import React from "react";
import { SvgScatterPlot } from "../../chart/PlotModels";
import { useCurrentPlot, useModifyPlot } from "../../store/Hooks";
import { LabeledSwitch } from "./LabeledSwitch";
import SettingsGroup from "./SettingsGroup";

export const SvgScatterSettings = React.memo(SvgScatterSettings_);

function SvgScatterSettings_(): JSX.Element {
  const plotKind = useCurrentPlot((plot) => plot.kind);
  const translucent = useCurrentPlot((plot: SvgScatterPlot) => plot.translucent)!;
  const setTranslucent = useModifyPlot("translucent") as (event: any) => void;

  if (plotKind !== "SvgScatter") {
    console.log("ScatterSettings: unexpected plot kind:", plotKind);
    return <></>;
  }

  return (
    <div>
      <SettingsGroup title="SVG Scatter">
        <LabeledSwitch
          label="translucent"
          value={translucent}
          onChange={setTranslucent}
        />
      </SettingsGroup>
    </div>
  );
}
