import _ from "lodash";
import React, { ReactNode, useCallback, useContext, useRef, useState } from "react";
import { Vec2 } from "../../math/Vec";
import { ForwardRef, setParentRef } from "../../util/ReactUtil";
import { ResizeContext } from "../dash-layout/ResizeContext";

export const SizeContext = React.createContext<Vec2 | undefined>(undefined);

export interface SizedContainerProps {
  children?: ReactNode;
  className?: string;
}

const forwardedSizedContainer = React.forwardRef(SizedContainerInternal);

export const SizedContainer = React.memo(forwardedSizedContainer);

function SizedContainerInternal(
  props: SizedContainerProps,
  parentRef: ForwardRef
): JSX.Element {
  const [size, setSize] = useState<Vec2 | undefined>(undefined);
  const divRef = useRef<HTMLDivElement | null>(null);
  const resize = useContext(ResizeContext);
  const lastResize = useRef<number>(resize);

  const measureSize = useCallback(() => {
    const div = divRef.current;
    if (div) {
      const newSize = [div.clientWidth, div.clientHeight] as Vec2;
      if (!_.isEqual(size, newSize)) {
        setSize(newSize);
      }
    }
  }, [divRef, setSize, size]);

  const gotRef = useCallback(
    (elem: HTMLDivElement) => {
      divRef.current = elem;
      setParentRef(parentRef, elem);
      setTimeout(measureSize, 0);
    },
    [parentRef, divRef, measureSize]
  );

  if (resize !== lastResize.current) {
    measureSize();
    lastResize.current = resize;
  }

  function contents(): JSX.Element {
    if (size) {
      return <SizeContext.Provider value={size}>{props.children}</SizeContext.Provider>;
    } else {
      return <></>;
    }
  }

  return (
    <div ref={gotRef} className={props.className}>
      {contents()}
    </div>
  );
}
