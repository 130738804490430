const isBrowser: boolean = typeof window !== "undefined";
const globalObj: any = isBrowser ? window : {};
globalObj._testingApi = {};

const qa = globalObj._testingApi;
qa.qaEnabled = qaQueryParam();

/** return true if url enables qa api */
function qaQueryParam(): boolean {
  if (isBrowser) {
    const url = new URL(document.location.href);
    if (url.searchParams.get("qa") === "true") {
      return true;
    }
  }
  return false;
}

/** This modules stores some internal variables in global window state so that
 * e2e testing can get at them.
 *
 * Some e2e tests inject scripts into the browser via playWright
 * to get access the global state.
 */

export function qaEnabled(): boolean {
  return qa.qaEnabled;
}

/** set a global variable for e2e testing */
export function qaSet(keyOrObj: string | Record<string, unknown>, value?: unknown): void {
  if (qaEnabled()) {
    if (typeof keyOrObj === "string") {
      qa[keyOrObj] = value;
    } else if (typeof keyOrObj === "object") {
      Object.entries(keyOrObj).forEach(([key, v]) => (qa[key] = v));
    } else {
      throw `qaSet, unexpected key: ${keyOrObj}`;
    }
  }
}

/** add an element to a global array for e2e testing */
export function qaPush(key: string, value: unknown): void {
  if (qaEnabled()) {
    getArray(key).push(value);
  }
}

/** get an array at key, creating the array as necessary */
function getArray(key: string): [any] {
  if (qaEnabled()) {
    if (!qa[key]) {
      qa[key] = [];
    }
    return qa[key];
  } else {
    return [undefined];
  }
}
