export interface CsvFormat {
  separator: string;
  lineBreak: string;
  quoteChar: string;
  escapeChar: string;
  comment: string;
  headerLines: number;
}

export const defaultCsvFormat = {
  separator: ",",
  lineBreak: "\n",
  quoteChar: '"',
  escapeChar: "\\",
  comment: "#",
  headerLines: 0,
};
