import _ from "lodash";
import { useRef } from "react";
import { chooseTabularParser, TabularParser } from "../../parse/ParseTabular";
import { DataPipeHeader } from "../../protocol/PipeProtocol";
import { ChartEditMode } from "../../store/EditChartData";
import { dlog } from "../../util/DebugLog";
import { DataEditOptions, useTabularData } from "../dash-data/useTabularData";
import { PendingData } from "./PendingData";
import { LocalDataSocket, PendingDataStream, socketListener } from "./SocketListener";

const makeWebSocket = (url: string): WebSocket => new WebSocket(url);

/** listen for data arriving on a network socket.  As data arrives, parse it and
 * store it in the local database. Notify the application that new data has
 */
export function useLocalDataSocket(): LocalDataSocket {
  const handleOneStream = useOneStream();
  const listener = useRef<LocalDataSocket>();

  if (!listener.current) {
    const newListener = socketListener({ makeWebSocket });
    listener.current = newListener;
    awaitData(newListener.dataStreams).catch((e) => console.error("listener", e));
  }

  return listener.current;

  async function awaitData(
    streams: AsyncGenerator<PendingDataStream, void>
  ): Promise<void> {
    for await (const stream of streams) {
      handleOneStream(stream).catch(() => {});
    }
    dlog("out of streams, hmm?");
  }
}

function useOneStream(): (stream: AsyncGenerator<PendingData, void>) => Promise<void> {
  const makeHandleTabular = useTabularData();

  return async function handleOneStream(
    dataStream: AsyncGenerator<PendingData, void>
  ): Promise<void> {
    const handleTabular = makeHandleTabular();
    const lines: string[] = [];
    let parser: TabularParser | undefined = undefined;
    let dataEdit: DataEditOptions | undefined = undefined;

    for await (const pending of dataStream) {
      if (pending.kind === "header") {
        dataEdit = dataEditOptions(pending.header);
      } else if (pending.kind === "data") {
        console.assert(dataEdit !== undefined);
        onData(pending.data).catch((e) => dlog("handling one stream", e));
      }
    }

    async function onData(data: Uint8Array): Promise<void> {
      const decoder = new TextDecoder();
      const newText = decoder.decode(data, { stream: true });
      const started = parser !== undefined;
      appendLines(lines, newText);

      if (!started && lines.length > 4) {
        // find an appropriate parser
        const completeLines = lines.slice(0, lines.length - 1);
        const fullText = combineLines(completeLines);
        parser = chooseTabularParser(fullText);
      }

      if (parser && dataEdit) {
        // send to store and update chart
        const takeLines = lines.splice(0, lines.length - 1);
        const text = combineLines(takeLines);
        const { chartName } = dataEdit;
        const tableName = await handleTabular(text, dataEdit!, parser, started);

        // subsequent blocks append data into possibly renamed table
        dataEdit = { noShow: true, chartName, tableName, editMode: "append" };
      }
    }
  };
}

function combineLines(lines: string[]): string {
  return lines.filter((s) => s.length > 0).join("\n");
}

function appendLines(linesBuffer: string[], text: string): void {
  const newLines = text.split("\n");
  const firstNew = _.head(newLines);
  if (firstNew) {
    const lastInBuf = _.last(linesBuffer);
    if (lastInBuf) {
      linesBuffer[linesBuffer.length - 1] = lastInBuf.concat(firstNew);
    } else {
      linesBuffer.push(firstNew);
    }

    const rest = _.tail(newLines);
    linesBuffer.push(...rest);
  }
}

/** construct DataEditOptions based on fields in the protocol header */
function dataEditOptions(header: DataPipeHeader): DataEditOptions {
  const { name: chartName = "data", replace = false, forceNew = false, noShow } = header;
  let edit: ChartEditMode;
  if (replace) {
    edit = "replace";
  } else if (forceNew) {
    edit = "forceNew";
  } else {
    if (header.name) {
      edit = "append";
    } else {
      edit = "forceNew";
    }
  }
  return {
    chartName,
    editMode: edit,
    noShow,
  };
}
