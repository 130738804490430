import { FourPoints } from "../math/SplineMath";
import { BezierCurve } from "../math/Splines";

const varying1Control: FourPoints = [
  [0, 1],
  [0.5, 40],
  [0.7, -10],
  [1, 0.5],
];

const varying4Control: FourPoints = [
  [0, 2],
  [0.6, -1],
  [0.7, 30],
  [1, 1],
];

const varying2Control: FourPoints = [
  [0, 2],
  [0.5, 2],
  [0.7, 1.5],
  [1, 1.2],
];

const varying3Control: FourPoints = [
  [0, -1],
  [0.5, -1],
  [0.7, 1.5],
  [1, 1.5],
];

export const varying1Shape = BezierCurve(varying1Control);
export const varying2Shape = BezierCurve(varying2Control);
export const varying3Shape = BezierCurve(varying3Control);
export const varying4Shape = BezierCurve(varying4Control);
