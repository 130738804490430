import { Action, action } from "easy-peasy";
import { Layout } from "react-grid-layout";
import { Vec3 } from "../math/Vec";
import { dlog } from "../util/DebugLog";
import { DashId } from "./DashId";
import dashItemsModel, { DashItemsModel } from "./DashItems";

export interface DashboardModel {
  title: string;
  dashItems: DashItemsModel;
  setTitle: Action<DashboardModel, string>;
  layout: Layout[];
  setLayout: Action<DashboardModel, Layout[]>;
  nextId: number;
  toNextId: Action<DashboardModel>;
  modelVersion: Vec3;
}

const currentModelVersion: Vec3 = [0, 5, 0];

const dashboardModel: DashboardModel = {
  modelVersion: currentModelVersion,
  title: "My Dashboard",
  dashItems: dashItemsModel,
  setTitle: action((state, string) => {
    state.title = string;
  }),
  setLayout: action((state, layout) => {
    state.layout = layout;
  }),
  layout: [],
  nextId: 5 as DashId,
  toNextId: action((state) => {
    state.nextId++;
    dlog({ nextId: state.nextId });
  }),
};

export default dashboardModel;
