import { makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import { SectionTitle } from "./SectionTitle";
import SettingsGroup from "./SettingsGroup";

interface SettingsSectionProps {
  children?: ReactNode;
  title?: string;
}
const useStyles = makeStyles({
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export function SettingsSection(props: SettingsSectionProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <SectionTitle title={props.title} />
      <SettingsGroup>{props.children}</SettingsGroup>
    </div>
  );
}
