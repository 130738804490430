import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { chartDefaultMargin } from "../../chart/Chart";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { useLoadFailed, useLoadFile } from "../dash-data/LoadFileHook";
import {
  chartButtonRowHeight,
  chartRightGutterWidth,
  chartTitleHeight,
} from "./DashChart";

const top = chartButtonRowHeight() + chartDefaultMargin.top + chartTitleHeight();
const overlap = 15; // the drop box outline should overlap the axis by this amount
const left = chartDefaultMargin.left - overlap;
const marginBottom = chartDefaultMargin.bottom - overlap;
const marginRight = chartDefaultMargin.right;

const useStyles = makeStyles({
  dropZoneStyle: {
    position: "absolute",
    left,
    top,
    width: `calc(100% - ${marginRight + left}px - ${chartRightGutterWidth()})`,
    height: `calc(100% - ${top + marginBottom}px)`,
    borderWidth: 4,
    borderRadius: 8,
    borderColor: "darkgrey",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition:
      "border .24s ease-in-out, background .24s ease-in-out, color .24s ease-in-out",
    background: "#ffffffe0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activeStyle: {
    borderColor: "blue",
  },
  acceptStyle: {
    borderColor: "green",
    backgroundColor: "#b6dbb0e0",
    color: "black",
  },
  rejectStyle: {
    borderColor: "red",
    backgroundColor: "#f1babae0",
    color: "black",
  },
  dropText: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
  },
});

export interface ChartDropzoneProps {
  dashId: DashId;
}

export const acceptFileFormats = [
  "text/tab-separated-values",
  "text/csv",
  "text/plain",
  "application/vnd.ms-excel",
  ".tsv",
  ".csv",
].join(",");

export const ChartDropzone = React.memo(ChartDropzone_);

function ChartDropzone_(props: ChartDropzoneProps): JSX.Element {
  const { dashId } = props,
    classes = useStyles(),
    dragActiveInWindow = useStoreState((app) => app.view.dragActive),
    dataKind = useStoreState((app) => app.findChart(dashId)?.tableSource.kind),
    setSheet = useStoreActions((app) => app.view.setSheet),
    loadFailed = useLoadFailed(dashId),
    loadFile = useLoadFile();

  const onClick = useCallback(() => {
    if (dataKind === "none") {
      setSheet(dashId);
    }
  }, [setSheet, dashId, dataKind]);

  const loadFiles = useCallback(
    (files: File[]): void => {
      files.forEach((file) => {
        loadFile(file, dashId).catch((err) => {
          loadFailed(`unable to load file: ${file.name}`);
          console.error(file.name, err);
        });
      });
    },
    [loadFile, loadFailed, dashId]
  );
  const dropZone = useDropzone({
    // re-renders overmuch due to upstream bug: https://github.com/react-dropzone/react-dropzone/issues/1035
    onDrop: loadFiles,
    multiple: false,
    noClick: true,
    accept: acceptFileFormats,
  });
  const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } =
    dropZone;

  const onDrop = useCallback((e: React.DragEvent) => {
    (e as any).chartDrop = true; // mark so upstream receivers can ignore drops on the chart
  }, []);
  const { dropZoneStyle, activeStyle, acceptStyle, rejectStyle } = classes;
  const className = useMemo(
    () =>
      clsx(
        dropZoneStyle,
        isDragActive && activeStyle,
        isDragAccept && acceptStyle,
        isDragReject && rejectStyle
      ),
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      acceptStyle,
      activeStyle,
      rejectStyle,
      dropZoneStyle,
    ]
  );

  const dropOrRejectText = isDragReject
    ? "(Must be a .tsv or .csv file)"
    : "Click to choose data (or drop a file).";
  const dropMessage = dropOrRejectText;

  if (dragActiveInWindow || dataKind === "none") {
    return (
      <div {...{ onDrop }}>
        <div {...getRootProps({ className })} {...{ onClick }}>
          <input {...getInputProps()} />
          <p className={classes.dropText}> {dropMessage} </p>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
