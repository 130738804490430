import { identityFn } from "../util/Utils";
import { DateFormat, DateParser, detectDate, getDateParser } from "./DetectDateFormat";
import {
  detectNumber,
  getNumberParser,
  NumberFormat,
  NumberParser,
} from "./DetectNumberFormat";

export type CellFormat =
  | NumberCellFormat
  | DateCellFormat
  | StringCellFormat
  | JsonNumberCellFormat;
export type ParseDataFormat = CellFormat | EmptyCellFormat;
export type DataSubtype = NumberFormat | DateFormat;
export type CellParseResultType = "string" | "number";
export type ParseDataType = "string" | "number" | "date" | "jsonNumber" | "empty";

export interface CellFormatBase {
  parseType: ParseDataType;
}

export interface NumberCellFormat extends CellFormatBase {
  parseType: "number";
  formatSubtype: NumberFormat;
}

export interface StringCellFormat extends CellFormatBase {
  parseType: "string";
}

export interface DateCellFormat extends CellFormatBase {
  parseType: "date";
  formatSubtype: DateFormat;
}

export interface JsonNumberCellFormat extends CellFormatBase {
  parseType: "jsonNumber";
}

export interface EmptyCellFormat {
  parseType: "empty";
}

export type ParseType = CellFormatBase["parseType"];

export type CellParser =
  | NumberFormatParser
  | DateFormatParser
  | StringFormatParser
  | JsonNumberParser;

interface CellParserBase {
  parseResultType: CellParseResultType;
}

export interface NumberFormatParser extends NumberCellFormat, CellParserBase {
  parser: NumberParser;
  parseResultType: "number";
}

export interface StringFormatParser extends StringCellFormat, CellParserBase {
  parser: (text: string) => string;
  parseResultType: "string";
}

export interface DateFormatParser extends DateCellFormat, CellParserBase {
  parser: DateParser;
  parseResultType: "number";
}

export interface JsonNumberParser extends CellParserBase {
  parseType: "jsonNumber";
  parser: (n: number) => number;
  parseResultType: "number";
}

const stringDataFormat: CellFormat = {
  parseType: "string",
};

const emptyDataFormat: EmptyCellFormat = { parseType: "empty" };

/** guess at the best parsing format for a single table cell */
export function detectCellFormat(text: string): ParseDataFormat {
  if (text === undefined || text === "") return emptyDataFormat;

  const dateFormat = detectDate(text);
  if (dateFormat) {
    return {
      parseType: "date",
      formatSubtype: dateFormat,
    };
  }

  const numberFormat = detectNumber(text);
  if (numberFormat) {
    return {
      parseType: "number",
      formatSubtype: numberFormat,
    };
  }

  return stringDataFormat;
}

/** @return a parser for the given DataFormat. */
export function dataFormatParser(format: CellFormat): CellParser {
  if (format.parseType === "number") {
    const parser = getNumberParser(format.formatSubtype);
    return { ...format, parser, parseResultType: "number" };
  } else if (format.parseType === "date") {
    const parser = getDateParser(format.formatSubtype);
    return {
      ...format,
      parser,
      parseResultType: "number",
    };
  } else {
    return {
      parseType: "string",
      parser: identityFn,
      parseResultType: "string",
    };
  }
}

export function fullDataFormatName(format: CellFormat): string {
  const subtype = (format as NumberCellFormat).formatSubtype;
  const subtypeName = subtype ? `:${subtype}` : "";
  return format.parseType + subtypeName;
}
