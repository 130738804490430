import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { dLog } from "./DebugLog";

/** return value from previous render */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}

/** return value from previous invocation */
export function usePreviousDirect<T>(value: T): T | undefined {
  const ref = useRef<T>();

  const prev = ref.current;
  ref.current = value;

  return prev;
}

export type ForwardRef =
  | ((instance: unknown) => void)
  | React.MutableRefObject<unknown>
  | null;

export function setParentRef<T>(parentRef: ForwardRef, elem: T): void {
  if (parentRef) {
    if (_.isFunction(parentRef)) {
      parentRef(elem);
    } else {
      (parentRef as any).current = elem;
    }
  }
}

/**
 * Report which values have changed since last time.
 *
 * debug utility to check which props or hooks have changed since last time and
 * are causing a re-render.
 */
export function useCheckChange(values: Record<string, unknown>): void {
  for (const key in values) {
    const value = values[key];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const old = usePreviousDirect(value);
    if (old !== value) {
      dLog("changed", { key, old, value });
    }
  }
}

/** Return a function that returns true if the component is mounted. */
export function useIsMounted(): () => boolean {
  const mounted = useRef(true);

  useEffect(() => {
    return function cleanup(): void {
      mounted.current = false;
    };
  }, []);

  const isMounted = useCallback(() => mounted.current, []);
  return isMounted;
}
