/** a promise with the resolve and reject functions exposed externally */
export interface Deferred<T> {
  resolve: (d: T) => void;
  reject: (e: any) => void;
  promise: Promise<T>;
}

/** a promise with the resolve and reject functions exposed externally */
export function deferred<T>(): Deferred<T> {
  let resolve: (d: T) => void = () => {};
  let reject: (e: any) => void = () => {};
  const promise = new Promise<T>((resolve_, reject_) => {
    reject = reject_;
    resolve = resolve_;
  });
  return { promise, resolve, reject };
}
