import { FrameAndSet } from "../data/ColumnFrame";
import { UnaryFn } from "../math/SplineMath";
import { identityFn } from "../util/Utils";

/** Scale the time data so that it more fully utilizes the range of 32 bit floats.
 * Note that linear scaling of the time values results in the same graph as the unscaled data,
 * but with reduced jitter from resolution artifacts.
 * (The plot's axes are handled at a higher level, unchanged by this transformation.)
 */
export function NormalizeX(frameAndSet: FrameAndSet, disable?: boolean): UnaryFn {
  if (disable) {
    return identityFn;
  }

  const { frame, columnSet } = frameAndSet;
  const [start, end] = frame.xExtent(columnSet?.xColumn),
    extent = end - start,
    mid = start + extent / 2;

  if (extent === 0) {
    return identityFn;
  }

  /** scale to a range from approx min 32 bit float to max 32 bit float.
   * This maximizes our ability to zoom in without jitter.
   */
  return function (x: number) {
    return ((x - mid) / extent) * 2 ** 64;
  };
}
