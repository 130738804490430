import _ from "lodash";
import { Regl, Texture2D } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { debugBucketStats, debugPercentile } from "./Debug";
import { frameReduce, FrameReduceOptions, ReduceResult } from "./FrameReduce";
import { InitialBucketStats } from "./InitialBucketStats";
import { textureFB } from "./TextureFB";
import { FullFB } from "./WebGLUtil";

export type PercentileOperation = "percentile" | "percentileG";

export interface FramePercentileResult {
  percentileCmd: (percentage: number | undefined) => void;

  /** a 1x1 pixel texture containing min,max range that bracket the target percentile value
   * min is in the red channel, and max is in the green channel */
  percentileFB: FullFB;
}

export interface FramePercentileOptions {
  percentilePasses?: number;
  debug?: ChartDebug;
}

/** Calculate a percentile value from a density field. e.g. the 99th percentile largest
 * value of any pixel.
 *
 * Uses a multipass bucket sort with 24 buckets divided equally across the range of values.
 * The first pass divides the entire range of values (based on a previous nzStats
 * calculation of the max value). Subsequent passes use a narrower range by using buckets
 * only within the target bucket range from the previous pass.
 *
 * @param src density is collected from the red channel of this framebuffer
 */
export function FramePercentile(
  regl: Regl,
  src: FullFB,
  nzTexture: Texture2D,
  operation: PercentileOperation,
  options: FramePercentileOptions = {}
): FramePercentileResult {
  const { debug, percentilePasses = 1 } = options;
  const {
    fb: stats0FB,
    texture: stats0,
    initBucketStats,
  } = InitialBucketStats(regl, nzTexture);

  const { runPasses, stats } = setupPasses(
    regl,
    src,
    operation,
    percentilePasses,
    stats0,
    debug
  );

  function framePercentile(percentage: number | undefined): void {
    initBucketStats({ percentage });
    debug?.logPercentileStats &&
      !operation.includes("G") &&
      debugBucketStats(regl, stats0FB, `${operation}-initial`);
    runPasses();
  }

  return { percentileCmd: framePercentile, percentileFB: stats };
}

interface PercentilePassResult {
  result: ReduceResult;
  statsTexture: Texture2D;
  statsFB: FullFB;
}

interface Passes {
  runPasses: () => void;
  stats: FullFB;
}

function setupPasses(
  regl: Regl,
  src: FullFB,
  operation: PercentileOperation,
  passes: number,
  stats0: Texture2D,
  debug?: ChartDebug
): Passes {
  let prevStats = stats0;
  const results = _.times(passes).map((i) => {
    const result = percentilePass(regl, src, operation, prevStats, i);
    prevStats = result.statsTexture;
    return result;
  });

  function runPasses(): void {
    results.forEach((pass, i) => {
      pass.result.reduce();
      debugLogging(pass, i);
    });
  }

  function debugLogging(pass: PercentilePassResult, i: number): void {
    const buckets = debug?.logPercentileBuckets;
    const stats = debug?.logPercentileStats;
    if (!operation.includes("G") && (buckets || stats)) {
      const label = `${operation}-pass-${i + 1}`;
      buckets && debugPercentile(regl, pass.result.debugLastFBs, label);
      stats && debugBucketStats(regl, pass.statsFB, label);
    }
  }

  const stats = _.last(results)?.statsFB as FullFB;
  return { runPasses, stats };
}

/**
 * A single reduction pass to bucket all the pixel density values into 24 buckets
 *
 * When run, the returned statsTexture will contain the bucket stats, including
 * the min,max boundaries of the bucket containing the target percentile value.
 */
function percentilePass(
  regl: Regl,
  src: FullFB,
  operation: PercentileOperation,
  prevStats: Texture2D,
  pass: number
): PercentilePassResult {
  const [stats, statsFB] = textureFB(regl, [1, 1]);
  const reduceOpts: FrameReduceOptions = {
    uniforms: { bucketStats: prevStats },
    numTextures: 6, // regl fails with 7, bug?
    extraFinalTexture: stats,
    passName: `-pass${pass}`,
  };
  const result = frameReduce(regl, src, operation, reduceOpts);
  return { result, statsTexture: stats, statsFB };
}

/*
data structures: 
. bucketStats: an 1 pixel rgba texture containing info about the bucket search
  . r = min. values in the selected bucket are >= this value
  . g = max. values in the selected bucket are < this value
  . b = count. total number of elements < min (sorted into other buckets in this pass, or sorted in previous passes)
  . a = percentileNth. the element we're searching for. (This value is propagated unchanged through the bucket search)
. buckets: 6 rgba textures containing count. 4 buckets per texture for total of 24 buckets

setup: Read stats from nzStats and write to bucketStats. (InitialBucketStats)
  . min=0,max from nzStats
  . percentileNth from nzStats (e.g. 4231st element is at p95)

Vn: all vertex shaders
  . read min,max for this pass from bucketStats texture, emit as varying

Fn: all fragment shaders (except last fragment shader)
  . get min,max from varyings
  . update bucket counts

Fl: last frag shader in each pass as above, plus:
  . calculate next min/max, update count. 
  . fetch percentileNth from bucktStats texture
  . emit into new bucketStats texture
*/
