import React from "react";
import { DensityPlotData, isDensityPlotKind } from "../../chart/PlotModels";
import { DashId } from "../../store/DashId";
import { useStoreState } from "../../store/Hooks";
import { plottableSource } from "../../store/TableSource";
import { ColorStrip } from "./ColorStrip";
import { DensityAxis, DensityThresholds } from "./DensityAxis";

export interface DensityLegendProps extends DensityThresholds {
  dashId: DashId;
}

export interface VerticalMargin {
  top: number;
  bottom: number;
}

export interface LegendMargin {
  legendMargin: VerticalMargin;
}

// space for axis text to extend above and below strip
const legendMargin: VerticalMargin = { top: 8, bottom: 8 };

/** display a color swatch for the colors for each density value. */
export const DensityLegend = React.memo(DensityLegendInternal);

function DensityLegendInternal(props: DensityLegendProps): JSX.Element {
  const { dashId, min, max, threshold, brightness } = props;
  const dragActive = useStoreState((app) => app.view.dragActive);
  const plotKind = useStoreState((app) => app.findChart(dashId)?.plot.kind);
  const plottable = useStoreState((app) => {
    const kind = app.findChart(dashId)?.tableSource.kind;
    return plottableSource(kind);
  });
  const publishDensityScale = useStoreState(
    (app) => (app.findChart(dashId)?.plot as DensityPlotData)?.publishDensityScale
  );
  const axisProps = { min, max, threshold, brightness, legendMargin };

  if (
    dragActive ||
    !plottable ||
    !isDensityPlotKind(plotKind) ||
    !publishDensityScale ||
    brightness === 0
  ) {
    return <></>;
  }

  return (
    <>
      <ColorStrip legendMargin={legendMargin} />
      <DensityAxis {...axisProps} />
    </>
  );
}
