import { Dialog } from "@material-ui/core";
import React from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { DialogContent, DialogTitle } from "../misc/DialogTitle";
import { SelectDataSources } from "./SelectDataSources";

export function DataDialog(): JSX.Element {
  const open = useStoreState((state) => state.view.dataDialogOpen);
  const setOpen = useStoreActions((actions) => actions.view.setDataDialogOpen);

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Data
      </DialogTitle>
      <DialogContent dividers>
        <SelectDataSources />
      </DialogContent>
    </Dialog>
  );
}
