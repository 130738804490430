import React, { CSSProperties } from "react";
import { DashId } from "../../store/DashId";
import { useStoreState } from "../../store/Hooks";
import { DashChart } from "../rich-chart/DashChart";
import { DashText } from "../text/DashText";
import { ResizeContext } from "./ResizeContext";

interface DashboardItemProps {
  dashId: DashId;
  resizeCount?: number;
  style?: CSSProperties;
  className?: string;
  key?: string | number | null;
}

export function DashboardItem(props: DashboardItemProps): JSX.Element {
  const { style, className, dashId, resizeCount = 0 } = props;
  const dashKind = useStoreState((app) => app.findDashItem(dashId)?.kind);

  if (dashKind === "Chart") {
    return (
      <ResizeContext.Provider value={resizeCount}>
        <DashChart {...{ dashId, className, style }} />
      </ResizeContext.Provider>
    );
  } else if (dashKind === "Text") {
    return <DashText {...{ dashId }} />;
  } else {
    return <></>;
  }
}

export const growEventName = "grow-dash-item";
export interface GrowEventDetails {
  dashId: DashId;
  lines: number;
}
