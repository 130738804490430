import _ from "lodash";
import * as math from "mathjs";
import { matrix, Matrix, multiply, transpose } from "mathjs";

export function arrayString(s: string): number[][] {
  const lines = s.trim().split("\n");
  const arr = lines.map((line) => {
    return line
      .trim()
      .split(/\s+/)
      .map((s) => math.evaluate(s));
  });
  return arr;
}

export function matrixString(s: string): Matrix {
  const array = arrayString(s);
  return matrix(array);
}

/** Return a toeplitz matrix for a convolution operation */
export function toeplitzConvolution(vector: number[], extraColumns: number): Matrix {
  const vectorLength = vector.length;
  const columnLength = vectorLength * 2 - 1 + extraColumns;
  const zeroColumn = _.times(columnLength, () => 0);
  const columns = _.times(vectorLength + extraColumns, (i) => {
    const column = zeroColumn.slice();
    column.splice(i, vectorLength, ...vector);
    return column;
  });
  const mat = transpose(matrix(columns)) as Matrix;
  return mat;
}

export function convolve1D(a: number[], b: number[]): number[] {
  let vec;
  let convolveVec: number[];
  if (a.length <= b.length) {
    convolveVec = a;
    vec = b;
  } else {
    convolveVec = b;
    vec = a;
  }
  const extraColumns = vec.length - convolveVec.length;
  const mat = toeplitzConvolution(convolveVec, extraColumns);
  const result = multiply(mat, vec) as Matrix;
  const resultArray = result.toArray() as number[];
  return resultArray;
}
