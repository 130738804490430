import _ from "lodash";
import { ReactNode, useState } from "react";
import { CellProps, Column as TableColumn, Renderer } from "react-table";
import { ColumnFrame, DataType } from "../../data/ColumnFrame";
import { DashId } from "../../store/DashId";
import { useStoreState } from "../../store/Hooks";
import { TableSource } from "../../store/TableSource";
import { frameRowsAndColumns } from "./FrameRowsAndColumns";
import { localRowsAndColumns } from "./LocalRowsAndColumns";

export interface SimpleRow {
  values: (number | string)[];
}

export interface RowsAndColumns {
  rows: SimpleRow[];
  columns: TableColumn<SimpleRow>[];
}

/** A cached key value pair with asyncrhonous value computation.
 *
 * A value is always returned synchronously.  If the key is out of date, the old
 * value is returned initially. A new value is then loaded asynchronously and
 * the stored in local state to trigger a react re-render that will return the new value.
 */
function useCachedPromiseFn<V, P>(
  initialParam: P,
  initialValue: V
): (param: P, fn: () => Promise<V>) => V {
  const [current, setCurrent] = useState(initialValue);
  const [requested, setRequested] = useState(initialParam);

  function check(param: P, fn: () => Promise<V>): V {
    if (!_.isEqual(param, current) && !_.isEqual(param, requested)) {
      setRequested(param);
      fn().then(setCurrent);
    }

    return current;
  }

  return check;
}

interface FrameAndSource {
  columnFrame: ColumnFrame | undefined;
  tableSource: TableSource | undefined;
}

const emptyFrameAndSource: FrameAndSource = {
  columnFrame: undefined,
  tableSource: undefined,
};

const emptyRowsAndColumns: RowsAndColumns = {
  rows: [],
  columns: [],
};

/** @return column descriptions and the first few rows of data for display with react-table.
 *
 * If the data is from a "generated" source, the rows and columns are fetched
 * from the provided columnFrame.
 * If the data is from a "local" stored source, the rows and columns are fetched
 * from the local indexdb store. Using the store allows the sheet to show
 * columns beyond those already selected for use in the columnFrame.
 */
export function useRowsAndColumns(
  dashId: DashId | undefined,
  columnFrame: ColumnFrame
): RowsAndColumns {
  const checkCurrent = useCachedPromiseFn(emptyFrameAndSource, emptyRowsAndColumns);
  const tableSource = useStoreState(
    (app) => dashId && app.findChart(dashId)?.tableSource
  );
  const param: FrameAndSource = { columnFrame, tableSource };

  return checkCurrent(param, loadRowsAndColumns);

  function loadRowsAndColumns(): Promise<RowsAndColumns> {
    if (tableSource?.kind === "local") {
      return localRowsAndColumns(tableSource.tableId);
    } else {
      return frameRowsAndColumns(columnFrame);
    }
  }
}

export function cellRenderer(displayType: DataType): Renderer<CellProps<SimpleRow>> {
  if (displayType === "number") {
    return renderNumberCell;
  } else if (displayType === "date") {
    return renderDateCell;
  } else {
    return renderStringCell;
  }
}

const numberFormat = Intl.NumberFormat(undefined, { maximumFractionDigits: 4 });
function renderNumberCell(props: CellProps<SimpleRow>): ReactNode {
  return numberFormat.format(props.value);
}

const dateFormat = Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "medium",
} as any);

function renderDateCell(props: CellProps<SimpleRow>): ReactNode {
  if (isNaN(props.value)) {
    return "-";
  } else {
    return dateFormat.format(props.value);
  }
}

function renderStringCell(props: CellProps<SimpleRow>): ReactNode {
  return props.value.toString();
}
