import _ from "lodash";
import { deferred, Deferred } from "../util/Deferred";
import { float64SequenceGenerator } from "../util/FloatArrayUtil";
import { zip2tuple } from "../util/Utils";
import { ColumnId, DataType } from "./ColumnFrame";
import { ColumnChunk, StorageType } from "./DataChunk";
import { dbStore, indexColumnId, StoredChunk, StoredColumn, TableId } from "./DbStore";

/** structure to report info about a chunk */
export interface ChunkWithId {
  chunk: ColumnChunk;
  columnId: ColumnId;
}

const watchingFloatColumns = new Map<ColumnId, Array<Deferred<Float64Array>>>();

export async function* watchFloatColumn(
  columnId: ColumnId,
  maxRows?: number
): AsyncGenerator<Float64Array, void, unknown> {
  if (columnId === indexColumnId) {
    for (const array of float64SequenceGenerator(maxRows)) {
      yield array;
    }
  }

  const column = await getColumnInfo(columnId);
  const first = await getFloatColumnInternal(column, maxRows);
  yield first;
  if (maxRows && first.length >= maxRows) {
    return;
  }
  for (;;) {
    yield await addColumnWatch(columnId);
  }
}

function addColumnWatch(columnId: ColumnId): Promise<Float64Array> {
  let array = watchingFloatColumns.get(columnId);
  if (!array) {
    array = [];
    watchingFloatColumns.set(columnId, array);
  }
  const waiter = deferred<Float64Array>();
  array.push(waiter);
  return waiter.promise;
}

/** return a portion of column data, starting at the beginning, no more than maxRows elements */
export async function columnSection(
  columnId: ColumnId,
  maxRows?: number
): Promise<string[] | Float64Array> {
  const column = await getColumnInfo(columnId);
  const { storageType } = column;
  if (storageType === "string") {
    return getStringColumnInternal(column, maxRows);
  } else if (storageType === "float64") {
    return getFloatColumnInternal(column, maxRows);
  } else {
    throw new Error(`unexpected storage type ${storageType}`);
  }
}

/** create columns in a table */
export async function addColumns(
  tableId: TableId,
  columnChunks: ColumnChunk[]
): Promise<ColumnId[]> {
  const added = columnChunks.map((chunk) => {
    const { storageType, displayType, label } = chunk;

    return addColumn(tableId, label, storageType, displayType);
  });
  return Promise.all(added);
}

export async function findOneColumnByLabel(
  tableId: TableId,
  label: string
): Promise<ColumnId> {
  const found = await dbStore.columns
    .where("tableId")
    .equals(tableId)
    .and((col) => col.label === label)
    .first();
  if (found) {
    return found.id as ColumnId;
  } else {
    return Promise.reject(`col ${label} not found in table ${tableId}`);
  }
}

/* Find columns in a table */
export async function findColumnsByLabel(
  tableId: TableId,
  chunks: ColumnChunk[]
): Promise<ChunkWithId[]> {
  const cols = await dbStore.columns.where("tableId").equals(tableId).toArray();
  const matched: ChunkWithId[] = [];
  for (const chunk of chunks) {
    const found = cols.find((col) => col.label === chunk.label);
    if (found) {
      matched.push({ chunk, columnId: found.id! });
    } else {
      return Promise.reject(`col ${chunk.label} not found in table ${tableId}`);
    }
  }
  return matched;
}

/** @return info about a column, including its name, size and data type */
export async function getColumnInfo(columnId: ColumnId): Promise<StoredColumn> {
  const promisedCol = dbStore.columns.get(columnId);
  return promisedCol.then((col) => {
    if (!col) {
      return Promise.reject(new Error(`columnId ${columnId} not found`));
    }
    return col;
  });
}

export type BasicColumnInfo = Pick<StoredColumn, "label" | "displayType" | "storageType">;

/** @return basic information about a column, works on virtual index column too */
export async function getBasicColumnInfo(columnId: ColumnId): Promise<BasicColumnInfo> {
  if (columnId === indexColumnId) {
    return { label: "#", displayType: "number", storageType: "float64" };
  } else {
    return getColumnInfo(columnId);
  }
}

export async function deleteColumn(columnId: ColumnId): Promise<void> {
  return dbStore.transaction("rw", dbStore.chunks, dbStore.columns, async () => {
    await deleteChunks(columnId);
    await dbStore.columns.delete(columnId);
  });
}

/** Append chunks of data to their columns */
export async function addColumnChunks(chunks: ChunkWithId[]): Promise<void> {
  await dbStore.transaction("rw", dbStore.chunks, dbStore.columns, async () => {
    const added = chunks.map(addChunk);
    return Promise.all(added).then();
  });

  notifyWatchers(chunks);
}

/** notify observers of this column that data has been aded */
function notifyWatchers(chunks: ChunkWithId[]): void {
  for (const chunkAndId of chunks) {
    const { chunk, columnId } = chunkAndId;
    if (chunk.storageType === "float64") {
      const data: Float64Array = chunk.data;
      const watchers = watchingFloatColumns.get(columnId);
      if (watchers) {
        watchers.forEach((w) => w.resolve(data));
        watchers.length = 0;
      }
    }
  }
}

export async function addChunksNewColumns(
  tableId: TableId,
  chunks: ColumnChunk[]
): Promise<ColumnId[]> {
  // TODO transaction
  const columnIds = await addColumns(tableId, chunks);

  const chunksWithIds = zip2tuple(chunks, columnIds).map(([chunk, columnId]) => ({
    chunk,
    columnId,
  }));

  await addColumnChunks(chunksWithIds);
  return columnIds;
}

/** add one new column to the table */
async function addColumn(
  tableId: TableId,
  label: string,
  storageType: StorageType,
  displayType: DataType
): Promise<ColumnId> {
  return dbStore.columns
    .add({ tableId, label, storageType, displayType })
    .then((id) => id as ColumnId);
}

/** add a chunk of data to the store and update column length */
async function addChunk(chunkWithId: ChunkWithId): Promise<void> {
  const { columnId, chunk } = chunkWithId;
  const { data } = chunk;

  const column = await dbStore.columns.get(columnId);
  if (!column) {
    return Promise.reject(`columnId ${columnId} not found`);
  }
  const currentSize = column.size || 0;

  const added = dbStore.chunks.add({
    columnId,
    data,
    startRow: currentSize + 1,
  });

  const newSize = currentSize + data.length;
  const sized = dbStore.columns.update(columnId, { size: newSize });

  return Promise.all([added, sized]).then(() => {});
}

async function deleteChunks(columnId: ColumnId): Promise<void> {
  // TODO transaction
  const chunks = await dbStore.chunks.where("columnId").equals(columnId).toArray();
  for (const chunk of chunks) {
    if (chunk.id) {
      await dbStore.chunks.delete(chunk.id);
    }
  }
}

/** exposed for testing */
export async function storedChunksInternal(columnId: ColumnId): Promise<StoredChunk[]> {
  return await dbStore.chunks.where("columnId").equals(columnId).toArray();
}

async function getFloatColumnInternal(
  column: StoredColumn,
  maxRows?: number
): Promise<Float64Array> {
  const currentSize = column.size || 0;
  const size = maxRows ? Math.min(currentSize, maxRows) : currentSize;
  const buffer = new Float64Array(size);

  let offset = 0;
  let err: Promise<any> | undefined = undefined;
  const chunks = dbStore.chunks.where("columnId").equals(column.id!);
  await chunks
    .until((chunk) => {
      const { data } = chunk;
      if (data instanceof Float64Array) {
        const { length } = chunk.data;
        if (length + offset <= size) {
          // add entire block
          buffer.set(data, offset);
          offset += length;
          return false;
        } else {
          // add partial block
          const remaining = size - offset;
          const dataStart = data.subarray(0, remaining);
          buffer.set(dataStart, offset);
          offset += remaining;
          console.assert(offset === size);
          return true;
        }
      } else {
        err = Promise.reject(
          new Error(`column: ${column.id} is not numeric: [${_.take(data, 2)}]`)
        );
        return true;
      }
    })
    .each(() => {});

  return err || buffer;
}

async function getStringColumnInternal(
  column: StoredColumn,
  maxRows?: number
): Promise<string[]> {
  const currentSize = column.size || -2;
  const size = maxRows ? Math.min(currentSize, maxRows) : currentSize;
  const buffer: string[] = [];
  const chunkCollection = await dbStore.chunks.where("columnId").equals(column.id!);

  let err: undefined | Promise<any> = undefined;

  await chunkCollection
    .until((chunk) => {
      const { data } = chunk;
      if (data instanceof Array) {
        if (data.length + buffer.length <= size) {
          // add entire block
          buffer.push(...data);
          return buffer.length === size;
        } else {
          // add partial block
          const remaining = size - buffer.length;
          buffer.push(...data.slice(0, remaining));
          console.assert(buffer.length === size);
          return true;
        }
      } else {
        err = Promise.reject(
          new Error(`column: ${column.id} is not string: [${_.take(data, 1)}]`)
        );
        return true;
      }
    })
    .each(() => {});

  return err || buffer;
}
