import { Column as TableColumn } from "react-table";
import { letterIndex } from "../../csv/LetterIndex";
import { Column as FrameColumn, ColumnArray, ColumnFrame } from "../../data/ColumnFrame";
import { dlog } from "../../util/DebugLog";
import { transpose } from "../../util/Utils";
import { cellRenderer, RowsAndColumns, SimpleRow } from "./UseRowsAndColumns";

/** @return the column info and the first few rows of data (for use in react-table) */
export async function frameRowsAndColumns(frame: ColumnFrame): Promise<RowsAndColumns> {
  const frameColumns = frame.columns();

  const rows = tableRows(frameColumns);
  const columns = tableColumns(frameColumns);

  return {
    rows,
    columns,
  };
}

function tableColumns(
  frameColumns: FrameColumn<ColumnArray>[]
): TableColumn<SimpleRow>[] {
  return frameColumns.map((col, idx) => {
    const { name, columnId } = col;
    const tCol: TableColumn<SimpleRow> = {
      Header: name || letterIndex(idx),
      accessor: (row) => row.values[idx],
      id: columnId!.toString(),
      Cell: cellRenderer(col.displayType),
    };
    (tCol as any).columnId = columnId;
    return tCol;
  });
}

function tableRows(
  frameColumns: FrameColumn<ColumnArray>[],
  start = 0,
  length = 100
): SimpleRow[] {
  const cols = frameColumns.map((col) => col.data.slice(start, start + length));
  const rows = transpose(cols as number[][]);
  if (!rows) {
    dlog("uh oh, rows not evenly sized");
    return [];
  }
  const simpleRows = rows.map((row) => ({ values: row }));

  return simpleRows;
}
