import { IconButton, makeStyles } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useCallback } from "react";
import { useStoreActions } from "../../store/Hooks";
import { HelpButton } from "../help/HelpButton";

const sheetHelp = (
  <div>
    <h3>Data</h3>
    <dl>
      <dt>Load Data</dt>
      <dd>
        Load data into Dashberry by dragging and dropping a .csv or .tsv file onto a
        chart. (A variety of number and date formats are supported. Please let me know if
        the Dashberry doesn’t handle your file correctly.)
      </dd>
      <dt>Data Security</dt>
      <dd>
        Data you add to Dashberry is stored persistently on your computer, in your
        browser. Data you add to Dashberry does not leave your computer.
      </dd>
      <dt>Columns</dt>
      <dd>Click on the column headers to choose which columns to plot.</dd>
      <dt>Max Rows</dt>
      <dd>
        For large data sets, limit the plot to using the first n rows of data. For
        generated data, set the number of generated rows.
      </dd>
    </dl>
  </div>
);

const useStyles = makeStyles({
  buttons: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});

export function SheetButtons(): JSX.Element {
  const classes = useStyles();
  const setSheet = useStoreActions((app) => app.view.setSheet);
  const closeSheet = useCallback(() => setSheet(null), [setSheet]);

  return (
    <span className={classes.buttons}>
      <HelpButton help={sheetHelp} />
      <IconButton onClick={closeSheet}>
        <CloseIcon />
      </IconButton>
    </span>
  );
}
