import _ from "lodash";
import REGL, { Regl, Texture2D, Vec4 } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { Rectangle } from "../chart/Rectangle";
import { Vec2 } from "../math/Vec";
import { blendAndProjection, BlendSetupArgs } from "./BlendAndProjection";
import { circleVertices } from "./EndCap";
import * as ReglPerf from "./ReglPerf";
import { ScatterCmdProps } from "./ScatterShader";
import { FullFB } from "./WebGLUtil";

const defaultColor: Vec4 = [62, 12, 83, 255];

export interface ScatterMarksCmdProps extends ScatterCmdProps {
  points: REGL.Buffer;
  numPoints: number;
  color?: Vec4;
  size: number;

  /* 
    Only draw the mark if density under the mark is less than this value. 
    This is to save rendering time by not drawing marks that would be visually buried 
    under the density field. 

    Density under the mark is checked for the 5 pixels in a plus pattern around the center 
    pixel. Checking 5 pixels helps avoid flicker during zooms.

    Checking a larger density field under the mark could make this estimate accurate, but at the 
    cost of more computation time. If we draw too many marks, we waste rendering time. If we draw too
    few marks, the user will see chart background poking through in an area that should be covered with marks.
    Better to draw too many marks.

    Note that the density measured here comes after blur filtering (but the density is not normalized 
    to [0,1], nor scaled for brightness.) So a two lonely points atop each other will have a 
    density value of 2.0 with no smoothing, or 0.3 with default smoothing enabled.
  */
  maxDensity?: number;

  /** enable skipping the draw of marks by checking density < maxDensity */
  skipEnabled?: boolean;

  feather?: number;
}

export interface ScatterMarksRenderFn {
  (props: ScatterMarksCmdProps): void;
}

const circleFan = _.memoize((size: number): Vec2[] => {
  const center: Vec2 = [0, 0];
  const circle = circleVertices(size * 3);
  const last = circle[0]; // close the circle by repeating first elem as last elem
  const verts = [center, ...circle, last];
  return verts;
});

interface InternalCmdProps
  extends Pick<ScatterMarksCmdProps, "points" | "numPoints" | "color" | "maxDensity"> {
  circle: Vec2[];
  featheredRadius: number;
  radius: number;
  feather: number;
  skipEnabled: boolean;
}

export interface ScatterMarksArgs {
  regl: Regl;
  densityMap: Texture2D;
  framebuffer: FullFB | null;
  pixelsSize: [number, number];
  plotRect: Rectangle;
  normX: (time: number) => number;
  debug: ChartDebug;
}

/** Draw circles into the target framebuffer.
 *
 * Circles are not drawn if the average density near the circle
 * center is above a threshold.
 */
export function ScatterMarksCmd(args: ScatterMarksArgs): ScatterMarksRenderFn {
  const { regl, densityMap, pixelsSize, plotRect } = args;
  const blendArgs: BlendSetupArgs = { ...args, blendType: "alpha" };
  const baseCmd = blendAndProjection(blendArgs);
  const clipRect = textureClipRect(plotRect, pixelsSize);

  const marksCmd = regl({
    vert: `
      precision highp float;
      attribute vec2 xy;
      attribute vec2 circle;

      uniform sampler2D densityMap;
      uniform mat3 projection;
      uniform vec2 canvasSize;     // size of canvas in pixels.
      uniform float featheredRadius;
      uniform vec2 texelSize;      // size of a pixel in texture coordinates
      uniform bool skipEnabled;    // check density and skip drawing marks in dense regions
      uniform float maxDensity;    // if skip enabled, only draw a circle if the density under the mark is <= this value
      uniform float clipRight;
      uniform float clipLeft;
      uniform float clipTop;
      uniform float clipBottom;

      varying float distToCenter;  // distance in canvas pixels from center

      bool checkSkip(vec2 sampleSpot) {
        if (!skipEnabled) {
          return false;
        }

        // note: sampleSpot doesn't perfectly match the values written in ScatterShader
        // (perhaps rounding error with webgl to texture coordinate conversion?)
        // sampleSpot += halfPixelTex; // this doesn't fix the alignment

        vec2 uc = vec2(0.0, 1.0);
        vec2 cr = vec2(1.0, 0.0);
        vec2 lc = vec2(0.0, 1.0);
        vec2 cl = vec2(-1.0, 0.0);
        float dc = texture2D(densityMap, sampleSpot).r;
        float duc = texture2D(densityMap, sampleSpot + (texelSize * uc)).r;
        float dcr = texture2D(densityMap, sampleSpot + (texelSize * cr)).r;
        float dlc = texture2D(densityMap, sampleSpot + (texelSize * lc)).r;
        float dcl = texture2D(densityMap, sampleSpot + (texelSize * cl)).r;

        bool skipMark = false;
        if (dc > maxDensity 
          && duc > maxDensity 
          && dcr > maxDensity 
          && dlc > maxDensity 
          && dcl > maxDensity) {
            skipMark = true;
        } 
        return skipMark;
      }

      void main() {
        vec4 discardPosition = vec4(-1000.0, -1000.0, -1000.0, 1.0);
        vec3 spot = projection * vec3(xy, 1.0); // center in webgl coordinates [-1,1]
        vec2 sampleSpot = (spot.xy + vec2(1.0)) / 2.0; // center in texture coordinates [0,1]
        bool skipMark = checkSkip(sampleSpot);

        if (skipMark) {
          gl_Position = discardPosition;
        } else if (sampleSpot.x > clipRight || sampleSpot.x < clipLeft) {
          gl_Position = discardPosition;
        } else {  
          vec2 fromCenter = circle.xy * featheredRadius;
          vec2 circleVert = (fromCenter / canvasSize) * 2.0; // vert offset in webgl coords
          spot.xy += circleVert;

          if (circle.xy == vec2(0.0)) {
            distToCenter = 0.0;
          } else {
            distToCenter = featheredRadius;
          }

          gl_Position = vec4(spot.xy, 0.0, 1.0);
        }
      }`,

    frag: `
      precision highp float;

      uniform vec4 color;
      uniform float feather;
      uniform float radius;

      varying float distToCenter;

      varying vec4 debugColor;

      void main() {
        float blend = 1.0;
        if (distToCenter > radius) {
          blend -= (distToCenter - radius) / feather;
        }
        blend *= color.a;
        gl_FragColor = vec4(color.rgb, blend);
      }`,
    primitive: "triangle fan",
    count: (_ctx: any, props: InternalCmdProps) => props.circle.length,
    instances: (_ctx: any, props: InternalCmdProps) => props.numPoints,
    attributes: {
      xy: {
        buffer: (_ctx: any, props: InternalCmdProps) => props.points,
        divisor: 1,
      },
      circle: {
        buffer: (_ctx: any, props: InternalCmdProps) => props.circle,
        divisor: 0,
      },
    },
    uniforms: {
      color: (_ctx, props: InternalCmdProps) => props.color,
      radius: (_ctx, props: InternalCmdProps) => props.radius,
      featheredRadius: (_ctx, props: InternalCmdProps) => props.featheredRadius,
      feather: (_ctx, props: InternalCmdProps) => props.feather,
      maxDensity: (_ctx, props: InternalCmdProps) => props.maxDensity,
      skipEnabled: (_ctx, props: InternalCmdProps) => props.skipEnabled,
      texelSize: pixelsSize.map((c) => 1.0 / c),
      canvasSize: pixelsSize,
      densityMap,
      clipLeft: clipRect.left,
      clipRight: clipRect.right,
      clipTop: clipRect.top,
      clipBottom: clipRect.bottom,
    },
  });

  const draw = (props: ScatterMarksCmdProps): void => {
    const radius = props.size / 2;
    const feather = props.feather || 0;
    const featheredRadius = radius + feather;
    const cssColor = props.color || defaultColor;
    const glColor = cssColor.map((byte) => byte / 255) as Vec4;
    const { points, numPoints, skipEnabled = true, maxDensity = 1 } = props;

    const internalProps: InternalCmdProps = {
      circle: circleFan(props.size),
      points,
      numPoints,
      radius,
      feather,
      featheredRadius,
      color: glColor,
      maxDensity,
      skipEnabled,
    };
    baseCmd(props, () => marksCmd(internalProps));
  };

  ReglPerf.registerCmd(marksCmd, "scatter-marks");

  return draw;
}

/** given a rectangle in canvas coordinates,
 * @return a rectangle in texture coordinates [0,1], [0,1]
 * @param plotRect rectangle in coordinates [0, canvasWidth], [0, canvasHeight]
 */
function textureClipRect(plotRect: Rectangle, canvasSize: Vec2): Rectangle {
  // LATER - why are we clipping in texture coordinates rather than webgl coordinates?
  const left = plotRect.left / canvasSize[0];
  const right = plotRect.right / canvasSize[0];
  const top = plotRect.top / canvasSize[1];
  const bottom = plotRect.bottom / canvasSize[1];

  return {
    top,
    left,
    right,
    bottom,
  };
}
