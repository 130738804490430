import * as d3 from "d3";
import { AnyTransition, SvgGSelection, SvgSVGSelection } from "../util/d3Util";
import { ChartState } from "./Chart";
import { PlotArea } from "./plotArea";

export interface Brush {
  draw: (transition: AnyTransition) => void;
}

export function createBrushContainer(plotArea: PlotArea): SvgGSelection {
  return plotArea.area.append("g").attr("class", "brush").attr("data-qa", "brush");
}

export function brushBehavior(svg: SvgSVGSelection, brushElem: SvgGSelection): Brush {
  const state: ChartState = svg.datum();
  const { api } = state;
  const { plotRect } = state.plotArea;

  const brush = d3
    .brushX()
    .filter(noMetaKeys)
    .extent([
      [plotRect.left, plotRect.top],
      [plotRect.right, plotRect.bottom],
    ])
    .on("end", brushed);

  brushElem.call(brush).on("dblclick", brushReset);

  return {
    draw,
  };

  function brushed(event: any): void {
    const { zooming } = state.scales;
    if (event.selection === null) {
      return;
    }
    const selectedExtent = event.selection || zooming.x.range();
    api.zoomTo(selectedExtent, 400);
  }

  function draw(transition: AnyTransition): void {
    const { zooming } = state.scales;
    const brushRect = brushElem.select(".brush > rect.selection");
    if (!isDisplayed(brushRect.node() as Element)) {
      return;
    }
    // base x1,x2 in current zoom coordinates
    const b1 = parseFloat(brushRect.attr("x"));
    const b2 = b1 + parseFloat(brushRect.attr("width"));

    // base u1,u2 in unzoomed coordinates
    const [u1, u2] = [b1, b2].map(zooming.x.invert);

    const rectTransition = brushRect.transition(transition);
    rectTransition.tween("brush-rect", () => () => {
      const x = zooming.x(u1)!;
      const width = zooming.x(u2)! - x;

      brushRect.attr("x", x).attr("width", width);
    });

    rectTransition
      .end()
      .finally(() => {
        brushElem.call(brush.move, null);
      })
      .catch(() => {});
  }

  function noMetaKeys(event: any): boolean {
    return !event.ctrlKey && !event.button && !event.shiftKey && !event.altKey;
  }

  // function resize(plotSize: Vec2) {
  //   fullSize = plotSize;
  //   brush.extent([[0, 0], plotSize]);
  //   brushElem.call(brush);
  // }

  function brushReset(): void {
    brushElem.call(brush.move, null);
  }
}

function isDisplayed(elem: Element): boolean {
  // TODO this is expensive in the redraw loop for appended data (1.5ms / frame)
  const style = getComputedStyle(elem);

  return style.display !== "none";
}
