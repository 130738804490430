import { IconButton, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback } from "react";
import { DensityPlotData, SummaryId, SummaryStat } from "../../chart/PlotModels";
import { Vec3 } from "../../math/Vec";
import { DashId } from "../../store/DashId";
import { useCurrentPlot, useStoreActions } from "../../store/Hooks";
import { useModalContainer } from "../dash-controls/ModalContainer";
import { ColorButton } from "./ColorButton";
import { LabeledSlider } from "./LabeledSlider";
import { StatFeatherSettings } from "./StatFeatherSettings";
import { percentFormat, StatPercentileSetting } from "./StatPercentileSetting";

const useStyles = makeStyles({
  title: {
    fontSize: 16,
  },
  indent: {
    marginLeft: "2em",
  },
});

export interface OneStatProps {
  dashId?: DashId;
  summaryId: SummaryId;
}

const defaultStatColor: Vec3 = [200, 50, 50];
const defaultPalette = [
  "#CC2222",
  "#FF7A18",
  "#FCCB00",
  "#9acd32",
  "#6b8e23",
  "#008B02",
  "#1E90ff",
  "#003DFF",
  "#808080",
  "#000000",
];

export function OneStatSetting(props: OneStatProps): JSX.Element {
  const { summaryId, dashId } = props;
  const popupState = usePopupState({ variant: "popover", popupId: `stat-${summaryId}` });
  const classes = useStyles();
  const summary = useCurrentPlot((p: DensityPlotData) =>
    p.summaries?.find((s) => s.summaryId === summaryId)
  );
  const thickness = summary?.thickness || 1;
  const modifySummary = useStoreActions((app) => app.modifyPlotSummary);
  const removeSummary = useStoreActions((app) => app.removePlotSummary);
  const container = useModalContainer();

  const setThickness = useCallback(
    (_e, thickness: number): void => {
      if (dashId) {
        modifySummary({ dashId, summaryId, summaryPartial: { thickness } });
      }
    },
    [modifySummary, dashId, summaryId]
  );

  const deleteMe = useCallback(() => {
    popupState.close();
    if (dashId) {
      removeSummary({ dashId, summaryId });
    }
  }, [popupState, removeSummary, dashId, summaryId]);

  const onColorChange = useCallback(
    (color: Vec3) => {
      if (dashId) {
        modifySummary({ dashId, summaryId, summaryPartial: { color } });
      }
    },
    [modifySummary, dashId, summaryId]
  );

  if (summary) {
    const color = summary.color || defaultStatColor;
    const title = summaryTitle(summary);
    return (
      <div>
        <ColorButton {...{ color, onColorChange, colorChoices: defaultPalette }} />
        <span className={classes.title}>{title}</span>
        <IconButton {...bindTrigger(popupState)}>
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          {...bindMenu(popupState)}
          {...{ container }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MenuItem onClick={deleteMe}>Delete</MenuItem>
        </Menu>
        <div className={classes.indent}>
          <LabeledSlider
            label="thickness:"
            min={1}
            max={20}
            value={thickness}
            onChange={setThickness as any}
          />
          <StatPercentileSetting {...props} />
          <StatFeatherSettings {...props} />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

function summaryTitle(summary: SummaryStat): string {
  if (summary.kind === "percentile") {
    const percent = summary.percentile || 0;
    const percentStr = percentFormat(percent);
    return `${percentStr} percentile`;
  } else {
    return summary.kind;
  }
}
