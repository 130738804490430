import fileSaver from "file-saver";
import { transpose } from "../util/Utils";
import { ColumnFrame } from "./ColumnFrame";
import { stringEncoder } from "./DsvStringEncoder";

const suffixes = new Map([
  ["\t", ".tsv"],
  [",", ".csv"],
]);

const mimeTypes = new Map([
  ["\t", "text/tab-separated-values;charset=utf-8"],
  [",", "text/csv;charset=utf-8"],
]);
const defaultMimeType = "text/plain;chartset=utf-8";

/** save a column frame as in csv or tsv format */
export function saveDsv(columnFrame: ColumnFrame, separator = "\t"): void {
  const suffix = getSuffix(columnFrame.name, separator);
  const fileName = columnFrame.name + suffix;
  const headerLine = columnFrame
    .columns()
    .map((col) => col.name || "")
    .join(separator);

  const rowArrays = frameToRows(columnFrame, separator);
  const rowLines = rowArrays.map((row) => row.join(separator));
  const lineArray = [headerLine, ...rowLines];
  const content = lineArray.join("\n");

  const type = getMimeType(separator);
  const blob = new Blob([content], { type });
  fileSaver.saveAs(blob, fileName);
}

function frameToRows(frame: ColumnFrame, separator: string): string[][] {
  const dataColumns = frame.columns().map((col) => col.data) as any[][];
  const encoder = stringEncoder(separator);
  const stringColumns = dataColumns.map((data) => data.map(encoder));
  return transpose(stringColumns)!;
}

function getSuffix(name: string, separator: string): string {
  const suffix = suffixes.get(separator);
  if (!suffix || name.endsWith(suffix)) {
    return "";
  } else {
    return suffix;
  }
}

function getMimeType(separator: string): string {
  return mimeTypes.get(separator) || defaultMimeType;
}
