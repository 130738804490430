import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { ColumnFrame } from "../../data/ColumnFrame";
import { saveDsv } from "../../data/SaveDsv";
import { DashId } from "../../store/DashId";
import { useStoreActions } from "../../store/Hooks";
import { useModalContainer } from "../dash-controls/ModalContainer";

export interface ChartMenuProps {
  dashId: DashId;
  frame: ColumnFrame;
}

export const ChartMenu = React.memo(ChartMenuInternal);

function ChartMenuInternal(props: ChartMenuProps): JSX.Element {
  const { dashId, frame } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openRightDrawer = useStoreActions((state) => state.view.setRightDrawerOpen);
  const setSelected = useStoreActions((state) => state.view.setSelectedDashItem);
  const removeItem = useStoreActions((state) => state.removeDashItem);
  const setSheet = useStoreActions((state) => state.view.setSheet);
  const container = useModalContainer();

  function toggleMenu(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  }

  function editChart(): void {
    openRightDrawer(true);
    setOpen(false);
    setSelected(dashId);
  }

  function closeMenu(): void {
    setAnchorEl(null);
    setOpen(false);
  }

  function removeChart(): void {
    removeItem(dashId);
    setOpen(false);
  }

  const showSheet = useCallback(() => {
    setSheet(dashId);
    setOpen(false);
  }, [setSheet, dashId]);

  const saveTsv = useCallback(() => saveDsv(frame), [frame]);

  const onMouseDown = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <>
      <IconButton data-qa="ChartMenu" onClick={toggleMenu} {...{ onMouseDown }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        {...{ onMouseDown, container }}
      >
        <MenuItem data-qa="EditChart" onClick={editChart}>
          Chart Settings
        </MenuItem>
        <MenuItem onClick={showSheet}>Data</MenuItem>
        <MenuItem onClick={saveTsv}>Export as .tsv file</MenuItem>
        <MenuItem onClick={removeChart}>Remove</MenuItem>
      </Menu>
    </>
  );
}
