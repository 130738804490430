import { AxisScale } from "d3";
import { InverseLirp, Lirp } from "../math/Lirp";
import { unitPartitions } from "../math/TableInterpolator";
import { Vec2 } from "../math/Vec";

export interface NumberScale extends AxisScale<number> {
  ticks(count: number): number[];
  // clean up some typing from D3
  range(newRange: Vec2): NumberScale;
  range(): Vec2;
  domain(newDomain: Vec2): NumberScale;
  domain(): Vec2;
  invert(): (y: number) => number;
}
/*
 A configurable power function for scaling.

 Uses a function based on the upper left quandrant of a squared 
 off circle centered at [1,0].

 The base function is (1-x)**n + y**m = 1. At n = m = 2, the
 function is a circle centered at 1,0.  
 
 At higher powers for n and m, the circle squishes towards a square.
 In terms of x, the function is:

   y = (1 - (1-x)**n )**(1/m)

 It's inverse is:
   x = 1 - (1 - y**m)**(1/n)


 @param bend controls how non-linear the scale is. 
 Bend controls the exponents n and m in the squared circle formula.
 Bend must be at least 0. At 0, the scale is linear.

 @param assymetry controls the center of the bend. If assymetry
 is one, the bend in the middle of the domain. Typically
 the bend should be offset to expand relatively small 
 values in the domain more than large values (e.g. log scaling).
 larger values of assymetry expand the range more for
 small domain values.
*/
export function SquircleScale(
  brightness = 0,
  domain: Vec2 = [0, 1],
  range: Vec2 = [0, 1]
): NumberScale {
  console.assert(brightness >= 0, "brightness should be 0 or more");
  const { m, n } = brightnessSquircle(brightness);

  let rangeLirp = Lirp(range[0], range[1]);
  let normalizeX = InverseLirp(domain[0], domain[1]);

  const fn: NumberScale = ((x: number): number => {
    const normalX = normalizeX(x);
    const result = normalFn(normalX);
    return result;
  }) as NumberScale;

  /** return the value based on an x normalized between 0 and 1 */
  function normalFn(normalX: number): number {
    const inner = 1 - (1 - normalX) ** n;
    const value = m === 0 ? 0 : inner ** (1 / m);
    return rangeLirp(value);
  }

  /** return the inverse value based on a Y normalized between 0 and 1 */
  function normalInverseFn(): (normalY: number) => number {
    const domainLirp = Lirp(domain[0], domain[1]);

    return function (normalY: number): number {
      const inner = 1 - normalY ** m;
      const normalX = 1 - inner ** (1 / n);
      return domainLirp(normalX);
    };
  }

  function getSetDomain(): Vec2;
  function getSetDomain(newDomain: Vec2): NumberScale;
  function getSetDomain(newDomain?: Vec2): Vec2 | NumberScale {
    if (newDomain !== undefined) {
      domain = newDomain;
      normalizeX = InverseLirp(domain[0], domain[1]);
      return fn;
    } else {
      return domain;
    }
  }
  fn.domain = getSetDomain;

  function getSetRange(): Vec2;
  function getSetRange(newRange: Vec2): NumberScale;
  function getSetRange(newRange?: Vec2): Vec2 | NumberScale {
    if (newRange !== undefined) {
      range = newRange;
      rangeLirp = Lirp(range[0], range[1]);
      return fn;
    } else {
      return range;
    }
  }
  fn.range = getSetRange;

  fn.copy = () => SquircleScale(brightness, domain, range);

  fn.ticks = (count: number): number[] => {
    const normalInverse = normalInverseFn();
    if (count === 1) {
      return [normalInverse(0.5)];
    }

    const parts = unitPartitions(count);
    const inverses = parts.map(normalInverse);

    return inverses;
  };

  function invert(): (y: number) => number {
    const normalizeY = InverseLirp(range[0], range[1]);
    const normalInvert = normalInverseFn();
    return (y: number): number => {
      const normalY = normalizeY(y);
      return normalInvert(normalY);
    };
  }

  fn.invert = invert;

  return fn;
}

interface SquricleExponents {
  m: number;
  n: number;
}

export function brightnessSquircle(brightness: number): SquricleExponents {
  const b = brightness / 20; // normalize scale: zero to one
  const n = b * 10 + 0.4;
  const m = b * b;
  return { n, m };
}
