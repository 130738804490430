import _ from "lodash";
import REGL, { Regl, Texture2D } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { floatBuffer } from "./LineBuffers";
import * as ReglPerf from "./ReglPerf";
import { FullFB } from "./WebGLUtil";

/**
 * Take the unaligned x,y positions from StatLineFill and produces a line texture
 * where of x,y positions where the x coordinates align with the texel coordinates.
 *
 * A result from StatLineFill might be:
 * x: .2 .2 .8 .8
 * y: .1 .1 .4 .4
 *
 * We want to produce:
 * x: .2 .4 .6 .8
 * y: .1 .2 .3 .4
 *
 * The x coordinates should align with the texel coordinates. We want this alignment
 * so we can do FIR filtering for smoothing.
 */

export interface AlignLineProps {
  /** source stats summary texture 1 x width, stats in x,y */
  filled: Texture2D;

  /** aligned result texture same size 1 x width, stats in x,y (but x is unnecessary) */
  aligned: FullFB;

  debug?: ChartDebug;
}

export interface AlignLineFn {
  (props: AlignLineProps): void;
}

export function alignLine(regl: Regl): AlignLineFn {
  const cmd = regl({
    vert: `
        precision highp float;
        attribute float vert1;
        attribute float vert2;
        attribute float odd;
        uniform sampler2D filled;
        varying vec2 xy;
            
        void main() {
          vec2 xy1 = texture2D(filled, vec2(vert1, .5)).xy;
          vec2 xy2 = texture2D(filled, vec2(vert2, .5)).xy;
          if (xy1 == xy2) {
            gl_Position = vec4(-2.0, -2.0, -2.0, 1.0);  // place line out of view so it isn't rasterized
          } else {
            xy = xy1;
            if (odd != 0.0) {
              xy = xy2;
            } 
            gl_Position = vec4(xy.x * 2.0 - 1.0, xy.y, 1.0, 1.0); // position range (-1, -1)
          }
        }`,
    frag: `
        precision highp float;
        varying vec2 xy; 

        void main() { 
          gl_FragColor = vec4(xy, 0.0, 1.0);
        }`,
    uniforms: {
      filled: (_ctx, props: AlignLineCmd) => props.filled,
    },
    attributes: {
      vert1: (_ctx, props: AlignLineCmd) => ({
        buffer: props.verts,
        offset: 0,
        divisor: 1,
      }),
      vert2: (_ctx, props: AlignLineCmd) => ({
        buffer: props.verts,
        offset: Float32Array.BYTES_PER_ELEMENT * 1,
        divisor: 1,
      }),
      odd: [0, 1],
    },
    framebuffer: (_ctx: any, props: AlignLineCmd) => props.aligned,
    depth: { enable: false, mask: false },
    primitive: "lines",
    count: 2,
    instances: (_ctx, props: AlignLineCmd) => props.filled.width,
  });

  ReglPerf.registerCmd(cmd, "smooth-stats-line");

  let buffer: REGL.Buffer | undefined = undefined;
  let bufferVerts = 0; // count of vertices in buffer

  function getVertBuffer(width: number): REGL.Buffer {
    if (buffer && bufferVerts === width) {
      return buffer;
    } else {
      const data = new Float32Array(lineVertices(width));
      buffer = floatBuffer(regl, data);
      bufferVerts = width;
      return buffer;
    }
  }

  return function align(props: AlignLineProps): void {
    const { filled, aligned } = props;
    const verts = getVertBuffer(filled.width);

    const cmdProps: AlignLineCmd = {
      filled,
      aligned,
      verts,
    };
    cmd(cmdProps);
  };
}

interface AlignLineCmd
  extends Pick<AlignLineProps, "filled">,
    Pick<AlignLineProps, "aligned"> {
  verts: REGL.Buffer;
}

function lineVertices(width: number): number[] {
  const verts = _.times(width).map((i) => (i + 0.5) / width);
  verts.push(verts[verts.length - 1]); // repeat last element
  return verts;
}
