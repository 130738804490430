import { useCallback, useEffect, useState } from "react";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";

export function useScrollToDashItem(): (
  dashId: DashId,
  scrollOptions?: boolean | ScrollIntoViewOptions
) => void {
  const [targetId, setTargetId] = useState<DashId>();
  const [scrollArg, setScrollArg] = useState<boolean | ScrollIntoViewOptions>();

  const scrollToDashItem = useCallback(
    (dashId: DashId, scrollArg?: boolean | ScrollIntoViewOptions) => {
      if (targetId !== dashId) {
        setTargetId(dashId);
        setScrollArg(scrollArg);
      }
    },
    [setTargetId, targetId]
  );

  useEffect(() => {
    if (targetId) {
      const dashItem = document.querySelector(`[data-dashid="${targetId}"]`);
      if (dashItem) {
        setTimeout(() => {
          dashItem.scrollIntoView(scrollArg);
        }, 250); // the delay gives the item time to get drawn, otherwise we scroll only to the top
      }
      setTargetId(undefined);
    }
  }, [targetId, scrollArg]);

  return scrollToDashItem;
}

const smoothScroll: ScrollIntoViewOptions = { behavior: "smooth" };

/** if there's a newly added item on the dashboard, scroll to it, and reset the new item flag */
export function useScrollToNewItem(): () => void {
  const scrollToDashItem = useScrollToDashItem();
  const newDashItem = useStoreState((app) => app.view.newDashItem);
  const modifyView = useStoreActions((app) => app.modifyView);

  const scrollIfNecessary = useCallback((): void => {
    if (newDashItem) {
      setTimeout(() => {
        // timeout trigger, so setState doesn't happen in caller's render
        scrollToDashItem(newDashItem, smoothScroll);
        modifyView({ newDashItem: null });
      });
    }
  }, [newDashItem, scrollToDashItem, modifyView]);

  return scrollIfNecessary;
}
