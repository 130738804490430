import REGL, { Framebuffer2D, Regl, Vec2 } from "regl";
import { ChartDebug } from "../chart/ChartDebug";
import { blendAndProjection, BlendSetupArgs } from "./BlendAndProjection";
import * as ReglPerf from "./ReglPerf";
import { ZoomRenderProps } from "./WebGLUtil";

export interface ScatterCmdProps extends ZoomRenderProps {
  points: REGL.Buffer;
  numPoints: number;
}

export interface ScatterRenderFn {
  (props: ScatterCmdProps): void;
}

export interface ScatterArgs {
  regl: Regl;
  framebuffer: Framebuffer2D | null;
  pixelsSize: Vec2;
  normX: (x: number) => number;
  debug: ChartDebug;
}

/** draw points into the target framebuffer */
export function ScatterCmd(args: ScatterArgs): ScatterRenderFn {
  const { regl } = args;
  const blendArgs: BlendSetupArgs = { ...args, blendType: "add" };
  const baseCmd = blendAndProjection(blendArgs);

  const scatterCmd = regl({
    vert: `
      precision highp float;
      attribute vec2 xy;
      uniform mat3 projection;

      void main() {
        vec3 hSpot = projection * vec3(xy, 1.0);
        gl_Position = vec4(hSpot.xy, 0.0, 1.0);
        gl_PointSize = 1.0;
      }`,

    frag: `
      precision highp float;
      void main() {
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
      }`,
    primitive: "points",
    count: (_ctx, props: ScatterCmdProps) => props.numPoints,
    // count: 5000,
    attributes: {
      xy: (_ctx, props: ScatterCmdProps) => props.points,
    },
  });

  const draw = (props: ScatterCmdProps): void => {
    baseCmd(props, () => scatterCmd(props));
  };
  ReglPerf.registerCmd(scatterCmd, "scatter");

  return draw;
}
