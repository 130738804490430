import _ from "lodash";
import { Vec2 } from "../math/Vec";
import { ColumnSet } from "../store/TableSource";
import { Brand } from "../util/Brand";
import { dlog } from "../util/DebugLog";
import { checkSorted, transpose } from "../util/Utils";
import { rangeTree, RangeTree } from "./RangeTree";

export type DataType = "date" | "number" | "string";

export type ColumnId = Brand<number, "columnId">;

export interface FrameAndSet {
  frame: ColumnFrame;
  columnSet: ColumnSet | undefined;
}

/**
 * A ColumnFrame provides column oriented storage of tabular numeric data.
 *
 * The ColumnFrame contains:
 *  . Columns: raw arrays of numbers with bit of metadata (name, L8r units)
 */
export interface ColumnFrame {
  columns: () => Column[];
  addColumn: <T extends ColumnArray = ColumnArray>(
    data: T,
    name?: string,
    displayType?: DataType,
    columnId?: ColumnId
  ) => ColumnId;
  getColumn: (columnId: ColumnId) => Column | undefined;
  named: (newName: string) => ColumnFrame;
  filteredYExtent: (seriesColumns: ColumnSet, xExtent: Vec2) => Vec2;
  indexColumn: () => ColumnId;
  xExtent: (xColumnId: ColumnId | undefined) => Vec2;
  name: string;
}

export type ColumnArray = Uint32Array | Float32Array | Float64Array | number[];

export interface Column<T extends ColumnArray = ColumnArray> {
  name?: string;
  data: T;
  columnId: ColumnId;
  displayType: DataType;
}

let globalNextId = -1; // TODO get rid of this

export function column<T extends ColumnArray>(
  data: T,
  name?: string,
  displayType: DataType = "number"
): Column<T> {
  return {
    name,
    data,
    displayType,
    columnId: globalNextId-- as ColumnId,
  };
}

export function columnFrame(): ColumnFrame {
  const cols: Column<ColumnArray>[] = [];
  const rangeTrees = new Map<ColumnArray, RangeTree>();

  // cache of columns, with rows sorted by the most recently requested x
  let sortedColumns: Column<ColumnArray>[] | undefined = undefined;
  let sortX: ColumnId | undefined;

  let autoId = -20;
  let indexId: ColumnId | undefined = undefined;

  const self: ColumnFrame = {
    columns,
    addColumn: addColumn,
    getColumn,
    named,
    filteredYExtent,
    indexColumn,
    xExtent,
    name: "",
  };

  function columns(): Column[] {
    return cols;
  }

  function getColumn(columnId: ColumnId): Column | undefined {
    return cols.find((col) => col.columnId === columnId);
  }

  function addColumn<T extends ColumnArray = ColumnArray>(
    data: T,
    name?: string,
    displayType: DataType = "number",
    columnId?: ColumnId
  ): ColumnId {
    if (cols.find((col) => col.columnId === columnId)) {
      console.error("addColumn(): duplicate columnId", columnId);
      return 0 as ColumnId;
    } else {
      const colId = columnId || (autoId-- as ColumnId);
      const column: Column<T> = {
        data,
        name,
        displayType,
        columnId: colId,
      };
      cols.push(column);
      return colId;
    }
  }

  function named(newName: string): ColumnFrame {
    self.name = newName;
    return self;
  }

  function sortedXyColumns(columnSet: ColumnSet | undefined): [Column, Column][] {
    if (!columnSet) {
      return [];
    }
    const { xColumn, yColumns } = columnSet;
    const x = findSortedColumn(xColumn, xColumn)!;
    return yColumns.map((yId) => [x, findSortedColumn(xColumn, yId)!]);
  }

  function findSortedColumn(sortColumn: ColumnId, requestedColumnId: ColumnId): Column {
    const sorted = getSorted(sortColumn);
    return sorted.find((c) => c.columnId === requestedColumnId)!;
  }

  function filteredYExtent(seriesColumns: ColumnSet | undefined, xExtent: Vec2): Vec2 {
    if (!seriesColumns) {
      return [0, 0];
    }
    const xys = sortedXyColumns(seriesColumns);
    const extents = xys.map(([xs, ys]) => {
      const tree = getTree(xs.data, ys.data);
      const queryResult = tree.rangeQuery(...xExtent);
      if (queryResult) {
        const { min, max } = queryResult;
        return [min, max] as Vec2;
      } else {
        // dLog("no result", { xExtent });
        return undefined;
      }
    });
    const realExtents = extents.filter((v) => v !== undefined) as Vec2[];

    return reduceExtents(realExtents);

    /** get the previously built range tree for an xy pair of columns, or build
     * the range tree if necessary  */
    function getTree(xData: ColumnArray, yData: ColumnArray): RangeTree {
      const foundTree = rangeTrees.get(yData);
      if (foundTree) {
        return foundTree;
      } else {
        const tree = rangeTree(xData, yData);
        rangeTrees.set(yData, tree);
        return tree;
      }
    }
  }

  function indexColumn(): ColumnId {
    if (!indexId) {
      indexId = createIndexColumn();
    }
    return indexId;
  }

  /** create an '_index' column that simply counts from 0 to the length of the existing columns
   * (useful as a stand in x column if there's no x data provided)
   */
  function createIndexColumn(): ColumnId {
    const firstColumn = _.head(columns());
    let length: number;
    if (!firstColumn) {
      console.warn("creating index for empty columnFrame:", name);
      length = 0;
    } else {
      length = firstColumn.data.length;
    }

    const data = new Uint32Array(length);
    for (let i = 0; i < data.length; i++) {
      data[i] = i;
    }
    const columnId = addColumn(data, "_index");
    return columnId;
  }

  function xExtent(xColumnId: ColumnId | undefined): Vec2 {
    if (xColumnId === undefined) {
      return [0, 0];
    }
    const sorted = getSorted(xColumnId);
    const { data } = sorted.find((col) => col.columnId === xColumnId)!;

    return [firstFinite(data)!, lastFinite(data)!];
  }

  function getSorted(xColumnId: ColumnId): Column<ColumnArray>[] {
    if (sortX !== xColumnId || !sortedColumns) {
      sortX = xColumnId;
      sortedColumns = sortColumnsBy(xColumnId, cols);
    }

    return sortedColumns;
  }

  return self;
}

function sortColumnsBy(
  xColumnId: ColumnId,
  cols: Column<ColumnArray>[]
): Column<ColumnArray>[] {
  const xDex = cols.findIndex((col) => col.columnId === xColumnId);
  if (xDex < 0) {
    dlog("expected to find", { xColumnId });
    return [];
  }
  const xColumn = cols[xDex];
  if (checkSorted(xColumn.data)) {
    return cols;
  }

  const rows = transpose(cols.map((c) => c.data) as number[][]);
  if (!rows) {
    dlog("columns not equal length");
    return [];
  }
  const sortedRowData = rows.sort((a, b) => a[xDex] - b[xDex]);
  const sortedColumnData = transpose(sortedRowData)!;
  const sortedColumns = _.zip(cols, sortedColumnData).map(([origCol, sortedData]) => {
    const column: Column<ColumnArray> = {
      ...origCol!,
      data: sortedData!,
    };
    return column;
  });
  return sortedColumns;
}

export function yExtent(frameAndSet: FrameAndSet): Vec2 {
  const { frame, columnSet } = frameAndSet;
  if (!columnSet) {
    return [0, 0];
  }
  const yColumns = columnSet.yColumns.map((colId) => frame.getColumn(colId)!);
  const extents = yColumns.map(
    (col) => [_.min(col.data)!, _.max(col.data)!] as [number, number]
  );

  return reduceExtents(extents);
}

function firstFinite(data: ColumnArray): number | undefined {
  return data.find((x) => Number.isFinite(x));
}

function lastFinite(data: ColumnArray): number | undefined {
  for (let i = data.length - 1; i >= 0; i--) {
    const n = data[i];
    if (Number.isFinite(n)) {
      return n;
    }
  }
  return undefined;
}

export interface DataExtent {
  x: Vec2;
  y: Vec2;
}

/** @return selected columns from the columnset in xyd triplets. Each triplet
 * contains the x column, the y column, and an optional dispersion column.
 */
export function frameXydColumns(
  frameAndSet: FrameAndSet
): [Column, Column, Column | undefined][] {
  const { frame, columnSet } = frameAndSet;
  if (!columnSet) {
    return [];
  }
  const { xColumn, yColumns, yDispersion } = columnSet;
  const x = frame.getColumn(xColumn)!;
  if (yDispersion) {
    console.assert(yDispersion.length === yColumns.length);
    return yColumns.map((yId, i) => {
      const y = frame.getColumn(yId)!;
      const d = frame.getColumn(yDispersion[i]);
      return [x, y, d];
    });
  }
  return yColumns.map((yId) => [x, frame.getColumn(yId)!, undefined]);
}

/** @return an array of tuples built from the x column and the first y column */
export function frameToXyArray(frameAndSet: FrameAndSet): Vec2[] {
  const { frame, columnSet } = frameAndSet;
  if (!columnSet) {
    return [];
  }
  const xData = frame.getColumn(columnSet.xColumn)!.data;
  const yData = frame.getColumn(columnSet.yColumns[0])!.data;
  const array: Vec2[] = [];
  xData.forEach((x: number, i: number) => array.push([x, yData[i]]));

  return array;
}

/**
 * Given a set of min,max pairs
 *
 * @return the min max of the entire set
 */
function reduceExtents(extents: Vec2[]): Vec2 {
  if (extents.length === 0) {
    return [0, 0];
  }

  return extents.reduce(([start, end], [first, last]) => {
    first = Math.min(first, start);
    last = Math.max(last, end);
    return [first, last];
  });
}

/** @return true if all columnIds in the columnSet are in this frame */
export function columnsInFrame(
  frame: ColumnFrame,
  columnSet: ColumnSet | undefined
): boolean {
  if (
    columnSet &&
    frame.getColumn(columnSet.xColumn) &&
    columnSet.yColumns.find((id) => frame.getColumn(id) !== undefined)
  ) {
    return true;
  }
  return false;
}
