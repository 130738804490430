import React from "react";
import { HeatLinesPlot } from "../../chart/PlotModels";
import { useCurrentPlot, useModifyPlot } from "../../store/Hooks";
import DensitySettings from "./DensitySettings";
import { FeatherSettings } from "./FeatherSettings";
import { LabeledSlider } from "./LabeledSlider";
import { LabeledSwitch } from "./LabeledSwitch";
import SettingsGroup from "./SettingsGroup";

export const LineSettings = React.memo(LineSettingsInternal);
export default LineSettings;

export function LineSettingsInternal(): JSX.Element {
  const width = useCurrentPlot((p: HeatLinesPlot) => p.width || 0);
  const weightHorizontal = useCurrentPlot((p: HeatLinesPlot) => !!p.weightHorizontal);
  const plotKind = useCurrentPlot((p) => p.kind);

  const setWidth = useModifyPlot("width") as (event: any) => void;
  const setHorizontal = useModifyPlot("weightHorizontal");

  if (plotKind !== "HeatLines") {
    return <div></div>;
  }

  return (
    <div>
      <SettingsGroup title="Lines">
        <LabeledSlider
          label="thickness:"
          min={0}
          max={20}
          value={width}
          onChange={setWidth}
        />
        <FeatherSettings />

        {/* LATER: choose line color. "auto" is lowest density color.. */}
      </SettingsGroup>
      <DensitySettings>
        <LabeledSwitch
          label="slope weighted density"
          checked={weightHorizontal}
          onChange={setHorizontal}
        />
      </DensitySettings>
    </div>
  );
}
