import { Store } from "easy-peasy";
import { AppModel } from "../../store/AppModel";

let enterExitCount = 0;
let installed = false;

/** set view state if the user is trying to drag a file into the browser window */
export function watchDragActive(store: Store<AppModel>): void {
  if (installed) return;
  const actions = store.getActions();

  window.ondragenter = (e: DragEvent) => {
    if (draggingFile(e)) {
      enterExitCount++;
      if (enterExitCount === 1) {
        actions.view.setDragActive(true);
      }
    }
  };

  window.ondrop = (e: DragEvent) => {
    if (draggingFile(e)) {
      enterExitCount = 0;
      actions.view.setDragActive(false);
    }
  };

  window.ondragleave = (e: DragEvent) => {
    if (draggingFile(e)) {
      enterExitCount--;
      if (enterExitCount === 0) {
        actions.view.setDragActive(false);
      }
    }
  };

  installed = true;
}

function draggingFile(e: DragEvent): boolean {
  const count = e.dataTransfer?.items?.length || 0;
  for (let i = 0; i < count; i++) {
    const item = e.dataTransfer!.items[i];
    if (item.kind === "file") {
      return true;
    }
  }

  return false;
}
