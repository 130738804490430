import { Vec2 } from "../math/Vec";
import { SvgSelection, SvgSVGSelection } from "../util/d3Util";
import Clip from "./Clip";
import { Margin, SizedRectangle } from "./Rectangle";

export interface PlotArea {
  // eslint-disable-next-line
  // g element containing the plots (or containing the foreign object/canvas that contains the plot)
  area: SvgSelection;
  resize: (newSize: [number, number]) => void;
  size: () => [number, number];
  plotRect: SizedRectangle;
}

/** create a g element with a clip to contain the plotted marks */
export default function getPlotArea(
  svg: SvgSVGSelection,
  plotSize: [number, number],
  axesMargin: Margin,
  plotMargin: Margin,
  chartId: number
): PlotArea {
  const clip = Clip(svg, plotSize, chartId);
  let size = plotSize.slice(0) as [number, number];

  const area = svg
    .selectAll("g.graph")
    .data([chartId])
    .join("g")
    .attr("class", "graph")
    .attr("data-qa", "graph")
    .attr("clip-path", `url(#${clip.clipId})`)
    .attr(
      "transform",
      `translate(${axesMargin.left}, ${axesMargin.top})`
    ) as SvgSelection;

  area.on("mousedown mouseup", (e: Event) => {
    e.stopPropagation();
  });

  const plotRect = innerPlot(plotSize, plotMargin);
  function resize(plotSize: Vec2): void {
    size = plotSize.slice(0) as Vec2;
    clip.resize(size);
  }

  return {
    area,
    resize,
    size: () => size.slice() as Vec2,
    plotRect,
  };
}

function innerPlot(plotSize: Vec2, plotMargin: Margin): SizedRectangle {
  const top = plotMargin.top;
  const left = plotMargin.left;
  const right = plotSize[0] - plotMargin.right;
  const bottom = plotSize[1] - plotMargin.bottom;
  const size: Vec2 = [right - left, bottom - top];

  return {
    top,
    left,
    right,
    bottom,
    size,
  };
}
