import React from "react";
import { DensityPlotData, isDensityPlotKind } from "../../chart/PlotModels";
import { useCurrentPlot, useStoreState } from "../../store/Hooks";
import { AddStatButton } from "./AddStatButton";
import { OneStatSetting } from "./OneStatSetting";
import SettingsGroup from "./SettingsGroup";

export function StatsSettings(): JSX.Element {
  const plotKind = useCurrentPlot((p) => p.kind);
  const summaries = useCurrentPlot((p: DensityPlotData) => p.summaries);
  const dashId = useStoreState((app) => app.currentSelection?.id);

  if (!isDensityPlotKind(plotKind)) {
    return <> </>;
  }

  const summaryItems = summaries?.map((summary) => {
    const { summaryId } = summary;
    return <OneStatSetting key={summaryId} {...{ dashId, summaryId }} />;
  });

  return (
    <>
      <SettingsGroup title="Stats">
        {summaryItems}
        <AddStatButton />
      </SettingsGroup>
    </>
  );
}
