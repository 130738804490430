import { DataArray } from "../data/DataChunk";
import { nameEqValue, NameValueFormat } from "./DetectNameValue";
import { splitIntoLines } from "./ParseAsciiTable";
import { TabularColumn } from "./ParseTabular";
import { parseColumns } from "./ParseColumns";

export interface NameValueParseParams {
  columns: TabularColumn[];
  parseOptions: NameValueFormat;
}

/** Read the data from a text, according the NameValueParse format */
export function parseNameValue(text: string, parse: NameValueParseParams): DataArray[] {
  const rowTable = valueRows(text, parse);
  const data = parseColumns(rowTable, parse.columns);
  return data;
}

function valueRows(text: string, parse: NameValueParseParams): string[][] {
  const keys = parse.columns.map((col) => col.label);

  const { parseOptions } = parse;
  if (!parseOptions) {
    return [];
  }
  const { headerLines, lineBreak } = parseOptions;
  const lines = splitIntoLines(text, lineBreak, headerLines);

  const kvRows = lines.map(nameEqValue);
  const nonEmpty = kvRows.filter((row) => row.length > 0);
  const values = nonEmpty.map((row) => {
    return keys.map((key) => row.find((kv) => kv.key === key)?.value || "");
  });

  return values;
}
