import { DrawCommand, Framebuffer2D, Regl, Texture2D } from "regl";
import * as ReglPerf from "./ReglPerf";
import { FullFB, fullScreenTriangles } from "./WebGLUtil";

export const nullFilter: FiveArray = [0.0, 0.0, 1.0, 0.0, 0.0]; // null filter
export const gaussianFilter: FiveArray = [0.06136, 0.24477, 0.38774, 0.24477, 0.06136]; // gaussian sigma = 1
export const defaultFilter = nullFilter;
type FiveArray = [number, number, number, number, number];
export interface BlurCmd {
  blur: () => void;
  blurTexture: Texture2D;
  blurFB: FullFB;
}

export function blurCmd(
  regl: Regl,
  source: Texture2D,
  separableFilter: FiveArray = defaultFilter
): BlurCmd {
  const [vertTexture, vertFB] = similarTextureFB(regl, source);
  const [horizTexture, horizFB] = similarTextureFB(regl, source);
  const { width, height } = source;
  const pixelSize = [1.0 / width, 1.0 / height];
  const integerOffset = [-2, -1, 0, 1, 2];
  const vertOffsets = integerOffset.map((i) => i * pixelSize[1]);
  const vertOffsetParams = arrayToParams("offset", vertOffsets);
  const horizOffsets = integerOffset.map((i) => i * pixelSize[0]);
  const horizOffsetParams = arrayToParams("offset", horizOffsets);
  const filterParams = arrayToParams("filter", separableFilter);

  const vertFilter = makeCommand(source, vertFB, vertOffsetParams, true);
  const horizFilter = makeCommand(vertTexture, horizFB, horizOffsetParams, false);

  function makeCommand(
    src: Texture2D,
    dest: Framebuffer2D,
    offsetParams: Record<string, unknown>,
    vertical: boolean
  ): DrawCommand {
    let addOffset;
    if (vertical) {
      addOffset = "uv.x, uv.y + offset[i]";
    } else {
      addOffset = "uv.x + offset[i], uv.y";
    }

    return regl({
      vert: `
        precision highp float;
        attribute vec2 position;
        varying vec2 uv;
            
        void main() {
            gl_Position = vec4(position, 1.0, 1.0); // position range (-1, -1)
            uv = 0.5 * (position + 1.0);            // uv range (0, 1)
        }`,
      frag: `
        precision highp float;

        varying vec2 uv;

        uniform sampler2D src;
        uniform float offset_0;
        uniform float offset_1;
        uniform float offset_2;
        uniform float offset_3;
        uniform float offset_4;
        uniform float filter_0;
        uniform float filter_1;
        uniform float filter_2;
        uniform float filter_3;
        uniform float filter_4;

        void main() {
            float filter[5];
            float offset[5];
            offset[0] = offset_0;
            offset[1] = offset_1;
            offset[2] = offset_2;
            offset[3] = offset_3;
            offset[4] = offset_4;

            filter[0] = filter_0;
            filter[1] = filter_1;
            filter[2] = filter_2;
            filter[3] = filter_3;
            filter[4] = filter_4;

            vec3 color = vec3(0.0);
            for (int i = 0; i < 5; i++) {
                vec2 spot = vec2(${addOffset});
                color += texture2D(src, spot).rgb * filter[i];
            }
            gl_FragColor = vec4(color, 1.0);
        }`,

      attributes: {
        position: fullScreenTriangles,
      },
      uniforms: {
        src,
        ...offsetParams,
        ...filterParams,
      },
      depth: { enable: false, mask: false },
      framebuffer: dest,
      primitive: "triangles",
      count: 6,
    });
  }

  ReglPerf.registerCmd(vertFilter, "vertFilter");
  ReglPerf.registerCmd(horizFilter, "horizFilter");

  return {
    blur: () => {
      vertFilter();
      horizFilter();
    },
    blurTexture: horizTexture,
    blurFB: horizFB as FullFB,
  };
}

function similarTexture(regl: Regl, src: Texture2D): Texture2D {
  const { width, height, type, format } = src;

  return regl.texture({
    width,
    height,
    format,
    type,
  });
}

function similarTextureFB(regl: Regl, src: Texture2D): [Texture2D, Framebuffer2D] {
  const texture = similarTexture(regl, src),
    fb = regl.framebuffer({
      color: texture,
      depthStencil: false,
    });
  return [texture, fb];
}

function arrayToParams(prefix: string, elems: number[]): Record<string, unknown> {
  const entries = elems.map((elem, i) => [`${prefix}_${i}`, elem]);
  return Object.fromEntries(entries);
}
