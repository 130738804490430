import { ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { PermDataSetting as TricksIcon } from "@material-ui/icons";
import React, { useCallback } from "react";
import { ClickTip } from "./ClickTip";
import { useRenderedHelp } from "./HelpButton";

const helpTricks = (
  <div>
    <h3>Advanced Tricks</h3>
    <dl>
      <dt>Erase All Data</dt>
      <dd>
        Launch dashberry with the <a href="?eraseButton=true">eraseButton</a> option to
        show an erase button. When clicked, the erase button will delete all dashboards
        and locally stored data.
      </dd>
      <dt>Frames Per Second</dt>
      <dd>
        Launch Dashberry with the <a href="?framesPerSecond=true">framesPerSecond</a>{" "}
        option and then press the ‘f’ key to show a live chart tracking the animation
        performance of Dashberry.
      </dd>
      <dt>Max Performance</dt>
      <dd>The fastest plot is a scatter plot with size set to zero.</dd>
    </dl>
  </div>
);

const useStyles = makeStyles({
  bottom: {
    position: "absolute",
    bottom: 0,
  },
});

export function TricksButton(): JSX.Element {
  const classes = useStyles();
  const renderedHelp = useRenderedHelp(helpTricks);

  const button = useCallback(
    (toggleOpen: () => void): JSX.Element => {
      return (
        <ListItem
          {...{
            button: true,
            className: classes.bottom,
            key: "Advanced Tricks",
            onClick: toggleOpen,
          }}
        >
          <ListItemIcon>
            <TricksIcon />
          </ListItemIcon>
          <ListItemText primary="Advanced Tricks" />
        </ListItem>
      );
    },
    [classes]
  );

  return (
    <ClickTip title={renderedHelp} render={button} elevation={20} arrow placement="top" />
  );
}
