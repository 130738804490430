import { Vec2 } from "../math/Vec";
import { SvgSVGSelection } from "../util/d3Util";

export interface ClipResult {
  clipId: string;
  resize: (newPlotSize: Vec2) => void;
}

export default function Clip(
  svg: SvgSVGSelection,
  plotSize: Vec2,
  chartId: number
): ClipResult {
  const clipId = `chart-${chartId}-clip`,
    clip = svg.append("clipPath").attr("id", clipId).append("rect");

  resize(plotSize);

  function resize(newPlotSize: Vec2): void {
    clip.attr("width", newPlotSize[0]).attr("height", newPlotSize[1]);
  }

  return {
    clipId: clipId,
    resize: resize,
  };
}
