import * as d3 from "d3";
import memoizeOne from "memoize-one";
import { FrameAndSet, frameToXyArray } from "../data/ColumnFrame";
import { AnyTransition, SvgGSelection } from "../util/d3Util";
import { XyScales } from "./ChartScales";
import { Renderable } from "./RenderPlot";

export interface SvgRendererArgs {
  frameAndSet: FrameAndSet;
  targetScales: XyScales;
}

export default function svgLines(plotArea: SvgGSelection): Renderable<SvgRendererArgs> {
  const dataArray = memoizeOne((frameAndSet: FrameAndSet) => {
    return frameToXyArray(frameAndSet);
  });

  function draw(transition: AnyTransition, args: SvgRendererArgs): void {
    const { frameAndSet, targetScales } = args;
    const data = dataArray(frameAndSet);

    const lineFn: any = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x(([x]) => targetScales.x(x)!)
      .y(([, y]) => targetScales.y(y)!);

    plotArea
      .selectAll(".mark")
      .data([data])
      .join(
        (selection) => selection.append("path").attr("class", "mark line-mark"),
        (selection) => selection,
        (selection) => selection.remove()
      )
      .transition(transition)
      .attr("d", lineFn);
  }

  return {
    draw: draw,
  };
}
