import _ from "lodash";
import REGL, { DrawCommand, Regl } from "regl";
import sprintfjs from "sprintf-js";
import { bytes, measureReport, msec } from "../util/Perf";
const sprintf = sprintfjs.sprintf;

interface NamedCommand {
  command: DrawCommand;
  name: string;
}

const drawCommands: Set<NamedCommand> = new Set();

export function registerCmd(command: DrawCommand, name: string): void {
  drawCommands.add({ command, name });
}

export function reset(): void {
  drawCommands.clear();
}

function reportDrawCommands(): void {
  const cmds = [...drawCommands];
  const reports = cmds.map(commandReport);
  const gpuTimes = cmds.map(gpuTime);
  const gpuTotalAvg = _.sum(gpuTimes) / cmds[0].command.stats.count;
  reports.push(`gpuTime.total.avg: ${msec(gpuTotalAvg)}`);
  console.log(reports.join("\n"));

  cmds.map(clearStats);
}

export function asyncReport(regl: Regl): void {
  if (drawCommands.size > 0) {
    const cmd = drawCommands.values().next().value.command;
    reportReady(regl, cmd).then(() => {
      reportDrawCommands();
      measureReport();
      reglStats(regl);
    });
  }
}

function gpuTime(namedCommand: NamedCommand): number {
  return namedCommand.command.stats.gpuTime;
}

function clearStats(namedCommand: NamedCommand): void {
  const stats = namedCommand.command.stats;
  stats.cpuTime = 0;
  stats.gpuTime = 0;
  stats.count = 0;
}

function commandReport(namedCommand: NamedCommand): string {
  const { name, command } = namedCommand,
    stats = command.stats,
    { cpuTime, gpuTime, count } = stats,
    avgCpu = cpuTime / count,
    avgGpu = gpuTime / count,
    named = sprintf("%15s", name);

  const report = `${named}  cpuTime.avg: ${msec(avgCpu)}  gpuTime.avg: ${msec(
    avgGpu
  )}  count: ${count}`;

  return report;
}

export function reglStats(regl: Regl): void {
  const stats = regl.stats,
    bufferSize = stats.getTotalBufferSize,
    textureSize = stats.getTotalTextureSize,
    textureUnits = stats.maxTextureUnits;

  if (bufferSize && textureSize) {
    const texSize = bytes(textureSize()),
      bufSize = bytes(bufferSize());

    console.log(
      `TextureSize: ${texSize}  ` +
        `Buffer Size: ${bufSize}  ` +
        `Texture Units: ${textureUnits}`
    );
  } else {
    if (regl.hasExtension("EXT_disjoint_timer_query")) {
      console.log("no stats, profiling not enabled?");
    } else {
      console.log("profiling not enabled. EXT_disjoint_timer_query not available");
    }
  }
}

function reportReady(regl: Regl, cmd: REGL.DrawCommand): Promise<undefined> {
  return new Promise((resolve) => {
    if (regl.hasExtension("EXT_disjoint_timer_query")) {
      callWhenReady(resolve);
    } else {
      resolve(undefined);
    }
  });

  function callWhenReady(
    resolve: (value: PromiseLike<undefined> | undefined) => void
  ): void {
    regl.poll();
    if (cmd.stats.gpuTime !== 0) {
      resolve(undefined);
    } else {
      setTimeout(() => callWhenReady(resolve), 200);
    }
  }
}
