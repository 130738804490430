import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { useModalContainer } from "../dash-controls/ModalContainer";

export interface TextMenuProps {
  dashId: DashId;
  className?: string;
}

export const TextMenu = React.memo(TextMenu_);

function TextMenu_(props: TextMenuProps): JSX.Element {
  const { dashId, className } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const removeItem = useStoreActions((state) => state.removeDashItem);
  const autoGrow = useStoreState((app) => {
    const item = app.findDashItem(dashId);
    return item?.kind === "Text" ? item.autoGrow : false;
  });
  const modifyDashItem = useStoreActions((app) => app.modifyDashItem);
  const container = useModalContainer();

  const toggleMenu = useCallback(
    // TODO DRY with ChartMenu and AddToDashMenu
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
      setOpen(!open);
    },
    [open]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const onMouseDown = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const removeText = useCallback(() => {
    removeItem(dashId);
  }, [removeItem, dashId]);

  const autoGrowText = autoGrow ? "Don't Grow Automatically" : "Grow Automatically";

  const toggleAutoGrow = useCallback(() => {
    modifyDashItem({ dashId, dashPartial: { autoGrow: !autoGrow } });
  }, [autoGrow, modifyDashItem, dashId]);

  return (
    <span {...{ className }}>
      <IconButton data-qa="ChartMenu" onClick={toggleMenu} {...{ onMouseDown }}>
        <MoreVertIcon />
      </IconButton>
      <Menu keepMounted {...{ anchorEl, container, open, onMouseDown, onClose }}>
        <MenuItem onClick={removeText}>Remove</MenuItem>
        <MenuItem onClick={toggleAutoGrow}>{autoGrowText}</MenuItem>
      </Menu>
    </span>
  );
}
