import { dlog } from "../util/DebugLog";

export const protocolVersion = 1;

/* Communication between browser and dashpipe daemon over the websocket

    DashPipe          <->             Browser  
                    <Connect>                             ; either browser or dashpipe may connect
                      <- DashBoardInfo (json) 
                Ping ->
                      <- Pong                             ; (ping,pong is optional, may repeat before data is sent)
         Data Header ->                                   ; sent in a separate web socket message from subsequent data
                Data ->
                Data ->                                   ; dashpipe may send 0 or more messages containing data
             <Close> ->                                   ; dashpipe closes the connection when data is complete 
 */
export interface PingPipeHeader {
  kind: "ping";
}

export interface DataPipeHeader {
  kind: "data";
  name?: string;
  replace?: boolean;
  forceNew?: boolean;
  dataFormat?: string;
  dashboard?: string;
  chart?: string;
  noShow?: boolean;
}

type PipeHeader = DataPipeHeader | PingPipeHeader;

/*
 * The header protocol is sent as single websocket binary message.
 *
 *    [version number (16 bits)] [json length 16 bits] [header: json utf8]
 *
 * The header payload is a utf encoded JSON encoded instace of DataPipeHeader
 */
export function parseHeader(data: ArrayBuffer): PipeHeader | undefined {
  if (!(data instanceof ArrayBuffer)) {
    dlog("unexpected type of data", { data });
    return undefined;
  }
  const view = new DataView(data);
  const version = view.getUint16(0);
  const length = view.getUint16(2);
  const headerBytes = data.slice(4, 4 + length);
  const decoder = new TextDecoder("utf8");
  const headerString = decoder.decode(headerBytes);

  if (version !== protocolVersion) {
    dlog("unexpected version: ", { version });
    return undefined;
  }

  const header = JSON.parse(headerString) as PipeHeader;
  return header;
}
