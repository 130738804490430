import { IconButton, makeStyles, Popover, Theme } from "@material-ui/core";
import { bindPopover, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback } from "react";
import { ColorResult, TwitterPicker } from "react-color";
import { Vec3 } from "../../math/Vec";

const useStyles = makeStyles<Theme, ColorButtonProps>({
  swatch: {
    width: 24,
    height: 24,
    borderRadius: 4,
    backgroundColor: (props) => rgbColor(props.color),
  },
});

function rgbColor(color: Vec3): string {
  const colorString = color.map((c) => c.toString()).join(",");
  return `rgb(${colorString})`;
}

export interface ColorButtonProps {
  color: Vec3;
  colorChoices?: string[];
  onColorChange?: (color: Vec3) => void;
}

export function ColorButton(props: ColorButtonProps): JSX.Element {
  const { color, colorChoices, onColorChange } = props;
  const classes = useStyles({ color });
  const popupState = usePopupState({ variant: "popper", popupId: "color" });

  const onChange = useCallback(
    (colorResult: ColorResult) => {
      const { r, g, b } = colorResult.rgb;
      onColorChange && onColorChange([r, g, b]);
      popupState.close();
    },
    [onColorChange, popupState]
  );
  const pickerColor = { r: color[0], g: color[1], b: color[2] };

  return (
    <span>
      <IconButton className={classes.button} {...bindTrigger(popupState)}>
        <span className={classes.swatch} />
      </IconButton>

      <Popover {...bindPopover(popupState)}>
        <TwitterPicker color={pickerColor} colors={colorChoices} {...{ onChange }} />
      </Popover>
    </span>
  );
}
