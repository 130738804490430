import _ from "lodash";

export interface LineSplits {
  nonEmpty: string[];
  lineBreak: string;
}

export function splitTextByLine(text: string, ignoreLastLine = false): LineSplits {
  const lineBreak = detectLineBreak(text);
  const lines = text.split(lineBreak);
  const fullLines = ignoreLastLine ? _.dropRight(lines, 1) : lines;
  const nonEmpty = fullLines.filter((line) => line.length > 0);
  return { nonEmpty, lineBreak };
}

function detectLineBreak(text: string): string {
  if (text.includes("\r\n")) return "\r\n";
  if (text.includes("\n")) return "\n";
  if (text.includes("\r")) return "\r";
  return "\n";
}