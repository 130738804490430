import _ from "lodash";
import { time } from "../util/Perf";
import { integratePolynomial } from "./IntegratePolynomial";
import { MakePolynomialFn } from "./PolynomialFunction";
import { parametricToPolynomial, polynomialRegressionFn } from "./PolynomialUtil";
import { ParametricFn, UnaryFn } from "./SplineMath";
import {
  tableInterpolator,
  TableInterpolator,
  unitPartitions,
} from "./TableInterpolator";
import { Vec2 } from "./Vec";

/*
goal: 
. given a probability distribution sketch specified by a spline, 
. generate n samples between 0 and 1 that follow that probability distribution.

Strategy:
  convert the spline to a polynomial via regression
  take the integral of the polynomial 
  scale the integral fn so that its area is 1: that's the CDF
    (could scale the polynomial function by the same factor to get the PDF)
  sample the integral at m points (m determines the accuracy)
  invert the sample table yielding the inverse CDF or Quantile function
  sample the Quantile function at n uniformally distributed points 
    . use equidistant parititioning for efficiency (rather than textbook uniformly distributed random samples)
    . use linear interpolation between the m sample points
  the result should be distributed according to the PDF
*/

/** Generate samples according to a probability density.
 *
 * The PDF is specified by a parametric function (e.g. a spline) describing the shape
 * of the probability density function. The PDF shape is approximated by a polynomial.
 * An approximation of the quantile function for that PDF is then used for generating an
 * arbitrary number of points.
 *
 * Internally, the quantile function is calculated by
 * sampling the cumulative distribution function (CDF), which is the integral of the PDF.
 * The inverse of the CDF table is the quantile function.
 *
 * Samples are generated by linear interpolation from the quantile table.
 */
export function samplesFromSplinePDF(
  curve: ParametricFn,
  length: number,
  regressionSamples = 100,
  regressionDegree = 3,
  quantileSamples = 200
): number[] {
  return time("samplesFromSplinePDF", () => {
    const cdf = cdfFromSpline(curve, regressionSamples, regressionDegree);
    const quantile = tableInterpolator(cdf, quantileSamples).invert();
    const parts = unitPartitions(length);
    const xs = quantile.samples(parts);
    return xs;
  });
}

/** Generate samples according to a probability density.
 *
 * The PDF is specified by a parametric function (e.g. a spline) describing the shape
 * of the probability density function. The PDF shape is approximated by a polynomial.
 * An approximation of the quantile function for that PDF is then used for generating an
 * arbitrary number of points.
 *
 * Internally, the quantile function is calculated by
 * sampling the cumulative distribution function (CDF), which is the integral of the PDF.
 * The inverse of the CDF table is the quantile function.
 *
 * The quantile function is then approximated by another polynomial, which is
 * a bit faster than using the table directly, although at the cost of some additional
 * loss of accuracy.
 */
export function samplesFromSplinePDFApprox(
  curve: ParametricFn,
  length: number,
  regressionSamples = 100,
  regressionDegree = 3,
  quantileSamples = 200,
  quantileDegree = 4
): number[] {
  return time("samplesFromSplinePDFApprox", () => {
    const cdf = cdfFromSpline(curve, regressionSamples, regressionDegree);
    const quantile = quantileApproxFromCDF(cdf, quantileSamples, quantileDegree);
    const parts = unitPartitions(length);
    const xs = parts.map(quantile);
    return xs;
  });
}

export function quantileFromCDF(cdf: UnaryFn, samples = 200): TableInterpolator {
  return tableInterpolator(cdf, samples).invert();
}

export function quantileApproxFromCDF(cdf: UnaryFn, samples = 200, degree = 4): UnaryFn {
  const quantileTable = quantileFromCDF(cdf, samples).table();
  const quantilePoints = _.zip(...quantileTable) as Vec2[];
  return polynomialRegressionFn(quantilePoints, degree);
}

export function cdfFromSpline(curve: ParametricFn, samples = 100, degree = 3): UnaryFn {
  const { maxX, polynomialCoeff } = parametricToPolynomial(curve, samples, degree);
  const { integralCoeff, totalArea } = integratePolynomial(polynomialCoeff, maxX);
  const scaledIntegralCoeff = integralCoeff.map((c) => c / totalArea);
  const scaledIntegral = MakePolynomialFn(scaledIntegralCoeff.toArray() as number[]);
  return scaledIntegral;
}

export function pdfFromSpline(curve: ParametricFn, samples = 100, degree = 3): UnaryFn {
  const { maxX, polynomialCoeff } = parametricToPolynomial(curve, samples, degree);
  const { totalArea } = integratePolynomial(polynomialCoeff, maxX);
  const scaledCoeff = polynomialCoeff.map((c) => c / totalArea);
  const scaledCurve = MakePolynomialFn(scaledCoeff.toArray() as number[]);
  return scaledCurve;
}
