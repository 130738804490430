import React from "react";
import { useCurrentSelection } from "../../store/Hooks";
import { ChartSettings } from "./ChartSettings";

export default function DashItemSettings(): JSX.Element {
  const itemKind = useCurrentSelection((item) => item.kind);

  if (!itemKind) {
    return <></>;
  }

  switch (itemKind) {
    case "Chart":
      return <ChartSettings />;
    case "Text":
      return <></>;
    default:
      console.log("DashItemSettings: unhandled dashItem", itemKind);
      return <></>;
  }
}
