import { ChartDebug } from "../chart/ChartDebug";

export interface AppDebug extends ChartDebug {
  debugSampleData: boolean;
  svgCharts: boolean;
  adjustableFeather: boolean;
  fadeSwitch: boolean;
  debugMenu: boolean;
  dataMenu: boolean;
  eraseButton: boolean;
  framesPerSecond: boolean;
  openChartData: boolean;
  openChartSettings: boolean;
  minDensitySlider: boolean;
}

export type AppDebugKey = keyof AppDebug;

const appDebug: AppDebug = {
  showPerformance: false,
  miniCanvas: false,
  miniBorder: false,
  logDisplayFB: false,
  logDensityFB: false,
  logBlurredFB: false,
  logNzStats: false,
  logFade: false,
  logPercentileBuckets: false,
  logPercentileStats: false,
  logBaseStat: false,
  logFilledStat: false,
  logAlignStat: false,
  logSmoothedStat: false,
  debugSampleData: false,
  rawWidth: false,
  noSmoothStat: false,
  svgCharts: false,
  hideDensity: false,
  no32expand: false,
  adjustableFeather: false,
  fadeSwitch: false,
  debugMenu: false,
  dataMenu: false,
  eraseButton: false,
  framesPerSecond: false,
  openChartData: false,
  openChartSettings: false,
  minDensitySlider: false,
};

export default appDebug;
