import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, {
  Dispatch,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGridLayout, { ItemCallback, Layout } from "react-grid-layout";
import useResizeObserver from "use-resize-observer";
import { xmur3a } from "../../math/PsuedoRandom";
import { Vec2 } from "../../math/Vec";
import { DashId } from "../../store/DashId";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { cacheOneDeep } from "../../util/Utils";
import { useDashboardDrop } from "../dash-controls/UseDashboardDrop";
import { HiddenInput, isEditable } from "../misc/HiddenInput";
import { DashboardItem, GrowEventDetails } from "./DashboardItem";
import { reactGridLayoutStyles } from "./ReactGridLayoutStyles";
import { useScrollToNewItem } from "./UseScrollToDashItem";

interface StyleParams {
  dashWidth: number;
  dashHeight: number;
}

/* grid for dash items */
const grid = {
  width: 12, // align x positions on 12 pixel boundaries, cols are 12 pixels wide
  height: 2, // align y positions on 2 pixel boundaries, rows are 2 pixels high
};

const resizeMargin = 500;

const useStyles = makeStyles({
  dashboard: {
    width: "100%",
    minHeight: "calc(100vh - 64px)", // 64px for title bar
    height: "100%",
    paddingTop: 15,
    paddingBottom: 20,
    // backgroundColor: "grey",
  },
  resizing: {
    height: (p: StyleParams) => `max(125vh, ${p.dashHeight + resizeMargin}px`, // grow height so we can drag into more space
    // backgroundColor: "cadetblue",
    overflow: "hidden",
  },
  rearranging: {
    borderColor: "grey",
    borderStyle: "solid",
    borderWidth: 4,
    margin: -4,
  },
});

const noMargin: Vec2 = [0, 0];

export const GrowItemDispatch = React.createContext<
  Dispatch<GrowEventDetails> | undefined
>(undefined);

export const Dashboard = React.memo(Dashboard_);

function Dashboard_(): JSX.Element {
  const divRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { dashWidth, dashHeight, cols } = useObservedSize(divRef);
  const dashSize = useRef({ dashWidth: 0, dashHeight: 0 });
  const [movingId, setMovingId] = useState<DashId>(); // to display shadow while resizing/moving
  if (movingId == undefined) {
    dashSize.current.dashWidth = dashWidth;
    dashSize.current.dashHeight = dashHeight;
  }
  const layoutClasses = reactGridLayoutStyles();
  const classes = useStyles(dashSize.current);
  const [cacheIds] = useState<(ids: DashId[]) => DashId[]>(cacheOneDeep);
  const dashItemIds = useStoreState((app) => {
    const ids = app.dashboard.dashItems.items.map((item) => item.id);
    return cacheIds(ids);
  });
  const setLayout = useStoreActions((app) => app.dashboard.setLayout);
  const editOpen = useStoreState((app) => app.view.rightDrawerOpen);
  const selectedId = useStoreState((app) => app.currentSelection?.id);
  const setDashWidth = useStoreActions((app) => app.view.setDashWidth);
  const layout = useStoreState((app) => app.dashboard.layout);
  const lastLayout = useRef<Layout[]>(); // last layout, so we can detect resize from undo/redo
  const rearrangeMode = useStoreState((app) => app.view.rearrangeMode);
  const onDrop = useDashboardDrop();

  // a resize counter per child. Used to trigger individual children to resize
  // when we're notified by RGL that a resize is complete
  const resizeCounts = useRef(new Map<DashId, number>()).current;
  const scrollToNewItem = useScrollToNewItem();

  useEffect(() => setDashWidth(dashWidth), [dashWidth, setDashWidth]);

  // adjust the layout when a DashText item wants to autogrow
  const growDispatch = useCallback(
    (action: GrowEventDetails): void => {
      const { dashId, lines } = action;
      const idString = dashId.toString();
      const newLayout = [...layout];
      const foundDex = newLayout.findIndex((elem) => elem.i === idString);
      if (foundDex >= 0) {
        const item = newLayout[foundDex];
        const h = item.h + Math.round(lines / grid.height);
        const newItem = { ...item, h };
        newLayout.splice(foundDex, 1, newItem);
        setLayout(newLayout);
      }
    },
    [layout, setLayout]
  );

  const onLayoutChange = useCallback(
    (newLayout: Layout[]) => {
      if (lastLayout.current) {
        const resized = checkResized(lastLayout.current, newLayout);
        resized.forEach((dashId) => {
          const resizeCount = resizeCounts.get(dashId) || 0;

          // a layoutChange will be triggered which will cause a render,
          // and then this new count will trigger that child to resize
          resizeCounts.set(dashId, resizeCount + 1);
        });
      }
      setLayout(newLayout);
      lastLayout.current = newLayout;
    },
    [setLayout, resizeCounts]
  );

  const moving: ItemCallback = useCallback(
    (_layout: Layout[], _oldItem: Layout, newItem: Layout) => {
      const dashId = Number.parseInt(newItem.i) as DashId;
      setMovingId(dashId);
    },
    [setMovingId]
  );
  const movingStop: ItemCallback = useCallback(() => {
    setMovingId(undefined);
  }, []);

  const onDrag = moving;
  const onDragStop = movingStop;
  const onResize = moving;
  const onResizeStop = movingStop;

  const resizeHash = xmur3a(JSON.stringify(resizeCounts));

  const rearrange = rearrangeMode ? classes.rearranging : undefined;

  const children = useMemo(() => {
    resizeHash; // just for the linter
    return dashItemIds.map((dashId) => {
      const elevate = dashId === movingId || (dashId === selectedId && editOpen);
      const elevation = elevate ? 24 : 0;
      const promoted = elevate ? "promoted" : undefined;
      const resizeCount = resizeCounts.get(dashId);
      const selected = dashId === selectedId ? "selected" : undefined;
      return (
        // (ReactGridLayout needs className and style properties to be supported on these children)
        <Paper
          {...{ elevation }}
          key={dashId}
          data-dashid={dashId}
          className={clsx(
            layoutClasses.reactGridItem,
            "dash-item-container",
            selected,
            promoted,
            rearrange
          )}
        >
          <DashboardItem {...{ dashId, resizeCount }} />
        </Paper>
      );
    });
  }, [
    dashItemIds,
    layoutClasses.reactGridItem,
    editOpen,
    movingId,
    resizeCounts,
    selectedId,
    resizeHash,
    rearrange,
  ]);

  const onClick = useCallback((e: React.MouseEvent) => {
    if (!isEditable(e.nativeEvent.target)) {
      // paste events go only to editable elements
      // so we focus on a hidden input element so that a subsequent paste will target the dashboard
      inputRef.current && inputRef.current.focus();
    }
  }, []);

  scrollToNewItem();

  if (cols === 0) {
    return <div ref={divRef} className={classes.dashboard}></div>;
  }

  return (
    <GrowItemDispatch.Provider value={growDispatch}>
      <div
        ref={divRef}
        data-dashboard={1}
        className={clsx(classes.dashboard, movingId && classes.resizing)}
        {...{ onClick, onDrop }}
      >
        <HiddenInput ref={inputRef} />
        <ReactGridLayout
          {...{
            onDragStop,
            onDrag,
            onResizeStop,
            onResize,
            onLayoutChange,
            layout,
            cols,
          }}
          className={layoutClasses.reactGridLayout}
          width={dashWidth}
          rowHeight={grid.height}
          margin={noMargin}
          isResizable={true}
          compactType="vertical"
        >
          {children}
        </ReactGridLayout>
      </div>
    </GrowItemDispatch.Provider>
  );
}

interface ObservedSize {
  dashWidth: number;
  dashHeight: number;
  cols: number;
}

/** @return the dashboard width and number of grid columns for the
 * current size of the dashboard container */
function useObservedSize(ref: RefObject<HTMLElement>): ObservedSize {
  const { width = 0, height = 0 } = useResizeObserver({ ref });

  // make the dash width an even multiple of the gridWidth,
  // so that items stay the same size as the dash grows and shrinks
  const dashWidth = width - (width % grid.width);
  const cols = dashWidth / grid.width;

  const dashHeight = height - (height % grid.height);
  return { dashWidth, cols, dashHeight };
}

function checkResized(oldLayout: Layout[], newLayout: Layout[]): DashId[] {
  const resized: DashId[] = [];
  oldLayout.forEach((old) => {
    const newer = newLayout.find((newer) => newer.i === old.i);
    if (newer && (newer.w !== old.w || newer.h !== old.h)) {
      const dashId = Number.parseInt(newer.i) as DashId;
      resized.push(dashId);
    }
  });
  return resized;
}
