import { createStyles, Drawer, IconButton, makeStyles, Theme } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import DashItemSettings from "./DashItemSettings";

const drawerWidth = 440;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      display: "flex",
    },
    drawer: {
      flexShrink: 0,
    },
    drawerPaper: {
      top: "auto",
      width: drawerWidth,
    },
    drawerOpen: {
      transition: "width .3s",
      width: drawerWidth,
    },
    drawerClose: {
      transition: "width .3s",
      width: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  })
);

export const RightDrawer = React.memo(RightDrawerInternal);
export default RightDrawer;

function RightDrawerInternal(): JSX.Element {
  const classes = useStyles();
  const open = useStoreState((state) => state.view.rightDrawerOpen);
  const setOpen = useStoreActions((actions) => actions.view.setRightDrawerOpen);

  return (
    <div className={classes.drawerContainer}>
      <Drawer
        // the persistent variant wastes up a bit of space on the right edge of the dash for a menu bar we don't use
        // but the transient variant puts a grey backdrop over the rest of the screen, which is worse.
        variant="persistent"
        anchor="right"
        open={open}
        className={clsx(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
        classes={{
          paper: clsx(classes.drawerPaper),
        }}
        PaperProps={{ elevation: 24 }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <DashItemSettings />
      </Drawer>
    </div>
  );
}
