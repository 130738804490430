import { createStore, createTransform, persist } from "easy-peasy";
import { undoRedo } from "undo-peasy";
import { appModel } from "./AppModel";

const saveNoViews = createTransform(skipViews);

export const store = createStore(
  persist(appModel, {
    mergeStrategy: "mergeDeep",
    storage: "localStorage",
    deny: ["view"],
    transformers: [saveNoViews],
  }),
  {
    middleware: [undoRedo()],
  }
);

export function noSaveKeys(key: string): boolean {
  return key === "view";
}

/** persistence layer should not store view properties */
function skipViews(data: any, key: string): any {
  /* transformers are called once per key on the root level object only. */

  // remove top level trees matching prefix
  if (key.startsWith("view")) {
    return undefined;
  }

  return data;
}

export function deleteSavedState(): void {
  localStorage.clear();
}
