import * as d3 from "d3";
import { Vec2 } from "../math/Vec";
import {
  AnySelection,
  AnyTransition,
  LinearScale,
  SvgSVGSelection,
} from "../util/d3Util";
import { qaEnabled, qaSet } from "../util/QaApi";
import { ChartAPI, ChartState, XyDimensions } from "./Chart";
import { linearZoom } from "./LinearZoom";
import {
  domainRangeToMTransform,
  matToDomain,
  scale,
  scaleToMTransform,
  sumTopRow,
} from "./MiniTransform";
import { wheeling } from "./Wheeling";

type PanZoom = Pick<ChartAPI, "zoomTo" | "zoomToXDomain">;

/*
  Zooming and Panning 

  *UI actions*
  There are three UI actions supported:
    * click and drag to select an x range for brush to zoom
    * shift drag to pan
    * scroll wheel zoom in/out
   
  *UI events*
  Brush zoom events are triggered by the Brush module, which calls the zoomTo() API here.
  Wheel zooms are handled here by wheeled(). The d3.zoom handler is not used for wheel events.
  Pan events are handled by d3.zoom module, which calls the zoomed() event handler per frame.
  Note that brush triggered zoomTo()s and scroll wheel animations also indirectly call the 
  zoomed() event handler.

  *ChartScales*
  The chart renderers use ChartScales to decide which zoom level to render.
  This PanZoom module is responsible for setting the scales for the appropriate zoom.
  In particular, the domain of the scales in ChartScale is modified to
  The WebGL renderers use the zooming scale to render the appropriate scale for each frame.
  The SVG renderers use the zoomTarget scale to render the appropriate scale for each animation.

  *Zoom State*
  The zoom state is set via the zoomTarget scale when panZoom is instantiated.
  After initialization, zoom state is maintained in three places: 
    in the transform stored inside the d3.zoom component
    in the ChartScales.zooming scale (updated per tick)
    in the ChartScales.zoomTarget scale (update per zoom)
*/

/** Handle zooming and panning */
export function panZoom(selection: AnySelection, svg: SvgSVGSelection): PanZoom {
  const state: ChartState = svg.datum(),
    { api } = state,
    scales = state.scales,
    { zooming, zoomTarget, baseScales } = scales,
    xRange = baseScales.x.range() as Vec2,
    yRange = baseScales.y.range() as Vec2;

  const extent: [Vec2, Vec2] = [
    [xRange[0], yRange[0]],
    [xRange[1], yRange[1]],
  ];

  resetDomTransform(selection);

  const zoom = d3
    .zoom()
    .filter((e: any) => e.shiftKey) // only capture pans (and wheels)
    .extent(extent)
    .translateExtent(extent)
    .on("zoom", zoomed);

  const { wheeled, clearLastWheel } = wheeling(selection, svg, zoomToXDomain);

  selection
    .call(zoom)
    .on("wheel.zoom", wheeled, { passive: false })
    .on("dblclick.zoom", null as any, { passive: true }); // d3 docs and types disagree, so we cast to any

  const constrain = zoom.constrain();

  /** public api call to zoom, called by Brush. */
  function zoomTo(xRange: Vec2, duration: number): void {
    clearLastWheel();

    // data (domain) x coordinates of query
    const xDomain = xRange.map(zooming.x.invert) as Vec2;
    zoomToXDomain(xDomain, duration);
  }

  function zoomToXDomain(
    xDomain: Vec2,
    duration: number,
    notifyViewChange = true,
    clearWheelState = false,
    yDomain?: Vec2
  ): void {
    if (clearWheelState) {
      clearLastWheel();
    }
    const { frame, columnSet } = state.props;
    if (!columnSet) {
      return;
    }
    const yExtent = yDomain || frame.filteredYExtent(columnSet!, xDomain);
    updateZoomTarget(xDomain, yExtent, notifyViewChange);
    const yTween = yScaler(zooming.y, yExtent);

    // r: x range
    const [r0, r1] = baseScales.x.range() as Vec2;

    // u: selection x coordinates as if scale were unzoomed
    const [u0, u1] = xDomain.map(baseScales.x) as Vec2;

    // k: scale factor
    const k = (r0 - r1) / (u0 - u1);

    // x translation offset
    const x = r0 - k * u0;

    const transform = d3.zoomIdentity.translate(x, 0).scale(k);
    const constrained = constrain(transform, extent, extent);
    zoomToTransform(constrained, duration, yTween);
  }

  /** @returns a function that will transition the yScale domain to match the new xDomain */
  function yScaler(startScaleY: LinearScale, yExtent: Vec2): (p: number) => void {
    // transform for current scale
    const t0 = scaleToMTransform(startScaleY);

    // transform for target scale
    const t1 = domainRangeToMTransform(yExtent, yRange);
    return yTween;

    function yTween(p: number): void {
      /* We can just work on the top row because we know that the 
         sumT matrix will have bottom row of [0 1]
         
         (the original matrices have bottom rows of [0 1] and
          they are scaled by 1-p and p, so bottom row sum is [0 1])
         (also, sumT must be an affine transformation on homogenous
          coordinates, and so must have a bottom row of [0 1])
      */

      const st0 = scale(t0, 1 - p);
      const st1 = scale(t1, p);
      const sumT = sumTopRow(st0, st1);

      const yDomain = matToDomain(sumT, yRange);
      if (isFinite(yDomain[0]) && isFinite(yDomain[1])) {
        zooming.y.domain(yDomain);
      }
    }
  }

  /** handler for zoom event. This is called by brushes, pans, and wheels */
  function zoomed(event: any): void {
    const inZoomTo = !!d3.active(selection.node(), "zoomTo");
    const transform: d3.ZoomTransform = event.transform;
    const xDomain = transform.rescaleX(baseScales.x).domain() as Vec2;
    if (isNaN(xDomain[0])) {
      // dLog("xDomain isNaN");
      return;
    }
    zooming.x.domain(xDomain);

    if (!inZoomTo) {
      if (event.sourceEvent?.type === "mousemove") {
        // called: by the d3.zoom controller during a pan
        const panTransition = d3.transition("pan").ease(d3.easeCubicOut).duration(125);
        const { frame, columnSet } = state.props;

        const yExtent = frame.filteredYExtent(columnSet!, xDomain);
        updateZoomTarget(xDomain, yExtent);
        const yTween = yScaler(zooming.y, yExtent);
        panTransition.tween("panToY", () => yTween);
        api.drawAll(panTransition);
      } else {
        // called: when zoom is initially bound
        api.drawAll(d3.transition("zoomInit").duration(0));
      }
    } else {
      // called: in the tween of a zoomTo or wheel zoomToTransform
    }
  }

  /** generate a d3 transition to the specified zoom transform */
  function zoomToTransform(
    transform: d3.ZoomTransform,
    duration: number,
    yTween: (p: number) => void
  ): AnyTransition {
    const zoomToTransition = selection
      .transition("zoomTo")
      .duration(duration)
      .ease(d3.easeLinear);

    if (qaEnabled()) {
      qaSet({ zoomToEnd: zoomToTransition.end().catch(() => {}) });
    }
    zoom.interpolate(linearZoom).transform(zoomToTransition, transform);
    zoomToTransition.tween("zoomToY", () => yTween);

    api.drawAll(zoomToTransition);
    return zoomToTransition;
  }

  /** update state with a new zoom. Also notify any listeners that the zoom has changed.. */
  function updateZoomTarget(x: Vec2, y: Vec2, notifyViewChange = true): void {
    zoomTarget.x.domain(x);
    zoomTarget.y.domain(y);

    if (notifyViewChange && state.props.zoomChanged) {
      const zoom: XyDimensions = { x, y };
      state.props.zoomChanged(zoom);
    }
  }

  return {
    zoomTo,
    zoomToXDomain,
  };
}

/** reinit zoom state saved on dom node in case there was previous zoom and then chartState was reset */
function resetDomTransform(selection: AnySelection): void {
  const zoomInit = d3.zoom();
  selection.call(zoomInit.transform, d3.zoomIdentity);
}
