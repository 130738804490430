import _ from "lodash";
import { dsert } from "../util/DebugLog";
import { LinePattern } from "./LinePattern";

/** combine two line compatible column patterns.
 * Two patterns are compatible if their columns don't overlap. */
export function mergeLinePatterns(
  a: LinePattern,
  b: LinePattern
): LinePattern | undefined {
  if (a.ends.length === 0 || b.ends.length === 0) {
    return undefined;
  }
  const length = Math.max(a.ends[a.ends.length - 1], b.ends[b.ends.length - 1]);
  const buf = new Array(length).fill(0);
  fillLinePattern(a, buf);
  if (fillLinePattern(b, buf)) {
    return patternFromBuffer(buf);
  }
  return undefined;
}

/** Given a rasterized buffer of column numbers and spaces,
 * return the line pattern of column start and end positions. */
export function patternFromBuffer(buf: number[]): LinePattern {
  const starts: number[] = [];
  const ends: number[] = [];
  let prevCol = 0;

  // collect the start and end of each run in the rasterized buffer
  for (let i = 0; i < buf.length; i++) {
    const col = buf[i];
    if (col !== prevCol) {
      if (col !== 0) {
        starts.push(i);
      }
      if (prevCol !== 0) {
        ends.push(i);
      }
      prevCol = col;
    }
  }
  const last = _.last(buf);
  if (last) {
    ends.push(buf.length);
  }

  dsert(starts.length === ends.length);
  // debug for bulk test
  if (starts.length !== ends.length) {
    throw new Error("patternFromBuffer err");
  }

  return { starts, ends };
}

/** rasterize the line pattern endpoints into an array. The array maps to character
 * positions in the text lines. Elements of 0 indicate that white space is expected
 * at that position. Elements of n > 0, indicate the nth column is expected at that
 * position.
 *
 * @return false if the pattern cannot be written to the buffer without overlapping
 * another column.
 *
 * In this way, two patterns may be merged.
 *
 */
export function fillLinePattern(pattern: LinePattern, buf: number[]): boolean {
  const { starts, ends } = pattern;
  dsert(starts.length === ends.length);
  for (let colDex = 0; colDex < starts.length; colDex++) {
    const start = starts[colDex];
    const end = ends[colDex];

    const colNumber = colDex + 1;

    // merge column numbers into buffer, return false if we detect an overlap
    for (let i = start; i < end; i++) {
      if (buf[i] === 0) {
        buf[i] = colNumber;
      } else if (buf[i] != colNumber) {
        // LATER we shouldn't allow columns to abut w/o a space
        return false; // detected an illegal overlap
      }
    }
  }
  return true;
}
