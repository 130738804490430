import _ from "lodash";
import { useState } from "react";
import { DashPosition } from "./DataGrid";

export function useDashPosition(node: HTMLElement | null): DashPosition | undefined {
  const [position, setPosition] = useState<DashPosition | undefined>(); // CONSIDER useRef here
  if (!node) {
    return undefined;
  }

  const nodeRect = node.getBoundingClientRect();
  const nodeLeft = nodeRect.left;
  const nodeRight = nodeRect.right;
  const nodeCenter = (nodeLeft + nodeRight) / 2;
  const dashRect = node.closest("[data-dashboard]")?.getBoundingClientRect();
  const dashLeft = dashRect?.left || 0;

  const left = nodeLeft - dashLeft;
  const center = nodeCenter - dashLeft;

  const newPosition = {
    left,
    center,
  };

  if (!_.isEqual(position, newPosition)) {
    setPosition(newPosition);
    return newPosition;
  }
  return position;
}
