import _ from "lodash";
import { detectObjectTable } from "./DetectJsonObjectTable";
import { arrayRowsParser } from "./ParseJsonArrayTable";
import { numberColumnParser, stringColumnParser } from "./ParseJsonColumn";
import { objectTableParser } from "./ParseObjectTable";
import { JsonTableParser } from "./ParseTabular";

export function baseJsonScore(): number {
  return 20;
}
export type JsonTableFormat = "single" | "rows" | "objects";

/**
 *  try to parse a string a json table. The json should be in one of three formats:
 * [7,8,9]
 *      a single array of numbers or strings:   parsed as a single column of data
 *
 *  [[1,2], [3,4]]
 *    an array of arrays: the inner arrays are parsed as rows
 *
 *  [{x:1, y:2}, {x:3, y:4}]
 *    an array of objects: the objects are parsed as rows
 *    all rows should have the same fields
 *
 *  all rows should have the same length
 *  all columns should be the same format (number or string)
 */
export function detectJsonTable(text: string): JsonTableParser | undefined {
  try {
    const json = JSON.parse(text);
    if (typeof json === "object") {
      if (_.isArray(json)) {
        return trySingleColumn(json) || tryArrayOfArrays(json) || tryArrayOfObjects(json);
      }
    }
  } catch (e) {
    // ignore JSON parsing errors
  }

  return undefined;
}

function trySingleColumn(array: unknown[]): JsonTableParser | undefined {
  if (isNumberArray(array)) {
    return numberColumnParser;
  } else if (isStringArray(array)) {
    return stringColumnParser(array);
  } else {
    return undefined;
  }
}

function tryArrayOfArrays(array: unknown[]): JsonTableParser | undefined {
  if (isArrayTable(array)) {
    return arrayRowsParser(array);
  }
  return undefined;
}

function tryArrayOfObjects(array: unknown[]): JsonTableParser | undefined {
  const signatures = detectObjectTable(array);
  if (signatures) {
    return objectTableParser(signatures);
  }
  return undefined;
}

function isStringArray(array: unknown[]): array is string[] {
  return array.every((e) => typeof e === "string");
}

export function isNumberArray(array: unknown[]): array is number[] {
  return array.every((e) => typeof e === "number");
}

function isArrayTable(array: unknown[]): array is (number | string)[][] {
  const firstLength = (_.head(array) as [])?.length || 0;
  return array.every(
    (row) => _.isArray(row) && row.every(isNumberOrString) && row.length === firstLength
  );
}

export function isNumberOrString(e: unknown): e is number | string {
  return typeof e === "number" || typeof e === "string";
}
