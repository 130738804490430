import { useCallback } from "react";
// import { ColumnId } from "../data/ColumnFrame";
// import { DashId } from "./DashId";
// import { DashChartModel } from "./DashItems";
import { useStoreActions, useStoreState } from "./Hooks";

// const demoChart: DashChartModel = {
//   kind: "Chart",
//   id: 2 as DashId,
//   chart: {
//     plot: {
//       kind: "HeatScatter",
//       size: 4,
//       feather: 2,
//       brightness: 5,
//       smoothDensity: true,
//     },
//     tableSource: {
//       kind: "generatedStream",
//       name: "varying4",
//       maxRows: 10,
//     },
//     columnSet: { xColumn: -20 as ColumnId, yColumns: [-21 as ColumnId] },
//   },
// };

/** Install some demo stuff on the dashboard. L8r evolve this into a help demo.
 */
export function useShowDemo(): () => void {
  // const addItem = useStoreActions((app) => app.addDashItem);
  const demoShown = useStoreState((app) => app.help.demoShown);
  const introShown = useStoreState((app) => app.help.introShown);
  const modifyHelp = useStoreActions((app) => app.modifyHelp);
  const modifyView = useStoreActions((app) => app.modifyView);

  // const installDemoChart = useCallback((): void => {
  //   addItem({ item: demoChart, width: 50, height: 180 });
  // }, [addItem]);

  const showDemoIfNeeded = useCallback((): void => {
    if (!demoShown || !introShown) {
      if (!demoShown) {
        // installDemoChart();
        modifyHelp({ demoShown: true });
      }
      if (!introShown) {
        modifyView({ introOpen: true });
      }
    }
  }, [introShown, demoShown, modifyHelp, modifyView]);

  return showDemoIfNeeded;
}
