import _ from "lodash";
import { Framebuffer2D, Regl, Texture2D } from "regl";
import sprintfjs from "sprintf-js";
import { Vec2 } from "../math/Vec";
import { pairs, replaceUndefined } from "../util/Utils";
import * as Debug from "./Debug";
import { PercentileOperation } from "./FramePercentile";
import {
  percentileAccumulate,
  percentileLast,
  percentileStartBuckets,
} from "./FramePercentileSnippets";
import { maxSnippets, nzFirstSnippets, nzSnippets } from "./NonZeroStats";
import { reductionFBs } from "./ReductionFrameBuffers";
import * as ReglPerf from "./ReglPerf";
import { fetchPixels, frameBufferSize, FullFB, fullScreenTriangles } from "./WebGLUtil";
const sprintf = sprintfjs.sprintf;

const debug = false;

export interface ReduceResult {
  reducedTexture: Texture2D;
  reducedFB: FullFB;
  debugLastFBs: FullFB[]; // single texture fbs containing all six textures in the final 1x1 reduced fb
  reduce: () => void;
}

export type NzStatsOperation = "nzStats" | "nzStatsG";
export type ReduceOperation = "max" | NzStatsOperation | PercentileOperation;

interface ReduceProps {
  src: Texture2D;
  srcs: Texture2D[];
  dest: Framebuffer2D;
  uniforms?: OptionalUniforms;
}

interface OptionalUniforms {
  nzTexture?: Texture2D;
  percentile?: number;
  bucketStats?: Texture2D;
}

interface ReduceCmd {
  (props: ReduceProps): void;
}

export interface FrameReduceSnippets {
  vertexDeclare?: string;
  vertexMain?: string;
  pragma?: string;
  declare: string;
  accumulate: string;
  initialize: string;
  assignColor: string;
}

export interface FrameReduceOptions {
  uniforms?: OptionalUniforms;
  numTextures?: number;
  extraFinalTexture?: Texture2D;
  reduction?: Vec2;
  passName?: string;
}

const defaults = {
  numTextures: 1,
  reduction: [4, 4],
  passName: "",
};

// note informal perf testing of step size: on a canvas of size 1000, 670
// 4,4 is equally as fast as 2,2 on my mac. 4,4 takes less memory than 2,2.
// other values: (3,3) (5,5) (8,8) (16,16) were slower.
// LATER: redo testing after 2020.01.21 code changes.

export function frameReduce(
  regl: Regl,
  srcFB: FullFB,
  operation: ReduceOperation,
  options: FrameReduceOptions = {}
): ReduceResult {
  const srcSize = frameBufferSize(srcFB);
  const { uniforms, reduction, passName, numTextures, extraFinalTexture } =
    replaceUndefined(options, defaults);
  const { reduceFBs, debugLastFBs } = reductionFBs(
    regl,
    srcSize,
    reduction,
    numTextures,
    extraFinalTexture
  );
  const fbs = [srcFB].concat(reduceFBs);
  const pixelFB = _.last(fbs)!;
  const pixelTexture = pixelFB.color[0];
  const [firstSnip, snippets, lastSnip] = fragSnippets(operation);
  const commands = reductionCmds(
    regl,
    firstSnip,
    snippets,
    lastSnip,
    fbs,
    reduction,
    operation,
    passName
  );

  function reduce(): void {
    [...pairs(fbs)].forEach(([srcFB, destFB], i) => {
      const srcTexture = srcFB.color[0];
      const srcs = srcFB.color;
      commands[i]({ src: srcTexture, srcs, dest: destFB, uniforms });

      if (debug) debugLogFrame(regl, destFB);
      if (debug) logFrameStats(regl, destFB, `reduction ${i}`);
    });
  }

  return {
    reduce: reduce,
    reducedFB: pixelFB,
    debugLastFBs,
    reducedTexture: pixelTexture,
  };
}

/** @returns an array of regl commands, one to reduce each frame buffer to the next smaller size */
function reductionCmds(
  regl: Regl,
  firstSnippet: FrameReduceSnippets,
  snippets: FrameReduceSnippets,
  lastSnippet: FrameReduceSnippets,
  fbs: FullFB[],
  reduction: Vec2,
  operation: ReduceOperation,
  passName = ""
): ReduceCmd[] {
  const fbPairs = [...pairs(fbs)];
  const [firstFbPair, ...mainFbPairs] = fbPairs.slice(0, fbPairs.length - 1);
  const lastFbPair = fbPairs[fbPairs.length - 1];
  const perfPrefix = operation.concat(passName);

  const firstCmd = oneReduceCmd(
    regl,
    firstSnippet,
    firstFbPair,
    reduction,
    `${perfPrefix}-1`
  );
  const lastCmd = oneReduceCmd(
    regl,
    lastSnippet,
    lastFbPair,
    reduction,
    `${perfPrefix}-${fbPairs.length}`
  );
  const mainCmds = mainFbPairs.map((fbs, i) => {
    return oneReduceCmd(regl, snippets, fbs, reduction, `${perfPrefix}-${i + 2}`);
  });
  const cmds = [firstCmd, ...mainCmds, lastCmd];
  return cmds;
}

/** @return one regl command to reduce a framebuffer to smaller one */
function oneReduceCmd(
  regl: Regl,
  snippets: FrameReduceSnippets,
  fbs: [FullFB, FullFB],
  reduction: Vec2,
  perfName: string
): ReduceCmd {
  const [src, dst] = fbs;
  const srcSize = frameBufferSize(src);
  const dstSize = frameBufferSize(dst);
  return reduceCmd(regl, snippets, srcSize, dstSize, reduction, perfName);
}

/** @return regl command to reduce a framebuffer to smaller one */
function reduceCmd(
  regl: Regl,
  snippets: FrameReduceSnippets,
  srcSize: Vec2,
  dstSize: Vec2,
  reduction: Vec2,
  perfName: string
): ReduceCmd {
  const frag = fragShader(snippets, srcSize, reduction);
  const vert = vertShader(snippets, srcSize, dstSize, reduction);
  const cmd = regl({
    vert,
    frag,
    primitive: "triangles",
    count: 6,
    attributes: {
      position: fullScreenTriangles,
    },
    depth: { enable: false, mask: false },
    uniforms: {
      src0: (_ctx, props: ReduceProps) => props.srcs[0],
      src1: (_ctx, props: ReduceProps) => props.srcs[1],
      src2: (_ctx, props: ReduceProps) => props.srcs[2],
      src3: (_ctx, props: ReduceProps) => props.srcs[3],
      src4: (_ctx, props: ReduceProps) => props.srcs[4],
      src5: (_ctx, props: ReduceProps) => props.srcs[5],
      nzTexture: (_ctx, props: ReduceProps) => props.uniforms?.nzTexture,
      bucketStats: (_ctx, props: ReduceProps) => props.uniforms?.bucketStats,
      percentile: (_ctx, props: ReduceProps) => props.uniforms?.percentile,
    },
    framebuffer: (_ctx: any, props: ReduceProps) => props.dest,
  });

  ReglPerf.registerCmd(cmd, perfName);

  return (props: ReduceProps) => {
    cmd(props);
  };
}

const defaultSnippets = {
  vertexDeclare: "",
  vertexMain: "",
};

/** @return a string containing a glsl vert shader  */
function vertShader(
  snippets: FrameReduceSnippets,
  srcSize: Vec2,
  dstSize: Vec2,
  reduction: Vec2
): string {
  const [toStartU, toStartV] = offsetToSrcStart(srcSize, reduction);
  const overSize = overSizeFactor(srcSize, dstSize, reduction);
  const { vertexDeclare, vertexMain } = replaceUndefined(snippets, defaultSnippets);

  const vert = `
    precision highp float;
    attribute vec2 position;
    varying vec2 uv;  
    ${vertexDeclare}
        
    void main() {
      gl_Position = vec4(position, 1.0, 1.0); // position range (-1, -1)
      uv = 0.5 * (position + 1.0);            // uv range (0, 1)
      uv *= ${overSize};

      ${vertexMain}
      
      uv.x -= ${toStartU};     // move from center of src texel group
      uv.y -= ${toStartV};     // . to center of lower left texel
    }`;
  return vert;
}

/**
 * @returns offsets to align uv to the lower left corner of the src texel patch
 * rather than the center of the patch
 *
 * A larger src texel patch is mapped to each dst pixel (fragment). The
 * patch is reduction texels in size.
 * The natural mapping from position to uv maps uv for the fragment to
 * center of the src patch. This routine returns translation to
 * move uv to the corner of the patch.
 *
 * The appropriate translation is half a texel for a x2 reduction, 1.5 texels for x4
 * reduction, extending linearly from there.
 */
function offsetToSrcStart(srcSize: Vec2, reduction: Vec2): [string, string] {
  const srcPixelWidth = 1 / srcSize[0]; // texel size in uv (0,1) coordinates
  const toU = (srcPixelWidth * (reduction[0] - 1)) / 2;
  const toStartU = sprintf("%.6f", toU);

  const srcPixelHeight = 1 / srcSize[1];
  const toV = (srcPixelHeight * (reduction[1] - 1)) / 2;
  const toStartV = sprintf("%.6f", toV);
  return [toStartU, toStartV];
}

/** @returns a glsl vec2 string to scale uv for dst frame buffers that are not an even
 * size reduction from the src texture.
 *
 * uv will then range a bit past 1.0 in the last row or column of dst fragments.
 * The fragment shader than skips src texels with values greater than 1.0.
 */
function overSizeFactor(srcSize: Vec2, dstSize: Vec2, reduction: Vec2): string {
  const overSizeX = (dstSize[0] * reduction[0]) / srcSize[0];
  const overSizeY = (dstSize[1] * reduction[1]) / srcSize[1];
  const ox = sprintf("%.6f", overSizeX);
  const oy = sprintf("%.6f", overSizeY);
  return `vec2(${ox},${oy})`;
}

/** @returns a fragment shader string */
function fragShader(
  snippets: FrameReduceSnippets,
  srcSize: Vec2,
  reduction: Vec2
): string {
  const [xCount, yCount] = reduction;
  const srcTexelSizeX = sprintf("%.4f", 1 / srcSize[0]);
  const srcTexelSizeY = sprintf("%.4f", 1 / srcSize[1]);
  const { pragma, declare, accumulate, initialize, assignColor } = snippets;
  const frag = `
    ${pragma || ""}
    precision highp float;
    uniform sampler2D src0;
    varying vec2 uv;  
    ${declare}

    void sampleRow(vec2 left) {
      vec2 spot = left;
      for (int x = 0; x < ${xCount}; x++) {
        if (spot.x <= 1.0) {
          vec4 srcTexel = texture2D(src0, spot);
          ${accumulate}
          spot.x += ${srcTexelSizeX};
        }
      }
    }

    void sampleAllRows(vec2 topLeft) {
      vec2 left = topLeft;
      for (int y = 0; y < ${yCount}; y++) {
        if (left.y <= 1.0) {
          sampleRow(left);
          left.y += ${srcTexelSizeY};
        }
      }
    }

    void main() {
      ${initialize}
      sampleAllRows(uv);
      
      ${assignColor}
    }
  `;
  return frag;
}

/** @returns snippets of glsl code for the frag shader */
function fragSnippets(
  operation: ReduceOperation
): [FrameReduceSnippets, FrameReduceSnippets, FrameReduceSnippets] {
  switch (operation) {
    case "max":
      return [maxSnippets, maxSnippets, maxSnippets];
    case "nzStats":
      return [nzFirstSnippets("r"), nzSnippets, nzSnippets];
    case "nzStatsG":
      return [nzFirstSnippets("g"), nzSnippets, nzSnippets];
    case "percentile":
      return [percentileStartBuckets("r"), percentileAccumulate, percentileLast];
    case "percentileG":
      return [percentileStartBuckets("g"), percentileAccumulate, percentileLast];
  }
}

/** debug logging */
function logFrameStats(regl: Regl, fb: FullFB, prefix: string): void {
  const pixels = fetchPixels(regl, fb);
  const stats = Debug.pixelStats(pixels);
  console.log(`${prefix}: ${stats}`);
}

function debugLogFrame(regl: Regl, fb: FullFB): void {
  const size = frameBufferSize(fb);
  const pixels = fetchPixels(regl, fb);
  Debug.printPixels(pixels, size, "g");
}
