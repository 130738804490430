import { Snackbar, SnackbarOrigin } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useCallback } from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";

const anchorOrigin: SnackbarOrigin = { vertical: "bottom", horizontal: "left" };

export function Notification(): JSX.Element {
  const notification = useStoreState((app) => app.view.notificationMessage);
  const modifyView = useStoreActions((app) => app.modifyView);

  const onClose = useCallback(() => {
    modifyView({ notificationMessage: null });
  }, [modifyView]);

  if (notification) {
    const { message, severity = "info" } = notification;
    return (
      <Snackbar open={true} {...{ onClose, anchorOrigin }} autoHideDuration={7000}>
        <Alert {...{ color: severity, severity }} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
}
