import * as d3 from "d3";
import { PlotArea } from "../chart/plotArea";
import { Vec2 } from "../math/Vec";

export interface PlotCanvasProps {
  miniCanvas?: boolean;
  miniBorder?: boolean;
}

export type SelectionCanvas = d3.Selection<HTMLCanvasElement, any, any, any>;

/**
 * Revise the size of the HTML canvas for webgl plots. Create the canvas it doesn't exist.
 */
export function plotCanvas(
  plotArea: PlotArea,
  debugMiniCanvas = false
): HTMLCanvasElement {
  const plotSelect = plotArea.area,
    plotSize: Vec2 = plotArea.size(), // size in css 'pixels'
    canvasPixels = plotSize.map((v) => Math.round(v * window.devicePixelRatio)) as Vec2; // size in raw pixels

  let canvasSel = selectCanvas(plotArea);

  if (!canvasSel.size()) {
    // create canvas only if doesn't already exist
    canvasSel = plotSelect
      .append("foreignObject")
      .lower()
      .classed("webgl-container", true)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", plotSize[0])
      .attr("height", plotSize[1])
      .append("xhtml")
      .append("canvas")
      .attr("x", 0)
      .attr("y", 0)
      .classed("webgl-plots", true);
  }

  canvasSel // always reset size in case forceSizeDebug set it too small
    .style("width", `${plotSize[0]}px`)
    .style("height", `${plotSize[1]}px`)
    .attr("width", canvasPixels[0])
    .attr("height", canvasPixels[1]);

  const canvas = canvasSel.node()! as HTMLCanvasElement;

  if (debugMiniCanvas) miniSize(canvas, plotSize);
  return canvas;
}

export function selectCanvas(plotArea: PlotArea): SelectionCanvas {
  return plotArea.area.select("canvas.webgl-plots") as SelectionCanvas;
}

function miniSize(canvas: HTMLCanvasElement, plotSize: Vec2): void {
  const side = Math.min(...plotSize),
    style = canvas.style;
  style.width = `${side}px`;
  style.height = style.width;
  style.imageRendering = "pixelated";
  canvas.width = 10;
  canvas.height = 10;
}
