import * as deNumbers from "cldr-data/main/de/numbers.json";
import * as enNumbers from "cldr-data/main/en/numbers.json";
import * as frNumbers from "cldr-data/main/fr/numbers.json";
import * as likelySubtags from "cldr-data/supplemental/likelySubtags.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import currency from "currency.js";
import Globalize from "globalize";

/** parsers for numbers.
 * We parse a couple of std int'l formats.
 * For local integer and float formats,
 *   we parse an extended set of strings including "None", "-", etc.
 *   The extended float and int parsers are kept separate because parsing is a performance hotspot.
 */
export type NumberFormat =
  | "boolean"
  | "integer"
  | "float"
  | "en-US"
  | "de-DE"
  | "fr-FR"
  | "SpecialInteger"
  | "SpecialFloat"
  | "percent"
  | "currency";

export type NumberParser = (numberString: string) => number;

const localeParsers = setupLocaleParsers();
const allParsers: Map<NumberFormat, NumberParser> = new Map([
  ...localeParsers.entries(),
  ["boolean", (s) => parseBoolean(s)],
  ["integer", (s) => Number.parseInt(s)],
  ["float", (s) => Number.parseFloat(s)],
  ["SpecialInteger", specialIntegerParser],
  ["SpecialFloat", specialFloatParser],
  ["percent", percentParser],
  ["currency", currencyParser],
]);

/** check to see if the string can be parsed as a number.
 * @return a key to a number parser */
export function detectNumber(numStr: string): NumberFormat | undefined {
  if (numStr === undefined || numStr === "") return undefined;

  if (isInteger(numStr)) {
    return "integer";
  }
  if (isStandardFloat(numStr)) {
    return "float";
  }
  if (isSpecialInteger(numStr)) {
    return "SpecialInteger";
  }
  if (isSpecialFloat(numStr)) {
    return "SpecialFloat";
  }
  if (isBoolean(numStr)) {
    return "boolean";
  }
  const localFound = [...localeParsers.entries()].find(([, parser]) => {
    return !isNaN(parser(numStr));
  });
  if (localFound) {
    const [name] = localFound;
    return name;
  }
  if (isCurrency(numStr)) {
    return "currency";
  }
  if (isPercent(numStr)) {
    return "percent";
  }

  return undefined;
}

export function getNumberParser(format: NumberFormat): NumberParser {
  const parser = allParsers.get(format);
  if (!parser) {
    throw new Error(`number parser for format: ${format} not found`);
  }
  return parser;
}

function setupLocaleParsers(): Map<NumberFormat, NumberParser> {
  const locales: NumberFormat[] = ["en-US", "de-DE", "fr-FR"];
  Globalize.load(likelySubtags);
  Globalize.load(deNumbers);
  Globalize.load(enNumbers);
  Globalize.load(frNumbers);
  Globalize.load(numberingSystems);
  const entries: [NumberFormat, NumberParser][] = locales.map((locale) => {
    Globalize.locale(locale);
    const baseParser = Globalize.numberParser();
    const parser = (text: string): number => baseParser(text.trim());
    return [locale, parser];
  });

  return new Map(entries);
}

const specialIntegerStrings = ["-", "None", "Not Available", "NA", "N/A", "nan"];
const specialFloatStrings = ["Inf", "-Inf", "-", "None"];
const specialFloatMap = new Map<string, number>([
  ["Inf", Infinity],
  ["-Inf", -Infinity],
]);

function specialFloatParser(numStr: string): number {
  return specialFloatMap.get(numStr) || Number.parseFloat(numStr);
}

function isSpecialFloat(numStr: string): boolean {
  return specialFloatStrings.includes(numStr);
}

const simpleFloat = /^\s*[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?\s*$/;
const nonFiniteFloats = ["Infinity", "-Infinity", "NaN"];

function isStandardFloat(numStr: string): boolean {
  return simpleFloat.test(numStr) || nonFiniteFloats.indexOf(numStr) !== -1;
}

const integer = /^\s*[-+]?[0-9]+\s*$/;

function isInteger(numStr: string): boolean {
  return integer.test(numStr);
}

function isSpecialInteger(numStr: string): boolean {
  return specialIntegerStrings.indexOf(numStr) !== -1;
}

function specialIntegerParser(numStr: string): number {
  return specialFloatMap.get(numStr) || Number.parseFloat(numStr);
}

const boolTrue = ["true", "yes"];
const boolFalse = ["false", "no"];
const booleans = [...boolTrue, ...boolFalse];

function isBoolean(numStr: string): boolean {
  const s = numStr.toLowerCase();
  return booleans.indexOf(s) !== -1;
}

function parseBoolean(numStr: string): number {
  const s = numStr.toLowerCase();
  if (boolTrue.indexOf(s) !== -1) {
    return 1;
  } else if (boolFalse.indexOf(s) !== -1) {
    return 0;
  } else {
    return NaN;
  }
}

const accountingNegative = /^\$\([0-9.,]+\)$/; // "$ (123,000)"
const usCurrency = /^[-+]?\$[-+]?[0-9.,]+$/; // "$123.00"
const noneCurrency = /^\$-$/; // "$ -"

function isCurrency(numStr: string): boolean {
  const noSpaces = numStr.replaceAll(" ", "");
  return (
    usCurrency.test(noSpaces) ||
    noneCurrency.test(noSpaces) ||
    accountingNegative.test(noSpaces)
  );
}

function currencyParser(numStr: string): number {
  return currency(numStr).value;
}

const percent = /^\s*[-+]?\s*[0-9,.]+\s*%$/;
function isPercent(numStr: string): boolean {
  return percent.test(numStr);
}

function percentParser(numStr: string): number {
  const percentDex = numStr.lastIndexOf("%");
  if (percentDex < 0) {
    return NaN;
  }
  const percentStr = numStr.slice(0, percentDex);
  const noSeps = percentStr.replaceAll(",", "");
  const noSpaces = noSeps.replaceAll(" ", "");
  return Number.parseFloat(noSpaces) / 100;
}
