import _ from "lodash";
import { Buffer, Regl } from "regl";
import { ColumnArray, FrameAndSet, frameXydColumns } from "../data/ColumnFrame";
import { identityFn } from "../util/Utils";

export interface LineBuffers {
  xPlus: Buffer; // x values with the last item repeated
  yPlus: Buffer; // y values with the last item repeated
  yDispersion?: Buffer; // matches y values
  lines: number;
}

export function setupLineBuffers(
  regl: Regl,
  frameAndSet: FrameAndSet,
  normX: (t: number) => number
): LineBuffers {
  if (!frameAndSet.columnSet) {
    const emptyBuffer = regl.buffer([]);
    return {
      xPlus: emptyBuffer,
      yPlus: emptyBuffer,
      lines: 0,
    };
  }

  const { xs, ys, ds, lines } = lineBuffersData(frameAndSet, normX);

  return {
    xPlus: floatBuffer(regl, xs),
    yPlus: floatBuffer(regl, ys),
    yDispersion: ds && floatBuffer(regl, ds),
    lines,
  };
}

export function floatBuffer(regl: Regl, data: Float32Array): Buffer {
  return regl.buffer({
    data,
    type: "float32",
  });
}

export interface LineBuffersData {
  xs: Float32Array;
  ys: Float32Array;
  ds?: Float32Array; // dispersion
  lines: number;
}

interface XydData {
  x: ColumnArray;
  y: ColumnArray;
  d: ColumnArray | undefined;
}

export function lineBuffersData(
  frameAndSet: FrameAndSet,
  normX: (t: number) => number
): LineBuffersData {
  const seriesXYD = frameXydColumns(frameAndSet); // array of 2 or 3 columns per series

  // array of 2 or 3 arrays per series
  const seriesXydData: XydData[] = seriesXYD.map(([xCol, yCol, dCol]) => ({
    x: xCol.data,
    y: yCol.data,
    d: dCol?.data,
  }));

  return makeLineBufferData(seriesXydData, normX);
}

function makeLineBufferData(
  seriesXyd: XydData[],
  normX: (t: number) => number
): LineBuffersData {
  const gapSize = 1;
  const repeatSize = 1;
  const gapLengths = (seriesXyd.length - 1) * gapSize;
  const repeatLength = seriesXyd.length * repeatSize;
  const sumDataLength = _.sum(seriesXyd.map((xyd) => xyd.x.length));
  const totalLength = sumDataLength + gapLengths + repeatLength;
  const xs = new Float32Array(totalLength);
  const ys = new Float32Array(totalLength);
  const ds = seriesXyd[0].d ? new Float32Array(totalLength) : undefined;

  let index = 0;
  seriesXyd.forEach((xyd, xyDex) => {
    for (let i = 0; i < xyd.x.length; i++) {
      const x = xyd.x[i];
      const y = xyd.y[i];
      xs[index] = normX(x);
      ys[index] = y;

      if (xyd.d && ds) {
        const d = xyd.d[i];
        ds[index] = d;
      }
      index++;
    }
    // repeat last element
    ys[index] = ys[index - 1];
    xs[index] = xs[index - 1];
    if (ds) {
      ds[index] = ds[index - 1];
    }
    index++;

    if (xyDex < seriesXyd.length - 1) {
      // add gap between series
      xs[index] = NaN;
      ys[index] = NaN;
      if (ds) {
        ds[index] = NaN;
      }
      index++;
    }
  });
  console.assert(index === xs.length);

  return { xs, ys, ds, lines: xs.length - 1 }; // last elem is repeated
}

/** return a horizontal line at pixel centered positions x in (-1, 1) (exclusive of endpoints),
 *  and y == 0 */
export function singleLineData(width: number): LineBuffersData {
  const offset = 1 / width;
  const x = _.times(width).map((i) => (2 * i) / width + offset - 1);
  const y = new Array(width).fill(0);
  const seriesXydData = [{ x, y, d: undefined }];
  return makeLineBufferData(seriesXydData, identityFn);
}

/** return a horizontal line at pixel centered positions x in (-1, 1) (exclusive of endpoints),
 *  and y == 0 */
export function singleLineBuffers(regl: Regl, width: number): LineBuffers {
  const { xs, ys, ds, lines } = singleLineData(width);

  return {
    xPlus: floatBuffer(regl, xs),
    yPlus: floatBuffer(regl, ys),
    yDispersion: ds && floatBuffer(regl, ds),
    lines,
  };
}
