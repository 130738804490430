import _ from "lodash";
import { ChartEditMode } from "../store/EditChartData";
import { ColumnSet } from "../store/TableSource";
import { dlog } from "../util/DebugLog";
import * as Utils from "../util/Utils";
import { transpose } from "../util/Utils";
import { ColumnChunk } from "./DataChunk";
import {
  addChunksNewColumns,
  addColumnChunks,
  columnSection,
  deleteColumn,
  findColumnsByLabel,
} from "./DbColumns";
import {
  ColumnTableInfo,
  dbStore,
  indexColumnId,
  StoredColumn,
  StoredColumnInfo,
  TableId,
} from "./DbStore";

export interface TableResult {
  name: string;
  tableId: TableId;
  newTable: boolean;
}

/** @return the names and ids of the tables in the store */
export async function storeTables(): Promise<ColumnTableInfo[]> {
  const tables = await dbStore.columnTables.toArray();
  const infos: ColumnTableInfo[] = tables.map(({ id, name }) => ({ tableId: id!, name }));
  return infos;
}

export async function findTable(name: string): Promise<TableId | undefined> {
  const found = await dbStore.columnTables.where("name").equals(name).toArray();
  return _.first(found)?.id;
}

/** Store a new table in the store
 * @return the uniquified name and table id of the new table.
 */
export async function newColumnTable(baseName: string): Promise<ColumnTableInfo> {
  const found = await dbStore.columnTables.where("baseName").equals(baseName).toArray();
  let nameUsage;
  if (found.length > 0) {
    found.sort((a, b) => (b.nameUsage || 0) - (a.nameUsage || 0));
    const max = found[0].nameUsage || 0;
    nameUsage = max + 1;
  }
  const name = nameUsage ? `${baseName}-${nameUsage.toString()}` : baseName;
  const tableId = (await dbStore.columnTables.add({
    baseName,
    name,
    nameUsage,
  })) as TableId;
  return { tableId, name };
}

export async function deleteTable(tableId: TableId): Promise<void> {
  const found = await dbStore.columnTables.get(tableId);
  if (found) {
    const columns = await dbStore.columns.where("tableId").equals(tableId).toArray();
    for (const col of columns) {
      if (col.id) {
        await deleteColumn(col.id);
      }
    }
    await dbStore.columnTables.delete(tableId);
  }
}

/** @return all of the columns in the store */
export async function tableColumns(tableId: TableId): Promise<StoredColumnInfo[]> {
  const entries = await dbStore.columns.where("tableId").equals(tableId).toArray();
  const infos = entries.map((entry) => {
    const { tableId, id, ...info } = entry;
    return { ...info, columnId: id! };
  });
  const size = await tableRowCount(tableId);
  const rowVirtualColumn: StoredColumnInfo = {
    columnId: indexColumnId,
    size,
    label: "#",
    storageType: "float64",
    displayType: "number",
  };

  return [rowVirtualColumn, ...infos];
}

/** by default, we'll plot the first date or numeric columns */
export async function likelyPlotColumns(
  tableId: TableId
): Promise<ColumnSet | undefined> {
  const cols = await tableColumns(tableId);

  const possibleColumns = cols.filter((col) =>
    ["date", "number"].includes(col.displayType)
  );
  if (possibleColumns.length <= 1) {
    return undefined;
  } else if (possibleColumns.length === 2) {
    const columnId = possibleColumns.find((c) => c.columnId !== indexColumnId)!.columnId;
    return { xColumn: indexColumnId, yColumns: [columnId] };
  } else {
    const regularColumns = possibleColumns.filter((c) => c.columnId !== indexColumnId);
    return choosePreferredColumns(regularColumns);
  }
}

function choosePreferredColumns(cols: StoredColumnInfo[]): ColumnSet | undefined {
  // use date format as first column if possible
  let firstDex = cols.findIndex((col) => col.displayType === "date");
  if (firstDex < 0) {
    firstDex = cols.findIndex((col) => col.displayType === "number");
  }
  if (firstDex < 0) {
    return undefined;
  }

  // choose second column as number
  const second = _.find(cols, (col, i) => col.displayType === "number" && i !== firstDex);
  if (!second) {
    return undefined;
  }
  const first = cols[firstDex];
  return { xColumn: first.columnId, yColumns: [second.columnId] };
}

export async function tableRowCount(tableId: TableId): Promise<number> {
  const columns = await dbStore.columns.where("tableId").equals(tableId).toArray();
  return rowCountFromColumns(columns);
}

function rowCountFromColumns(columns: StoredColumn[]): number {
  const sizes = columns.map((column) => column.size || 0);
  return _.max(sizes) || 0;
}

export type RowData = number | string;

/** @return a subset of rows in the table */
export async function tableRows(tableId: TableId, maxRows = 100): Promise<RowData[][]> {
  const storedColumns = await dbStore.columns.where("tableId").equals(tableId).toArray();
  const columnIds = storedColumns.map((entry) => entry.id!);
  const futureSlices = columnIds.map((columnId) => columnSection(columnId, maxRows));
  const columnSlices = await Promise.all(futureSlices);
  const rowCount = _.max(columnSlices.map((c) => c.length)) || 0;

  const indexData = new Float64Array(rowCount);
  for (const i of Utils.count(rowCount)) {
    indexData[i] = i + 1;
  }
  const allSlices = [indexData, ...columnSlices];
  const rows = transpose(allSlices as (number | string)[][]);

  if (rows === undefined) {
    return Promise.reject(new Error(`table {tableId} not square`));
  } else {
    return rows;
  }
}

export async function saveIntoTableExternal(
  chunks: ColumnChunk[],
  tableName: string,
  edit: ChartEditMode,
  url: string
): Promise<TableResult> {
  const { chunks: dbChunks, columnTables, cachedTables, columns } = dbStore;
  return dbStore.transaction(
    "rw",
    dbChunks,
    columnTables,
    cachedTables,
    columns,
    async () => {
      const result = await saveIntoTable(chunks, tableName, edit);
      const { tableId } = result;
      await dbStore.cachedTables.add({ url, tableId });
      return result;
    }
  );
}

export async function saveIntoTable(
  chunks: ColumnChunk[],
  tableName: string,
  edit: ChartEditMode
): Promise<TableResult> {
  // TODO transaction?
  let result = await chooseTable(tableName, edit);
  const { tableId, newTable } = result;
  if (edit === "append" && !newTable) {
    const chunksWithIds = await findColumnsByLabel(tableId, chunks).catch(() => null);
    if (chunksWithIds) {
      await addColumnChunks(chunksWithIds);
    } else {
      const newTableInfo = await newColumnTable(tableName);
      await addChunksNewColumns(newTableInfo.tableId, chunks);
      result = { ...newTableInfo, newTable: true };
    }
  } else {
    await addChunksNewColumns(tableId, chunks);
  }
  return result;
}

async function chooseTable(tableName: string, edit: ChartEditMode): Promise<TableResult> {
  if (!(edit === "forceNew")) {
    const tableId = await findTable(tableName);
    if (tableId) {
      if (edit === "replace") {
        dlog("delete", { tableName });
        await deleteTable(tableId);
      } else {
        console.assert(edit === "append");
        return { tableId, name: tableName, newTable: false };
      }
    }
  }
  return newColumnTable(tableName).then((t) => ({ ...t, newTable: true }));
}
