import { TimeRate } from "./FramesPerSecond";

export interface MiniChart {
  update: (rates: TimeRate[]) => void;
  destroy: () => void;
}

export function miniChart(historyLength: number, maxFPS: number): MiniChart {
  const width = 400;
  const height = 100;
  const barWidth = width / historyLength;
  const refreshRate = 20;
  const refreshTime = 1000 / refreshRate;

  const div = chartDiv(document.body, width, height);
  const ctx = appendCanvas(div);
  const fpsSpan = appendFpsText(div);

  let lastDraw = 0;

  return {
    update,
    destroy,
  };

  function appendCanvas(div: HTMLDivElement): CanvasRenderingContext2D {
    const canvas = document.createElement("canvas");
    canvas.setAttribute("width", width.toString()); // get via computedContext
    canvas.setAttribute("height", height.toString());
    div.appendChild(canvas);
    return canvas.getContext("2d")!;
  }

  function destroy(): void {
    document.body.removeChild(div);
  }

  function update(timeRates: TimeRate[]): void {
    const now = performance.now();
    const time = now - lastDraw;
    if (time < refreshTime) {
      return;
    }
    lastDraw = now;

    updateBars(timeRates);
    const lastTimeRate = timeRates[timeRates.length - 1];
    const lastRate = lastTimeRate ? lastTimeRate[1] : 0;
    const trimmedRate = Math.min(maxFPS, lastRate);
    updateFpsText(fpsSpan, trimmedRate);
  }

  function updateBars(timeRates: TimeRate[]): void {
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);
    const windowMs = 16 * 60;
    let separators = 0;
    let lastPartition = 0;
    const realTimeRates = timeRates.filter((x) => x !== undefined);

    realTimeRates.some(([time, fps], i) => {
      const percentFPS = Math.min(1, fps / maxFPS);
      const barHeight = height * percentFPS;
      const x = (i + separators) * barWidth;
      ctx.fillStyle = colorRamp(percentFPS);
      ctx.fillRect(x, height - barHeight, barWidth, barHeight);

      const partition = Math.trunc(time / windowMs);
      if (partition != lastPartition) {
        lastPartition = partition;
        separators++;
        ctx.fillStyle = "black";
        ctx.fillRect(x + barWidth, height - barHeight, barWidth, barHeight);
      }
    });
  }
}

function updateFpsText(fpsSpan: HTMLSpanElement, lastFPS: number): void {
  fpsSpan.textContent = Math.round(lastFPS).toString();
}

function chartDiv(container: HTMLElement, width: number, height: number): HTMLDivElement {
  const div = document.createElement("div");
  const { style } = div;
  style.width = `${width.toString()}px`;
  style.height = `${height.toString()}px`;
  style.position = "fixed";
  style.bottom = "0px";
  style.right = "0px";
  style.zIndex = "2000";
  style.pointerEvents = "none";
  container.appendChild(div);
  return div;
}

function appendFpsText(div: HTMLDivElement): HTMLSpanElement {
  const labelValue = document.createElement("span");
  const labelSpan = document.createElement("span");
  const valueSpan = document.createElement("span");
  labelValue.style.position = "absolute";
  labelValue.style.top = "40%";
  labelValue.style.left = "10%";

  labelValue.style.fontSize = "18px";
  labelValue.style.fontWeight = "bold";
  labelValue.style.color = "white";
  labelValue.style.textShadow = "0 0 4px black";

  labelSpan.textContent = "FPS";
  valueSpan.style.marginLeft = "20px";
  valueSpan.style.fontSize = "24px";

  labelValue.appendChild(labelSpan);
  labelValue.appendChild(valueSpan);
  div.appendChild(labelValue);

  return valueSpan;
}

const green = [100, 100, 25];
const red = [0, 93, 23];

function colorRamp(percent: number): string {
  const hue = lirp(red[0], green[0], percent);
  const saturation = lirp(red[1], green[1], percent);
  const lightness = lirp(red[2], green[2], percent);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

function lirp(a: number, b: number, percent: number): number {
  return a + (b - a) * percent;
}
