import { makeStyles, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { invertMap } from "../../util/Utils";
import { LabeledCheckBox } from "./LabeledCheckbox";

export interface EnabledDataSources {
  generated: boolean;
  local: boolean;
  external: boolean;
}

const fieldNames: (keyof EnabledDataSources)[] = ["generated", "local"];

const useStyles = makeStyles({
  checkBox: {
    display: "block",
    marginLeft: 10,
  },
  sourcesLabel: {
    fontWeight: "bold",
    marginBottom: 10,
  },
});

export const SelectDataSources = React.memo(SelectDataSourcesInternal);

const labels = new Map<keyof EnabledDataSources, string>([
  ["generated", "generating functions"],
  ["local", "stored in browser database"],
]);
const labelToKey = invertMap(labels);

function SelectDataSourcesInternal(): JSX.Element {
  const classes = useStyles();
  const sources = useStoreState((state) => state.data.enabledSources);
  const setEnabledSource = useStoreActions((actions) => actions.data.setEnabledSource);

  const changeSourceEnabled = useCallback(
    (name: string, value: boolean) => {
      const key = labelToKey.get(name);
      if (key) {
        setEnabledSource({ [key]: value });
      }
    },
    [setEnabledSource]
  );

  const checkBoxes = fieldNames.map((sourceKind) => (
    <LabeledCheckBox
      className={classes.checkBox}
      key={sourceKind}
      label={labels.get(sourceKind) || ""}
      onChangeValue={changeSourceEnabled}
      checked={sources[sourceKind]}
    />
  ));

  return (
    <div>
      <span>
        <Typography className={classes.sourcesLabel} display="inline">
          Enabled data sources
        </Typography>
      </span>
      {checkBoxes}
    </div>
  );
}
