import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Chart, ChartProps, Destroyable } from "./chart/Chart";
import { useResizeContext } from "./components/dash-layout/ResizeContext";
import { useStoreState } from "./store/Hooks";
import { usePrevious } from "./util/ReactUtil";

export type ChartReactProps = ChartProps;

export default function ChartReact(props: ChartReactProps): JSX.Element {
  const container = React.useRef<HTMLDivElement>(null);
  const chartRef = React.useRef<Destroyable>();
  const oldProps = usePrevious(props);
  const resize = useResizeContext();
  const rearrangeMode = useStoreState((app) => app.view.rearrangeMode);
  const dirty = useRef(false);

  useEffect(() => {
    return () => {
      chartRef.current?.destroy();
    };
  }, []);

  let draw = false;
  if (container.current && (resize || !_.isEqual(oldProps, props))) {
    if (resize) {
      dirty.current = true;
    }
    draw = true;
  }
  if (dirty.current && !rearrangeMode) {
    chartRef.current?.destroy();
    dirty.current = false;
    draw = true;
  }
  if (draw) {
    chartRef.current = Chart(container.current, props);
  }

  return (
    <div ref={container} data-qa="chart-container" className="chart-container"></div>
  );
}
