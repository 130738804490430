import _ from "lodash";
import numeral from "numeral";
import sprintfjs from "sprintf-js";
import { qaPush } from "./QaApi";
import { pairs } from "./Utils";
const sprintf = sprintfjs.sprintf;

interface TimeFn {
  <T>(name: string, fn: () => T): T;
}
export let time: TimeFn;

export function mark(name: string): void {
  performance.mark(name);
  qaPush(name, performance.now());
}

function markFrequency(name: string): void {
  const reports = performance.getEntriesByName(name);
  const times = [...pairs(reports)].map((entry) => {
    const [a, b] = entry;
    return b.startTime - a.startTime;
  });
  const count = times.length;
  const sum = _.sum(times);
  const average = sum / count;
  const named = sprintf("%15s", name);
  console.log(`${named}  time.avg: ${msec(average)}  count: ${count}`);
}

export function measureReport(): void {
  const reports = performance.getEntriesByType("measure"),
    groups = _.groupBy(reports, (entry) => entry.name),
    summaries = _.entries(groups).map(([name, perfEntries]) => {
      const count = perfEntries.length,
        sum = perfEntries.reduce((total, perf) => {
          return total + perf.duration;
        }, 0),
        average = sum / count;
      return { name, count, average };
    }),
    report = summaries.map((entry) => {
      const { name, count, average } = entry,
        named = sprintf("%15s", name);

      return `${named}  cpuTime.avg: ${msec(average)}  count: ${count}`;
    });

  console.log(report.join("\n"));

  markFrequency("startFrame"); // LATER make this dynamic
  performance.clearMeasures();
  performance.clearMarks();
}

export function bytes(n: number): string {
  return numeral(n).format("0,0") + " bytes";
}

export function msec(n: number): string {
  return numeral(n).format("0,0[.]00") + "ms";
}

// set time function for browser vs node

if (typeof performance !== "undefined") {
  time = function <T>(name: string, fn: () => T): T {
    const startTime = performance.now();
    performance.mark(name);
    const result = fn();
    performance.measure(name, name);
    const duration = performance.now() - startTime;
    qaPush(name, duration);
    return result;
  };
} else {
  // in case we're running on node
  time = function <T>(name: string, fn: () => T): T {
    return fn();
  };
}
