import { ChartFrame } from "../components/rich-chart/DashChart";
import { columnFrame, ColumnId } from "../data/ColumnFrame";
import { FrameStreamAndColumns } from "../data/WatchData";
import { Vec2 } from "../math/Vec";

/** return an asynchronously generated series of columnFrames
 * the columnframes contain x,y data for a sin function */
export function sinStream(
  maxRows: number | undefined,
  timeout = 10
): Promise<FrameStreamAndColumns> {
  const xColumn = -99 as ColumnId;
  const yColumn = -98 as ColumnId;
  const xys = sinSlowly(timeout, maxRows);
  const frameStream = framesFromStream(xys, [xColumn, yColumn]);
  const frameStreamAndColumns = {
    frameStream,
    columnSet: { xColumn, yColumns: [yColumn] },
  };
  return Promise.resolve(frameStreamAndColumns);
}

async function* framesFromStream(
  xys: AsyncGenerator<Vec2, void, unknown>,
  colIds: [ColumnId, ColumnId]
): AsyncGenerator<ChartFrame, void> {
  const [xId, yId] = colIds;
  const xs: number[] = [];
  const ys: number[] = [];

  for await (const xy of xys) {
    xs.push(xy[0]);
    ys.push(xy[1]);
    const cf = columnFrame();
    cf.addColumn(xs, "x", "number", xId);
    cf.addColumn(ys, "y", "number", yId);

    const chartFrame: ChartFrame = {
      frame: cf,
    };

    yield chartFrame;
  }
}

/** return an asynchronously generated sin function */
async function* sinSlowly(
  timeout: number,
  maxRows = 100
): AsyncGenerator<Vec2, void, unknown> {
  let x = 0;
  let count = 0;
  while (count++ < maxRows) {
    const t: Vec2 = [x, Math.sin(x)];
    yield t;
    await sleep(timeout);
    x += Math.PI / 10;
  }
}

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
